import React from "react";

function Quimicos2() {
  const division = "Químicos";
  const familias = [
    "PVA",
    "Epóxicos",
    "Poliuretanos",
    "Solventes",
    "Químicos de Reacción",
    "Plastificantes",
    "Especialidades",
  ];
  const styles = {
    family:
      "w-[400px] text-[#fff] bg-[#9a3324] rounded-full font-bold text-3xl h-[80px] mx-6 my-2 hover:text-[#154734] hover:bg-[#c8c6c6] flex items-center justify-center",
  };
  return (
    <div>
      <h1 className="text-center pt-10 font-semibold text-[#154734] text-5xl">
        {division.toUpperCase()}
      </h1>
      <div className="flex flex-row justify-center">
        <div className="w-[50%] rounded-full flex flex-col items-center justify-center mx-2">
          <img
            src="/circulo_quimicos.png"
            alt="circulo_químicos"
            className="w-[100%]"
          />
        </div>
        <div className="flex flex-col justify-center items-center">
          <a href={`/Familias/${familias[0]}`}>
            <div className={styles.family}>{familias[0].toUpperCase()}</div>
          </a>
          <a href={`/Familias/${familias[1]}`}>
            <div className={styles.family}>{familias[1].toUpperCase()}</div>
          </a>
          <a href={`/Familias/${familias[2]}`}>
            <div className={styles.family}>{familias[2].toUpperCase()}</div>
          </a>
          <a href={`/Familias/${familias[3]}`}>
            <div className={styles.family}>{familias[3].toUpperCase()}</div>
          </a>
          <a href={`/Familias/${familias[4]}`}>
            <div className={styles.family}>{familias[4].toUpperCase()}</div>
          </a>
          <a href={`/Familias/${familias[5]}`}>
            <div className={styles.family}>{familias[5].toUpperCase()}</div>
          </a>
          <a href={`/Familias/${familias[6]}`}>
            <div className={styles.family}>{familias[6].toUpperCase()}</div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Quimicos2;
