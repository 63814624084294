import React, { useState } from "react";

function Mapa3() {
  const [estado, setEstado] = useState("CDMX");
  const styles = {
    clickable:
      "fill-[#154734] hover:fill-[#9a3324] cursor-pointer transition ease-in-out delay-150",
	  nonClickable:
	  "fill-[#154734]"
  };

  const renderSwitch = (estado) => {
    switch (estado) {
      case "CDMX":
        return ( <><h1 className="text-4xl text-[#9a3324] font-black">
        Ciudad de México
      </h1>
      <p>
        Av. Insurgentes Norte No.1501
        <br />
        Int. 203 Col. Tepeyac Insurgentes,
        <br />
        G.A.M, Cd. de México, C.P. 07020
      </p>
     
      <p className="text-xl text-[#9a3324] font-semibold">
      <a href="tel:5551180800"> Tel: (55) 5118 0800</a>
      </p></>);
      case "NuevoLeon":
        return (<><h1 className="text-4xl text-[#9a3324] font-extrabold">Nuevo León</h1>
        <p>
          Cda. Calidad No. 100-A
          <br />
          Col. Parque Ind. PROMOFISA,
          <br />
          Guadalupe, Nvo. León, C.P. 67110
        </p>
        <p className="text-xl text-[#9a3324] font-semibold">
        <a href="tel:8183361244">Tel: (81) 8336 1244</a>
          <br />
          <a href="tel:8183361812">Tel: (81) 8336 1812</a>
        </p></>);
      case "Jalisco":
        return (<> <h1 className="text-4xl text-[#9a3324] font-extrabold">Jalisco</h1>
        <p>
          Av. San Vicente No. 1090
          <br />
          Col. La Providencia
          <br />
          Tonalá, Jalisco, C.P. 45400
        </p>
        <p className="text-xl text-[#9a3324] font-semibold">
        <a href="tel:3338109020">Tel: (33) 3810 9020</a>
          <br />
          <a href="tel:3338111375">Tel: (33) 3811 1375</a>
          <br />
          <a href="tel:3338122501">Tel: (33) 3812 2501</a>
        </p></>);
      case "Queretaro":
        return (<><h1 className="text-4xl text-[#9a3324] font-extrabold">Querétaro</h1>
        <p>
          Carr. Querétaro-México Km 201.5
          <br />
          Bodega marcada 116, El Marqués
          <br />
          Querétaro, C.P. 76240
        </p>
        <p className="text-xl text-[#9a3324] font-semibold">
        <a href="tel:5551180800">Tel: (55) 5118 0800</a>
        </p></>);
      case "Hidalgo":
        return (<> <h1 className="text-4xl text-[#9a3324] font-extrabold">Hidalgo</h1>
        <p>
          Av. de las diligencias No.197
          <br />
          Bodega 1 Col. Tepojaco,
          <br />
          Tizayuca, Hidalgo, C.P. 43823
        </p>
        <p className="text-xl text-[#9a3324] font-semibold">
        <a href="tel:5551180826">Tel: (55) 5118 0826</a>
          <br />
          <a href="tel:5551180828">Tel: (55) 5118 0828</a>
          <br />
          <a href="tel:5551180829">Tel: (55) 5118 0829</a>
        </p></>);
      case "Guanajuato":
        return (<> <h1 className="text-4xl text-[#9a3324] font-extrabold">Guanajuato</h1>
        <p>
          Carr. 45 Irapuato-Silao
          <br />
          Km. 144 + 300 Bodega 11,
          <br />
          Silao de la Victoria, Gto. C.P. 36294
        </p>
        <p className="text-xl text-[#9a3324] font-semibold">
        <a href="tel:4726882714">Tel: (47) 2688 2714</a>
        </p></>);
      default:
        return "foo";
    }
  };

  return (
	<div className="flex sm:flex-row flex-col justify-center items-center">
	<div className="w-[90%]">
    <div className="relative">
      <div className="rounded-2xl bg-slate-50 border-solid border-[#c2bfbf] border-4 p-4 m-4 sm:absolute top-[20%] left-[60%]">
        {renderSwitch(estado)}
      </div>
      <div className="w-[90%]">
      <svg
        stroke-width="2"
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 411.68 299.41"
        style={{ "enable-background": "new 0 0 411.68 299.41" }}
      >
        <g fill="#c9d4cf">
          <path
            id="BajaCaliforniaSur"
            d="M103.38,140.88c-0.07-0.09-0.13-0.17-0.18-0.25c-0.1-0.16-0.15-0.33-0.2-0.48c-0.03-0.1-0.06-0.19-0.1-0.25
		c-0.14-0.23-0.41-0.37-0.73-0.49c-0.11-0.04-0.22-0.08-0.33-0.12c-0.29-0.1-0.59-0.21-0.85-0.37c-0.19-0.11-0.37-0.24-0.51-0.43
		c-0.21-0.28-0.25-0.59-0.24-0.89c0.01-0.18,0.03-0.35,0.06-0.51c0.02-0.12,0.04-0.24,0.04-0.34c0.02-0.38-0.04-0.45-0.34-0.77
		c0,0-0.01-0.01-0.01-0.01l-0.16-0.17c-0.06-0.06-0.12-0.13-0.19-0.19c-0.28-0.28-0.63-0.62-0.65-1.14
		c-0.01-0.25,0.09-0.48,0.2-0.68c0.03-0.06,0.07-0.12,0.1-0.17c0.07-0.13,0.18-0.31,0.18-0.38c0-0.05-0.01-0.07-0.01-0.07
		c-0.05,0-0.33,0.09-0.44,0.13c-0.1,0.03-0.2,0.06-0.29,0.07c-0.2,0.04-0.38,0.03-0.54-0.01c-0.08-0.02-0.15-0.05-0.22-0.09
		c-0.37-0.2-0.52-0.61-0.6-0.9c-0.05-0.18-0.08-0.34-0.11-0.48c-0.1-0.47-0.14-0.68-0.59-0.98c-0.44-0.29-0.72-0.54-0.96-0.87
		c-0.14-0.2-0.28-0.43-0.43-0.71c-0.04-0.08-0.08-0.14-0.11-0.2c-0.17-0.31-0.29-0.44-0.45-0.47c-0.04-0.01-0.09-0.01-0.14-0.01
		c-0.17,0.02-0.22,0.04-0.23,0.04c0,0-0.01,0.02-0.03,0.1c-0.01,0.04-0.01,0.07-0.01,0.11c0,0.11,0.05,0.22,0.13,0.38
		c0.09,0.19,0.18,0.39,0.21,0.66c0,0.05,0,0.1,0,0.14c0,0.02-0.01,0.03-0.01,0.04c-0.01,0.03-0.01,0.06-0.03,0.09
		c-0.01,0.02-0.02,0.03-0.02,0.05c-0.01,0.02-0.02,0.05-0.04,0.07c-0.01,0.02-0.02,0.03-0.03,0.05c-0.02,0.02-0.03,0.04-0.05,0.06
		c-0.01,0.01-0.03,0.03-0.04,0.04c-0.02,0.02-0.03,0.03-0.05,0.05c-0.01,0.01-0.03,0.03-0.04,0.04c-0.02,0.01-0.03,0.03-0.05,0.04
		c-0.02,0.01-0.03,0.03-0.05,0.04c-0.01,0.01-0.03,0.02-0.04,0.03c-0.02,0.01-0.04,0.03-0.06,0.04c-0.06,0.08-0.14,0.14-0.24,0.16
		c-0.16,0.03-0.36-0.05-0.45-0.19c-0.02-0.01-0.04-0.02-0.07-0.03c-0.01,0-0.01-0.01-0.02-0.01c-0.06-0.02-0.13-0.04-0.2-0.06
		c0,0-0.01,0-0.01,0c-0.16-0.04-0.34-0.06-0.46-0.08c-0.15-0.02-0.29-0.04-0.4-0.07c-0.69-0.15-1-0.28-1.48-0.73
		c-0.05-0.05-0.1-0.1-0.16-0.15c-0.15-0.15-0.3-0.24-0.47-0.34c-0.2-0.12-0.4-0.24-0.58-0.45c-0.36-0.42-0.67-1.13-0.72-1.66
		l-0.03-0.4c-0.03-0.42-0.06-0.78-0.15-1.22c-0.05-0.22-0.07-0.45-0.09-0.67c-0.02-0.44,0.02-0.87,0.14-1.26
		c0.06-0.2,0.13-0.38,0.22-0.55c0.09-0.18,0.2-0.34,0.3-0.5c0.23-0.35,0.41-0.63,0.4-1.02c-0.01-0.32-0.08-0.83-0.16-1.13
		c-0.02-0.09-0.13-0.24-0.23-0.36c-0.13-0.17-0.26-0.35-0.31-0.54c-0.02-0.09-0.04-0.18-0.04-0.25c0-0.08,0-0.15,0-0.21
		c0-0.04,0-0.07,0-0.1c0-0.07-0.01-0.11-0.05-0.17c-0.01-0.01-0.01-0.02-0.02-0.03c-0.05-0.06-0.16-0.1-0.28-0.15
		c-0.03-0.01-0.06-0.02-0.08-0.04c-0.12-0.05-0.25-0.11-0.37-0.21c-0.15-0.12-0.29-0.29-0.41-0.48c-0.05-0.08-0.1-0.16-0.14-0.24
		c-0.11-0.2-0.2-0.4-0.27-0.57c-0.02-0.06-0.04-0.11-0.06-0.16c-0.07-0.2-0.08-0.38-0.09-0.52c-0.01-0.1-0.01-0.18-0.03-0.27
		c-0.02-0.06-0.06-0.13-0.1-0.19c-0.01-0.01-0.02-0.03-0.03-0.04c-0.07-0.1-0.14-0.22-0.19-0.37c-0.02-0.07-0.04-0.14-0.05-0.22
		c-0.01-0.08-0.01-0.16-0.01-0.23c0-0.07,0-0.13,0.01-0.19c0.01-0.14,0.01-0.23-0.04-0.36c-0.04-0.1-0.1-0.21-0.16-0.32
		c-0.01-0.02-0.02-0.04-0.03-0.06c-0.03-0.05-0.06-0.1-0.08-0.15c-0.08-0.14-0.16-0.29-0.22-0.46c-0.04-0.13-0.05-0.25-0.06-0.37
		c0-0.05-0.01-0.11,0-0.16c0.01-0.17,0.04-0.34,0.06-0.49c0.02-0.1,0.04-0.2,0.04-0.3c0-0.02,0-0.04,0-0.06
		c0-0.06,0.01-0.12-0.01-0.17c-0.07-0.01-0.16-0.04-0.25-0.1c-0.05-0.03-0.09-0.07-0.12-0.11c0,0-0.01-0.01-0.01-0.01
		c-0.04,0-0.08-0.01-0.12-0.02l-0.01-0.01c0,0,0,0,0,0l-0.1-0.04l-0.07-0.03c-0.07-0.03-0.14-0.06-0.21-0.09
		c-0.04-0.02-0.07-0.03-0.11-0.05c-0.01,0-0.02-0.01-0.03-0.01c-0.31-0.15-0.6-0.36-0.83-0.73c-0.2-0.33-0.26-0.71-0.31-1.05
		c-0.04-0.27-0.08-0.52-0.18-0.7c-0.05-0.1-0.15-0.2-0.26-0.3c-0.22-0.22-0.5-0.5-0.55-0.95c-0.02-0.15,0.01-0.27,0.03-0.37
		c-0.14-0.07-0.21-0.18-0.26-0.28c-0.07-0.15-0.08-0.31-0.08-0.44c0-0.05,0-0.09,0-0.13c0-0.01,0-0.01,0-0.02
		c0-0.07,0-0.14-0.01-0.19c-0.01-0.05-0.05-0.14-0.09-0.23c-0.08-0.17-0.18-0.37-0.17-0.6c0.01-0.17,0.06-0.28,0.09-0.36
		c0.02-0.04,0.02-0.05,0.03-0.07c0.02-0.09,0.07-0.24,0.16-0.52l0-0.01l0.04-0.11c0.01-0.05,0.04-0.1,0.06-0.15
		c0.02-0.06,0.05-0.11,0.08-0.18c0.03-0.07,0.08-0.17,0.12-0.27c0.04-0.1,0.07-0.19,0.07-0.24c-0.01-0.11-0.12-0.33-0.21-0.5
		c0,0,0,0,0,0c0,0,0,0,0,0c-0.07-0.13-0.13-0.26-0.18-0.37l-0.1-0.24c-0.03-0.06-0.05-0.13-0.08-0.19c-0.13-0.33-0.27-0.7-0.31-1.06
		c-0.01-0.07-0.01-0.15-0.01-0.22c0-0.07,0.01-0.14,0.02-0.21c0.02-0.15,0.11-0.27,0.25-0.35c0-0.06-0.01-0.07-0.01-0.07
		c-0.01,0-0.04-0.03-0.07-0.04c-0.1-0.06-0.23-0.14-0.35-0.29c-0.13-0.16-0.13-0.32-0.13-0.4c-0.02-0.05-0.02-0.05-0.07-0.08
		c-0.04-0.03-0.08-0.06-0.11-0.09c-0.01-0.01-0.02-0.02-0.03-0.03c0,0,0,0,0,0c-0.13-0.12-0.2-0.3-0.19-0.6c0,0,0,0,0,0c0,0,0,0,0,0
		c0.01-0.33,0.2-0.56,0.34-0.72c0.02-0.02,0.03-0.04,0.05-0.06c0.02-0.02,0.04-0.04,0.05-0.06c-0.04-0.01-0.09-0.04-0.14-0.05
		c-0.03-0.01-0.06-0.02-0.1-0.04c-0.42-0.22-0.77-0.4-0.92-0.85c-0.05-0.15-0.08-0.33-0.08-0.55c0-0.19,0.05-0.42,0.1-0.66
		c0.05-0.22,0.15-0.68,0.09-0.8c-0.02-0.03-0.15-0.08-0.23-0.12c-0.1-0.05-0.2-0.09-0.28-0.14c-0.14-0.09-0.24-0.18-0.31-0.26
		c-0.07-0.08-0.12-0.16-0.17-0.23c-0.03-0.04-0.06-0.08-0.09-0.13c-0.02-0.03-0.04-0.06-0.07-0.09c-0.04-0.05-0.09-0.09-0.13-0.14
		c-0.05-0.05-0.1-0.11-0.16-0.16c-0.07-0.06-0.13-0.12-0.2-0.18c-0.04-0.03-0.07-0.07-0.11-0.1c-0.06-0.05-0.11-0.1-0.17-0.15
		c-0.04-0.03-0.08-0.08-0.13-0.12c-0.05-0.05-0.1-0.1-0.15-0.16c-0.13-0.15-0.38-0.42-0.49-0.46c-0.09-0.04-0.13-0.03-0.13-0.03
		c-0.17,0.22,0.15,1.68,0.49,2.09c0.08,0.1,0.23,0.2,0.39,0.31c0.36,0.24,0.81,0.54,0.9,1.12c0.04,0.25-0.03,0.64-0.27,0.93
		c-0.04,0.05-0.08,0.09-0.13,0.13c0,0,0,0,0,0c-0.03,0.03-0.07,0.05-0.1,0.07c-0.02,0.01-0.03,0.02-0.05,0.03
		c-0.02,0.01-0.04,0.01-0.05,0.02c-0.09,0.04-0.18,0.07-0.28,0.07c-0.05,0-0.1-0.01-0.15-0.01c-0.43-0.06-0.64-0.43-0.77-0.69
		c-0.01-0.02-0.02-0.05-0.03-0.07l-0.06-0.12c-0.04-0.07-0.07-0.13-0.1-0.19c0,0,0,0,0,0c-0.03-0.05-0.06-0.1-0.09-0.14
		c-0.01-0.01-0.01-0.02-0.02-0.02c-0.03-0.04-0.06-0.07-0.09-0.11c-0.04-0.05-0.08-0.09-0.13-0.15l-0.12-0.13
		c-0.16-0.19-0.27-0.37-0.37-0.57c-0.03-0.07-0.06-0.13-0.1-0.2l-0.02-0.03l-0.06-0.12c-0.05-0.11-0.09-0.15-0.13-0.2
		c-0.14-0.17-0.17-0.32-0.15-0.5c0.01-0.1,0.04-0.22,0.08-0.36c0,0,0,0,0,0c0.01-0.03,0.03-0.08,0.07-0.14
		c0.08-0.15,0.12-0.24,0.12-0.29c-0.01-0.45-0.29-0.76-0.61-1.12c-0.09-0.1-0.18-0.2-0.26-0.3c-0.26-0.32-0.5-0.69-0.56-1.18
		c-0.01-0.09-0.01-0.17,0-0.24c0-0.01,0.01-0.02,0.01-0.04c0.08-0.5,0.54-0.75,0.84-0.91c0.02-0.01,0.05-0.03,0.08-0.04
		c-0.18-0.08-0.47-0.19-0.86-0.33c-0.04-0.01-0.07-0.03-0.1-0.04c-0.06-0.02-0.1-0.04-0.12-0.05l-0.18-0.08
		c-0.19-0.08-0.37-0.16-0.53-0.23c-0.03-0.01-0.05-0.02-0.07-0.03c-0.05-0.02-0.1-0.04-0.14-0.07c-0.01-0.01-0.02-0.01-0.03-0.01
		c-0.05-0.03-0.1-0.05-0.13-0.07l-0.11-0.06c-0.33-0.19-0.77-0.46-0.9-0.94c-0.1-0.39,0.04-0.57,0.16-0.65
		c-0.07-0.11-0.15-0.22-0.2-0.28c-0.05-0.07-0.1-0.14-0.14-0.2c-0.06-0.09-0.11-0.17-0.16-0.25c-0.17-0.31-0.27-0.58-0.31-0.88
		c0-0.02-0.01-0.05-0.01-0.07c-0.03-0.23-0.03-0.48-0.03-0.77c0.02-0.52-0.02-1.03-0.35-1.28c-0.07-0.05-0.18-0.09-0.29-0.13
		c-0.15-0.06-0.32-0.12-0.48-0.23c-0.16-0.11-0.28-0.24-0.39-0.36c-0.12-0.13-0.23-0.24-0.36-0.32c-0.03-0.02-0.05-0.03-0.07-0.04
		c-0.08-0.04-0.15-0.07-0.23-0.1c-0.02-0.01-0.03-0.01-0.06-0.02c-0.01,0-0.02-0.01-0.03-0.01c-0.06-0.02-0.13-0.04-0.21-0.08
		c-0.19-0.07-0.35-0.16-0.48-0.27c0,0-0.01-0.01-0.01-0.01c-0.03-0.03-0.06-0.06-0.09-0.1c-0.03-0.03-0.06-0.06-0.08-0.09
		c-0.03-0.03-0.04-0.07-0.06-0.11c-0.02-0.03-0.04-0.07-0.06-0.1c0,0,0,0,0,0c0,0,0,0,0,0c-0.05-0.11-0.08-0.23-0.11-0.34
		c-0.03-0.1-0.05-0.17-0.07-0.22c-0.11,0.01-0.24,0.01-0.36-0.04c0,0-0.01,0-0.01,0c-0.02-0.01-0.03-0.02-0.05-0.03
		c-0.03-0.02-0.05-0.03-0.08-0.05c0,0,0,0,0,0c-0.16-0.13-0.23-0.27-0.29-0.4c-0.09-0.18-0.12-0.35-0.13-0.49c0-0.05,0-0.09,0-0.14
		c0-0.09,0-0.2-0.02-0.24c-0.02-0.04-0.09-0.11-0.16-0.16c-0.02-0.01-0.03-0.03-0.05-0.04c-0.03-0.03-0.06-0.05-0.1-0.08
		c-0.1-0.09-0.2-0.19-0.26-0.32c0,0,0,0,0,0c-0.03-0.06-0.05-0.12-0.06-0.18c0,0,0,0,0,0c-0.03-0.19,0.04-0.34,0.11-0.45
		c0.02-0.04,0.04-0.07,0.06-0.09c0.01-0.02,0.03-0.03,0.04-0.05c0,0,0,0,0,0c-0.01,0-0.01-0.05-0.01-0.08c0-0.08,0-0.18,0.02-0.28
		c0.02-0.11,0.04-0.22,0.06-0.33c0.01-0.04,0.02-0.09,0.02-0.13c0.02-0.13,0.04-0.26,0.08-0.39c-0.25-0.01-0.56-0.04-0.97-0.09
		l-0.14-0.02c-1.71-0.15-3.42-0.3-5.13-0.46l-2.49-0.22c-0.26-0.02-0.52-0.05-0.79-0.07c-1.69-0.15-3.37-0.3-5.06-0.44l-0.7-0.06
		c-0.5-0.04-1.01-0.08-1.51-0.13c0.01,0.19-0.13,0.38-0.32,0.41c0,0-0.01,0-0.01,0c0,0-0.01,0-0.01,0c-0.2,0.03-0.38-0.08-0.43-0.28
		c-0.01-0.04-0.02-0.09-0.02-0.13c-0.01,0.01-0.02,0.02-0.04,0.03c-0.07,0.05-0.15,0.11-0.27,0.18c-0.14,0.09-0.24,0.14-0.33,0.19
		c-0.08,0.04-0.12,0.06-0.16,0.11c0,0-0.01,0.01-0.01,0.02c-0.01,0.02-0.03,0.04-0.04,0.07c-0.02,0.04-0.04,0.09-0.07,0.16
		c-0.05,0.22-0.11,0.38-0.17,0.48c0.04,0.01,0.09,0.03,0.13,0.04c0.22,0.07,0.51,0.17,0.69,0.44c0.03,0.05,0.06,0.09,0.08,0.14
		c0.14,0.31,0.05,0.57,0,0.71c0.1,0.13,0.33,0.38,0.42,0.44c0.08,0.03,0.1,0.02,0.27-0.07c0.09-0.05,0.2-0.1,0.32-0.15
		c0.09-0.03,0.2-0.06,0.31-0.08c0.08-0.02,0.2-0.04,0.26-0.07c0.03-0.06,0.07-0.11,0.13-0.14c0,0,0,0,0,0c0,0,0,0,0,0
		c0.02-0.01,0.05-0.02,0.07-0.03c0.03-0.01,0.05-0.02,0.08-0.03c0.03,0,0.05,0,0.08,0c0.03,0,0.05,0,0.08,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0.02,0,0.03,0.01,0.05,0.02c0.02,0.01,0.03,0.01,0.05,0.02c0.02,0.01,0.04,0.03,0.06,0.04c0.02,0.01,0.03,0.02,0.05,0.03
		c0.03,0.02,0.06,0.05,0.09,0.07c0.01,0.01,0.02,0.01,0.02,0.02c0.03,0.03,0.07,0.06,0.1,0.09c0,0,0.01,0.01,0.01,0.01
		c0.05,0.05,0.1,0.1,0.14,0.15c0.07,0.07,0.12,0.13,0.13,0.15c0.03,0.05,0.07,0.08,0.09,0.11c0.05,0.05,0.14,0.14,0.17,0.28
		c0,0.02,0,0.04,0.01,0.06c0,0.03,0.01,0.05,0.01,0.08c0,0,0,0,0,0c0,0,0,0,0,0c-0.01,0.22-0.17,0.36-0.35,0.52
		c-0.48,0.39-0.82,0.09-0.96-0.04c-0.07-0.06-0.13-0.12-0.21-0.15c-0.05-0.02-0.09-0.03-0.14-0.03c-0.14-0.01-0.29,0.02-0.49,0.08
		c-0.27,0.07-0.62,0.17-0.99,0.05c-0.08-0.02-0.18-0.06-0.29-0.11c-0.06-0.03-0.12-0.05-0.18-0.08c-0.39-0.19-0.82-0.46-1.02-0.65
		c-0.1-0.09-0.17-0.2-0.22-0.31c-0.16-0.34-0.16-0.74-0.16-1.09c0-0.07,0-0.16,0-0.24c-0.13,0.12-0.29,0.3-0.38,0.39
		c-0.12,0.13-0.22,0.23-0.28,0.28c-0.18,0.14-0.34,0.24-0.51,0.31c-0.49,0.21-0.93,0.16-1.42,0.09l-0.43-0.05
		c-0.46-0.05-0.89-0.1-1.41-0.41c-0.11-0.07-0.2-0.16-0.27-0.24c-0.01-0.01-0.02-0.02-0.04-0.04c-0.03-0.04-0.07-0.07-0.09-0.08
		c-0.03-0.01-0.1-0.01-0.17,0c-0.03,0-0.05,0-0.08,0.01c-0.08,0.01-0.17,0.02-0.27,0.02c-0.1,0-0.2-0.01-0.31-0.04
		c-0.22-0.07-0.39-0.17-0.54-0.26c-0.17-0.11-0.29-0.18-0.49-0.19c-0.08,0-0.19,0.02-0.3,0.04c-0.05,0.01-0.11,0.02-0.17,0.03
		c-0.04,0.01-0.07,0.01-0.11,0.02c-0.1,0.01-0.2,0.01-0.31,0c-0.21-0.03-0.36-0.13-0.48-0.22c0.01,0.04,0.01,0.08,0.02,0.13
		c0.03,0.21,0.11,0.69,0.18,0.81c0.03,0.02,0.08,0.02,0.12,0.02c0.15-0.01,0.41-0.02,0.66,0.2c0,0,0,0,0,0
		c0.05,0.04,0.09,0.1,0.13,0.16c0.03,0.04,0.05,0.08,0.08,0.12c0.04-0.04,0.09-0.06,0.14-0.08c0,0,0,0,0,0
		c0.05-0.02,0.1-0.02,0.16-0.02c0.4,0.04,0.71,0.34,0.75,0.69c0,0,0,0,0,0l0,0c0.01,0.1,0,0.19-0.03,0.27
		c0.16,0.06,0.33,0.12,0.46,0.25c0.16,0.16,0.25,0.34,0.31,0.5c0.02,0.06,0.04,0.12,0.06,0.17c0.01,0.02,0.02,0.04,0.02,0.06
		c0.03,0.08,0.06,0.17,0.09,0.23c0.19,0.3,0.44,0.42,0.77,0.57c0.21,0.1,0.45,0.2,0.68,0.37c0.02,0.01,0.06,0.05,0.12,0.09
		c0.03,0.02,0.06,0.05,0.1,0.07c0.02,0.01,0.04,0.03,0.06,0.04c0.04,0.04,0.09,0.07,0.13,0.11c0.03,0.03,0.07,0.06,0.1,0.09
		c0.03,0.03,0.07,0.06,0.1,0.09c0.05,0.04,0.1,0.09,0.15,0.14c0.02,0.02,0.04,0.04,0.06,0.06c0.05,0.05,0.11,0.11,0.16,0.16
		c0.02,0.02,0.04,0.04,0.06,0.06c0.05,0.05,0.09,0.1,0.14,0.16c0.03,0.03,0.05,0.06,0.08,0.1c0.03,0.04,0.07,0.09,0.1,0.13
		c0.05,0.07,0.09,0.14,0.13,0.21c0,0.01,0.01,0.01,0.01,0.02c0.11,0.21,0.15,0.44,0.16,0.68c0.01,0.23-0.01,0.46-0.04,0.68
		c-0.01,0.13-0.03,0.27-0.03,0.39c0,0.12,0,0.23,0.03,0.3c0.01,0,0.01,0,0.02,0c0.11,0,0.26,0.03,0.42,0.15
		c0.02,0.01,0.03,0.02,0.05,0.04c0.13,0.12,0.18,0.27,0.21,0.4c0.01,0.05,0.01,0.09,0.02,0.13c0.11-0.02,0.22,0,0.33,0.06
		c0.08,0.05,0.16,0.1,0.23,0.16c0.04,0.03,0.09,0.07,0.13,0.1c0.13,0.11,0.26,0.21,0.37,0.25c0.14,0.05,0.2,0.05,0.3,0.02
		c0.02,0,0.04-0.01,0.06-0.01c0.09-0.02,0.19-0.05,0.32-0.06c0.13-0.01,0.29,0,0.48,0.05c0.51,0.13,1.14,0.53,1.52,0.95
		c0.06,0.06,0.11,0.12,0.15,0.18c0.16,0.22,0.25,0.48,0.33,0.72c0.13,0.37,0.22,0.61,0.42,0.71c0.02,0.01,0.03,0.01,0.05,0.02
		c0.08,0.02,0.15,0.01,0.3-0.02c0.01,0,0.03-0.01,0.04-0.01c0.08-0.02,0.18-0.04,0.28-0.05c0.18-0.03,0.4-0.03,0.65,0.04
		c0.75,0.22,1.16,0.73,1.47,1.27c0.01,0.02,0.02,0.04,0.03,0.06c0.08,0.15,0.16,0.31,0.24,0.46c0,0.01,0.01,0.01,0.01,0.02
		c0,0,0,0,0,0.01c0.14,0.29,0.28,0.57,0.46,0.8c0.06,0.08,0.12,0.15,0.19,0.21c0.13,0.12,0.26,0.27,0.39,0.44
		c0.19,0.23,0.45,0.54,0.6,0.6c0.03,0,0.14,0,0.25-0.01c0.01-0.11,0.06-0.19,0.11-0.25c0.09-0.11,0.35-0.36,0.6-0.58
		c0.03-0.02,0.05-0.05,0.08-0.07c0,0,0.01-0.01,0.01-0.01c0.05-0.04,0.1-0.08,0.15-0.12c0.1-0.08,0.2-0.14,0.26-0.16
		c0.01,0,0.74-0.26,1.12,0.05c0,0,0,0,0,0c0,0,0,0,0,0c0.03,0.02,0.05,0.05,0.08,0.08c0.01,0.01,0.01,0.01,0.02,0.02
		c0.02,0.02,0.03,0.05,0.04,0.08c0.01,0.01,0.02,0.02,0.02,0.04c0.01,0.02,0.01,0.04,0.02,0.06c0.01,0.02,0.01,0.04,0.02,0.06
		c0.01,0.04,0.01,0.09,0.01,0.14c0.12,0.06,0.24,0.13,0.35,0.19c0.12,0.06,0.23,0.16,0.36,0.26c0.04,0.04,0.09,0.07,0.13,0.11
		c0.02,0.01,0.03,0.03,0.05,0.04c0.06,0.05,0.13,0.11,0.19,0.16c0.01-0.02,0.02-0.04,0.04-0.05l0,0c0,0,0,0,0,0l0,0
		c0,0,0,0,0.01-0.01c0.03-0.05,0.05-0.1,0.09-0.16c-0.05-0.11-0.05-0.24,0-0.35c0.13-0.24,0.3-0.4,0.46-0.53
		c0.19-0.16,0.29-0.27,0.35-0.45c0.01-0.04,0.02-0.17,0.03-0.26c0.03-0.39,0.06-0.88,0.45-1.02c0.37-0.14,0.68,0.18,0.78,0.28
		c0.5,0.5,0.33,1.32-0.46,2.13c-0.11,0.12-0.24,0.23-0.37,0.33c-0.45,0.38-0.56,0.52-0.47,0.8c0.01,0.03,0.02,0.05,0.03,0.07
		c0.01,0.01,0.02,0.02,0.03,0.03c0.01,0.01,0.01,0.02,0.02,0.02c0.05,0.03,0.13,0.07,0.29,0.14l0.2,0.09
		c0.16,0.07,0.3,0.11,0.45,0.16c0.02-0.02,0.05-0.04,0.08-0.06c0.05-0.03,0.1-0.05,0.16-0.07c0.01,0,0.02-0.01,0.03-0.01
		c0,0,0,0,0,0l0,0c0.06-0.01,0.12,0,0.18,0.01c0.06,0.02,0.12,0.04,0.17,0.08c0.14,0.11,0.21,0.26,0.2,0.42
		c-0.01,0.19-0.13,0.32-0.26,0.42c0.08,0.07,0.18,0.14,0.24,0.18c0.15,0.11,0.28,0.21,0.36,0.3l0.16,0.18
		c0.3,0.34,0.47,0.55,0.79,0.7c0.39,0.18,0.6,0.34,0.79,0.54c0.02,0.03,0.05,0.06,0.07,0.08c0.01,0.01,0.02,0.03,0.04,0.04
		c0.07,0.08,0.13,0.17,0.21,0.26c0.01,0.01,0.01,0.02,0.02,0.03l0.11,0.15c0.08,0.11,0.17,0.19,0.27,0.29
		c0.01,0.01,0.02,0.02,0.02,0.02c0.01,0,0.02,0,0.02,0c0.15-0.01,0.29,0.03,0.43,0.12c0.04,0.03,0.08,0.07,0.11,0.11
		c0.04,0.06,0.05,0.11,0.07,0.16c0,0.02,0.01,0.04,0.01,0.06c0.01,0.07,0,0.12-0.01,0.17c0.05,0.07,0.09,0.14,0.1,0.15
		c0.22,0.31,0.59,0.74,0.74,0.86c0.03,0,0.06-0.01,0.09-0.02c0.02-0.01,0.04-0.02,0.06-0.02c0.3-0.09,0.58-0.01,0.78,0.22
		c0.03,0,0.06,0,0.09,0.01c0.14,0.01,0.23,0.02,0.31,0.04c0.12-0.01,0.25-0.01,0.39,0.05c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0.13,0.06,0.21,0.18,0.22,0.32c0,0.02,0,0.03-0.01,0.05c0,0.03,0,0.06-0.01,0.09c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0.01-0.01,0.03-0.01,0.04c0.07,0.04,0.13,0.09,0.19,0.14c0.08,0.05,0.12,0.06,0.12,0.06c0-0.01,0.01-0.02,0.03-0.03
		c0.09-0.06,0.24-0.15,0.46-0.15c0.13,0,0.28,0.04,0.46,0.13c0.17,0.09,0.3,0.21,0.4,0.35c0.31,0.4,0.34,0.93,0.37,1.32l0.01,0.1
		c0,0.06,0,0.13,0,0.21c0,0.08,0,0.17-0.01,0.26c0,0.11-0.01,0.28-0.01,0.43c0.12-0.03,0.25-0.01,0.34,0.07
		c0.05,0.04,0.09,0.09,0.11,0.14c0.03,0.06,0.04,0.12,0.03,0.18c0,0.07,0.01,0.1,0.01,0.1c0.01,0.01,0.07,0.04,0.12,0.06
		c0.1,0.05,0.23,0.12,0.35,0.23c0.44,0.4,0.64,1.05,0.8,1.56l0.03,0.11c0.07,0.22,0.15,0.46,0.24,0.7c0.11,0.32,0.23,0.65,0.33,0.99
		c0.01,0.03,0.02,0.06,0.03,0.09c0.04,0.13,0.07,0.27,0.1,0.4c0.01,0.05,0.03,0.1,0.04,0.15c0.04,0.18,0.06,0.37,0.08,0.55
		c0.12,0.03,0.22,0.13,0.26,0.25c0.08,0.25,0.1,0.53,0.09,0.8c-0.01,0.28-0.05,0.57-0.1,0.85c-0.05,0.31-0.09,0.6-0.08,0.85
		c0.01,0.35-0.02,0.64-0.05,0.91l-0.01,0.09c0,0.06,0,0.17,0.01,0.24c0,0.02,0,0.04,0,0.05c0.01,0.03,0.01,0.07,0,0.11
		c-0.02,0.15-0.06,0.26-0.09,0.33c-0.03,0.07-0.04,0.1-0.04,0.17c0,0.05,0.01,0.14,0.03,0.22c0.01,0.05,0.02,0.1,0.03,0.16
		c0.01,0.08,0.02,0.16,0.02,0.24c0,0,0,0.01,0,0.03c-0.01,0.2-0.06,1.44-0.44,1.95c-0.08,0.11-0.19,0.15-0.3,0.18
		c-0.04,0.01-0.1,0.03-0.13,0.05c-0.01,0.01-0.01,0.02-0.02,0.04c0,0,0,0.01-0.01,0.02c-0.02,0.05-0.05,0.13-0.08,0.21l-0.05,0.12
		c-0.02,0.05-0.03,0.1-0.04,0.14c-0.04,0.14-0.06,0.27-0.06,0.44c0,0.32-0.01,0.6-0.05,0.89c0.05,0.01,0.1,0.02,0.15,0.05
		c0.09,0.04,0.14,0.12,0.17,0.21c0.02,0.04,0.04,0.08,0.04,0.13c0,0.07,0.01,0.19,0.02,0.32c0.02,0.26,0.03,0.4,0.03,0.49
		c0,0.03-0.01,0.06-0.01,0.09c-0.01,0.06-0.04,0.11-0.07,0.16c-0.04,0.08-0.09,0.15-0.14,0.21c0.04,0.09,0.06,0.22,0.01,0.39
		c-0.02,0.07-0.06,0.12-0.1,0.17c-0.02,0.02-0.05,0.05-0.07,0.09c0.03,0.06,0.05,0.12,0.05,0.19c0,0.01,0,0.02,0,0.03
		c0.07-0.05,0.16-0.09,0.27-0.11c0.04-0.01,0.08-0.01,0.12-0.01c0-0.01,0-0.01,0-0.02c-0.01-0.05,0-0.11,0.01-0.16
		c0.01-0.05,0.03-0.09,0.06-0.13c0.07-0.09,0.17-0.15,0.28-0.15c0.06,0,0.12,0,0.18,0.02c0.37,0.1,0.47,0.64,0.53,1
		c0.02,0.09,0.03,0.17,0.04,0.21c0.04,0.1,0.14,0.21,0.24,0.33c0.14,0.16,0.3,0.34,0.38,0.58c0.01,0.04,0.02,0.09,0.02,0.13
		c0,0.04-0.01,0.09-0.03,0.13c0.08,0.09,0.18,0.19,0.26,0.27c0.11,0.12,0.23,0.23,0.33,0.36c0.1,0.12,0.19,0.25,0.25,0.39
		c0.18,0.41,0.23,0.82,0.25,1.23c0.17,0.02,0.35,0.11,0.53,0.19c0.05,0.02,0.12,0.06,0.18,0.08c0.01-0.16,0.07-0.36,0.32-0.52
		c0.11-0.08,0.26-0.08,0.38-0.02c0.06,0.03,0.1,0.06,0.15,0.09c0,0,0.01,0.01,0.01,0.01c0.01,0.01,0.02,0.02,0.04,0.03
		c0.12,0.09,0.2,0.18,0.25,0.28c0.01,0.03,0.02,0.05,0.03,0.08c0,0.01,0.01,0.02,0.01,0.03c0.02,0.07,0.04,0.14,0.04,0.21
		c0,0,0,0.01,0,0.01c0,0.02,0,0.04,0,0.05c0,0.02,0,0.04,0,0.06c0,0.07,0.01,0.16,0.03,0.26c0.11,0.48,0.42,0.96,0.81,1.28
		c0.06,0.05,0.09,0.06,0.14,0.08c0.12,0.05,0.24,0.12,0.43,0.32c0.11,0.12,0.19,0.25,0.26,0.36c0.05,0.08,0.1,0.16,0.16,0.24
		c0.06,0.06,0.31,0.24,0.49,0.35c0.53,0.36,0.75,0.51,0.8,0.74c0.01,0.05,0.01,0.1,0.01,0.14c0.07,0.04,0.13,0.08,0.19,0.12
		c0.22,0.15,0.43,0.31,0.63,0.47c0.1,0.08,0.21,0.16,0.31,0.24c0.09,0.07,0.18,0.13,0.27,0.19c0.04,0.03,0.09,0.06,0.13,0.09
		c0,0,0,0,0,0c0.24,0.15,0.5,0.29,0.78,0.37c0.11,0.04,0.2,0.12,0.24,0.23c0.01,0.04,0.02,0.07,0.03,0.11
		c0.05,0.12,0.54,0.45,0.79,0.63c0.2,0.14,0.38,0.27,0.54,0.39c0.08,0.06,0.15,0.13,0.21,0.19c0.29,0.31,0.52,0.6,0.73,0.93
		c0.12,0.19,0.24,0.39,0.35,0.6c0.1,0.2,0.22,0.32,0.36,0.44c0.08,0.07,0.17,0.13,0.27,0.2c0.07,0.05,0.14,0.1,0.21,0.15
		c0.07,0.05,0.15,0.11,0.22,0.17c0.19,0.16,0.36,0.34,0.52,0.53c0.31,0.36,0.57,0.75,0.82,1.14c0.35,0.54,0.68,1.05,1.14,1.44
		c0.35,0.3,0.78,0.48,1.23,0.66c0.4,0.16,0.81,0.33,1.18,0.59c0.96,0.68,1.2,1.54,1.45,2.45c0.06,0.22,0.14,0.41,0.23,0.6
		c0.09,0.18,0.2,0.36,0.3,0.54c0.11,0.19,0.22,0.37,0.32,0.58c0.01,0.02,0.02,0.03,0.02,0.05c0.09,0.19,0.18,0.39,0.25,0.62
		c0.21,0.73,0.26,1.46,0.3,2.16c0.04,0.64,0.08,1.24,0.23,1.83c0.12,0.44,0.39,1.08,0.68,1.47c0.13,0.16,0.33,0.32,0.47,0.37
		c0.14,0.05,0.15,0.04,0.26-0.03c0.08-0.05,0.19-0.12,0.35-0.16c1.2-0.38,2-0.83,2.38-1.33c0.44-0.58,1.05-0.84,1.59-1.07
		c0.47-0.2,0.92-0.39,1.23-0.78c0.4-0.49,0.65-0.82,0.72-1.37c0.04-0.3,0.13-0.61,0.21-0.9c0.05-0.18,0.1-0.35,0.13-0.51
		c0.01-0.06,0.03-0.13,0.05-0.21c0.06-0.24,0.14-0.54,0.09-0.77C103.55,141.1,103.46,140.98,103.38,140.88z"
          />
          <path
            id="BaJaCalifornia"
            d="M39.87,53.62c0.12,0.2,0.24,0.39,0.37,0.46c0.12,0.07,0.34,0.09,0.55,0.11c0.21,0.02,0.42,0.05,0.61,0.11
		l0.21,0.06c0.03,0.01,0.07,0.02,0.1,0.03c0.35,0.11,0.75,0.24,1.03,0.42c0.28,0.18,0.48,0.42,0.66,0.68
		c0.11,0.15,0.21,0.31,0.3,0.47c0.21,0.35,0.41,0.68,0.68,0.89c0.07,0.06,0.15,0.11,0.23,0.16c0.24,0.16,0.51,0.34,0.71,0.66
		c0.11,0.18,0.17,0.36,0.21,0.52c0.05,0.17,0.08,0.28,0.16,0.36c0.02,0,0.03,0.01,0.05,0.01c0.02,0.01,0.05,0.01,0.08,0.02
		c0.14,0.03,0.3,0.08,0.45,0.2c0.04,0.03,0.08,0.06,0.11,0.09c0.01,0.01,0.01,0.02,0.02,0.03c0.04,0.05,0.08,0.1,0.12,0.16
		c0.03,0.05,0.06,0.11,0.08,0.17c0.01,0.02,0.02,0.04,0.03,0.07c0.03,0.07,0.05,0.13,0.08,0.2c0.01,0.02,0.02,0.04,0.02,0.06
		c0.02,0.05,0.03,0.1,0.05,0.16c0.01,0.04,0.03,0.08,0.04,0.11c0.06,0.2,0.07,0.35,0.07,0.47c0,0.04,0,0.07,0.01,0.1
		c0.01,0.09,0.04,0.16,0.14,0.29l0.03,0.02c0.03,0.02,0.06,0.06,0.1,0.09c0.02,0.02,0.04,0.03,0.06,0.05
		c0.06,0.06,0.12,0.12,0.16,0.2c0.06,0.1,0.09,0.19,0.1,0.27c0.11-0.04,0.23-0.06,0.35-0.03c0.16,0.04,0.51,0.23,0.71,0.37
		c0.04,0.03,0.08,0.06,0.1,0.09c0.04,0.05,0.08,0.1,0.1,0.15c0.02,0.05,0.04,0.11,0.05,0.16c0.07,0.03,0.12,0.08,0.16,0.15
		l0.05,0.08c0,0.01,0.01,0.02,0.01,0.02c0.05,0.08,0.1,0.17,0.15,0.25c0.01,0.01,0.02,0.03,0.02,0.04v0c0,0,0,0,0,0
		c0.08,0.15,0.08,0.3,0.07,0.41c0,0.02,0,0.04,0,0.06c0.14,0.05,0.32,0.13,0.46,0.34c0.13,0.19,0.18,0.36,0.22,0.5
		c0.02,0.06,0.03,0.11,0.05,0.15c0.02,0.04,0.04,0.08,0.06,0.11c0.06,0.08,0.16,0.16,0.26,0.25c0.13,0.11,0.26,0.22,0.36,0.36
		c0.09,0.11,0.11,0.22,0.11,0.31c0.03,0,0.07-0.01,0.1-0.01c0.03,0,0.07-0.01,0.12-0.01c0.12-0.01,0.25,0,0.4,0.05
		c0.02,0.01,0.04,0.01,0.06,0.02c0,0,0,0,0,0c0.07,0.03,0.15,0.08,0.22,0.14c0.21,0.18,0.29,0.4,0.31,0.59
		c0.02,0.12,0.01,0.23,0.01,0.32c0,0.16,0,0.26,0.04,0.33c0.02,0.04,0.07,0.08,0.13,0.12c0.03,0.02,0.06,0.04,0.09,0.07
		c0.21,0.14,0.5,0.33,0.62,0.75c0.03,0.11,0.05,0.23,0.06,0.34c0.05,0.81-0.49,1.64-0.9,2.28l-0.12,0.18
		c-0.06,0.09-0.17,0.23-0.3,0.38c-0.02,0.02-0.05,0.05-0.07,0.08c-0.14,0.17-0.34,0.41-0.37,0.5c0.01,0.01,0.06,0.04,0.11,0.07
		c0.01,0.01,0.03,0.02,0.04,0.03c0.05,0.03,0.1,0.07,0.15,0.12c0.16,0.13,0.34,0.33,0.41,0.66c0.02,0.1,0.03,0.19,0.03,0.27
		c0,0.02,0.01,0.04,0.01,0.06c0,0.05-0.01,0.1-0.02,0.15c-0.03,0.25-0.1,0.47-0.18,0.67c-0.05,0.14-0.1,0.27-0.13,0.41
		c0,0.03,0.01,0.13,0.01,0.22c0.01,0.11,0.01,0.2,0.01,0.26c0,0.07-0.01,0.12-0.03,0.17c0,0,0,0,0,0c0,0,0,0,0,0
		c0.17,0.02,0.35,0.05,0.51,0.08c0.14,0.02,0.26,0.05,0.36,0.06l0.29,0.02c0.19,0.02,0.37,0.03,0.56,0.05l1.24,0.11
		c1.32,0.11,2.64,0.23,3.96,0.35c0.52,0.05,1.04,0.09,1.55,0.14l6.77,0.6c0.22,0.02,0.43,0.04,0.65,0.06
		c-0.01-0.07-0.02-0.15-0.03-0.23c0,0,0,0,0-0.01l-0.01-0.06l-0.01-0.11c-0.05-0.43-0.07-0.92-0.05-1.49
		c0.01-0.27-0.09-0.37-0.29-0.57c-0.17-0.17-0.39-0.39-0.52-0.76c-0.03-0.08-0.04-0.15-0.05-0.22c-0.01-0.09-0.02-0.18-0.01-0.27
		c0.01-0.16,0.04-0.31,0.08-0.44c0.02-0.11,0.05-0.21,0.06-0.32c0-0.05,0-0.09-0.01-0.14c-0.01-0.09-0.02-0.2-0.01-0.33
		c0.01-0.11,0.06-0.2,0.11-0.29c-0.03-0.02-0.07-0.06-0.11-0.09c-0.05-0.03-0.09-0.06-0.13-0.11c-0.09-0.01-0.19,0.01-0.33,0.03
		c-0.14,0.02-0.3,0.05-0.49,0.05c-0.39-0.01-0.79-0.16-1.18-0.43c-0.06-0.04-0.13-0.08-0.19-0.14c-0.21-0.17-0.34-0.34-0.43-0.52
		c-0.14-0.28-0.15-0.57-0.16-0.87c0-0.01,0-0.03,0-0.04c0-0.1-0.01-0.2-0.01-0.32c-0.03-0.41-0.12-0.79-0.21-1.19
		c-0.08-0.34-0.16-0.69-0.21-1.08c-0.01-0.07-0.01-0.15-0.01-0.22c0-0.02,0-0.04,0-0.06c0-0.05,0-0.11,0.01-0.16
		c0-0.01,0-0.02,0-0.04c0.02-0.36,0-0.46-0.08-0.5c-0.03-0.01-0.06-0.02-0.1-0.02c-0.02,0-0.09,0.04-0.16,0.07
		c-0.06,0.03-0.12,0.06-0.17,0.08c-0.33,0.16-0.79,0.4-1.17,0.18c0,0-0.01,0-0.01,0c-0.02-0.01-0.03-0.03-0.05-0.04
		c-0.04-0.03-0.07-0.05-0.11-0.09c-0.02-0.03-0.04-0.06-0.06-0.08c-0.1-0.16-0.13-0.38-0.12-0.61c0.01-0.19,0.04-0.4,0.07-0.61
		c0.02-0.11,0.04-0.25,0.04-0.31c0,0,0-0.01,0-0.02c0-0.02-0.01-0.04-0.01-0.05c-0.03-0.03-0.06-0.07-0.09-0.11
		c-0.09-0.04-0.19-0.09-0.3-0.17c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.02-0.02-0.05-0.04-0.07-0.06
		c-0.02-0.02-0.04-0.05-0.06-0.09c0,0.03,0,0.06,0,0.08c0,0.06,0.01,0.12,0,0.19c-0.01,0.21-0.05,0.44-0.27,0.58
		c-0.31,0.2-0.69,0-0.83-0.07c-0.35-0.18-0.55-0.7-0.6-0.92c-0.05-0.25-0.03-0.43,0.07-0.57c-0.01-0.04-0.02-0.08-0.04-0.14
		c-0.04-0.12-0.09-0.26-0.09-0.46c0-0.07,0.01-0.14,0.03-0.22c0.04-0.21,0.13-0.31,0.2-0.4c0,0,0.01-0.01,0.01-0.02
		c0-0.01,0-0.02,0.01-0.04c0.02-0.36-0.33-0.77-0.59-1.08l-0.13-0.16c-0.04-0.05-0.08-0.07-0.13-0.11
		c-0.14-0.09-0.32-0.22-0.44-0.57c-0.04-0.12-0.05-0.22-0.04-0.32c0-0.1,0.02-0.19,0.03-0.27c0.01-0.08,0.02-0.14,0.02-0.19
		c0-0.05-0.01-0.1-0.05-0.16c-0.24-0.47-0.64-1.01-1.19-1.63c-0.38-0.42-0.75-0.76-1.15-1.12c-0.09-0.08-0.17-0.15-0.26-0.23
		L55.28,55c-0.06-0.05-0.1-0.1-0.16-0.16c-0.13-0.13-0.24-0.27-0.35-0.4c-0.16-0.2-0.31-0.39-0.51-0.54
		c-0.22-0.16-0.38-0.32-0.52-0.49c-0.13-0.16-0.25-0.32-0.36-0.48c-0.08-0.11-0.16-0.22-0.25-0.34c-0.05-0.06-0.09-0.12-0.14-0.18
		c-0.11-0.12-0.22-0.24-0.33-0.36c-0.14-0.15-0.29-0.3-0.41-0.46c-0.09-0.11-0.15-0.2-0.21-0.28c-0.11-0.17-0.12-0.17-0.35-0.24
		c-0.05-0.01-0.1-0.03-0.15-0.06c-0.08,0.07-0.19,0.13-0.35,0.13c0,0,0,0,0,0c-0.28,0-1.09-0.58-1.18-0.67
		c-0.34-0.37-0.3-0.8-0.27-1.11c0.02-0.24,0.03-0.37-0.04-0.48c-0.03-0.03-0.06-0.03-0.08-0.04c-0.09-0.01-0.23-0.04-0.39-0.14
		c-0.03-0.02-0.06-0.04-0.09-0.06c-0.1-0.08-0.16-0.18-0.19-0.27c-0.01-0.02-0.01-0.04-0.02-0.07c-0.02-0.07-0.04-0.13-0.05-0.18
		c-0.01-0.03-0.02-0.08-0.02-0.1c-0.02-0.02-0.05-0.04-0.13-0.08c-0.04-0.02-0.09-0.05-0.14-0.08c-0.05-0.03-0.11-0.07-0.16-0.12
		c0,0,0,0,0,0c0,0,0,0,0,0c-0.41-0.38-0.5-0.72-0.6-1.1c-0.03-0.1-0.05-0.2-0.09-0.32c-0.05-0.16-0.12-0.26-0.21-0.38
		c-0.04-0.05-0.08-0.11-0.12-0.17c0,0,0,0,0,0c-0.04-0.07-0.08-0.14-0.12-0.22l-0.03-0.06c-0.14-0.3-0.22-0.49-0.27-0.64
		c-0.04-0.12-0.06-0.2-0.07-0.27c-0.01-0.2,0.05-0.45,0.14-0.82c0.04-0.14,0.07-0.28,0.09-0.37c0.04-0.26,0.09-0.53,0.15-0.79
		c0.02-0.11,0.04-0.22,0.06-0.32c0.1-0.54,0.19-1.09,0.17-1.62c-0.01-0.21-0.03-0.42-0.08-0.62c-0.02-0.1-0.05-0.2-0.08-0.3
		c-0.03-0.1-0.07-0.2-0.1-0.3c-0.14-0.42-0.31-0.9-0.29-1.41c0.01-0.31,0.06-0.63,0.1-0.95c0.02-0.15,0.04-0.29,0.06-0.44
		c0.04-0.35,0.02-0.6-0.01-0.92c-0.01-0.12-0.02-0.25-0.03-0.39c-0.01-0.15,0-0.28,0.01-0.39c0.01-0.22,0.02-0.26-0.1-0.4
		c-0.06-0.07-0.21-0.12-0.35-0.17c-0.24-0.09-0.51-0.18-0.7-0.43c-0.11-0.14-0.18-0.26-0.21-0.37c-0.01-0.05-0.02-0.09-0.02-0.14
		c0-0.01,0-0.02,0-0.03c0-0.06,0.01-0.12,0.02-0.19c0.01-0.04,0.02-0.1,0.03-0.18c0.01-0.16,0.02-0.24,0.03-0.3
		c-0.01-0.03-0.03-0.08-0.06-0.16c0,0-0.02-0.05-0.02-0.05c-0.03-0.1-0.09-0.19-0.16-0.29c-0.07-0.1-0.14-0.21-0.2-0.35
		c-0.02-0.05-0.04-0.11-0.06-0.17c0-0.02-0.01-0.03-0.02-0.05c-0.02-0.08-0.03-0.17-0.04-0.26c-0.01-0.12,0-0.25,0.01-0.37
		c0.02-0.28,0.07-0.56,0.12-0.84c0.03-0.16,0.06-0.31,0.07-0.46c0.07-0.55,0.17-1.1,0.28-1.64c0.02-0.12,0.05-0.25,0.07-0.37
		c0.05-0.26,0.1-0.53,0.15-0.79c0.02-0.1,0.03-0.2,0.05-0.29c0.04-0.27,0.08-0.55,0.19-0.83c0.13-0.33,0.37-0.53,0.56-0.7
		c0.12-0.11,0.23-0.2,0.28-0.29c0.04-0.07,0.03-0.14-0.04-0.3c-0.02-0.04-0.03-0.07-0.05-0.12c-0.02-0.04-0.04-0.09-0.06-0.13
		c-0.05-0.1-0.09-0.2-0.12-0.32c-0.04-0.16-0.02-0.33-0.01-0.51c0-0.04,0.01-0.08,0.01-0.11c0.01-0.13,0.04-0.34,0.01-0.41
		c0.01,0.02-0.04,0.01-0.08,0c0,0-0.01,0-0.01,0c-0.12-0.02-0.28-0.06-0.44-0.16c-0.25-0.18-0.39-0.41-0.48-0.62
		c-0.03-0.07-0.06-0.13-0.08-0.19c-0.02-0.06-0.04-0.11-0.06-0.16c0,0,0,0,0,0c-0.02-0.05-0.04-0.09-0.07-0.13
		c-0.01,0-0.17-0.08-0.28-0.14c-0.37-0.19-0.81-0.42-0.98-0.79c-0.02-0.05-0.04-0.11-0.05-0.17c-0.01-0.04-0.01-0.09-0.01-0.14
		c0-0.15,0.05-0.34,0.23-0.54c0,0,0,0,0,0c0.04-0.04,0.09-0.08,0.15-0.1c0.02-0.01,0.03-0.01,0.05-0.01
		c0.03-0.01,0.06-0.02,0.09-0.02c0-0.1,0.03-0.2,0.1-0.28c0,0,0,0,0,0c0,0,0,0,0,0c0.15-0.18,0.3-0.24,0.39-0.28
		c0-0.01,0-0.02,0.01-0.03c0.01-0.02,0.01-0.05,0-0.08c-0.02-0.07-0.06-0.16-0.11-0.27c-0.11-0.23-0.23-0.5-0.18-0.79
		c0-0.02,0.01-0.05,0.02-0.07c0,0,0,0,0,0c0,0,0,0,0,0c0.02-0.07,0.06-0.12,0.12-0.17c0,0,0,0,0,0c0,0,0,0,0,0
		c0.04-0.03,0.09-0.06,0.14-0.07c0.01-0.05,0.02-0.12,0.02-0.2c0-0.03,0-0.05,0-0.07c0-0.08,0-0.15,0.01-0.21
		c0.01-0.11,0-0.2-0.01-0.27c-0.02-0.21-0.05-0.45,0.16-0.81c0,0,0.01-0.01,0.01-0.01c0,0,0,0,0,0c0.01-0.02,0.03-0.04,0.04-0.07
		c0.11-0.15,0.23-0.28,0.36-0.4c0.13-0.13,0.26-0.26,0.34-0.39c0.07-0.12,0.14-0.25,0.21-0.37c0.04-0.08,0.09-0.15,0.13-0.23
		c0.21-0.4,0.43-0.8,0.77-1.15c0.05-0.06,0.1-0.12,0.15-0.17c0.06-0.07,0.12-0.14,0.17-0.2c0.04-0.06,0.09-0.11,0.12-0.17
		c0.01-0.01,0.02-0.02,0.02-0.03c0-0.01,0.01-0.01,0.01-0.02c0.02-0.04,0.04-0.08,0.06-0.12c0.02-0.05,0.04-0.1,0.06-0.16
		c0,0,0,0,0,0c0.05-0.18,0.07-0.32,0.09-0.45c0.01-0.04,0.01-0.09,0.02-0.13c0.05-0.31,0.14-0.61,0.53-0.99
		c0.12-0.12,0.21-0.23,0.3-0.36c-5.44,0.05-10.07,0.05-14.74-0.08c-2.25-0.06-4.55-0.03-6.77,0.01c-1,0.02-2.01,0.03-3.02,0.04
		c-1.39,0.01-2.79,0.01-4.17-0.04c-0.01,0.08-0.03,0.16-0.05,0.24c-0.01,0.04-0.02,0.08-0.02,0.11c-0.01,0.08-0.03,0.16-0.03,0.22
		c0,0.03,0,0.06,0.01,0.08c0.04,0.14,0.14,0.35,0.24,0.55c0.05,0.1,0.1,0.2,0.15,0.3c0.04,0.08,0.07,0.15,0.09,0.22l0.07,0.21
		c0.12,0.35,0.25,0.74,0.47,0.97c0.05,0.05,0.12,0.09,0.19,0.13c0.06,0.03,0.12,0.05,0.18,0.08c0.14,0.06,0.29,0.12,0.44,0.23
		c0.05,0.03,0.1,0.07,0.14,0.12c0.38,0.36,0.5,0.92,0.58,1.47c0.04,0.26,0.02,0.55,0.01,0.83c-0.02,0.38-0.02,0.69,0.04,0.92
		c0.02,0.08,0.05,0.16,0.09,0.22c0.05,0.08,0.12,0.15,0.22,0.2c0.06-0.02,0.14-0.04,0.23-0.03c0,0,0.01,0,0.01,0
		c0.02,0,0.03,0.01,0.05,0.01c0.05,0.01,0.11,0.03,0.16,0.05c0.04,0.02,0.08,0.03,0.12,0.06c0.11,0.08,0.18,0.18,0.22,0.28
		c0,0,0,0.01,0.01,0.01c0.01,0.02,0.01,0.04,0.02,0.07c0.03,0.08,0.04,0.16,0.06,0.23c0.01,0.08,0.03,0.16,0.05,0.2
		c0.06,0.09,0.09,0.11,0.18,0.16c0.08,0.05,0.16,0.1,0.26,0.18c0.03,0.02,0.06,0.06,0.1,0.1c0.02,0.02,0.04,0.04,0.07,0.07
		c0.05,0.05,0.1,0.1,0.14,0.13c0.05,0.04,0.13,0.07,0.2,0.1c0.15,0.06,0.29,0.14,0.41,0.23c0.02,0.02,0.05,0.03,0.06,0.05l0,0
		c0,0,0,0,0,0c0.03,0.03,0.06,0.08,0.08,0.12c0.17,0.23,0.28,0.52,0.29,0.84c0,0.12-0.01,0.25-0.03,0.38
		c-0.02,0.11-0.06,0.21-0.1,0.31c0,0,0,0,0,0.01c-0.01,0.02-0.03,0.04-0.04,0.07c-0.13,0.26-0.33,0.46-0.59,0.56
		c-0.02,0.01-0.04,0.02-0.06,0.02c0.02,0.05,0.04,0.09,0.06,0.15c0.02,0.05,0.03,0.11,0.05,0.17c0.06,0.27,0.03,0.46-0.02,0.61
		c-0.02,0.05-0.03,0.1-0.05,0.14c-0.02,0.06-0.05,0.13-0.07,0.23c0.05,0.07,0.15,0.27,0.02,0.52c-0.07,0.14-0.17,0.22-0.27,0.26
		c0.02,0.02,0.04,0.04,0.05,0.06c0.03,0.03,0.05,0.06,0.08,0.08c0.12,0.03,0.26,0.09,0.35,0.23c0.09,0.13,0.12,0.27,0.14,0.39
		c0.01,0.04,0.02,0.09,0.03,0.11c0.11,0.12,0.25,0.18,0.43,0.27c0.15,0.07,0.33,0.16,0.5,0.29c0.08,0.06,0.15,0.13,0.22,0.22
		c0.08,0.09,0.15,0.21,0.21,0.34l0.08,0.17c0.3,0.64,0.58,1.24,0.97,1.83c0.14,0.21,0.26,0.41,0.36,0.61
		c0.32,0.6,0.5,1.16,0.46,1.82c-0.02,0.29-0.08,0.6-0.19,0.93c-0.03,0.11-0.08,0.21-0.11,0.31c-0.04,0.11-0.11,0.27-0.1,0.32
		c0,0,0.02,0.02,0.07,0.05c0.06,0.04,0.1,0.04,0.17,0.05c0.05,0.01,0.09,0.01,0.14,0.03c0,0,0,0,0,0c0.14,0.03,0.3,0.11,0.48,0.29
		c0,0,0,0,0,0c0,0,0,0,0,0c0.02,0.02,0.03,0.04,0.05,0.06c0.02,0.02,0.04,0.05,0.06,0.07c0.03,0.04,0.06,0.09,0.08,0.13c0,0,0,0,0,0
		c0.05,0.09,0.08,0.18,0.12,0.25c0.01,0.03,0.03,0.06,0.04,0.09c0.02,0.05,0.04,0.1,0.07,0.13c0.12,0.16,0.33,0.29,0.55,0.43
		c0.01,0,0.01,0.01,0.02,0.01c0.16,0.1,0.32,0.2,0.47,0.32c0.11,0.1,0.21,0.19,0.3,0.28c0.61,0.63,0.64,1.17,0.63,2.1
		c0,0.18-0.01,0.36-0.02,0.54c-0.01,0.1-0.02,0.2-0.03,0.31c-0.01,0.07-0.01,0.14-0.02,0.21c-0.01,0.06-0.01,0.12-0.02,0.18
		c-0.03,0.28-0.07,0.56-0.11,0.84c-0.02,0.12-0.03,0.24-0.05,0.36c0.01-0.01,0.03-0.02,0.04-0.03c0.11-0.07,0.24-0.08,0.35-0.03
		c0.48,0.21,0.78,0.6,0.79,1.02c0,0.14-0.03,0.28-0.09,0.41c0.29,0.05,0.61,0.14,0.87,0.42c0.07,0.08,0.13,0.16,0.19,0.26
		c0.01,0.01,0.02,0.02,0.03,0.03c0.02,0.03,0.03,0.07,0.05,0.1c0.14,0.29,0.23,0.62,0.29,0.89c0.11,0.48,0.16,0.88,0.15,1.25
		c0,0.12-0.02,0.24-0.03,0.37c-0.02,0.1-0.05,0.22-0.09,0.33c-0.01,0.04-0.03,0.08-0.04,0.12c-0.01,0.02-0.01,0.04-0.01,0.06
		c0,0.01-0.01,0.03-0.01,0.04c0,0.1,0.03,0.2,0.07,0.32c0.05,0.15,0.11,0.34,0.11,0.56c0,0.07-0.01,0.15-0.02,0.24
		c-0.03,0.17-0.12,0.36-0.23,0.59c-0.06,0.13-0.16,0.33-0.21,0.47c-0.02,0.05-0.02,0.09-0.02,0.11c0.01-0.02,0.1,0.01,0.17,0.03
		c0.19,0.06,0.45,0.15,0.65,0.39c0.48,0.55,0.42,1.16,0.38,1.64c-0.01,0.15-0.03,0.29-0.03,0.42c0,0.13,0.02,0.24,0.07,0.35
		c0.11,0.24,0.35,0.36,0.62,0.51c0.23,0.12,0.5,0.26,0.7,0.5c0.16,0.18,0.2,0.39,0.24,0.55c0.01,0.06,0.03,0.15,0.05,0.17
		c0.05,0.06,0.05,0.06,0.16,0.06c0.11,0,0.29,0,0.49,0.09c0.26,0.12,0.45,0.34,0.62,0.55c0.03,0.03,0.05,0.07,0.08,0.1
		c0.12,0.15,0.23,0.29,0.33,0.37c0.2,0.16,0.45,0.27,0.71,0.4c0.3,0.14,0.61,0.29,0.9,0.52C39.57,53.12,39.73,53.38,39.87,53.62z"
          />
          <path
            id="Chihuahua"
            d="M121.49,35.81l-0.67-0.06c-0.11-0.01-0.23-0.02-0.3-0.02c-0.02,0-0.04,0-0.05,0
		c-0.02,0.03-0.12,0.23-0.21,0.42c0,0,0,0,0,0c0,0,0,0,0,0c-0.06,0.13-0.13,0.25-0.2,0.36c-0.01,0.02-0.02,0.03-0.02,0.04
		c-0.04,0.07-0.08,0.13-0.11,0.19c-0.02,0.04-0.04,0.08-0.05,0.12c-0.01,0.03-0.03,0.06-0.03,0.08c0,0.08,0.02,0.13,0.08,0.18
		c0.01,0.01,0.02,0.02,0.03,0.02c0.03-0.03,0.05-0.06,0.08-0.09c0.01-0.01,0.02-0.02,0.03-0.03c0.02-0.02,0.03-0.04,0.04-0.05
		c0.04-0.05,0.07-0.09,0.11-0.14c0,0,0.01-0.01,0.01-0.01c0.04-0.05,0.1-0.08,0.16-0.1c0.01-0.01,0.03-0.01,0.04-0.01
		c0.05-0.01,0.09-0.01,0.14-0.01c0.01,0,0.02,0,0.03,0c0.01,0,0.02,0.01,0.03,0.01c0,0,0,0,0,0c0,0,0.01,0,0.01,0
		c0.04,0.02,0.08,0.04,0.12,0.07c0,0,0,0,0,0c0,0,0,0,0,0c0.03,0.03,0.06,0.06,0.08,0.1c0,0,0,0.01,0,0.01
		c0.03,0.05,0.04,0.11,0.05,0.17c0,0,0,0.01,0,0.01c0,0,0,0,0,0l0.01,1.11c0.01,1.44,0.02,2.88,0.04,4.32c0,0.01,0,0.02,0,0.04
		c0,0.06,0,0.12,0,0.18c0,0.19-0.01,0.42-0.02,0.66c-0.03,0.5-0.06,1.07,0.07,1.41c0.03,0.07,0.12,0.16,0.21,0.25
		c0.12,0.12,0.25,0.26,0.34,0.43c0.01,0.02,0.02,0.05,0.03,0.07c0.31,0.77,0.34,1.79,0.37,2.69c0,0,0,0.01,0,0.01
		c0.01,0.4,0.02,0.77,0.06,1.1c0.04,0.44,0.11,0.9,0.17,1.35c0.13,0.97,0.26,1.98,0.25,2.96v0c0,0.96-0.22,1.96-0.43,2.93
		c-0.09,0.42-0.18,0.84-0.25,1.23c-0.02,0.09-0.03,0.18-0.05,0.28c-0.01,0.06-0.02,0.12-0.03,0.17c0,0.01,0,0.01,0,0.02c0,0,0,0,0,0
		c-0.11,0.62-0.21,1.24-0.31,1.86c-0.01,0.09-0.02,0.19-0.03,0.31c-0.02,0.27-0.04,0.56-0.1,0.81c-0.06,0.39-0.15,0.61-0.33,0.74
		c-0.19,0.14-0.48,0.12-1.01,0.04c-0.03,1.37,0.05,2.77,0.13,4.13c0.04,0.78,0.09,1.57,0.11,2.35c0,0.13,0.02,0.28,0.03,0.43
		c0.04,0.53,0.09,1.13-0.15,1.59c-0.06,0.11-0.15,0.21-0.24,0.29c-0.07,0.09-0.15,0.17-0.22,0.24c-0.14,0.15-0.19,0.2-0.21,0.33
		c0,0.03,0,0.07,0.01,0.11c0.02,0.06,0.03,0.11,0.04,0.15c0.03,0.11,0.06,0.21,0.07,0.28l0.04,0.21c0,0,0,0.01,0,0.01l0.17,0.92
		c0.18,0.97,0.35,1.94,0.54,2.91c0,0.03,0.01,0.05,0.01,0.08c0,0,0,0,0,0l0.04,0.19c0.08,0.39,0.08,0.53-0.14,0.84
		c-0.12,0.23-0.33,0.41-0.6,0.59c-0.13,0.1-0.25,0.18-0.35,0.22c-0.02,0.01-0.03,0.01-0.05,0.02c-0.23,0.15-0.36,0.14-0.61,0.07
		l-0.04-0.01c-0.35-0.07-0.67-0.27-0.94-0.45c-0.08-0.05-0.17-0.11-0.25-0.16l-0.16-0.1c-0.56-0.34-1.38-0.82-2.01-0.8
		c-0.05,0.01-0.1,0.02-0.14,0.03c-0.11,0.03-0.17,0.08-0.24,0.13c-0.09,0.07-0.18,0.11-0.26,0.13c-0.06,0.02-0.12,0.04-0.2,0.03
		c-0.11-0.01-0.21-0.05-0.31-0.12c-0.02,0.02-0.04,0.05-0.06,0.07c-0.07,0.08-0.18,0.19-0.29,0.31c-0.2,0.22-0.42,0.48-0.5,0.62
		c-0.01,0.01-0.01,0.02-0.01,0.03c0.03,0.05,0.07,0.13,0.1,0.17c0.04,0.06,0.07,0.12,0.09,0.16l0.02,0.04
		c0.01,0.01,0.01,0.02,0.01,0.03l1.12,2.34c0,0,0,0,0,0c0.03,0.06,0.06,0.12,0.09,0.19c0.07,0.13,0.14,0.27,0.21,0.4
		c0.22,0.42,0.46,0.86,0.6,1.31c0.04,0.13,0.05,0.27,0.06,0.39c0.01,0.08,0.01,0.17,0.03,0.21c0.01,0.03,0.03,0.05,0.05,0.08
		c0.06,0.04,0.16,0.11,0.23,0.15c0.19,0.13,0.38,0.26,0.51,0.4c0.22,0.26,0.38,0.5,0.49,0.76c0.04,0.09,0.07,0.18,0.09,0.27
		c0.05,0.18,0.07,0.36,0.09,0.52c0.03,0.26,0.06,0.5,0.17,0.64c0.07,0.1,0.21,0.19,0.36,0.28c0.12,0.08,0.25,0.16,0.37,0.26
		c0.3,0.26,0.46,0.5,0.52,0.74c0.09,0.31,0.02,0.63-0.07,1.01l-0.02,0.08c0,0,0,0.01,0,0.01c-0.05,0.23-0.05,0.39-0.03,0.56
		c0.01,0.09,0.03,0.19,0.05,0.3c0.01,0.04,0.02,0.08,0.02,0.12c0.01,0.06,0.02,0.12,0.03,0.18c0.09,0.55-0.07,0.87-0.21,1.15
		c-0.05,0.11-0.11,0.22-0.16,0.36c-0.15,0.46-0.16,1.04-0.02,1.56c0.09,0.32,0.19,0.63,0.34,0.87c0.09,0.14,0.19,0.25,0.3,0.32
		c0.06,0.04,0.2,0.08,0.33,0.12c0.17,0.05,0.39,0.12,0.59,0.24c0.07,0.04,0.13,0.09,0.19,0.14c0.32-0.39,0.79-0.66,1.36-0.78
		c0.54-0.13,1.12-0.11,1.52,0.27c0.01,0.01,0.01,0.01,0.02,0.01c0.08,0.07,0.14,0.14,0.2,0.22c0.15,0.21,0.18,0.42,0.21,0.58
		c0.04,0.17,0.05,0.2,0.2,0.26c0.16,0.06,0.37,0.07,0.59,0.08c0.19,0.01,0.39,0.02,0.59,0.06c0.28,0.04,0.56,0.12,0.79,0.34
		c0.37,0.33,0.41,0.8,0.41,1.23c0.01,0.06,0.01,0.13,0.02,0.19c0.03,0.33,0.07,0.65,0.2,0.9c0.08,0.15,0.16,0.29,0.24,0.43
		c0.1,0.14,0.21,0.28,0.32,0.43c0.39,0.52,0.8,1.05,0.94,1.63c0.19,0.78,0.17,1.67,0.15,2.53c-0.01,0.38-0.02,0.74-0.01,1.07
		c0.01,0.26,0.02,0.52,0.03,0.77c0.01,0.12,0,0.26-0.01,0.41c-0.01,0.24-0.02,0.59,0.04,0.73c0,0,0,0,0,0
		c0.03,0.05,0.11,0.11,0.22,0.16c0.07,0.03,0.17,0.05,0.29,0.08c0.1,0.02,0.2,0.05,0.31,0.08c0.33,0.1,0.66,0.21,1,0.31
		c0.49,0.15,0.99,0.31,1.48,0.46c0.13,0.04,0.27,0.07,0.4,0.1c0.05,0.01,0.11,0.02,0.16,0.04c0.35,0.06,0.74,0.14,1.01,0.37
		c0.5,0.43,0.6,1.19,0.7,1.87c0.05,0.36,0.1,0.7,0.19,0.92c0.16,0.39,0.42,0.7,0.7,1.04c0.23,0.27,0.46,0.56,0.66,0.9
		c0.43,0.75,0.71,1.56,0.82,2.37c0.22-0.01,0.44,0.01,0.65,0.03c0.04,0,0.08,0.01,0.11,0.01c0.55,0.01,1.1,0.05,1.63,0.08l0.31,0.02
		c0.01,0,0.01,0,0.02,0c0.07,0,0.14,0.01,0.21,0.01c0.81,0.05,0.9,0.03,1.59-0.49c0.1-0.07,0.2-0.14,0.3-0.22
		c0.07-0.06,0.14-0.11,0.21-0.16c0.13-0.09,0.25-0.18,0.38-0.27c0.14-0.09,0.27-0.18,0.38-0.27c0.08-0.07,0.15-0.13,0.22-0.2
		c0.28-0.26,0.31-0.52,0.36-1.03c0.01-0.12,0.02-0.24,0.04-0.37c0.02-0.12,0.02-0.27,0.03-0.43c0.02-0.44,0.05-0.94,0.24-1.27
		c0.17-0.31,0.42-0.39,0.64-0.46c0.03-0.01,0.06-0.02,0.09-0.03c0.1-0.06,0.21-0.11,0.32-0.16c0.12-0.05,0.23-0.11,0.33-0.16
		c0.3-0.19,0.5-0.4,0.58-0.7c0-0.02,0.01-0.03,0.01-0.05c0.04-0.18,0.05-0.42-0.02-0.72c-0.06-0.25-0.18-0.49-0.29-0.75
		c-0.1-0.21-0.2-0.43-0.28-0.67c-0.12-0.29-0.18-0.6-0.1-1.08c0.01-0.08,0.05-0.15,0.11-0.2c0.08-0.09,0.21-0.13,0.34-0.11
		l0.18,0.03c0.83,0.14,1.49,0.26,2.08-0.25c0,0,0,0,0,0c0.25-0.22,0.31-0.4,0.35-0.77c0-0.04,0.01-0.07,0.01-0.11
		c0.05-0.51,0.21-0.73,0.37-0.94c0.07-0.09,0.14-0.19,0.22-0.34c0.01-0.02,0.02-0.04,0.03-0.07c0.05-0.16,0.1-0.47,0.14-0.7
		c0.14-0.89,0.27-1.65,0.81-1.76c0.2-0.04,0.36,0.03,0.5,0.14c0.08-0.1,0.16-0.19,0.24-0.28c0.16-0.18,0.31-0.35,0.37-0.51
		c0.01-0.04,0.03-0.07,0.05-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0h0c0.05-0.06,0.12-0.1,0.19-0.12
		c0.12-0.03,0.26,0,0.35,0.08l2.29,2.01c0.06,0.06,0.15,0.14,0.24,0.25c0.1,0.11,0.29,0.31,0.4,0.39c0.03-0.01,0.06-0.03,0.09-0.04
		c0.15-0.08,0.33-0.2,0.55-0.18c0.15,0,0.3,0.03,0.44,0.08c0.43,0.13,0.88,0.46,1.08,0.82c0.13,0.23,0.18,0.38,0.22,0.49
		c0.04,0.13,0.06,0.16,0.26,0.33c0.33,0.27,0.72,0.51,1.09,0.74c0.05,0.03,0.1,0.06,0.15,0.09c0.09,0.06,0.18,0.11,0.27,0.17
		c0.01,0.01,0.02,0.01,0.03,0.02c0.05,0.03,0.1,0.06,0.14,0.09c0.07,0.05,0.17,0.12,0.27,0.21c0.11,0.09,0.31,0.26,0.43,0.31
		c0.01,0,0.01,0,0.02,0.01c0.11,0.03,0.45-0.16,0.62-0.24c0.12-0.06,0.24-0.11,0.32-0.15c0.05-0.02,0.14-0.06,0.25-0.12
		c0.68-0.33,1.03-0.47,1.3-0.38c0.2,0.06,0.34,0.26,0.55,0.56c0.03,0.04,0.07,0.1,0.11,0.15c0,0,0.01,0.01,0.01,0.01
		c0.09,0.11,0.18,0.21,0.27,0.28c0.14,0.09,0.25,0.14,0.38,0.16c0.01-0.01,0.03-0.02,0.05-0.03c0.11-0.07,0.28-0.16,0.57-0.21
		c0.09-0.01,0.18-0.01,0.26,0c0.04,0,0.08,0.01,0.13,0.02c0.19,0.04,0.31,0.16,0.39,0.26c0.01,0.01,0.02,0.01,0.02,0.02c0,0,0,0,0,0
		c0.1-0.1,0.27-0.27,0.55-0.28c0.33-0.02,0.5,0.16,0.61,0.34c0.16,0.15,0.23,0.32,0.27,0.43c0.01,0.02,0.01,0.04,0.02,0.06
		c0.02-0.01,0.04-0.02,0.06-0.02c0.14-0.06,0.36-0.16,0.65-0.11c0.53,0.1,0.85,0.55,1.14,0.95c0.06,0.08,0.12,0.16,0.17,0.23
		c0.39,0.42,0.56,0.45,0.9,0.15c0.1-0.09,0.2-0.18,0.29-0.27c0.06-0.07,0.11-0.14,0.16-0.21c0.1-0.13,0.19-0.25,0.28-0.35l1.44-1.61
		c0.15-0.17,0.31-0.37,0.47-0.58c0.44-0.55,0.94-1.2,1.54-1.57c0.09-0.06,0.18-0.11,0.28-0.15c0.21-0.09,0.44-0.15,0.67-0.15
		c1.29,0,2.88,0.34,4.47,0.97l0.02,0.01c0.39,0.13,0.84,0.28,1.26,0.48c-0.03-0.24-0.05-0.49-0.08-0.72
		c-0.02-0.25-0.04-0.48-0.07-0.68c-0.11-0.69-0.21-1.38-0.32-2.08l-0.81-5.36c-0.09-0.56-0.17-1.13-0.26-1.69
		c-0.14-0.92-0.28-1.8-0.42-2.68c-0.03-0.22-0.09-0.46-0.15-0.71c-0.1-0.45-0.22-0.93-0.24-1.38c-0.05-0.24-0.07-0.47-0.03-0.66
		c0.04-0.17,0.13-0.35,0.24-0.52c0.23-0.49,0.59-0.97,0.94-1.44c0.18-0.24,0.35-0.47,0.49-0.68c0.11-0.17,0.22-0.33,0.33-0.5
		l0.15-0.23c1.69-2.59,3.36-5.18,5.04-7.78l0.17-0.26c0.64-0.99,1.28-1.98,1.93-2.97c-0.02-0.05-0.04-0.09-0.04-0.13
		c-0.03-0.02-0.08-0.04-0.12-0.07c-0.06,0.03-0.13,0.04-0.21,0.05c-0.06,0.01-0.13,0-0.21-0.01c-0.09-0.02-0.18-0.07-0.26-0.13
		c-0.14-0.1-0.29-0.23-0.46-0.42c-0.07-0.08-0.14-0.15-0.19-0.19c-0.05-0.04-0.09-0.06-0.12-0.08c-0.02-0.01-0.04-0.02-0.06-0.03
		c-0.04-0.02-0.07-0.04-0.1-0.06c-0.13-0.08-0.23-0.19-0.41-0.47c-0.05-0.07-0.1-0.14-0.14-0.2c-0.14-0.18-0.28-0.3-0.44-0.4
		c-0.03-0.02-0.11-0.03-0.19-0.04c-0.05-0.01-0.1-0.01-0.13-0.02c-0.07-0.01-0.13-0.02-0.2-0.03c-0.06-0.01-0.12-0.03-0.18-0.05
		l-0.07-0.03c-0.38-0.15-0.85-0.33-1.22-0.58c-0.13-0.09-0.62-0.51-0.73-0.69c-0.11-0.2-0.11-0.37-0.08-0.49
		c-0.01-0.01-0.03-0.01-0.04-0.02c-0.14-0.06-0.31-0.08-0.49-0.1c-0.23-0.03-0.48-0.06-0.74-0.17c-0.05-0.02-0.1-0.05-0.14-0.07
		c-0.38-0.19-0.73-0.45-1.06-0.7c-0.13-0.1-0.27-0.2-0.4-0.3c-0.15-0.11-0.31-0.21-0.47-0.32c-0.31-0.2-0.63-0.42-0.92-0.66
		c-0.14-0.12-0.24-0.23-0.31-0.33c-0.02-0.03-0.04-0.06-0.06-0.09c-0.01-0.02-0.03-0.04-0.04-0.06c0,0,0,0,0,0
		c-0.03-0.05-0.05-0.09-0.07-0.14c-0.02-0.04-0.04-0.08-0.07-0.12c-0.02-0.03-0.03-0.07-0.06-0.1c-0.16-0.24-0.41-0.47-0.66-0.62
		c-0.1-0.06-0.18-0.06-0.31-0.06c-0.12,0-0.26,0-0.41-0.06c-0.4-0.16-0.75-0.41-1.06-0.7c-0.99-0.97-1.52-2.51-1.76-3.49
		c-0.22-0.86-0.42-1.8-0.41-2.87v0c0,0,0,0,0,0l0-0.01l0-0.08c0.01-0.62,0.02-1.16-0.05-1.7c0-0.02-0.01-0.04-0.01-0.06
		c-0.01-0.09-0.03-0.17-0.04-0.26c-0.01-0.06-0.03-0.12-0.04-0.18c-0.01-0.05-0.02-0.1-0.04-0.15c-0.03-0.11-0.07-0.22-0.11-0.34
		c-0.3-0.84-0.77-1.72-1.47-2.76l-0.12-0.18c-0.19-0.28-0.39-0.56-0.48-0.88c-0.03-0.12-0.06-0.24-0.08-0.37
		c-0.02-0.1-0.03-0.19-0.06-0.28c-0.02-0.08-0.04-0.16-0.07-0.23l-0.03-0.07l-0.01-0.01c-0.16-0.38-0.24-0.57-0.52-0.77
		c-0.09-0.07-0.16-0.1-0.2-0.13c-0.05-0.03-0.09-0.05-0.12-0.08c-0.01,0-0.01-0.01-0.02-0.01c-0.01-0.01-0.02-0.02-0.03-0.04
		c-0.02-0.02-0.04-0.04-0.06-0.06c-0.02-0.02-0.03-0.05-0.04-0.07c-0.01-0.02-0.02-0.03-0.03-0.06c-0.02-0.05-0.05-0.11-0.08-0.18
		l-0.03-0.06c-0.05-0.13-0.08-0.21-0.1-0.28c-0.02-0.03-0.07-0.07-0.16-0.13c0.01,0.01,0,0.01-0.01,0.01c0,0,0,0,0,0
		c-0.01,0-0.01,0-0.02,0c0,0-0.01,0-0.01,0c-0.04-0.01-0.09-0.02-0.15-0.03c-0.06-0.02-0.13-0.04-0.2-0.08
		c-0.24-0.15-0.36-0.34-0.46-0.49c-0.06-0.08-0.1-0.16-0.16-0.22c-0.04-0.04-0.09-0.08-0.15-0.12c0,0,0,0,0,0
		c-0.05-0.04-0.11-0.08-0.17-0.11c-0.07-0.04-0.13-0.09-0.19-0.13c-0.09-0.07-0.17-0.15-0.25-0.24c-0.01-0.01-0.02-0.02-0.03-0.03
		c-0.05-0.06-0.14-0.16-0.18-0.19c-0.12-0.05-0.22-0.06-0.35-0.07c-0.12-0.01-0.26-0.02-0.42-0.08c-0.12-0.04-0.26-0.13-0.45-0.25
		c-0.05-0.03-0.1-0.07-0.15-0.1c-0.03-0.02-0.05-0.04-0.08-0.05c-0.09-0.06-0.18-0.12-0.24-0.15c-0.01-0.01-0.02-0.01-0.03-0.02
		c-0.37-0.21-0.54-0.44-0.66-0.65c-0.01-0.02-0.03-0.04-0.04-0.06c-0.1-0.17-0.18-0.3-0.38-0.44c-0.46-0.34-0.75-0.68-1.06-1.05
		l-0.11-0.13c-0.06-0.07-0.1-0.11-0.14-0.14c-0.12-0.11-0.23-0.21-0.33-0.47c-0.05-0.14-0.08-0.26-0.11-0.37
		c-0.03-0.13-0.05-0.19-0.11-0.27c-0.01-0.02-0.03-0.03-0.05-0.05c-0.25-0.25-0.55-0.49-0.88-0.73c-0.15-0.11-0.3-0.21-0.47-0.32
		c-0.5-0.32-0.85-0.82-1.18-1.3c-0.25-0.37-0.49-0.71-0.79-0.97c-0.18-0.16-0.32-0.34-0.46-0.52c-0.04-0.05-0.07-0.09-0.11-0.13
		c-0.09-0.12-0.19-0.24-0.3-0.33c-0.15-0.13-0.31-0.18-0.5-0.24c-0.16-0.05-0.34-0.11-0.53-0.22c-0.4-0.23-0.77-0.73-1.1-1.16
		c-0.13-0.17-0.25-0.33-0.36-0.46c-0.06-0.06-0.11-0.13-0.16-0.19c-0.39-0.45-0.71-0.86-1-1.38c-0.01-0.01-0.01-0.03-0.02-0.04
		c-0.05-0.09-0.09-0.18-0.14-0.27c-0.05-0.11-0.1-0.22-0.15-0.34c-0.13-0.3-0.25-0.54-0.39-0.73c-0.23-0.31-0.49-0.46-0.81-0.51
		c-0.39-0.05-0.56-0.16-0.75-0.3c-0.07-0.06-0.14-0.11-0.27-0.17c-0.31-0.15-0.79-0.16-1.21-0.18c-0.13,0-0.25-0.01-0.37-0.01
		c-0.57-0.04-1.14-0.08-1.71-0.13c-0.43-0.04-0.86-0.08-1.29-0.11c-0.12-0.01-0.23-0.02-0.35-0.03l-0.04,0c-0.02,0-0.04,0-0.06-0.01
		l-0.19-0.02c-0.12-0.01-0.24-0.02-0.37-0.03c-1.2-0.11-2.4-0.21-3.59-0.31c-1.21-0.1-2.42-0.21-3.62-0.32
		c-0.75-0.07-1.5-0.13-2.25-0.19c-0.55-0.05-1.1-0.09-1.65-0.14c-0.25-0.02-0.49-0.05-0.74-0.07c-0.22-0.02-0.45-0.04-0.67-0.06
		l-0.64-0.06c-0.06-0.01-0.19-0.03-0.35-0.05c-0.24-0.04-0.91-0.15-1.2-0.15c0,0,0,0,0,0c-0.04,0.24-0.03,0.9-0.02,1.2
		c0,0.11,0,0.22,0,0.31c0,0.09,0,0.16-0.01,0.22c-0.05,0.68-0.11,1.36-0.18,2.04c-0.07,0.79-0.27,1.39-1.04,1.95
		c-0.08,0.06-0.13,0.1-0.16,0.13c-0.18,0.16-0.29,0.18-0.53,0.18c-0.01,0-0.02,0-0.04,0l-0.15,0c0,0,0,0,0,0h0
		c-0.38,0.01-0.77-0.03-1.15-0.08c-0.17-0.02-0.34-0.04-0.5-0.06C123.78,36.02,122.63,35.92,121.49,35.81z"
          />
          <path
            id="Coahuila"
            d="M186.24,73.31c0,0-0.01,0-0.01,0c-0.1-0.01-0.18-0.03-0.25-0.04c-0.01,0-0.02,0-0.03-0.01
		c-0.06-0.01-0.13-0.02-0.16-0.03c0.01,0.01-0.04,0.05-0.14,0.2c0,0,0,0,0,0c-0.02,0.03-0.04,0.06-0.06,0.09
		c-0.52,0.8-1.04,1.6-1.56,2.4l-0.23,0.35c-0.37,0.57-0.73,1.14-1.1,1.71c-0.97,1.5-1.93,3.01-2.91,4.5
		c-0.44,0.68-0.89,1.35-1.33,2.03l-1.28,1.97c-0.06,0.09-0.14,0.2-0.23,0.31c-0.07,0.09-0.16,0.2-0.23,0.31
		c-0.02,0.06-0.05,0.11-0.07,0.17c-0.05,0.15-0.06,0.33-0.05,0.53c0.03,0.13,0.06,0.26,0.09,0.36c0.04,0.16,0.08,0.3,0.09,0.41
		c0.22,1.33,0.43,2.66,0.63,3.99c0.01,0.04,0.01,0.08,0.02,0.12c0.19,1.22,0.39,2.4,0.59,3.59c0.42,2.48,0.86,5.05,1.1,7.58
		c0,0,0,0.01,0.01,0.01c0.08,0.08,0.14,0.16,0.2,0.25l0.64,0.77c0.72,0.86,1.43,1.72,2.15,2.57c0.4,0.48,0.65,0.93,0.76,1.38
		c0.19,0.67,0.09,1.2-0.1,1.68c-0.1,0.31-0.24,0.62-0.4,0.95c-0.41,0.82-0.37,1.73-0.33,2.68c0.03,0.8,0.07,1.63-0.17,2.45
		c0,0,0,0.01,0,0.01c0,0.01,0,0.01-0.01,0.02c-0.09,0.3-0.22,0.56-0.38,0.79c-0.34,0.52-0.78,0.95-1.07,1.21
		c-0.94,0.82-1.08,1.3-0.59,1.96l0.05,0.07c0.05,0.06,0.1,0.13,0.15,0.21c0.12,0.15,0.23,0.33,0.26,0.58
		c0.04,0.22,0.01,0.47-0.15,0.75c-0.2,0.35-0.52,0.46-0.73,0.52c-0.02,0.01-0.04,0.01-0.07,0.02c0,0.01,0.01,0.03,0.01,0.05
		c0.05,0.04,0.13,0.11,0.18,0.14c0.05,0.04,0.11,0.09,0.15,0.13c0.04,0.03,0.07,0.06,0.1,0.1c0.21,0.25,0.29,0.48,0.37,0.69
		c0.04,0.1,0.07,0.21,0.13,0.32c0.13,0.23,0.32,0.37,0.57,0.55c0.08,0.06,0.16,0.12,0.25,0.19l0.11,0.09
		c0.37,0.29,0.78,0.61,0.89,1.2c0.05,0.28-0.01,0.56-0.05,0.81c-0.01,0.04-0.02,0.08-0.02,0.12c-0.02,0.14-0.03,0.28-0.03,0.4
		c0,0.01,0,0.01,0,0.02c0.08,0.53,0.54,0.91,1.42,1.18c0.09,0.03,0.17,0.05,0.26,0.08c0.01,0,0.01,0,0.02,0
		c0.29,0.07,0.59,0.14,0.88,0.26c0.5,0.21,1.01,0.54,1.49,0.95c-0.02-0.33-0.02-0.66,0.09-0.91c0.1-0.24,0.32-0.48,0.56-0.71
		c0.23-0.31,0.49-0.59,0.74-0.86c0.28-0.3,0.54-0.59,0.77-0.9c0.03-0.09,0.08-0.28,0.11-0.39c0.08-0.33,0.11-0.44,0.2-0.53
		c0.21-0.24,0.61-0.27,2.13-0.18c0.18,0.01,0.34,0.02,0.44,0.02c0.05,0,0.1,0,0.15,0c0.02,0,0.03,0,0.05,0
		c0.23-0.01,0.47-0.03,0.72-0.05c0.65-0.05,1.31-0.1,1.92,0.01c0.64,0.12,1.28,0.43,1.89,0.73c0.2,0.1,0.4,0.19,0.59,0.28l0.39,0.17
		c0.13,0.05,0.25,0.11,0.38,0.16c0.55,0.23,1.11,0.47,1.64,0.77c0.35,0.19,0.69,0.42,0.93,0.83c0.13,0.23,0.21,0.44,0.28,0.61
		c0.13,0.34,0.18,0.47,0.56,0.61c0.05,0.02,0.13,0.04,0.23,0.07c0.65,0.2,1.16,0.37,1.33,0.74c0.2,0.44-0.05,0.78-0.18,0.96
		c-0.02,0.03-0.05,0.07-0.07,0.1c-0.01,0.06,0,0.1,0.01,0.12c0.02,0.02,0.12,0.13,0.59,0.16c0.57,0.03,0.91-0.17,1.42-0.46
		c0.11-0.13,0.3-0.33,0.55-0.36c0.1-0.02,0.2-0.01,0.32,0.04c0.19,0.07,0.27,0.2,0.31,0.29c0.03,0.07,0.04,0.14,0.04,0.2
		c0.01-0.01,0.03-0.01,0.04-0.02c0.15-0.08,0.37-0.2,0.64-0.21c0.11-0.01,0.22,0.01,0.35,0.06c0.1,0.04,0.2,0.1,0.3,0.18
		c0.22,0.18,0.43,0.44,0.63,0.71c0.18,0.25,0.34,0.51,0.49,0.76c0.12,0.19,0.23,0.38,0.35,0.56c0.21,0.29,0.39,0.51,0.55,0.68
		c0.17,0.16,0.35,0.3,0.56,0.41c0.15,0.08,0.28,0.05,0.52-0.04c0.16-0.05,0.35-0.12,0.56-0.12c0.17,0,0.35,0.04,0.53,0.16
		c0.04,0.02,0.07,0.04,0.1,0.07c0.02-0.08,0.05-0.16,0.08-0.26c0.12-0.42,0.14-0.88,0.16-1.36c0.01-0.27,0.02-0.55,0.05-0.81
		c0.01-0.07,0.01-0.16,0.02-0.25c0.03-0.54,0.07-1.22,0.49-1.51c0.13-0.09,0.28-0.12,0.44-0.14l-0.02-0.02
		c-0.38-0.32-0.89-0.75-0.98-1.29c-0.15-0.83,0.81-1.79,1.01-1.98c0.22-0.21,0.47-0.35,0.74-0.45c0.58-0.26,1.28-0.38,2.02-0.32
		c0.18,0.01,0.36,0.04,0.53,0.07c0.77,0.08,1.36,0.37,1.81,0.9c0.32-0.21,0.75-0.34,1.13-0.38c-0.05-0.13-0.05-0.28,0.02-0.44
		c0-0.01,0-0.01,0.01-0.01c0.06-0.11,0.13-0.19,0.21-0.23c-0.13-0.06-0.29-0.12-0.45-0.18c-0.01,0-0.02-0.01-0.02-0.01c0,0,0,0,0,0
		c-0.16-0.05-0.42-0.06-0.68-0.06c-0.4,0-0.81-0.01-1.12-0.19c0,0,0,0,0,0c-0.02-0.01-0.05-0.03-0.07-0.05
		c-0.17-0.12-0.26-0.29-0.34-0.43c-0.05-0.08-0.09-0.16-0.14-0.2c-0.04-0.03-0.11-0.06-0.19-0.09c-0.11-0.04-0.23-0.09-0.34-0.17
		c-0.29-0.21-0.4-0.43-0.49-0.61c-0.01-0.01-0.01-0.03-0.02-0.04c-0.04-0.09-0.08-0.16-0.14-0.23c0,0-0.01-0.01-0.01-0.01
		c-0.05-0.05-0.25-0.12-0.38-0.17c-0.38-0.14-1.02-0.38-0.97-1.01c0.02-0.42,0.22-0.6,0.37-0.68c0.06-0.03,0.12-0.05,0.18-0.06
		c0.08-0.01,0.16-0.01,0.25,0c0-0.06,0.01-0.12,0.03-0.18c0,0,0-0.01,0-0.01c-0.1,0.01-0.22,0.01-0.35-0.04
		c-0.03-0.01-0.05-0.02-0.08-0.03c-0.08-0.02-0.17-0.05-0.25-0.11c-0.17-0.12-0.3-0.29-0.4-0.48c-0.3-0.39-0.58-0.9-0.83-1.4
		c-0.06-0.12-0.11-0.24-0.14-0.36c-0.08-0.23-0.13-0.47-0.12-0.76c0.01-0.24,0.06-0.46,0.11-0.67l0.03-0.19
		c0.05-0.33,0.06-0.54,0-0.71c-0.06-0.08-0.14-0.14-0.26-0.23c-0.17-0.12-0.39-0.28-0.55-0.54c-0.19-0.32-0.11-0.65-0.06-0.89
		c0,0,0-0.01,0-0.01c0.02-0.43-0.1-0.73-0.43-1.09c-0.1-0.11-0.18-0.16-0.3-0.23c-0.11,0.03-0.28,0.07-0.45-0.05
		c-0.12-0.09-0.21-0.21-0.29-0.38c-0.07-0.1-0.13-0.21-0.18-0.32c-0.02-0.04-0.03-0.07-0.05-0.1c-0.23-0.37-0.45-0.73-0.68-1.1
		c-0.07-0.11-0.14-0.21-0.22-0.33c-0.09-0.12-0.17-0.24-0.25-0.37c-0.36-0.48-0.52-0.74-0.47-0.99c0.07-0.39,0.62-0.78,1.91-1.55
		c0.21-0.12,0.37-0.22,0.45-0.28l0.2-0.15c0.22-0.15,0.43-0.31,0.65-0.46c0.23-0.16,0.47-0.36,0.71-0.56
		c0.4-0.33,0.81-0.66,1.23-0.92c0.46-0.4,0.75-0.57,1.02-0.52c0.46,0.1,0.66,0.53,0.82,0.88c0.04,0.09,0.1,0.21,0.15,0.29
		c0.05-0.03,0.12-0.1,0.22-0.23c0.05-0.08,0.1-0.17,0.15-0.29c0.01-0.04,0.03-0.07,0.04-0.11c0.05-0.15,0.11-0.31,0.21-0.47
		c0.17-0.27,0.31-0.36,0.45-0.42c0.03-0.02,0.05-0.04,0.08-0.06c0.08-0.05,0.14-0.1,0.2-0.16c0-0.06,0.02-0.12,0.05-0.18
		c0.01-0.06,0.02-0.13,0.04-0.19c0.07-0.16,0.18-0.26,0.27-0.35c0.02-0.02,0.05-0.05,0.07-0.07c0.04-0.08,0.07-0.16,0.09-0.25
		c0.01-0.07,0.02-0.15,0.02-0.23l0.03-0.31c0.01-0.05,0.01-0.1,0.02-0.15c0-0.12,0-0.25,0-0.36l0-0.09c0-0.65-0.03-0.69-0.55-0.96
		c-0.04-0.02-0.1-0.06-0.18-0.11c-0.03-0.02-0.07-0.05-0.12-0.07c-0.02,0.03-0.04,0.08-0.06,0.12c-0.1,0.2-0.26,0.53-0.66,0.55
		c-0.01,0-0.02,0-0.03,0c-0.01,0-0.02,0-0.03,0c-0.25,0-0.42-0.16-0.6-0.35c-0.05-0.05-0.12-0.12-0.18-0.18
		c-0.06,0.12-0.19,0.2-0.33,0.21c-0.03,0-0.05,0-0.07,0c-0.16-0.03-0.29-0.16-0.32-0.32c0,0,0,0,0,0v0c0,0,0,0,0,0
		c0-0.02,0-0.03,0-0.05c-0.02-0.61-0.03-1.22-0.05-1.83c0-0.06-0.01-0.14-0.02-0.22c-0.02-0.12-0.03-0.26-0.03-0.39
		c-0.03-0.24-0.02-0.43,0.05-0.59c0.12-0.25,0.41-0.4,0.81-0.59c0.06-0.03,0.11-0.05,0.16-0.07c0.18-0.12,0.37-0.26,0.58-0.4
		c0.6-0.43,1.28-0.92,1.92-1.05c0.31-0.06,0.62-0.01,0.92,0.05c0.26,0.05,0.53,0.09,0.69,0.04c0.23-0.11,0.61-1.17,0.75-1.57
		c0.08-0.23,0.16-0.44,0.22-0.6c0-0.01,0.01-0.02,0.01-0.03c0,0,0,0,0-0.01c0.06-0.13,0.11-0.26,0.16-0.38
		c0.24-0.59,0.5-1.2,1.06-1.61l0.07-0.05c0.41-0.31,0.72-0.52,0.96-0.64c0.23-0.13,0.42-0.19,0.57-0.18
		c0.07,0.01,0.13,0.02,0.2,0.06c0.42,0.16,0.93,0.69,1.73,1.63c0.14,0.16,0.26,0.3,0.34,0.39c0.06,0.07,0.11,0.11,0.14,0.13
		c0.05-0.03,0.1-0.06,0.16-0.09c0.02-0.01,0.05-0.03,0.07-0.04c0.09-0.07,0.18-0.15,0.3-0.24c0.38-0.28,0.77-0.56,1.16-0.84
		c-0.14-0.17-0.29-0.35-0.43-0.53c-0.06-0.07-0.09-0.15-0.09-0.24c-0.08-0.05-0.18-0.14-0.24-0.27c-0.02-0.04-0.03-0.09-0.04-0.15
		c-0.03-0.21,0.03-0.37,0.07-0.47c0.01-0.02,0.02-0.06,0.02-0.07c-0.01-0.01-0.07-0.05-0.11-0.07c-0.14-0.09-0.33-0.21-0.43-0.45
		c-0.06-0.14-0.04-0.28-0.02-0.39c0-0.02,0.01-0.04,0.01-0.06c-0.19-0.03-0.47-0.09-0.66-0.36c-0.22-0.3-0.17-0.6-0.14-0.78
		c0.01-0.03,0.01-0.08,0.02-0.1c-0.01,0-0.03-0.01-0.05-0.02c-0.02-0.01-0.05-0.03-0.08-0.04c0,0,0,0-0.01,0
		c-0.01-0.01-0.03-0.02-0.04-0.03c-0.02-0.01-0.03-0.02-0.05-0.03c-0.06-0.05-0.12-0.11-0.17-0.19c-0.1-0.16-0.11-0.34-0.12-0.48
		c0-0.06-0.01-0.14-0.02-0.17c-0.07-0.16-0.19-0.24-0.36-0.33c-0.07-0.03-0.14-0.07-0.23-0.11c-0.24-0.11-0.51-0.23-0.74-0.47
		c-0.03-0.03-0.05-0.06-0.07-0.1c-0.07-0.09-0.12-0.19-0.15-0.28c-0.01-0.03-0.03-0.06-0.04-0.09c-0.01-0.03-0.03-0.05-0.04-0.07
		c-0.05-0.07-0.11-0.13-0.18-0.19c-0.17-0.16-0.37-0.36-0.5-0.71c-0.07-0.19-0.16-0.37-0.25-0.56c0-0.01-0.01-0.02-0.01-0.03
		c-0.02-0.05-0.05-0.1-0.07-0.15c-0.02-0.04-0.04-0.08-0.06-0.12c-0.01-0.02-0.02-0.04-0.03-0.06c-0.06-0.13-0.11-0.26-0.16-0.39
		c-0.05-0.14-0.09-0.28-0.12-0.43c-0.01-0.03-0.03-0.14-0.06-0.28c-0.13-0.55-0.17-0.75-0.18-0.85c0,0,0,0,0-0.01
		c0-0.02,0-0.03,0-0.05c0-0.01,0-0.02,0-0.02c0,0,0-0.01,0-0.01c0-0.01,0.01-0.03,0.01-0.04c0.01-0.05,0.03-0.09,0.05-0.12
		c-0.01-0.01-0.01-0.01-0.02-0.02c-0.17-0.2-0.31-0.37-0.42-0.68c-0.07-0.18-0.03-0.34,0-0.45c0,0,0,0-0.01,0
		c-0.1-0.04-0.26-0.1-0.37-0.25c-0.1-0.13-0.14-0.26-0.14-0.39c0-0.04,0.01-0.09,0.02-0.14c-0.02-0.02-0.04-0.03-0.05-0.05
		c-0.1-0.09-0.22-0.2-0.3-0.35c-0.11-0.22-0.18-0.48-0.24-0.75c-0.02-0.1-0.04-0.19-0.06-0.29c-0.01-0.04-0.01-0.07-0.02-0.1
		c-0.02-0.11-0.04-0.22-0.07-0.32c-0.14-0.55-0.33-1.09-0.54-1.65c-0.07-0.2-0.14-0.4-0.22-0.6c-0.15-0.4-0.3-0.8-0.44-1.21
		c-0.04-0.12-0.06-0.24-0.08-0.37c-0.01-0.05-0.01-0.1-0.02-0.14c0-0.02-0.01-0.04-0.01-0.06c-0.02-0.14-0.04-0.28-0.08-0.36
		c-0.03-0.05-0.05-0.08-0.06-0.1c-0.03-0.02-0.07-0.04-0.11-0.07c0,0,0,0,0,0c-0.04-0.03-0.08-0.06-0.13-0.11
		c-0.07-0.07-0.15-0.15-0.23-0.22c-0.14-0.13-0.28-0.27-0.41-0.42c-0.11-0.13-0.2-0.26-0.26-0.41c-0.07-0.18-0.07-0.35-0.06-0.48
		c0,0,0,0,0,0c0,0,0,0,0,0c0-0.04,0-0.07,0-0.1c0-0.01,0-0.03,0-0.04c0-0.04-0.01-0.07-0.02-0.1c-0.01-0.03-0.04-0.07-0.07-0.11
		c-0.04-0.05-0.08-0.1-0.13-0.15c-0.02-0.02-0.05-0.05-0.07-0.06c-0.12-0.09-0.21-0.16-0.31-0.23c-0.14-0.1-0.27-0.19-0.42-0.33
		c-0.07-0.06-0.14-0.13-0.21-0.21c-0.15-0.17-0.33-0.27-0.54-0.39c-0.12-0.07-0.25-0.15-0.38-0.24c-0.13-0.09-0.25-0.18-0.36-0.28
		c-0.01-0.01-0.02-0.02-0.04-0.03c-0.04-0.04-0.08-0.09-0.12-0.14c-0.02-0.02-0.04-0.04-0.05-0.06c-0.05-0.08-0.09-0.16-0.13-0.26
		c-0.03-0.1-0.05-0.19-0.05-0.27c0-0.08,0.01-0.16,0.02-0.22c0.01-0.04,0.01-0.1,0.01-0.12c-0.07-0.19-0.18-0.22-0.48-0.3
		c-0.21-0.06-0.48-0.13-0.72-0.33c-0.14-0.11-0.23-0.25-0.3-0.36c-0.05-0.07-0.09-0.14-0.13-0.17c0,0-0.04-0.01-0.07-0.01
		c-0.13-0.03-0.34-0.08-0.48-0.28c0,0,0,0,0,0c0,0,0,0,0,0c-0.12-0.18-0.13-0.36-0.11-0.52c0.01-0.1,0.04-0.19,0.06-0.27
		c-0.01,0.01-0.02,0.02-0.03,0.03c-0.14,0.12-0.31,0.26-0.54,0.3c-0.11,0.01-0.22-0.02-0.3-0.08c-0.08-0.07-0.13-0.17-0.14-0.28
		c0-0.09,0.01-0.21,0.05-0.45c0.01-0.08,0.03-0.19,0.04-0.28c0,0-0.01,0-0.01,0c-0.18-0.05-0.47-0.12-0.66-0.43
		c-0.16-0.25-0.32-0.69-0.4-1.08c-0.06,0-0.12-0.01-0.17-0.02c-0.01,0-0.02,0-0.03,0c0,0,0,0-0.01,0c-0.01,0-0.02-0.01-0.03-0.01
		c-0.06-0.03-0.19-0.09-0.31-0.19c-0.09,0.04-0.2,0.08-0.32,0.1c-0.02,0-0.04,0.01-0.07,0.01c0,0,0,0,0,0c0,0,0,0,0,0
		c-0.17,0-0.45,0.01-0.61-0.21c-0.02-0.02-0.03-0.05-0.04-0.07c-0.13,0.07-0.27,0.12-0.53,0.12c-0.26,0.01-0.49-0.03-0.72-0.08
		c-0.11-0.02-0.21-0.05-0.32-0.08l-0.19-0.05c-0.09-0.02-0.25-0.05-0.41-0.08c-0.01,0-0.01,0-0.02,0c-0.33-0.06-0.51-0.1-0.61-0.14
		c-0.01,0-0.02-0.01-0.03-0.01c0,0,0,0,0,0c0,0,0,0,0,0c-0.05-0.02-0.08-0.04-0.12-0.06c-0.09-0.05-0.16-0.11-0.21-0.16
		c-0.23,0.08-0.55,0.06-0.86,0.02c-0.04,0-0.09-0.01-0.13-0.01c-0.07-0.01-0.14-0.02-0.19-0.02c-0.26-0.02-0.44-0.16-0.56-0.25
		c-0.02-0.02-0.04-0.03-0.07-0.05c-0.12,0.04-0.26,0.06-0.41,0.09c-0.19,0.04-0.46,0.09-0.69,0.07c-0.19-0.01-0.38-0.05-0.54-0.11
		c-0.17-0.05-0.32-0.12-0.44-0.18c-0.19-0.1-0.35-0.22-0.49-0.33c-0.1-0.08-0.2-0.16-0.28-0.2c-0.01,0-0.03-0.01-0.06-0.01
		c-0.03,0-0.06-0.01-0.09-0.01c-0.1-0.01-0.21-0.03-0.28-0.06c-0.12-0.04-0.25-0.12-0.32-0.28c-0.01,0-0.02-0.01-0.04-0.01
		c-0.11-0.04-0.22-0.1-0.28-0.13c-0.03-0.02-0.07-0.04-0.11-0.06c-0.07,0.06-0.16,0.12-0.28,0.19c-0.03,0.05-0.03,0.09-0.05,0.16
		c-0.02,0.11-0.04,0.25-0.13,0.44c-0.03,0.06-0.06,0.1-0.08,0.15c-0.15,0.26-0.32,0.38-0.5,0.43c-0.16,0.04-0.31,0.02-0.46-0.01
		c0,0,0,0,0,0c-0.01,0-0.01,0-0.02,0c-0.04-0.01-0.09-0.02-0.13-0.03l-0.17-0.04c-0.05-0.01-0.1-0.03-0.15-0.04
		c-0.04-0.01-0.07-0.02-0.11-0.03c-0.04-0.01-0.08-0.02-0.12-0.03c-0.07-0.02-0.13-0.04-0.19-0.04c-0.08,0.13-0.22,0.29-0.47,0.34
		c-0.17,0.04-0.3,0-0.39-0.05c-0.05-0.03-0.09-0.06-0.12-0.09c-0.1,0.04-0.22,0.07-0.35,0.12c0,0,0,0,0,0c0,0-0.01,0-0.01,0l0,0
		c-0.02,0.01-0.04,0.02-0.07,0.02c0,0,0,0,0,0c-0.23,0.05-0.4,0.06-0.52,0.06c-0.05,0-0.1,0-0.12,0.01c0,0-0.04,0.04-0.12,0.19
		c-0.03,0.05-0.05,0.1-0.07,0.14c-0.01,0.03-0.03,0.06-0.04,0.09c-0.02,0.06-0.05,0.11-0.08,0.17c-0.03,0.06-0.08,0.13-0.13,0.2
		c-0.07,0.09-0.16,0.17-0.25,0.25c-0.06,0.06-0.13,0.12-0.17,0.18c0,0,0,0.03,0,0.07c-0.01,0.06-0.03,0.15-0.06,0.23
		c-0.01,0.03-0.02,0.07-0.04,0.1c0,0,0,0,0,0c0,0,0,0,0,0c-0.01,0.02-0.03,0.03-0.04,0.05c-0.03,0.04-0.05,0.08-0.09,0.11
		c-0.01,0.04-0.03,0.08-0.05,0.12c-0.08,0.13-0.18,0.24-0.28,0.36c-0.11,0.13-0.21,0.26-0.3,0.4c-0.13,0.21-0.19,0.5-0.25,0.81
		c-0.03,0.13-0.05,0.26-0.09,0.4c-0.1,0.4-0.26,0.8-0.62,1.08c-0.13,0.1-0.26,0.11-0.36,0.1c-0.02,0.08-0.04,0.22-0.06,0.32
		c-0.03,0.17-0.06,0.29-0.08,0.39c0,0,0,0.01,0,0.01c0,0.01-0.01,0.02-0.01,0.03c-0.01,0.02-0.01,0.04-0.02,0.05c0,0,0,0,0,0
		c-0.04,0.09-0.09,0.19-0.13,0.29c-0.03,0.05-0.05,0.1-0.08,0.15c-0.02,0.03-0.03,0.06-0.05,0.09c-0.29,0.53-0.47,0.63-0.53,0.67
		c0,0,0,0,0,0h0c-0.03,0.02-0.07,0.03-0.11,0.04c-0.04,0.02-0.08,0.03-0.12,0.04c-0.03,0.01-0.06,0.02-0.09,0.03
		c-0.03,0.01-0.06,0.01-0.08,0.02c-0.04,0.01-0.08,0.03-0.12,0.03c-0.02,0.03-0.03,0.05-0.04,0.08c-0.01,0.02-0.02,0.04-0.04,0.06
		c-0.04,0.05-0.06,0.11-0.09,0.17c-0.08,0.16-0.17,0.36-0.36,0.49c-0.17,0.13-0.38,0.16-0.52,0.18c-0.03,0-0.06,0.01-0.08,0.01
		c-0.03,0.03-0.05,0.06-0.08,0.12c-0.06,0.09-0.14,0.25-0.32,0.33c-0.02,0.06-0.03,0.12-0.04,0.19c-0.04,0.23-0.1,0.6-0.5,0.77
		c-0.13,0.06-0.3,0.09-0.52,0.09c-0.1,0-0.21-0.02-0.32-0.05c-0.12-0.03-0.24-0.06-0.36-0.1C186.45,73.36,186.32,73.32,186.24,73.31
		z"
          />
          <path
            id="Sonora"
            d="M104.53,96.94c0.2-0.08,0.48-0.19,0.9-0.12c0.01,0,0.03,0,0.04,0.01c0-0.02-0.01-0.03-0.01-0.05
		c0-0.06,0-0.11,0.01-0.17c0.01-0.04,0.03-0.08,0.05-0.12c0.01-0.01,0.01-0.03,0.02-0.04c0.06-0.1,0.16-0.2,0.28-0.28
		c0.04-0.02,0.08-0.04,0.12-0.06c0.23-0.1,0.48-0.11,0.67,0.01c0.01,0.01,0.02,0.02,0.04,0.03c0.07,0.05,0.14,0.11,0.19,0.21
		c0.02,0.04,0.04,0.08,0.06,0.13c0.07,0.01,0.14,0.03,0.23,0.07c0,0,0.02,0.01,0.04,0.02c0.11,0.06,0.38,0.24,0.53,0.49
		c0.01,0.02,0.02,0.04,0.03,0.06c0.01,0.03,0.03,0.05,0.04,0.08c0,0,0,0,0,0c0,0,0,0,0,0c0.01,0.03,0.01,0.06,0.02,0.09
		c0,0.02,0.01,0.04,0.01,0.06c0,0.05,0,0.1-0.01,0.15c0.27,0.22,0.46,0.5,0.62,0.75c0.07,0.1,0.14,0.21,0.21,0.31
		c0.11,0.14,0.22,0.27,0.34,0.39c0.27,0.29,0.57,0.62,0.74,1.11c0.1,0.29,0.12,0.57,0.12,0.83c-0.01,0.26-0.04,0.51-0.08,0.74
		c-0.01,0.08-0.02,0.15-0.03,0.23c0.03-0.01,0.06-0.02,0.09-0.03c0-0.01,0.01-0.02,0.01-0.03c0.02-0.03,0.05-0.06,0.07-0.09
		c0.01-0.01,0.01-0.01,0.02-0.02c0.02-0.02,0.05-0.04,0.07-0.05c0.01,0,0.02-0.01,0.03-0.01c0.03-0.01,0.05-0.02,0.08-0.03
		c0.01,0,0.02,0,0.03-0.01c0.01,0,0.02-0.01,0.03-0.01c0.02,0,0.05,0,0.07,0.01c0.1,0.02,0.17,0.05,0.24,0.08
		c0.02,0.01,0.04,0.02,0.06,0.03c0.05,0.02,0.09,0.05,0.12,0.08c0.01,0.01,0.03,0.02,0.04,0.03c0.04,0.04,0.08,0.08,0.1,0.13
		c0.07-0.04,0.15-0.09,0.23-0.14l2.36-1.64c0.33-0.23,0.66-0.46,0.99-0.69c0.65-0.45,1.29-0.9,1.94-1.35
		c0.42-0.29,0.84-0.58,1.26-0.87c0.13-0.09,0.27-0.17,0.42-0.26c0.4-0.25,0.86-0.52,1.03-0.84c0.09-0.17,0.11-0.39,0.13-0.63
		c0.01-0.15,0.03-0.3,0.05-0.45c0-0.07-0.01-0.13-0.01-0.19c0-0.02,0-0.05,0-0.07c0,0,0,0,0,0c0,0,0,0,0,0
		c0.01-0.07-0.02-0.12-0.12-0.17c-0.08-0.05-0.21-0.1-0.42-0.16c-0.19-0.06-0.37-0.12-0.52-0.21c-0.2-0.13-0.36-0.29-0.49-0.48
		c-0.23-0.33-0.41-0.78-0.52-1.33c-0.13-0.62-0.1-1.31,0.08-1.83c0.06-0.19,0.14-0.33,0.2-0.46c0.12-0.24,0.19-0.37,0.15-0.64
		c0-0.01,0-0.02,0-0.02c0,0,0-0.01,0-0.01c-0.01-0.05-0.02-0.09-0.02-0.13c-0.01-0.05-0.02-0.09-0.03-0.13
		c-0.05-0.26-0.1-0.49-0.08-0.78c0.01-0.12,0.02-0.26,0.06-0.41l0.01-0.06c0.07-0.31,0.11-0.49,0.09-0.62
		c-0.02-0.14-0.11-0.24-0.29-0.41c-0.08-0.07-0.17-0.13-0.27-0.19c-0.22-0.14-0.48-0.3-0.64-0.55c-0.18-0.28-0.21-0.61-0.25-0.93
		c-0.02-0.16-0.03-0.3-0.06-0.43c-0.01-0.06-0.03-0.11-0.05-0.16c-0.11-0.25-0.36-0.46-0.61-0.67c-0.17-0.14-0.33-0.27-0.45-0.42
		c-0.08-0.07-0.14-0.14-0.18-0.22c-0.09-0.17-0.1-0.36-0.11-0.52c0-0.08-0.01-0.16-0.02-0.21c0,0,0,0,0-0.01c0,0,0,0,0,0
		c-0.13-0.39-0.34-0.8-0.55-1.19c-0.07-0.13-0.14-0.27-0.21-0.4c-0.04-0.07-0.07-0.15-0.11-0.22l-1.13-2.36
		c-0.01-0.02-0.03-0.05-0.05-0.09c-0.16-0.26-0.27-0.47-0.25-0.68c0.01-0.13,0.07-0.27,0.18-0.44c0.15-0.23,0.37-0.49,0.65-0.8
		c0.07-0.08,0.14-0.16,0.22-0.24c0.12-0.13,0.23-0.24,0.28-0.31c0.02-0.02,0.04-0.04,0.06-0.06h0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.06-0.04,0.14-0.07,0.22-0.07c0.03,0,0.07,0,0.11,0.01c0,0,0,0,0,0
		c0,0,0,0,0,0h0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0h0c0.03,0.01,0.06,0.03,0.09,0.05c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.01,0.01,0.02,0.02,0.03,0.03c0.02,0.03,0.05,0.05,0.06,0.07
		c0.07-0.05,0.17-0.1,0.28-0.14c0.16-0.05,0.32-0.08,0.49-0.09c0.87-0.08,1.84,0.53,2.5,0.94l0.24,0.15
		c0.07,0.04,0.13,0.08,0.2,0.13c0.18,0.12,0.37,0.24,0.55,0.29l0.07,0.02c0.06,0.01,0.11,0.01,0.16,0c0.03-0.02,0.07-0.04,0.11-0.06
		c0.07-0.04,0.13-0.07,0.18-0.1c0.15-0.11,0.31-0.26,0.37-0.33c0.01-0.01,0.01-0.02,0.02-0.03c0.06-0.13,0.04-0.28,0-0.49l0,0
		c-0.01-0.03-0.01-0.06-0.02-0.09c-0.19-0.99-0.37-1.98-0.55-2.97l-0.15-0.84c-0.01-0.07-0.03-0.15-0.06-0.23
		c-0.03-0.13-0.07-0.26-0.09-0.4c-0.06-0.24-0.09-0.44-0.04-0.62c0.07-0.28,0.32-0.47,0.53-0.64c0.04-0.03,0.08-0.06,0.12-0.1
		c0,0,0,0,0-0.01c0.24-0.34,0.22-0.64,0.19-1.14c-0.01-0.13-0.02-0.26-0.02-0.41c-0.03-0.78-0.07-1.56-0.11-2.35
		c-0.09-1.58-0.18-3.22-0.12-4.83c0,0,0,0,0-0.01c0-0.07,0.03-0.15,0.07-0.21c0,0,0,0,0,0c0,0,0,0,0,0
		c0.02-0.02,0.03-0.04,0.05-0.06c0.05-0.05,0.11-0.08,0.18-0.09c0,0,0,0,0,0c0,0,0,0,0,0c0.03-0.01,0.06-0.01,0.1-0.01
		c0.11,0.01,0.23,0.02,0.34,0.04c0.16,0.02,0.49,0.06,0.53,0.01c0.02-0.03,0.04-0.07,0.05-0.13c0.03-0.22,0.05-0.49,0.06-0.62
		c0.01-0.13,0.02-0.24,0.03-0.31c0.01-0.06,0.02-0.11,0.03-0.17c0-0.01,0-0.03,0.01-0.04c0.09-0.59,0.19-1.18,0.29-1.77
		c0.01-0.07,0.03-0.15,0.04-0.22c0.03-0.17,0.06-0.35,0.09-0.52c0.07-0.39,0.16-0.79,0.24-1.2c0.19-0.88,0.39-1.78,0.4-2.62
		c0-0.93-0.13-1.91-0.25-2.85c-0.06-0.46-0.12-0.92-0.17-1.37c-0.03-0.35-0.05-0.73-0.06-1.13c0-0.01,0-0.02,0-0.02
		c-0.02-0.84-0.05-1.78-0.31-2.42c0,0,0,0,0-0.01c0,0,0,0,0,0c-0.03-0.07-0.12-0.16-0.2-0.24c-0.14-0.14-0.29-0.29-0.38-0.51
		c-0.21-0.52-0.17-1.26-0.13-1.9c0.01-0.23,0.03-0.44,0.02-0.62c-0.02-1.47-0.03-2.95-0.05-4.42l0-0.15c-0.01,0-0.02,0-0.03,0.01
		c-0.08,0.03-0.17,0.04-0.26,0.03c-0.38-0.03-0.6-0.41-0.66-0.53c-0.13-0.23-0.14-0.46-0.09-0.67c0.06-0.4,0.31-0.8,0.5-1.11
		c0.01-0.02,0.02-0.03,0.03-0.05c-2.41-0.21-4.83-0.41-7.24-0.62c-0.2-0.02-0.4-0.03-0.59-0.05c-3.09-0.26-6.18-0.52-9.27-0.79
		c-0.91-0.08-1.81-0.17-2.72-0.25l-2.08-0.19c-0.5-0.05-1.03-0.06-1.58-0.08c-1.08-0.04-2.2-0.08-3.2-0.33
		c-0.57-0.15-1.13-0.44-1.66-0.73c-0.26-0.14-0.51-0.27-0.76-0.38l-2.78-1.27c-6.09-2.78-12.79-5.77-20.95-9.35
		c-0.55-0.24-1.1-0.49-1.67-0.73l-0.61-0.27c-0.56-0.25-1.13-0.5-1.7-0.74c-0.78-0.34-1.55-0.68-2.34-1.03
		c-0.16-0.07-0.33-0.14-0.49-0.21c-3.87-1.69-7.81-3.4-11.72-5.02c-0.08,0.11-0.19,0.22-0.32,0.36c-0.01,0.01-0.03,0.03-0.04,0.05
		c0,0,0,0,0,0l-0.03,0.03c-0.01,0.01-0.02,0.03-0.04,0.04c0,0,0,0.01-0.01,0.01l0,0c0,0,0,0-0.01,0.01
		c-0.24,0.3-0.43,0.61-0.62,0.94c-0.02,0.04-0.04,0.08-0.06,0.12c-0.13,0.24-0.25,0.48-0.41,0.72c-0.11,0.17-0.27,0.31-0.4,0.42
		c-0.08,0.07-0.17,0.15-0.22,0.21c-0.01,0.01-0.01,0.02-0.02,0.02c0,0,0,0,0,0c-0.03,0.08-0.04,0.39-0.04,0.58
		c0,0.15,0,0.29-0.01,0.43c0,0.27-0.03,0.57-0.25,0.77c0,0-0.01,0.01-0.01,0.01c0.02,0.05,0.04,0.09,0.06,0.13
		c0.06,0.14,0.13,0.29,0.17,0.46c0.06,0.22,0.07,0.47-0.07,0.72c-0.08,0.14-0.18,0.22-0.27,0.27c0,0.1-0.03,0.22-0.1,0.29
		c-0.05,0.06-0.1,0.1-0.15,0.14c-0.01,0.02-0.03,0.04-0.05,0.05c-0.01,0.01-0.02,0.01-0.02,0.02c0,0.01,0.01,0.01,0.01,0.02
		c0.03,0.03,0.07,0.07,0.12,0.1c0.13,0.09,0.32,0.17,0.49,0.25c0.25,0.11,0.5,0.22,0.7,0.38c0.19,0.15,0.35,0.31,0.51,0.47
		c0.19,0.2,0.38,0.39,0.59,0.5c0.03,0.02,0.1,0.03,0.17,0.05c0.12,0.03,0.26,0.06,0.41,0.15c0.17,0.11,0.28,0.27,0.36,0.39
		c0.04,0.05,0.09,0.13,0.11,0.15c0.11,0.06,0.27,0.06,0.46,0.06c0.23,0,0.49,0,0.76,0.12c0.18,0.08,0.35,0.21,0.52,0.35
		c0,0,0.01,0.01,0.02,0.01c0.03,0.03,0.06,0.06,0.09,0.09c0.13,0.13,0.26,0.26,0.37,0.38c0.21,0.24,0.43,0.43,0.66,0.61
		c0.22,0.18,0.46,0.34,0.71,0.51c0.08,0.05,0.16,0.11,0.24,0.17c0.07,0.05,0.14,0.09,0.21,0.14c0.29,0.21,0.52,0.44,0.75,0.67
		c0.28,0.28,0.54,0.55,0.9,0.75c0.14,0.08,0.28,0.15,0.42,0.21c0.24,0.1,0.48,0.16,0.78,0.2c0.36,0.05,0.52,0.06,0.66,0.01
		c0.08-0.03,0.16-0.08,0.26-0.15c0.1-0.07,0.21-0.1,0.3-0.14c0,0,0.01,0,0.01,0c-0.07-0.07-0.1-0.17-0.1-0.27c0,0,0-0.02,0-0.05
		c0.01-0.2,0.05-0.95,0.15-1.15c0,0,0,0,0,0v0c0.02-0.04,0.06-0.11,0.11-0.18c0.1-0.13,0.25-0.26,0.47-0.21
		c0.22,0.05,0.31,0.22,0.34,0.43c0.02,0.12,0.02,0.26,0.02,0.39c0,0.01,0,0.02,0,0.04c0,0,0.01,0,0.01,0
		c0.03-0.01,0.06-0.02,0.1-0.03c0,0,0,0,0.01,0c0.11-0.03,0.25-0.04,0.41,0c0.06,0.02,0.11,0.04,0.15,0.07
		c0.07,0.05,0.12,0.1,0.16,0.16c0,0.01,0.01,0.02,0.02,0.02c0,0,0,0.01,0.01,0.01c0.01,0.02,0.02,0.05,0.03,0.07l0.23,0.09
		c0.76,0.29,1.7,0.66,2.3,1.22c0.15,0.14,0.25,0.28,0.32,0.41c0.1,0.19,0.14,0.38,0.14,0.56c0,0.1-0.01,0.2-0.01,0.29
		c-0.01,0.11-0.02,0.22-0.01,0.36c0,0.06,0.02,0.15,0.04,0.24c0.02,0.11,0.05,0.23,0.06,0.34c0.01,0.11,0.02,0.23,0,0.33
		c0.06,0.02,0.12,0.04,0.16,0.06c0.04,0.01,0.08,0.03,0.12,0.04c0.12,0.04,0.23,0.08,0.32,0.13c0.11,0.06,0.21,0.14,0.32,0.23
		c0.11,0.09,0.21,0.17,0.3,0.2c0.04,0.02,0.07,0.02,0.11,0.02c0.03,0,0.06,0,0.11-0.01c0.02,0,0.04,0,0.07-0.01
		c0.04-0.01,0.07-0.01,0.12-0.01c0.07,0,0.16,0,0.25,0.02c0.19,0.04,0.38,0.13,0.58,0.22c0.12,0.06,0.25,0.12,0.38,0.19
		c0.17,0.09,0.34,0.18,0.51,0.25c0.03-0.09,0.09-0.18,0.17-0.23c0.73-0.43,1.08,0.11,1.27,0.4c0.07,0.11,0.15,0.22,0.24,0.33
		c0.13-0.1,0.26-0.16,0.38-0.19c0.12-0.03,0.24-0.02,0.36,0.01c0.41,0.12,0.56,0.57,0.68,0.93c0.01,0.03,0.02,0.07,0.03,0.1
		c0.06,0.02,0.11,0.04,0.14,0.06c0.08,0.05,0.13,0.12,0.17,0.2c0.01,0.01,0.01,0.03,0.01,0.05c0.01,0.03,0.02,0.05,0.03,0.08
		c0.03,0.15,0.02,0.34-0.02,0.55c-0.02,0.11-0.04,0.22-0.06,0.34l-0.04,0.21c-0.04,0.23-0.08,0.46-0.14,0.67
		c-0.11,0.39-0.26,0.72-0.54,0.87c-0.02,0.01-0.03,0.02-0.05,0.02c0.06,0.23,0.08,0.45,0.05,0.64c-0.05,0.29-0.18,0.53-0.3,0.74
		c-0.08,0.15-0.16,0.29-0.2,0.43c-0.07,0.27-0.02,0.49,0.05,0.8c0.02,0.09,0.04,0.18,0.06,0.28c0.15,0.73,0.35,1.32,0.66,1.88
		c0.1,0.19,0.22,0.37,0.35,0.56c0.12,0.18,0.26,0.36,0.4,0.55c0.03,0.04,0.05,0.07,0.08,0.11c0.16,0.22,0.32,0.44,0.47,0.66
		c0.04,0.07,0.08,0.14,0.13,0.21c0.04,0.06,0.08,0.13,0.12,0.19c0.08,0.14,0.14,0.27,0.2,0.41c0.17,0.4,0.32,1.02,0.22,1.52
		c-0.02,0.09-0.04,0.16-0.07,0.23c0.13,0.07,0.27,0.18,0.44,0.3c0.06,0.04,0.1,0.09,0.13,0.13c0.06,0.08,0.09,0.16,0.09,0.23
		c0.04,0.06,0.08,0.13,0.11,0.19c0.01,0.02,0.02,0.04,0.03,0.05c0.04,0.08,0.07,0.14,0.1,0.2c0.23,0.54,0.17,1.14,0.12,1.67
		c-0.04,0.36-0.07,0.71-0.01,0.99c0.05,0.25,0.04,0.43,0.02,0.59c-0.01,0.09-0.03,0.18-0.05,0.26c-0.02,0.09-0.04,0.2-0.06,0.33
		c0.06,0.01,0.11,0.02,0.18,0.04c0.32,0.11,0.54,0.44,0.66,0.72c0.04,0.09,0.07,0.18,0.09,0.25c0.13,0.51,0.24,0.78,0.48,1.28
		c0.09,0.18,0.13,0.37,0.17,0.55c0.01,0.06,0.03,0.12,0.04,0.17c0.01,0.03,0.02,0.07,0.03,0.1c0.03,0.08,0.06,0.15,0.09,0.21
		c0.02,0.03,0.07,0.06,0.12,0.1c0.1,0.07,0.24,0.17,0.34,0.34c0.12,0.18,0.2,0.37,0.27,0.54c0.04,0.1,0.08,0.19,0.13,0.28
		c0.05,0.09,0.1,0.17,0.16,0.25c0.24,0.31,0.43,0.56,0.61,0.85c0.01,0.02,0.02,0.03,0.03,0.05c0.06,0.1,0.12,0.19,0.17,0.27
		c0.19,0.27,0.32,0.5,0.31,0.81c0,0,0,0,0,0.01c0,0.13-0.03,0.27-0.09,0.43c-0.03,0.08-0.06,0.17-0.1,0.26
		c-0.32,0.72-0.35,0.79,0,1.07c0.02-0.02,0.05-0.04,0.08-0.06c0.31-0.22,0.67-0.27,1-0.16c0.09,0.03,0.18,0.07,0.27,0.12
		c0.12,0.07,0.22,0.17,0.32,0.28c0.22,0.25,0.39,0.57,0.45,0.9c0.03,0.17,0.04,0.34,0.01,0.49c-0.03,0.19-0.13,0.36-0.22,0.52
		c0,0.01-0.01,0.02-0.01,0.02c0.06,0.03,0.1,0.06,0.14,0.09c0.02,0.02,0.05,0.04,0.06,0.06c0.14,0.2,0.3,0.62,0.31,0.92
		c0,0.07,0,0.14-0.01,0.19c-0.03,0.17-0.1,0.28-0.15,0.35c-0.01,0.01-0.01,0.01-0.01,0.02c-0.01,0.02-0.02,0.03-0.02,0.03
		c-0.02,0.15,0,0.29,0.04,0.47c0.02,0.1,0.04,0.22,0.06,0.35c0,0.01,0,0.01,0,0.02c0,0.01,0,0.01,0,0.02
		c0.02,0.1,0.05,0.24,0.05,0.38c0,0,0,0,0,0c0.04,0.02,0.09,0.04,0.15,0.07c0.01,0,0.02,0.01,0.03,0.01
		c0.09,0.04,0.16,0.07,0.21,0.11c0.01,0.01,0.04,0.02,0.05,0.03c0.11,0.08,0.31,0.25,0.5,0.44c0.02,0.02,0.04,0.04,0.06,0.06
		c0.01,0.01,0.02,0.02,0.02,0.03c0.03,0.03,0.06,0.06,0.09,0.1c0.02,0.03,0.04,0.05,0.06,0.08c0.01,0.01,0.02,0.03,0.03,0.04
		c0.03,0.04,0.05,0.07,0.07,0.1c0.09,0.15,0.12,0.27,0.15,0.38c0.01,0.05,0.02,0.09,0.04,0.13c0.02,0.04,0.04,0.07,0.07,0.11
		c0.49,0.56,0.95,0.74,1.63,0.87c0.18,0.03,0.31,0.19,0.31,0.38c0,0.28,0,0.7-0.27,0.93c-0.13,0.11-0.3,0.16-0.48,0.13
		c-0.03,0-0.05-0.01-0.08-0.02c0.14,0.15,0.31,0.32,0.46,0.46c0.12,0.12,0.23,0.21,0.31,0.29c0.02,0.02,0.05,0.04,0.06,0.06
		c0.16,0.14,0.28,0.26,0.35,0.33c0,0,0.01,0.01,0.01,0.01h0c0,0,0,0,0,0c0.07,0.08,0.13,0.16,0.2,0.23c0.29,0.35,0.5,0.7,0.65,1.05
		c0.06,0.15,0.1,0.33,0.13,0.5c0.03,0.16,0.07,0.38,0.13,0.45c0.09,0.13,0.38,0.25,0.6,0.34c0.11,0.05,0.21,0.09,0.29,0.13
		c0.19,0.1,0.39,0.22,0.6,0.34c0.08,0.05,0.16,0.09,0.23,0.14c0.02,0.01,0.05,0.03,0.07,0.04c0.05,0.03,0.11,0.06,0.16,0.09
		c0.03,0.02,0.06,0.03,0.08,0.04c0.05,0.03,0.1,0.05,0.15,0.08c0.03,0.01,0.06,0.02,0.08,0.04c0.05,0.02,0.09,0.04,0.13,0.05
		c0.03,0,0.07,0.01,0.11,0.01c0.4,0.04,0.55,0.06,0.64,0.27c0,0,0,0,0,0c0,0,0,0,0,0c0.09,0.23,0,0.42-0.09,0.54
		c-0.03,0.04-0.06,0.08-0.08,0.1c-0.03,0.03-0.08,0.09-0.09,0.11c0.02,0.06,0.12,0.15,0.21,0.22c0.02,0.01,0.03,0.03,0.05,0.04
		c0.05,0.04,0.1,0.09,0.15,0.13c0.06,0.06,0.12,0.12,0.18,0.19c0.04,0.05,0.08,0.09,0.12,0.15c0,0,0,0,0,0c0,0,0,0,0,0
		c0.08,0.17,0.08,0.33,0.07,0.43c0,0.02,0,0.05,0,0.07c0.02,0.03,0.03,0.05,0.03,0.05c0.05,0.04,0.1,0.09,0.15,0.16
		c0.02,0.03,0.04,0.05,0.07,0.09c0.11,0.17,0.22,0.34,0.31,0.52c0.1,0.18,0.19,0.36,0.28,0.54c0.09,0.17,0.17,0.34,0.26,0.5
		c0.13,0.25,0.27,0.49,0.44,0.69c0.12,0.15,0.27,0.29,0.44,0.44c0.16,0.15,0.32,0.3,0.46,0.47c0.03,0.03,0.08,0.07,0.12,0.11
		c0.05,0.04,0.1,0.08,0.13,0.12c0.01,0.01,0.02,0.02,0.03,0.03c0.08,0.02,0.14,0.06,0.2,0.09c0.08,0.04,0.14,0.11,0.18,0.2
		c0,0,0,0.01,0.01,0.01c0.07-0.03,0.15-0.07,0.23-0.09c0.31-0.07,0.56-0.07,0.78-0.01c0.36,0.1,0.63,0.36,0.91,0.74
		c0.06,0.08,0.08,0.18,0.07,0.27c0.05-0.03,0.08-0.05,0.11-0.07c0.09-0.06,0.11-0.12,0.16-0.26c0.02-0.06,0.05-0.12,0.08-0.18
		c-0.02-0.06-0.04-0.12-0.04-0.2c0-0.04,0-0.08,0.01-0.12c0.01-0.04,0.02-0.08,0.05-0.12c0.07-0.13,0.2-0.21,0.37-0.25
		c0.18-0.04,0.31-0.01,0.41,0.03c0.06,0.03,0.11,0.06,0.15,0.1c0.1,0.11,0.14,0.23,0.14,0.35c0.01,0.12-0.02,0.22-0.04,0.3
		c0.02,0.07,0.03,0.12,0.05,0.17c0.01,0.02,0.01,0.05,0.02,0.08c0.55,0.03,1.11,0.19,1.64,0.48c0.08,0.04,0.16,0.1,0.25,0.18
		c0,0,0,0,0.01,0c0.33-0.19,0.73-0.02,1.12,0.15c0.12,0.05,0.3,0.13,0.36,0.13c0.12,0.01,0.25,0.08,0.31,0.19
		c0.09,0.16,0.12,0.3,0.11,0.41c-0.01,0.11-0.05,0.19-0.08,0.25c-0.15,0.23-0.43,0.29-0.75,0.28c-0.01,0-0.02,0-0.04,0
		c-0.09,0-0.19-0.01-0.29-0.02c-0.01,0.03-0.03,0.05-0.05,0.08c0,0,0,0.01-0.01,0.01c0.01,0.02,0.03,0.05,0.03,0.08
		c0.02,0.06,0.03,0.12,0.02,0.17c0,0.06-0.02,0.11-0.05,0.16c0.29,0.03,0.48,0.14,0.54,0.31c0,0.01,0,0.02,0,0.03
		c0.02,0.05,0.03,0.11,0.02,0.17c0,0,0,0,0,0c0,0,0,0,0,0c-0.01,0.14-0.06,0.27-0.17,0.36c-0.07,0.06-0.15,0.1-0.23,0.12
		c0.01,0.05,0.01,0.1,0.01,0.15c-0.02,0.27-0.25,0.6-0.58,0.7c0,0-0.01,0-0.02,0c-0.06,0.02-0.14,0.03-0.24,0.02
		c0.06,0.18,0.17,0.38,0.25,0.53c0.14,0.25,0.25,0.46,0.29,0.67c0.04,0.23,0.02,0.44,0.01,0.62c-0.01,0.19-0.02,0.36,0.02,0.53
		c0.09,0.32,0.13,0.51,0.13,0.72c0.11-0.04,0.24-0.06,0.38-0.02c0.56,0.16,0.55,0.56,0.55,0.73c0,0.06,0,0.1,0.01,0.14
		c0-0.01,0.01-0.01,0.01,0c0.01,0.01,0.02,0.02,0.03,0.03c0.29,0.3,0.29,0.55,0.2,0.74c-0.01,0.03-0.03,0.05-0.04,0.07
		c0.27,0.16,0.54,0.36,0.82,0.7c0.11,0.14,0.21,0.3,0.3,0.45c0.05,0.08,0.11,0.17,0.16,0.25c0.04,0.06,0.08,0.11,0.12,0.15
		c0,0,0,0,0.01,0c0.04,0,0.07,0,0.11,0.01c0.01,0,0.02,0,0.03,0c0.07-0.02,0.16-0.01,0.23,0.02c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0.03,0.01,0.06,0.03,0.09,0.05c0.08,0.04,0.15,0.08,0.21,0.13c0.03,0.02,0.06,0.04,0.08,0.06c0.02,0.01,0.03,0.02,0.05,0.04
		c0.06,0.05,0.12,0.09,0.18,0.11c0.28,0.12,0.6,0.22,0.92,0.31c0.03,0.01,0.06,0.02,0.09,0.03c0.19,0.06,0.38,0.11,0.55,0.18
		c0.24,0.09,0.34,0.08,0.51,0.05c0,0,0.01,0,0.01,0c0.1-0.02,0.22-0.03,0.36-0.04c0.03,0,0.08,0,0.14,0c0.01,0,0.02,0,0.02,0
		c0.04,0,0.09,0,0.14,0.01c0.04,0,0.09,0.01,0.13,0.02c0.01,0,0.03,0,0.04,0.01c0.05,0.01,0.1,0.03,0.14,0.05
		c0.28,0.17,0.49,0.43,0.63,0.7c0.14,0.27,0.22,0.54,0.27,0.74c0.01,0.05,0.03,0.13,0.06,0.23c0.13,0.48,0.2,0.8,0.22,1.03
		c0,0.08,0,0.14-0.01,0.2c-0.02,0.09-0.07,0.18-0.14,0.23c0.03,0.13,0.04,0.25,0.06,0.37c0.01,0.12,0.03,0.24,0.06,0.37
		c0.04,0.18,0.09,0.38,0.16,0.58c0.21,0.6,0.59,1.23,1.27,1.6c0.08,0.04,0.14,0.07,0.2,0.08C104.29,97.03,104.38,96.99,104.53,96.94
		z"
          />
          <path
            id="Guerrero"
            d="M229.69,248.42c0.5-0.27,1-0.28,1.4-0.28c0.26,0,0.51-0.01,0.59-0.1c0.04-0.05,0.14-0.22,0.1-0.79
		c-0.06-0.86,0.21-1.1,0.7-1.37c0.08-0.05,0.18-0.11,0.28-0.16c0.25-0.14,0.47-0.33,0.56-0.49c0.03-0.05,0.04-0.1,0.04-0.14
		c0-0.03,0.01-0.13-0.18-0.26c-0.08-0.06-0.14-0.15-0.15-0.25c0,0,0,0,0,0v0c0,0,0,0,0,0c-0.01-0.04,0-0.08,0-0.12
		c0.03-0.14,0.14-0.26,0.28-0.3c0,0,0,0,0,0c0,0,0,0,0,0c0.31-0.08,0.57-0.06,0.78-0.05c0.28,0.02,0.45,0.03,0.73-0.17
		c0.4-0.29,0.5-0.79,0.62-1.37c0.06-0.3,0.12-0.6,0.23-0.9c0.09-0.25,0.21-0.45,0.3-0.64c0.24-0.44,0.35-0.65,0.1-1.08
		c-0.13-0.23-0.32-0.42-0.54-0.61c-0.12-0.09-0.24-0.19-0.37-0.28c-0.25-0.19-0.49-0.37-0.69-0.56c-0.04-0.05-0.09-0.09-0.13-0.14
		c-0.01-0.01-0.03-0.03-0.04-0.04c-0.16-0.17-0.34-0.31-0.5-0.44c-0.06-0.05-0.12-0.09-0.18-0.14c-0.16-0.07-0.34-0.17-0.48-0.35
		c-0.26-0.32-0.28-0.71-0.24-1.07c0-0.07,0-0.14,0.01-0.21c0.02-0.2,0.06-0.38,0.09-0.54c0.02-0.11,0.05-0.2,0.06-0.3
		c0-0.29-0.05-0.54-0.16-0.82c-0.05-0.09-0.1-0.18-0.15-0.27c-0.11-0.2-0.23-0.41-0.32-0.65c-0.33-0.94-0.05-2.38,0.17-3.08
		c0.02-0.07,0.05-0.15,0.09-0.23c0.01-0.04,0.02-0.08,0.04-0.12c0.05-0.14,0.14-0.34,0.23-0.55c0.1-0.23,0.28-0.62,0.32-0.82
		c0-0.05-0.01-0.08-0.04-0.1c-0.05-0.04-0.09-0.09-0.12-0.14c-0.11-0.11-0.21-0.26-0.31-0.4c-0.03-0.05-0.07-0.1-0.09-0.13
		c-0.06-0.08-0.11-0.15-0.16-0.22c-0.1-0.14-0.17-0.24-0.24-0.31c-0.04-0.03-0.08-0.07-0.12-0.1c-0.04-0.02-0.09-0.04-0.14-0.05
		c-0.05,0-0.12,0.01-0.18,0.02l-0.14,0.02c-0.4,0.04-0.73,0.07-1.12,0.05c-0.01,0-0.02,0-0.03,0c-0.05,0-0.11-0.01-0.17-0.02
		c-0.18-0.02-0.35-0.05-0.53-0.09c-0.31-0.07-0.62-0.17-0.92-0.3c-0.04-0.02-0.09-0.04-0.13-0.06c-0.38-0.19-0.73-0.44-0.99-0.77
		c-0.27-0.34-0.42-0.73-0.45-1.15c-0.29,0.03-0.65-0.01-0.88-0.38c-0.14-0.23-0.16-0.45-0.18-0.61c0-0.01,0-0.03,0-0.05
		c-0.13,0.01-0.28-0.01-0.4-0.12c-0.04-0.03-0.06-0.07-0.09-0.11c-0.07-0.09-0.1-0.19-0.12-0.27c-0.05-0.04-0.09-0.08-0.12-0.12
		l-0.16,0c-0.07,0-0.14-0.02-0.2-0.06c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c-0.03-0.02-0.06-0.05-0.09-0.08c-0.07-0.09-0.1-0.21-0.07-0.32c0-0.01,0-0.01,0-0.02c0-0.03,0-0.06-0.04-0.15
		c-0.04-0.1-0.1-0.24-0.1-0.42c0-0.09,0.01-0.16,0.02-0.23c-0.03-0.19-0.04-0.38,0.01-0.58c-0.04-0.13-0.04-0.25-0.01-0.36
		c-0.03-0.08-0.06-0.16-0.11-0.24c-0.03-0.03-0.05-0.06-0.09-0.09c-0.11-0.11-0.23-0.23-0.32-0.39c-0.09-0.16-0.19-0.41-0.27-0.63
		c-0.14-0.15-0.21-0.34-0.2-0.56c-0.09,0-0.19,0-0.28,0c0,0.01,0,0.02-0.01,0.03c-0.03,0.12-0.07,0.29-0.2,0.46
		c-0.01,0.02-0.03,0.04-0.05,0.06c-0.08,0.14-0.21,0.27-0.42,0.34c-0.32,0.11-0.83,0.1-1.39-0.62c0,0,0,0,0,0
		c-0.8-0.77-1.51-2.06-1.67-2.53c-0.04-0.03-0.08-0.06-0.12-0.09c-0.06-0.03-0.13-0.06-0.18-0.09c-0.16-0.08-0.32-0.15-0.45-0.27
		c-0.1-0.09-0.18-0.19-0.26-0.29c-0.05-0.05-0.09-0.11-0.14-0.17c-0.07-0.08-0.21-0.25-0.32-0.35c0,0-0.01,0-0.01-0.01
		c-0.16-0.07-0.33,0.1-0.67,0.52c-0.08,0.1-0.16,0.19-0.24,0.29c-0.22,0.29-0.49,0.55-0.87,0.64c-0.4,0.09-0.73-0.05-1.03-0.16
		c-0.11-0.04-0.21-0.08-0.31-0.11c-0.23-0.03-0.42,0.02-0.77,0.09l-0.13,0.03c-0.1,0.02-0.21,0.04-0.33,0.06
		c-0.23,0.06-0.47,0.12-0.72,0.15c-0.2,0.03-0.28,0.05-0.3,0.06c0,0,0,0,0,0c0.01,0,0,0.05-0.02,0.1c-0.03,0.13-0.08,0.3-0.19,0.53
		c-0.02,0.04-0.04,0.08-0.07,0.12c-0.01,0.03-0.03,0.05-0.05,0.08c-0.32,0.51-1.23,1.18-1.73,1.52c-0.17,0.15-0.36,0.27-0.56,0.32
		c-0.07,0.02-0.13,0.03-0.2,0.03c-0.15,0.02-0.3,0.01-0.43-0.05c-0.26-0.1-0.44-0.3-0.58-0.56c-0.51-0.76-0.84-2.27-1.01-4.49
		c-0.01-0.15-0.02-0.29-0.02-0.42c-0.02-0.53-0.02-0.64-0.43-0.84c-0.03-0.01-0.06-0.03-0.09-0.04c-0.01,0-0.03-0.01-0.04-0.02
		l-0.13-0.05c-0.07-0.02-0.13-0.04-0.2-0.06c-0.07-0.02-0.16-0.04-0.25-0.08c-0.03,0.03-0.05,0.06-0.08,0.08
		c-0.1,0.1-0.2,0.21-0.32,0.31c-0.12,0.12-0.25,0.24-0.44,0.3c-0.13,0.08-0.26,0.13-0.42,0.09c-0.04-0.01-0.08-0.02-0.12-0.04
		c-0.07-0.02-0.13-0.05-0.19-0.09c-0.16-0.12-0.2-0.29-0.21-0.43c-0.04-0.1-0.06-0.21-0.08-0.31c0-0.02-0.01-0.05-0.02-0.08
		c-0.01,0.02-0.03,0.06-0.06,0.12c-0.02,0.05-0.03,0.1-0.05,0.18c0,0.01-0.01,0.03-0.01,0.04c0,0.09,0.01,0.2,0.03,0.34
		c0.01,0.08,0.02,0.16,0.03,0.24c0.07,0.09,0.16,0.23,0.15,0.42c-0.01,0.11-0.05,0.19-0.1,0.26c0,0.14-0.01,0.29-0.02,0.43
		c-0.02,0.35-0.03,0.68,0.02,1.03c0.02,0.12,0.03,0.23,0.05,0.34c0.01,0.09,0.02,0.17,0.03,0.25c0.1,0.37,0.26,0.7,0.46,0.98
		c0.07,0.07,0.16,0.14,0.23,0.2c0.43,0.37,0.8,0.69,0.78,1.07c0,0.05-0.03,0.13-0.08,0.21c-0.04,0.15-0.13,0.29-0.28,0.42
		c-0.11,0.15-0.2,0.26-0.21,0.27c-0.08,0.07-0.2,0.11-0.3,0.1c-0.1-0.01-0.17-0.05-0.24-0.09c-0.13-0.05-0.2-0.15-0.27-0.26
		c-0.07-0.05-0.22-0.17-0.28-0.2c-0.02,0-0.04,0-0.05,0c-0.05,0-0.09,0-0.13-0.01c-0.09,0.03-0.18,0.03-0.27,0.02
		c-0.28-0.05-0.44-0.27-0.54-0.42c-0.03-0.04-0.06-0.09-0.08-0.11c-0.01-0.01-0.02-0.02-0.03-0.03c-0.08-0.08-0.16-0.17-0.23-0.25
		c-0.21-0.24-0.3-0.34-0.48-0.35c-0.1,0-0.2,0.06-0.35,0.15c-0.25,0.15-0.61,0.37-1.12,0.18c-0.04-0.01-0.08-0.03-0.12-0.05
		c-0.05-0.02-0.09-0.06-0.13-0.1c0,0,0,0,0,0h0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c-0.04-0.08-0.07-0.16-0.09-0.24c-0.09-0.02-0.17-0.07-0.21-0.15c0,0,0,0,0-0.01
		c-0.02-0.04-0.05-0.06-0.07-0.09c-0.05-0.06-0.12-0.13-0.17-0.24c-0.1,0.03-0.18,0.05-0.26,0.06c0,0-0.01,0-0.01,0
		c-0.07,0.01-0.13,0.02-0.18,0.02c-0.37,0-0.73-0.1-1.05-0.19l-0.09-0.02c-0.07-0.02-0.13-0.04-0.2-0.06
		c-0.13-0.04-0.25-0.09-0.37-0.13c-0.07-0.03-0.15-0.06-0.22-0.08c-0.18-0.07-0.36-0.12-0.53-0.14c-0.05-0.01-0.11-0.01-0.15-0.01h0
		c-0.07,0-0.15,0-0.22,0.01c-0.21,0.02-0.44,0.07-0.68,0.12c-0.68,0.14-1.45,0.31-2.14-0.11c-0.03-0.02-0.05-0.03-0.07-0.05
		c-0.02-0.01-0.03-0.02-0.05-0.04c-0.37-0.26-0.55-0.62-0.7-0.94c-0.18-0.36-0.29-0.55-0.53-0.61c-0.41-0.1-0.93-0.13-1.38-0.07
		c-0.34,0.06-0.64,0.16-0.8,0.3c0,0,0,0-0.01,0.01c0,0,0,0,0,0c-0.43,0.38-0.37,1.15-0.31,1.97c0.08,1.07,0.18,2.39-1.11,2.87
		c-0.05,0.02-0.1,0.03-0.15,0.02c-0.06,0-0.13-0.01-0.19-0.02c-0.01,0.01-0.01,0.01-0.02,0.02c-0.01,0.01-0.03,0.03-0.04,0.04
		c-0.05,0.04-0.1,0.07-0.16,0.1c-0.24,0.07-0.41,0.08-0.57,0.09l-0.07,0c-0.19,0.03-0.38,0.04-0.56,0.05
		c-0.46,0.02-0.63,0.05-0.77,0.25c-0.02,0.04-0.05,0.09-0.07,0.13c-0.01,0.02-0.02,0.04-0.03,0.06c-0.02,0.05-0.04,0.11-0.06,0.17
		c-0.01,0.02-0.02,0.04-0.02,0.06c-0.02,0.07-0.04,0.14-0.06,0.21c0,0.02-0.01,0.03-0.01,0.05c-0.02,0.07-0.03,0.14-0.04,0.21
		c0,0.03-0.01,0.05-0.01,0.08c-0.01,0.06-0.02,0.13-0.03,0.2c0,0.04-0.01,0.07-0.02,0.11c-0.01,0.07-0.01,0.14-0.02,0.22
		c0,0.03-0.01,0.07-0.01,0.1c-0.01,0.07-0.01,0.15-0.01,0.22c0,0.03,0,0.07-0.01,0.1c0,0.11-0.01,0.22-0.01,0.32
		c0.08-0.05,0.17-0.12,0.27-0.21c0.34-0.29,0.8-0.68,1.65-0.41c0.83,0.26,2.16,1.28,2.59,1.98c0.12,0.2,0.21,0.39,0.29,0.57
		c0.09,0.2,0.17,0.38,0.29,0.56c0.08,0.11,0.17,0.21,0.25,0.32c0.19,0.23,0.38,0.46,0.52,0.74c0.04,0.08,0.08,0.16,0.12,0.25
		c0.05,0.11,0.1,0.22,0.15,0.32c0.16,0.31,0.34,0.58,0.62,0.64c0.03,0.01,0.06,0.02,0.08,0.03c0.01,0,0.1,0,0.16,0
		c0.09,0,0.19,0,0.3,0.02c0.01,0,0.01,0,0.02,0c0.05,0.01,0.09,0.02,0.14,0.03c0,0,0,0,0,0c0.05,0.02,0.11,0.04,0.16,0.06
		c0,0,0,0,0,0s0,0,0,0c0.21,0.11,0.35,0.26,0.45,0.43c0.1,0.17,0.16,0.35,0.21,0.5c0.02,0.07,0.04,0.13,0.07,0.19
		c0.11,0.26,0.12,0.29,0.46,0.49c0.15,0.09,0.31,0.17,0.47,0.26c0.42,0.23,0.85,0.46,1.24,0.79c0.18,0.15,0.36,0.29,0.54,0.44
		c0.31,0.25,0.63,0.5,0.92,0.77c0.25,0.22,0.44,0.45,0.61,0.69c0.11,0.16,0.16,0.37,0.21,0.53c0.01,0.05,0.02,0.09,0.03,0.12
		c0.06,0.02,0.14,0.05,0.23,0.1c0.06,0.03,0.12,0.07,0.18,0.1c0.07,0.04,0.15,0.08,0.22,0.12c0.25,0.12,0.47,0.27,0.68,0.41
		c0.13,0.08,0.25,0.17,0.39,0.25c0.33,0.19,0.69,0.32,1.08,0.45c0.23,0.08,0.47,0.16,0.69,0.26c0.28,0.12,0.53,0.27,0.77,0.41
		c0.2,0.12,0.4,0.24,0.6,0.33c0.34,0.16,0.69,0.31,1.03,0.47c0.94,0.42,1.92,0.86,2.86,1.38c0.24,0.13,0.46,0.3,0.68,0.45
		c0.23,0.17,0.45,0.33,0.67,0.44c0.14,0.07,0.31,0.09,0.5,0.11c0.19,0.02,0.41,0.05,0.63,0.13c0.25,0.1,0.45,0.25,0.62,0.38
		c0.1,0.08,0.2,0.15,0.31,0.21c0.11,0.06,0.22,0.11,0.32,0.16c0.24,0.12,0.5,0.23,0.74,0.49c0.01-0.01,0.03-0.02,0.05-0.02
		c0.48-0.2,0.78,0.21,0.89,0.37l0.04,0.06c0.08,0.11,0.1,0.25,0.06,0.37c0.01,0.03,0.02,0.05,0.04,0.08
		c0.08,0.06,0.13,0.15,0.14,0.25c0.02,0.13,0.01,0.25-0.04,0.34c0.11,0.09,0.33,0.22,0.45,0.3c0.16,0.09,0.3,0.18,0.39,0.25
		c0.38,0.3,0.82,0.49,1.34,0.58c0.24,0.05,0.48,0.12,0.74,0.2c0.32,0.1,0.66,0.2,1.01,0.25c0.13,0.02,0.25,0.03,0.38,0.04
		c0.41,0.04,0.83,0.09,1.24,0.25c0.19,0.07,0.33,0.18,0.45,0.27c0.07,0.05,0.13,0.1,0.2,0.14c0.16,0.08,0.35,0.11,0.57,0.15
		l0.21,0.04c0.33,0.06,0.61,0.18,0.89,0.29c0.22,0.09,0.43,0.17,0.66,0.23c0.14,0.03,0.27,0.05,0.41,0.07
		c0.28,0.04,0.59,0.09,0.89,0.24c0.19,0.09,0.39,0.25,0.62,0.44c0.17,0.14,0.51,0.41,0.73,0.52c0.05-0.18,0.17-0.25,0.27-0.28
		c0.04-0.01,0.11-0.02,0.18-0.03c0.08-0.01,0.16-0.01,0.24-0.01c0.16,0,0.32,0.01,0.41,0.04c0.66,0.18,0.87,0.59,1.09,1.03
		l0.07,0.13c0.22,0.41,0.5,0.75,0.8,1.12c0.13,0.15,0.26,0.31,0.38,0.47c0.04,0.05,0.08,0.11,0.12,0.16
		c0.06,0.09,0.13,0.17,0.19,0.26C229.34,248.62,229.52,248.51,229.69,248.42z"
          />
          <path
            id="Chiapas"
            d="M329.16,257.08c-0.02-0.05-0.05-0.09-0.07-0.12c-0.01,0-0.03,0-0.04,0c-0.1,0.01-0.28,0.02-0.46-0.08
		c-0.1-0.06-0.35-0.2-0.37-0.47c-0.03-0.01-0.06-0.03-0.08-0.04c-0.06-0.03-0.11-0.06-0.16-0.08c-0.01,0-0.02-0.01-0.02-0.01
		c-0.19-0.08-0.32-0.16-0.42-0.23c-0.03,0-0.06,0-0.09,0.01c-0.3,0.02-0.7,0.04-1.02-0.3c-0.08-0.09-0.12-0.22-0.1-0.34
		c0.02-0.12,0.1-0.21,0.21-0.27c-0.07-0.09-0.15-0.2-0.21-0.33c-0.21-0.42-0.18-0.65-0.12-0.92c0.02-0.11,0.05-0.2,0.04-0.35
		c-0.02-0.34-0.48-1.11-0.81-1.45c-0.03-0.03-0.09-0.06-0.15-0.09c-0.12-0.06-0.26-0.14-0.37-0.28c-0.07-0.08-0.11-0.19-0.15-0.3
		c-0.06,0.02-0.13,0.03-0.2,0.03c-0.07,0-0.15-0.01-0.23-0.05c-0.13-0.06-0.24-0.17-0.35-0.28c-0.04-0.04-0.08-0.08-0.11-0.1
		c-0.05-0.03-0.06-0.03-0.09-0.04c-0.18-0.02-0.33-0.08-0.53-0.32c-0.09-0.11-0.14-0.25-0.17-0.38c0,0-0.01,0.01-0.01,0.01
		c-0.1,0.07-0.25,0.08-0.36,0.04c-0.21-0.07-0.3-0.24-0.36-0.34c0,0,0-0.01-0.01-0.01c-0.42-0.15-0.51-0.22-0.73-0.57
		c-0.17-0.28-0.4-0.7-0.56-0.99c-0.07-0.13-0.08-0.25-0.08-0.33c-0.11-0.01-0.25-0.03-0.37-0.12c-0.4-0.28-0.52-0.64-0.61-0.93
		c-0.05-0.15-0.09-0.28-0.17-0.4c-0.01,0-0.03-0.01-0.04-0.01c-0.11-0.04-0.32-0.12-0.45-0.38c-0.1-0.19-0.07-0.39-0.05-0.52
		c0-0.01,0-0.02,0.01-0.03c-0.06-0.02-0.14,0-0.19,0.01c-0.14,0.03-0.31,0.06-0.49,0.01c-0.06-0.02-0.12-0.04-0.18-0.08
		c-0.09-0.06-0.17-0.16-0.31-0.33c-0.07-0.08-0.17-0.22-0.22-0.25c-0.03-0.02-0.06-0.05-0.1-0.07c-0.03-0.02-0.06-0.04-0.09-0.07
		c-0.23-0.17-0.49-0.4-0.38-0.72c0,0,0-0.01,0-0.01c-0.03-0.05-0.06-0.1-0.08-0.16c-0.08-0.15-0.11-0.31-0.09-0.48
		c0.02-0.17,0.1-0.35,0.26-0.48c-0.08-0.01-0.17-0.04-0.26-0.1c-0.03-0.02-0.05-0.03-0.07-0.05c-0.06-0.02-0.11-0.06-0.16-0.1
		c-0.31-0.24-0.37-0.5-0.35-0.71c-0.01-0.05-0.02-0.1-0.03-0.16c-0.03-0.16-0.03-0.28-0.02-0.38c-0.07-0.02-0.15-0.05-0.19-0.06
		c-0.06-0.02-0.14-0.04-0.22-0.06c-0.35-0.08-0.78-0.18-1-0.47c-0.2-0.25-0.35-0.49-0.16-0.81c0.01-0.08,0.03-0.16,0.05-0.24
		c0.05-0.2,0.11-0.41,0.07-0.66l-0.03-0.19c-0.09-0.59-0.14-0.88-0.4-1.03c-0.06-0.01-0.14-0.02-0.23-0.02
		c-0.25-0.01-0.6-0.03-0.82-0.33c-0.1-0.13-0.1-0.27-0.11-0.77c0-0.2-0.01-0.58-0.03-0.68c-0.01-0.05-0.03-0.12-0.04-0.19
		c-0.07,0-0.14-0.01-0.22-0.04c-0.07-0.02-0.15-0.06-0.25-0.1c-0.01,0-0.01-0.01-0.02-0.01c-0.05,0.04-0.12,0.08-0.21,0.11
		c-0.09,0.1-0.23,0.19-0.45,0.2c-0.04,0-0.09,0-0.14,0c-0.2-0.02-0.36-0.09-0.47-0.22c-0.11-0.13-0.14-0.27-0.15-0.39
		c-0.01-0.06-0.02-0.12-0.03-0.17c-0.02,0.02-0.04,0.04-0.06,0.05c-0.05,0.04-0.09,0.08-0.14,0.12c-0.03,0.02-0.06,0.05-0.11,0.08
		c0,0-0.01,0.01-0.01,0.01c-0.05,0.05-0.1,0.09-0.15,0.13c0.02,0.07,0.01,0.15-0.02,0.22c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0.01-0.01,0.01c0.02,0.11,0.02,0.23-0.04,0.36c-0.12,0.25-0.4,0.34-0.49,0.37c-0.19,0.06-0.36,0.04-0.5,0.01
		c-0.11-0.02-0.21-0.06-0.29-0.1c-0.06-0.02-0.14-0.06-0.18-0.05c-0.1,0.03-0.29,0.1-0.42,0.15c-0.25,0.09-0.48,0.17-0.63,0.21
		c-0.07,0.02-0.14,0.03-0.22,0.05c-0.05,0.02-0.1,0.03-0.15,0.04c-0.06,0.01-0.14,0.03-0.23,0.04c-0.06,0.01-0.15,0.03-0.23,0.04
		c-0.07,0.02-0.14,0.04-0.21,0.06c-0.06,0.03-0.1,0.06-0.13,0.07c-0.04,0.12-0.11,0.23-0.28,0.37c-0.04,0.07-0.08,0.14-0.15,0.2
		c-0.14,0.13-0.31,0.17-0.42,0.19c0,0,0,0-0.01,0c-0.01,0-0.01,0.01-0.02,0.01c-0.1,0.06-0.2,0.12-0.31,0.19
		c-0.13,0.09-0.27,0.18-0.4,0.28c-0.27,0.21-0.54,0.44-0.8,0.67c-0.21,0.19-0.43,0.37-0.64,0.54c-0.09,0.07-0.23,0.2-0.4,0.36
		c-1.27,1.17-1.97,1.71-2.48,1.61c-0.71-0.15-1.22-1.19-1.52-2.04c0-0.01-0.01-0.02-0.01-0.03l-0.07-0.2
		c-0.02-0.06-0.03-0.1-0.05-0.14c-0.03-0.01-0.06-0.02-0.11-0.03l-0.19-0.05c-0.03-0.01-0.05-0.02-0.08-0.02
		c-0.24,0.03-0.5-0.07-0.7-0.51c-0.11-0.25-0.07-0.52-0.02-0.74c0-0.22-0.03-0.46-0.06-0.72c-0.06-0.57-0.12-1.15,0.05-1.68
		c0.05-0.16,0.1-0.27,0.16-0.38c0.07-0.14,0.11-0.23,0.13-0.42c0.01-0.11,0.02-0.23,0.03-0.34c-0.13,0.03-0.27,0.04-0.41,0.04
		c-0.15,0.01-0.3-0.01-0.42-0.05c-0.12-0.03-0.23-0.08-0.32-0.13c-0.13-0.06-0.25-0.14-0.35-0.23c-0.14-0.12-0.27-0.28-0.4-0.45
		c-0.12-0.15-0.23-0.29-0.34-0.39c-0.22-0.13-0.44-0.18-0.65-0.14c-0.04,0.02-0.08,0.05-0.12,0.08c-0.08,0.05-0.14,0.09-0.16,0.11
		c-0.05,0.04-0.11,0.06-0.17,0.07l0,0.03c-0.01,0.06-0.03,0.11-0.06,0.16c-0.06,0.08-0.08,0.19-0.11,0.32
		c-0.02,0.12-0.05,0.26-0.12,0.39c-0.03,0.06-0.07,0.12-0.1,0.17c-0.03,0.07-0.07,0.14-0.12,0.22c-0.09,0.12-0.2,0.2-0.28,0.25
		c-0.01,0.01-0.02,0.01-0.03,0.02c-0.01,0.01-0.01,0.01-0.01,0.02c-0.03,0.03-0.05,0.06-0.08,0.08c-0.02,0.03-0.04,0.05-0.06,0.07
		c0,0,0,0,0,0.01c0.01,0.01,0.01,0.03,0.02,0.04c0.04,0.09,0.1,0.19,0.1,0.31c0.03,0.1,0.03,0.21,0,0.33
		c-0.02,0.09-0.12,0.24-0.26,0.36c0,0,0,0.01,0.01,0.01c0.07,0.14,0.15,0.33,0.14,0.56c-0.01,0.11-0.03,0.2-0.06,0.29
		c-0.05,0.16-0.13,0.28-0.22,0.38c-0.04,0.05-0.09,0.1-0.13,0.15c-0.04,0.04-0.08,0.08-0.12,0.13c-0.1,0.1-0.21,0.19-0.32,0.25
		c-0.02,0.03-0.05,0.06-0.07,0.08c-0.01,0.04-0.03,0.07-0.04,0.11c-0.02,0.06-0.06,0.11-0.11,0.15c-0.08,0.07-0.16,0.13-0.23,0.19
		c-0.08,0.07-0.15,0.12-0.22,0.19c-0.14,0.15-0.28,0.31-0.41,0.51c-0.13,0.2-0.27,0.43-0.41,0.68c-0.37,0.63-0.78,1.35-1.29,1.76
		c-0.39,0.32-0.85,0.57-1.29,0.8c-0.11,0.06-0.21,0.11-0.32,0.17c-0.08,0.06-0.17,0.12-0.26,0.18c-0.32,0.22-0.67,0.47-1.03,0.62
		c0.04,0.38-0.04,0.82-0.12,1.22c-0.01,0.04-0.02,0.09-0.02,0.13c-0.02,0.11-0.04,0.21-0.05,0.28c0,0.01,0,0.01,0,0.02
		c-0.01,0.14-0.1,0.26-0.22,0.31c-0.08,0.03-0.17,0.04-0.25,0.02c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c-0.01,0-0.01,0-0.02,0c-0.02,0.09-0.03,0.21-0.04,0.33c0,0.03,0,0.06,0,0.09c0,0.11-0.01,0.21-0.01,0.28c0,0.01,0,0.03-0.01,0.04
		c-0.02,0.43-0.04,0.67-0.17,0.84c-0.15,0.2-0.45,0.34-0.9,0.55c-0.1,0.05-0.19,0.09-0.25,0.12c0,0,0,0-0.01,0
		c-0.48,0.27-0.48,0.27-0.5,0.96c-0.01,0.54-0.02,1.1-0.32,1.64c-0.02,0.03-0.04,0.06-0.06,0.09c0,0-0.01,0.01-0.01,0.01
		c-0.12,0.2-0.27,0.36-0.43,0.52c-0.01,0.01-0.01,0.01-0.02,0.02c-0.27,0.3-0.46,0.52-0.52,0.74c0,0.01,0,0.01,0,0.02
		c-0.02,0.17,0,0.2,0.11,0.34c0.06,0.08,0.14,0.17,0.21,0.3c0.05,0.06,0.09,0.13,0.12,0.21c0.1,0.24,0.12,0.49,0.12,0.72
		c0.01,0.07,0.01,0.14,0.01,0.21c0.01,0.14,0.02,0.29,0.04,0.43c0.03,0.19,0.05,0.36,0.07,0.53c0.01,0.05,0.01,0.09,0.02,0.14
		c0,0,0,0.01,0,0.01c0.01,0.06,0.01,0.12,0.01,0.17c0,0.01,0,0.03,0,0.04c0,0.06,0,0.12,0,0.18c0,0.01,0,0.02,0,0.03
		c0,0.07,0,0.14-0.01,0.21c0,0,0,0.01,0,0.01c-0.02,0.22-0.07,0.45-0.17,0.7c0,0,0,0.01,0,0.01c-0.03,0.09-0.07,0.17-0.12,0.26
		c-0.06,0.12-0.14,0.22-0.2,0.3c-0.01,0.02-0.03,0.03-0.04,0.05c0.1,0.07,0.3,0.14,0.37,0.17c0.06,0.02,0.1,0.04,0.14,0.06
		c0.21,0.1,0.41,0.14,0.61,0.19c0.48,0.11,1.02,0.23,1.21,1.16c0.01,0.04,0.03,0.09,0.05,0.14c0.06,0.13,0.15,0.33,0.03,0.56
		c-0.03,0.06-0.08,0.11-0.12,0.15c0.03,0.02,0.05,0.03,0.07,0.05c0.66,0.54,1.3,1.06,1.96,1.61c0.2,0.17,0.4,0.34,0.59,0.52
		c0.58,0.54,1.11,1.12,1.62,1.68c0.49,0.54,1,1.09,1.53,1.59l0.17,0.16c0.55,0.51,1.11,1.05,1.62,1.58
		c0.24,0.26,0.43,0.55,0.61,0.83c0.06,0.1,0.11,0.19,0.17,0.29c0.24,0.41,0.46,0.8,0.79,1.1c0.23,0.21,0.44,0.43,0.65,0.65
		c0.23,0.25,0.46,0.48,0.7,0.69c0.08,0.07,0.18,0.14,0.27,0.22c0.32,0.25,0.69,0.54,0.91,0.88c0.12,0.18,0.22,0.41,0.32,0.62
		c0.09,0.21,0.19,0.43,0.3,0.59c0.6,0.89,1.19,1.75,1.83,2.56c0.38,0.48,0.74,0.98,1.11,1.48c0.78,1.05,1.58,2.14,2.47,3.07
		c0.11,0.12,0.21,0.24,0.3,0.35c0.06,0.07,0.13,0.16,0.19,0.22c0.02-0.02,0.04-0.04,0.06-0.08c0.32-0.45,0.68-1.16,0.63-1.68
		c-0.01-0.17-0.05-0.34-0.09-0.53c-0.06-0.27-0.12-0.56-0.12-0.86c0-0.44,0.13-0.63,0.35-0.81c0.06-0.05,0.11-0.09,0.16-0.18
		c0.09-0.14,0.08-0.23,0.07-0.43c-0.01-0.11-0.02-0.24-0.01-0.38l0-0.08c0.01-0.3,0.02-0.6,0.36-0.91c0.2-0.18,0.4-0.2,0.53-0.2
		c0.02,0,0.06,0,0.07-0.01c0.2-0.15,0.21-0.31-0.15-0.94c-0.22-0.38-0.44-0.75-0.67-1.12c-0.06-0.09-0.11-0.17-0.15-0.24
		c-0.26-0.39-0.35-0.6-0.14-1.16c0.2-0.54,0.58-1.06,0.95-1.56c0.2-0.27,0.38-0.52,0.53-0.76c0.8-1.3,1.65-2.59,2.5-3.86
		c0.28-0.42,0.57-0.84,0.84-1.25c0.32-0.47,0.64-0.94,0.96-1.42c0.1-0.15,0.18-0.3,0.26-0.44c0.3-0.54,0.64-1.15,1.56-1.1
		c0.59,0.04,1.19,0.09,1.78,0.14l0.65,0.06c2.34,0.2,4.67,0.41,7.01,0.62l3.69,0.33c0.1,0.01,0.3,0.04,0.52,0.08
		c0.25,0.04,0.75,0.13,1.07,0.15c-0.03-0.09-0.04-0.2,0-0.33c0.09-0.25,0.26-0.34,0.39-0.38c-0.09-0.08-0.21-0.2-0.25-0.4
		c-0.07-0.33-0.02-0.55,0.16-0.71c-0.07-0.13-0.1-0.31,0.01-0.56c0.06-0.14,0.18-0.22,0.32-0.24c-0.05-0.1-0.08-0.22-0.03-0.36
		c0.09-0.21,0.24-0.32,0.37-0.39c-0.05-0.15-0.03-0.29,0.05-0.42C329.1,257.13,329.13,257.1,329.16,257.08z"
          />
          <path
            id="Oaxaca"
            d="M284.07,255.2c0.06-0.26,0.07-0.53,0.04-0.85c-0.01-0.08-0.02-0.17-0.04-0.26c-0.02-0.12-0.04-0.24-0.06-0.37
		c-0.02-0.14-0.02-0.3-0.02-0.45c0-0.03,0-0.06,0-0.08c-0.02-0.2-0.05-0.38-0.12-0.54c-0.02-0.03-0.05-0.06-0.08-0.08
		c-0.11-0.12-0.25-0.26-0.32-0.47c-0.09-0.28-0.08-0.53-0.01-0.76c0.11-0.43,0.4-0.73,0.67-1.01c0.01-0.01,0.03-0.03,0.04-0.04
		c0.13-0.15,0.26-0.28,0.34-0.41c0.18-0.32,0.18-0.64,0.18-1.02c0-0.28,0-0.6,0.08-0.94c0.14-0.57,0.33-0.69,0.76-0.92l0.12-0.07
		c0,0,0,0,0,0c0.11-0.06,0.2-0.11,0.3-0.16c0.46-0.24,0.58-0.3,0.64-0.75c0-0.01,0-0.03,0-0.04c0.01-0.12,0.01-0.24,0.02-0.36
		c0.01-0.42,0.06-0.89,0.42-1.07c0.05-0.03,0.12-0.05,0.19-0.06c0.04-0.24,0.13-0.73,0.11-0.99c-0.42-0.01-0.88-0.12-1.29-0.22
		c-0.22-0.05-0.44-0.11-0.62-0.14c0,0,0,0-0.01,0c-0.84-0.13-1.67-0.25-2.51-0.37c-1.21-0.17-2.46-0.35-3.68-0.56l-4.52-0.78
		c-0.29,0.04-0.52-0.02-0.69-0.18c-0.01-0.01-0.01-0.01-0.02-0.02c0,0,0,0,0,0c0,0,0,0,0,0c-0.14-0.15-0.2-0.35-0.19-0.6
		c-0.06-0.18-0.07-0.38-0.09-0.58c-0.01-0.11-0.02-0.29-0.04-0.37c-0.03,0-0.06-0.01-0.09-0.01c-0.07-0.01-0.14-0.02-0.21-0.04
		c-0.11-0.02-0.24-0.05-0.35-0.12c-0.45-0.29-0.76-0.97-1.03-1.57c-0.03-0.06-0.06-0.13-0.08-0.18c-0.04-0.06-0.08-0.12-0.12-0.19
		c-0.37-0.56-0.78-1.2-0.8-1.68c0-0.05,0-0.09,0-0.13c0-0.17,0.03-0.34,0.12-0.51c0.11-0.22,0.25-0.32,0.34-0.39
		c0.07-0.05,0.09-0.06,0.12-0.13c0.02-0.04,0.04-0.12,0.06-0.21c-0.08,0.03-0.16,0.03-0.24,0.02c-0.09-0.01-0.18-0.03-0.25-0.05
		c-0.07,0.08-0.14,0.13-0.15,0.15c-0.22,0.17-0.38,0.24-0.68,0.35c-0.03,0.01-0.05,0.02-0.08,0.02c-0.02,0.04-0.05,0.09-0.08,0.12
		c-0.08,0.07-0.18,0.13-0.28,0.12c0,0-0.01,0-0.01,0c-0.2-0.01-0.39,0.02-0.58,0.07c-0.36,0.11-0.69,0.33-1.05,0.57
		c-0.16,0.11-0.33,0.22-0.51,0.33c-0.02,0.09-0.08,0.18-0.16,0.23c-0.08,0.06-0.19,0.08-0.29,0.06c-0.23,0.15-0.4,0.23-0.55,0.23
		c-0.22,0.01-0.38-0.09-0.46-0.15c-0.04-0.02-0.05-0.03-0.06-0.03l-0.07-0.01c-0.12-0.02-0.12-0.02-0.25,0.01
		c-0.06,0.01-0.11,0.02-0.17,0.04c-0.01,0.01-0.03,0.01-0.04,0.02c-0.33,0.15-0.64,0.25-1.17-0.13c-0.32-0.24-0.4-0.43-0.5-0.7
		l-0.03-0.09c0,0,0,0,0,0c-0.37-0.95-0.66-1.72-0.9-2.44c-0.36-1.07,0.19-1.62,0.73-2.15c0.09-0.09,0.18-0.18,0.27-0.28
		c0.02-0.02,0.04-0.05,0.06-0.07c0.25-0.3,0.47-0.78,0.52-1.26c0.01-0.07,0.01-0.14,0-0.21c-0.02-0.37-0.16-0.67-0.42-0.9
		c-0.07-0.06-0.09-0.07-0.1-0.07c-0.03,0-0.06,0-0.1,0c-0.08,0-0.17-0.02-0.27-0.06c-0.29-0.11-0.54-0.28-0.79-0.43
		c-0.16-0.1-0.33-0.21-0.5-0.3c-0.13-0.06-0.24-0.13-0.34-0.2c-0.05-0.03-0.1-0.06-0.14-0.09c-0.12-0.06-0.24-0.1-0.31-0.09
		c-0.04,0.01-0.08,0.04-0.15,0.09c-0.08,0.06-0.18,0.14-0.33,0.18c-0.09,0.03-0.21,0.04-0.35,0.01c-0.06-0.01-0.12-0.03-0.18-0.06
		c-0.03-0.01-0.06-0.03-0.09-0.06c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.06-0.06-0.11-0.14-0.12-0.22
		c-0.01-0.07,0-0.15,0.03-0.22c-0.02,0-0.05-0.01-0.06-0.01c-0.14-0.02-0.25-0.04-0.34-0.09c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c-0.22-0.12-0.35-0.32-0.44-0.52c-0.09-0.14-0.14-0.26-0.14-0.26c-0.08-0.17-0.13-0.34-0.17-0.49c-0.02-0.06-0.03-0.11-0.05-0.16
		c-0.08-0.17-0.16-0.33-0.31-0.49c-0.07-0.08-0.14-0.16-0.21-0.23c-0.06-0.06-0.11-0.12-0.16-0.18c-0.19-0.21-0.36-0.44-0.5-0.8
		c-0.01-0.02-0.02-0.05-0.03-0.07c-0.1-0.29-0.14-0.61-0.17-0.92c-0.03-0.33-0.07-0.67-0.18-0.84c-0.01-0.01-0.02-0.03-0.04-0.04
		c-0.16-0.15-0.52-0.34-0.77-0.47c-0.2-0.1-0.38-0.2-0.5-0.29c-0.29-0.2-0.58-0.41-0.88-0.63c0.06,0.32,0.1,0.63,0.08,0.94
		c0,0.01,0,0.03,0,0.04c-0.01,0.13-0.03,0.26-0.09,0.39c-0.04,0.1-0.09,0.18-0.15,0.26c-0.02,0.03-0.04,0.05-0.06,0.08
		c-0.01,0.01-0.61,0.59-1.15,0.93c0.01,0.05,0.02,0.1,0.03,0.15c0.05,0.11,0.08,0.22,0.07,0.33c-0.01,0.12-0.07,0.21-0.15,0.32
		c-0.14,0.43-0.41,0.84-0.61,1.06c-0.3,0.32-0.69,0.44-1.02,0.55c-0.2,0.06-0.39,0.12-0.54,0.21c-0.12,0.11-0.24,0.24-0.35,0.38
		c-0.25,0.3-0.53,0.63-0.96,0.76c-0.53,0.16-1,0.09-1.42-0.06c-0.32-0.08-0.62-0.2-0.85-0.32l-0.21-0.11
		c-0.03-0.02-0.06-0.03-0.09-0.05c-0.22-0.08-0.44-0.12-0.66-0.1c-0.03,0.01-0.06,0.01-0.1,0.02c-0.29,0.07-0.59,0.2-0.85,0.36
		c-0.27,0.18-0.5,0.4-0.66,0.63c-0.04,0.08-0.09,0.19-0.13,0.29c-0.15,0.36-0.38,0.91-0.86,1.04c-0.01,0-0.02,0.01-0.03,0.01
		c-0.16,0.04-0.32,0.03-0.48-0.02c-0.21-0.06-0.37-0.19-0.51-0.34c-0.28-0.31-0.4-0.73-0.51-1.11c0-0.01,0-0.01-0.01-0.02
		c0-0.01-0.01-0.02-0.01-0.02c-0.05-0.17-0.1-0.32-0.15-0.44c-0.07-0.15-0.17-0.22-0.32-0.33c-0.11-0.08-0.23-0.16-0.34-0.28
		c-0.11-0.09-0.21-0.2-0.28-0.35c-0.22-0.48,0.01-0.94,0.2-1.31c0.06-0.12,0.12-0.24,0.16-0.35c0.03-0.09,0.04-0.17,0.05-0.23
		c0-0.03,0-0.05-0.01-0.07c-0.01-0.02-0.01-0.04-0.01-0.06c-0.02,0.01-0.04,0.03-0.06,0.04c-0.1,0.06-0.2,0.13-0.31,0.18
		c-0.12,0.06-0.25,0.06-0.37-0.01c-0.02,0.02-0.03,0.04-0.05,0.06c-0.14,0.2-0.35,0.6-0.44,0.78c-0.06,0.11-0.11,0.21-0.15,0.28
		c-0.19,0.33-0.23,0.57-0.15,0.88c0.06,0.13,0.13,0.27,0.2,0.41c0.22,0.41,0.47,0.88,0.33,1.38c-0.15,0.5-0.8,0.98-1.34,1.17
		c-0.02,0.01-0.04,0.01-0.06,0.02c-0.07,0.02-0.14,0.04-0.21,0.06c-0.32,0.06-0.6,0.02-0.82-0.13c-0.36-0.24-0.47-0.62-0.57-0.93
		c-0.09-0.29-0.14-0.42-0.26-0.46c0,0,0,0,0,0c-0.09-0.03-0.34-0.02-0.51-0.01c-0.17,0.01-0.34,0.01-0.46,0
		c-0.03,0-0.07-0.01-0.11-0.01c-0.01,0-0.02,0-0.03,0c-0.11-0.01-0.23-0.04-0.36-0.06c-0.19-0.04-0.4-0.08-0.55-0.06
		c-0.25,0.02-0.29,0.09-0.45,0.38c-0.05,0.1-0.11,0.2-0.19,0.31c-0.13,0.27-0.32,0.55-0.66,0.69c-0.12,0.05-0.23,0.06-0.33,0.06
		c-0.06,0.01-0.12,0.01-0.19,0.01c-0.15,0-0.28-0.08-0.37-0.14c-0.02,0.01-0.03,0.01-0.05,0.02c0.01,0.02,0.01,0.04,0.02,0.06
		c0.02,0.1,0.02,0.21,0,0.34c-0.04,0.43-0.27,0.91-0.47,1.31c-0.06,0.12-0.12,0.24-0.16,0.34c-0.19,0.6-0.23,1.13-0.26,1.85
		c-0.01,0.14-0.02,0.25-0.02,0.35c-0.03,0.41-0.03,0.41,0.24,0.93c0.09,0.18,0.17,0.35,0.24,0.51c0.09,0.17,0.16,0.36,0.21,0.56
		c0.05,0.23,0.05,0.44,0.03,0.63c0,0.12-0.01,0.24-0.03,0.37c-0.01,0.1-0.04,0.21-0.06,0.32c-0.02,0.12-0.05,0.24-0.07,0.36
		c0.01,0.34,0.14,0.52,0.37,0.72c0.12,0.05,0.25,0.11,0.38,0.21c0.17,0.13,0.3,0.3,0.41,0.45c0.03,0.04,0.06,0.08,0.09,0.11
		c0.19,0.19,0.41,0.36,0.65,0.55c0.16,0.12,0.31,0.24,0.47,0.38c0.5,0.4,0.97,0.9,1.01,1.6c0.02,0.43-0.22,0.85-0.43,1.21
		c-0.1,0.18-0.2,0.35-0.25,0.49c-0.07,0.19-0.11,0.44-0.16,0.7c-0.08,0.44-0.17,0.9-0.38,1.26c-0.22,0.36-0.67,0.66-1.01,0.79
		c-0.28,0.11-0.5,0.11-0.7,0.09c0.01,0.08,0.02,0.15,0.01,0.22c-0.01,0.19-0.08,0.36-0.17,0.51c-0.17,0.29-0.45,0.53-0.71,0.69
		c-0.12,0.07-0.22,0.13-0.32,0.18c-0.04,0.02-0.08,0.04-0.11,0.06c-0.27,0.17-0.26,0.26-0.24,0.64c0.01,0.1,0.01,0.21,0.01,0.33
		c0.02,0.49-0.09,0.85-0.33,1.09c-0.24,0.25-0.58,0.37-1.08,0.37c-0.72,0-1.05,0.05-1.5,0.37c0.01,0,0.01,0.01,0.02,0.01
		c0.04,0.02,0.09,0.04,0.15,0.07c0.02,0.01,0.04,0.02,0.06,0.03c0.07,0.03,0.16,0.07,0.25,0.11c0.06,0.03,0.11,0.05,0.14,0.06
		c0.33,0.16,0.68,0.24,1.05,0.32c0.35,0.08,0.7,0.16,1.06,0.31c0.11,0.05,0.22,0.11,0.34,0.18c0.16,0.09,0.32,0.18,0.44,0.19
		c0.12,0.01,0.2,0,0.28-0.01c0.12-0.01,0.26-0.02,0.44,0.03c0.07,0.02,0.1,0.04,0.19,0.11c0.05,0.04,0.12,0.09,0.17,0.13
		c0.11-0.02,0.23-0.04,0.36,0c0.01,0,0.84,0.42,0.71,0.92c0,0.01-0.01,0.02-0.01,0.03c0.58,0.37,1.3,0.84,1.8,1.37
		c0.15-0.04,0.31,0,0.4,0.13c0.2,0.27,0.25,0.51,0.17,0.73c0,0,0,0.01,0,0.01c0.72,0.68,1.74,0.79,2.82,0.9
		c0.6,0.06,1.22,0.13,1.81,0.3c0.46,0.13,0.92,0.2,1.4,0.28c0.44,0.07,0.89,0.14,1.34,0.26c1.01,0.27,1.59,0.78,2.21,1.33
		c0.63,0.55,1.57,1.16,2.22,1.57c0.27,0.17,0.59,0.24,0.93,0.31c0.35,0.08,0.71,0.15,1.07,0.34l0.15,0.08
		c0.64,0.33,1.06,0.56,1.75,0.71l0.23,0.05c0.07,0.02,0.11,0.03,0.14,0.03c-0.01,0,0.01-0.02,0.06-0.04l0.14-0.06
		c0.07-0.03,0.09-0.05,0.09-0.05c0.2-0.28,0.45-0.28,0.78-0.26c0.27,0.02,0.54,0.07,0.79,0.12c0.15,0.03,0.3,0.06,0.45,0.08
		c0.21,0.03,0.43,0.05,0.64,0.03c0.6-0.05,0.95-0.22,1.55-0.55c0.27-0.15,0.51-0.22,0.74-0.3c0.23-0.07,0.44-0.14,0.7-0.28
		c0.78-0.43,1.57-0.6,2.4-0.78l0.24-0.05c0.18-0.04,0.31-0.11,0.46-0.19c0.15-0.08,0.31-0.17,0.53-0.23
		c0.24-0.07,0.48-0.12,0.71-0.16c0.2-0.04,0.4-0.07,0.59-0.13c0.39-0.11,0.81-0.16,1.22-0.21c0.54-0.06,1.05-0.12,1.46-0.32
		c0.19-0.09,0.22-0.17,0.3-0.33c0.09-0.2,0.22-0.48,0.62-0.7c0.14-0.08,0.29-0.12,0.42-0.15c0.12-0.03,0.22-0.06,0.28-0.1
		c0.03-0.02,0.04-0.04,0.07-0.11c0.04-0.09,0.09-0.21,0.21-0.32c0.27-0.24,0.64-0.27,0.94-0.29c0.1-0.01,0.25-0.02,0.34-0.04
		c0.03-0.37,0.21-0.56,0.54-0.61c0.06-0.01,0.11-0.01,0.18,0.01c0.04,0.01,0.09,0.03,0.15,0.06c0.1,0.04,0.4,0.17,0.48,0.19
		c0.12,0.01,0.25,0.01,0.39,0c0.13-0.01,0.27-0.03,0.41-0.05c-0.16-0.04-0.31-0.09-0.45-0.16c-0.24-0.12-0.48-0.27-0.52-0.53
		c-0.04-0.27,0.14-0.44,0.25-0.54c0.09-0.08,0.14-0.13,0.15-0.19c0.03-0.17,0.18-0.3,0.36-0.31c0.15-0.01,0.27,0,0.37,0
		c0.09,0,0.2,0,0.23-0.01c0.04-0.02,0.13-0.13,0.27-0.34l0.08-0.12c0.24-0.36,0.35-0.49,0.89-0.62l0.13-0.03
		c0.1-0.02,0.17-0.04,0.25-0.05c0.21-0.02,0.35,0.06,0.58,0.24c0.21,0.13,0.36,0.36,0.37,0.37c0,0,0.04,0.15,0.05,0.32
		c0,0.06,0,0.12,0,0.18c0.03,0.04,0.06,0.08,0.08,0.11c0.1,0.13,0.19,0.27,0.22,0.46c0.02-0.05,0.04-0.09,0.07-0.12
		c0.01-0.01,0.02-0.02,0.03-0.03c0.02-0.02,0.03-0.03,0.04-0.04c0,0,0,0,0,0c0,0,0,0,0,0c0.06-0.04,0.13-0.07,0.19-0.08
		c0.08-0.03,0.22-0.1,0.3-0.14c0.05-0.05,0.12-0.09,0.2-0.11c0.18-0.07,0.27-0.08,0.46-0.05c0.4,0.05,0.76,0.28,0.95,0.6
		c0.16,0.27,0.18,0.57,0.06,0.85c-0.17,0.42-0.68,0.63-1.18,0.79c0.36,0.05,0.85,0.1,1.5,0.16c0.28,0.02,0.55,0.08,0.79,0.15
		c0-0.03,0.01-0.07,0.01-0.1c-0.1-0.09-0.15-0.23-0.12-0.36c0.03-0.13,0.12-0.24,0.24-0.28c-0.02-0.06-0.02-0.13-0.01-0.21
		c0.05-0.24,0.26-0.38,0.33-0.42c0.71-0.43,1.27,0.36,1.48,0.66c0.33,0.47,0.85,0.73,1.3,0.73c0.03,0,0.06-0.02,0.13-0.07
		c0.14-0.11,0.51-0.39,0.97,0.06c0.06,0.06,0.11,0.12,0.14,0.19c0.01,0.02,0.02,0.05,0.03,0.08c0.02,0.05,0.04,0.09,0.06,0.14
		C283.99,255.47,284.03,255.34,284.07,255.2z"
          />
          <path
            id="Campeche"
            d="M343.68,228.13c0.01-0.14,0.03-0.29,0.04-0.43c0.11-1.23,0.22-2.46,0.33-3.7c0.2-2.25,0.4-4.58,0.64-6.87
		c0.03-0.29,0.05-0.58,0.07-0.88c0.05-0.64,0.09-1.29,0.22-1.91c0.01-0.03,0.01-0.06,0.02-0.09c0-0.05-0.01-0.09-0.02-0.14
		c0,0,0-0.01,0-0.01c-1.37-2.6-2.79-5.23-4.19-7.79c-0.22-0.41-0.45-0.82-0.67-1.23c-0.44-0.81-0.89-1.62-1.33-2.44
		c-0.15,0.15-0.35,0.26-0.64,0.27c-0.24,0.02-0.57-0.14-0.94-0.33c-0.08-0.04-0.15-0.08-0.2-0.1c-0.03-0.01-0.08-0.03-0.14-0.05
		c-0.5-0.17-0.73-0.27-0.83-0.48c-0.02-0.05-0.04-0.11-0.04-0.17c0-0.12,0.04-0.28,0.15-0.54c-0.52-0.04-1.05-0.06-1.56-0.09
		c-0.01,0-0.03,0-0.04,0c0,0,0,0,0,0c0,0-0.01,0-0.01,0c0,0,0,0,0,0c-0.08-0.02-0.16-0.06-0.21-0.12c-0.06-0.07-0.09-0.15-0.09-0.23
		c0,0,0,0,0,0c0,0,0,0,0,0c0-0.02,0-0.03,0-0.05l0.27-2.62c-0.06,0.06-0.11,0.13-0.16,0.19c-0.15,0.21-0.16,0.4-0.18,0.68
		c-0.01,0.14-0.02,0.29-0.05,0.45c-0.05,0.33-0.17,0.63-0.29,0.92c-0.13,0.32-0.24,0.62-0.26,0.92c-0.01,0.23,0.02,0.47,0.06,0.72
		c0.05,0.37,0.1,0.74,0.03,1.14c-0.06,0.32-0.13,0.63-0.19,0.94c-0.16,0.74-0.31,1.44-0.34,2.17c-0.01,0.18-0.02,0.33-0.04,0.46
		c-0.02,0.12-0.03,0.22-0.03,0.32c0,0.1,0.02,0.2,0.06,0.34c0.02,0.06,0.04,0.13,0.07,0.21c0.13,0.35,0.22,0.63,0.19,0.87
		c-0.14,0.97-1.02,1.48-1.8,1.94c-0.28,0.16-0.54,0.32-0.74,0.47c-0.51,0.41-0.92,1.09-0.96,1.62c-0.01,0.11,0,0.2,0,0.29
		c0.01,0.15,0.02,0.33-0.02,0.54c-0.08,0.41-0.12,0.81-0.18,1.23l-0.02,0.14c-0.03,0.23-0.06,0.45-0.09,0.66
		c-0.1,0.61-0.23,1.1-0.38,1.44c-0.19,0.44-0.49,0.68-0.79,0.86c-0.1,0.06-0.2,0.11-0.3,0.16c-0.14,0.08-0.29,0.15-0.43,0.25
		c-0.2,0.14-0.36,0.34-0.54,0.55c-0.19,0.23-0.39,0.46-0.65,0.65c-0.41,0.3-0.83,0.5-1.25,0.69c-0.17,0.08-0.34,0.16-0.52,0.25
		c-0.28,0.14-0.52,0.32-0.78,0.5c-0.4,0.29-0.8,0.59-1.37,0.75c0.01,0.06,0,0.13-0.03,0.18c-0.28,0.67-0.91,0.9-1.42,1.1
		c-0.36,0.13-0.61,0.24-0.75,0.4c0.06,0.07,0.11,0.15,0.16,0.26c0.02,0.04,0.05,0.1,0.07,0.13c0.13-0.07,0.3-0.15,0.58-0.09
		c0.49,0.09,0.89,0.89,0.89,1.24c0,0.19-0.09,0.4-0.22,0.65c0.3-0.13,0.55-0.19,0.74-0.13c0.06,0.02,0.12,0.06,0.18,0.11
		c0.04,0.04,0.14,0.15,0.13,0.33c0,0.06-0.02,0.13-0.05,0.2c-0.14,0.3-0.52,0.38-0.81,0.44l-0.1,0.02
		c-0.02,0.01-0.04,0.03-0.06,0.04c-0.08,0.06-0.19,0.13-0.34,0.16c-0.14,0.03-0.27-0.01-0.36-0.06c-0.01,0.02-0.02,0.04-0.03,0.06
		c-0.1,0.18-0.25,0.45-0.62,0.59c-0.24,0.09-0.47,0.11-0.68,0.12c-0.15,0.01-0.29,0.02-0.42,0.06c-0.16,0.05-0.29,0.09-0.41,0.14
		c0,0.01,0,0.01,0,0.02c0.08,0.34-0.01,0.9-0.24,1.1c-0.11,0.09-0.31,0.13-0.44,0.08c-0.13-0.05-0.2-0.14-0.25-0.25
		c-0.02,0.01-0.04,0.01-0.07,0.02c-0.2,0.05-0.43,0.11-0.7,0.09c-0.27-0.02-0.49-0.07-0.7-0.13l-0.19-0.05
		c-0.34-0.07-0.63-0.19-0.93-0.31c-0.07-0.03-0.14-0.06-0.21-0.08c0,0.17-0.04,0.34-0.2,0.45c0.06,0.19,0.08,0.4-0.1,0.56
		c-0.26,0.25-0.62,0.05-0.81-0.05c-0.24-0.13-0.56-0.66-0.57-0.92c0-0.26,0.15-0.45,0.27-0.58c0,0,0.01-0.01,0.01-0.01
		c-0.01-0.01-0.03-0.01-0.04-0.02c-0.04,0.02-0.09,0.03-0.14,0.03c-0.42,0.02-0.68-0.27-0.85-0.46c-0.07-0.08-0.16-0.17-0.21-0.2
		c-0.02,0-0.12,0-0.22,0c-0.1,0-0.19-0.01-0.24-0.01c-0.14-0.02-0.24-0.08-0.32-0.14c-0.11,0.04-0.24,0.03-0.34-0.03
		c-0.15-0.1-0.23-0.27-0.17-0.44c0.04-0.12,0.09-0.21,0.14-0.31c0.06-0.11,0.09-0.17,0.09-0.24c0-0.15,0.09-0.28,0.23-0.34
		c0.04-0.02,0.09-0.03,0.14-0.03c0.01-0.02,0.03-0.05,0.05-0.07c0.03-0.03,0.06-0.06,0.1-0.09c0,0,0.01,0,0.01-0.01h0c0,0,0,0,0,0
		c0.14-0.09,0.32-0.08,0.44,0.02c0.02,0,0.04,0,0.07,0c0.02-0.03,0.05-0.06,0.08-0.08c0,0,0.01-0.01,0.01-0.01
		c0.07-0.05,0.15-0.07,0.24-0.07c0.04,0,0.09,0.01,0.13,0.03c0,0,0,0,0,0c0,0,0,0,0,0c0.03,0.01,0.06,0.03,0.08,0.04c0,0,0,0,0,0
		c0,0,0,0,0,0c-0.06-0.1-0.1-0.21-0.14-0.3c-0.06-0.14-0.11-0.26-0.18-0.34c-0.12-0.13-0.13-0.13-0.38-0.06
		c-0.1,0.03-0.21,0.05-0.32,0.07l-0.06,0.01c-0.72,0.1-1.51,0.21-2.28,0.2c-0.26,0-0.51-0.02-0.77-0.05
		c-0.65-0.09-1.17-0.07-1.77,0.01l0.04,0.07c0.06,0.1,0.12,0.21,0.17,0.33c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0.1,0.24,0.16,0.45,0.2,0.67
		c0.02,0.12,0.03,0.22,0.03,0.3c0.03,0.01,0.06,0.02,0.09,0.03c0.11,0,0.23,0.01,0.34,0.02c0.19,0.01,0.37,0.03,0.56,0.05
		c0.08,0.01,0.2,0.01,0.34,0.02c1.08,0.04,1.47,0.09,1.66,0.33c0.3,0.37,0.18,1.08-0.05,2.14c-0.05,0.22-0.09,0.4-0.1,0.53
		c-0.03,0.26-0.05,0.51-0.08,0.77l-0.04,0.36c0,0.02,0,0.03-0.01,0.05l-0.07,0.67c-0.01,0.07-0.01,0.14-0.02,0.19
		c0.01,0.07,0.01,0.11,0.03,0.14c0.04,0.04,0.11,0.11,0.19,0.18c0.07,0.05,0.14,0.12,0.23,0.2l0.06,0.05
		c0.28,0.26,0.56,0.53,0.83,0.74c0.06,0.05,0.37,0.29,0.44,0.41c0.02,0.03,0.03,0.06,0.05,0.11c0.03,0.03,0.06,0.05,0.08,0.08
		c0.1,0.11,0.12,0.24,0.11,0.33c0.04,0.03,0.09,0.05,0.12,0.07c0.06,0.03,0.11,0.07,0.16,0.1c0.11,0.08,0.22,0.17,0.33,0.26
		c0.2,0.16,0.4,0.33,0.58,0.4c0.03,0.01,0.06,0.02,0.09,0.03c0.38,0.1,1,0.04,1.42,0.08c0.06,0.01,0.12,0.01,0.19,0.02
		c0.08,0,0.15,0.01,0.22,0.02c0.11,0.01,0.33,0.03,0.45,0.03c0.01-0.08,0.02-0.2,0.02-0.31l0.01-0.2c0.01-0.2,0.02-0.41,0.08-0.61
		c0.03-0.12,0.08-0.23,0.17-0.34c0.06-0.09,0.09-0.13,0.1-0.16c0.07-0.14,0.17-0.22,0.32-0.24c0.07-0.03,0.15-0.04,0.24-0.05
		c0.21-0.04,0.43-0.02,0.64,0c0.07,0.01,0.13,0.01,0.18,0.02l0.15,0.01c0.28,0.01,0.61,0.02,0.92,0.16
		c0.18,0.08,0.33,0.18,0.45,0.27c0.08,0.05,0.14,0.1,0.21,0.14c0.1,0.04,0.19,0.07,0.28,0.1c0.05,0.01,0.09,0.01,0.13,0.02
		c0.15,0.02,0.33,0.03,0.53,0.14c0.27,0.14,0.51,0.38,0.73,0.61c0.13,0.14,0.26,0.27,0.38,0.36c0.23,0.18,0.49,0.28,0.77,0.34
		c0.15,0.01,0.35,0.01,0.54,0.02c0.6,0.01,1.22,0.01,1.55,0.31c0.33,0.29,0.25,0.77,0.13,1.25c0,0,0,0,0,0l-0.01,0.14
		c-0.02,0.2-0.04,0.4-0.05,0.6c0,0,0.01,0,0.01,0c0.06,0,0.13,0.01,0.19,0.01c0.01,0,0.02,0,0.03,0c0.06,0,0.12,0,0.16,0
		c0.11,0,0.19,0,0.24,0.01c1.48,0.13,2.97,0.26,4.45,0.39c2.39,0.21,4.78,0.41,7.18,0.61l5.03,0.43c0.11,0.01,0.23,0.03,0.36,0.05
		c0.01,0,0.02,0,0.03,0c0.19,0.03,0.56,0.09,0.68,0.05c0.31-3.37,0.62-6.74,0.93-10.1C343.68,228.21,343.68,228.17,343.68,228.13z"
          />
          <path
            id="QuintanaRoo"
            d="M378.82,195.97c-0.03-0.17-0.05-0.41-0.05-0.66c0-0.25,0.02-0.52,0.04-0.75c0.02-0.15,0.04-0.29,0.06-0.4
		c0.02-0.1,0.06-0.23,0.1-0.36c0.08-0.26,0.2-0.66,0.09-0.8c-0.05-0.04-0.19-0.11-0.3-0.13c-0.02,0.03-0.04,0.05-0.07,0.07
		c-0.1,0.08-0.22,0.1-0.34,0.07c-0.45-0.12-0.54-0.54-0.59-0.77c-0.01-0.06-0.02-0.12-0.04-0.17c-0.06-0.13-0.15-0.27-0.24-0.41
		c-0.11-0.16-0.22-0.32-0.3-0.51c-0.21-0.46-0.75-0.95-1.29-0.95c-0.01,0-0.02,0-0.03,0c-0.02,0-0.04,0-0.06,0.01
		c0.12,0.06,0.21,0.16,0.25,0.27c0.03,0.07,0.07,0.23-0.01,0.42c0.08,0.23,0.06,0.46-0.06,0.68c-0.36,0.65-1.58,0.86-1.89,0.86
		c0,0,0,0-0.01,0c-0.48-0.01-1.02-0.15-1.44-0.26c-0.11-0.03-0.24-0.05-0.39-0.08c-0.17-0.03-0.32-0.07-0.46-0.1
		c-0.04-0.01-0.07-0.02-0.1-0.03c-0.09-0.02-0.18-0.04-0.26-0.07c-0.04-0.01-0.08-0.02-0.11-0.04c-0.06-0.02-0.12-0.04-0.17-0.06
		c-0.03-0.01-0.06-0.03-0.09-0.05c-0.03-0.01-0.06-0.03-0.08-0.04l-0.07,0.84c-0.03,0.33-0.06,0.66-0.09,1c0,0.03,0,0.06-0.01,0.09
		c-0.03,0.36-0.04,0.75-0.05,1.17c-0.02,0.94-0.04,1.91-0.31,2.72c-0.15,0.47-0.49,0.9-0.82,1.33c-0.07,0.09-0.14,0.17-0.2,0.26
		c-0.13,0.21-0.26,0.44-0.39,0.67c-0.58,1.02-1.24,2.18-2.15,2.84c-0.91,0.66-1.99,1.18-3.03,1.69c-0.55,0.27-1.08,0.52-1.57,0.79
		c-1.32,0.72-2.63,1.44-3.94,2.16l-0.41,0.22c-0.23,0.13-0.45,0.25-0.68,0.37l-0.94,0.52c-1.61,0.88-3.24,1.78-4.88,2.68
		c-0.94,0.52-1.87,1.03-2.81,1.55c-0.77,0.43-1.53,0.85-2.29,1.28l-0.01,0.01c-0.13,0.07-0.26,0.15-0.39,0.22
		c-0.07,0.05-0.1,0.09-0.13,0.18c0.04,0.61-0.07,1.26-0.18,1.88c-0.05,0.3-0.1,0.59-0.13,0.87c-0.05,0.46-0.09,0.92-0.13,1.38
		l-0.04,0.46c-0.21,2.37-0.43,4.75-0.65,7.12c-0.05,0.58-0.11,1.16-0.16,1.74c-0.01,0.13-0.02,0.27-0.04,0.4
		c0,0.02,0,0.04-0.01,0.07c0,0.03-0.01,0.06-0.01,0.08c-0.11,1.14-0.2,2.29-0.29,3.44c-0.17,2.2-0.35,4.46-0.64,6.68
		c0.21,0.02,0.42,0.05,0.63,0.08c0.62,0.08,1.25,0.16,1.84,0.15c0.04,0,0.08,0,0.12,0c0.08,0,0.15-0.01,0.21-0.01
		c0.01-0.05,0.01-0.11,0.02-0.17c0.01-0.16,0.01-0.29,0.01-0.41c0-0.43,0-0.92,0.46-1.3c0.23-0.19,1.18-0.58,1.59-0.44
		c0.35,0.11,0.47,0.37,0.54,0.53c0.02,0.05,0.04,0.08,0.06,0.11c0.01,0,0.02,0,0.03,0c0,0,0,0,0,0c0,0,0.01,0,0.01,0
		c0.13,0,0.31,0,0.47,0.12c0.13,0.1,0.2,0.24,0.28,0.37c0.03,0.05,0.07,0.12,0.09,0.15c0.1,0.11,0.21,0.23,0.33,0.35
		c0.06-0.23,0.13-0.44,0.27-0.59c0.12-0.12,0.23-0.23,0.35-0.33c0.16-0.15,0.31-0.28,0.46-0.47c0.03-0.04,0.05-0.08,0.07-0.12
		c0.05-0.08,0.1-0.18,0.19-0.26c0.11-0.1,0.24-0.15,0.37-0.19c0,0,0.01,0,0.01,0c0-0.02,0-0.06,0-0.09
		c0.01-0.11,0.01-0.23,0.05-0.35c0.07-0.19,0.17-0.34,0.27-0.48c0.04-0.06,0.09-0.13,0.13-0.2c0-0.03-0.01-0.05-0.01-0.08
		c-0.01-0.13-0.03-0.29,0.08-0.43c0.14-0.18,0.35-0.25,0.5-0.3c0.05-0.02,0.09-0.03,0.13-0.05c0.31-0.17,0.32-0.19,0.4-0.42
		c0.04-0.11,0.09-0.25,0.17-0.43c0.12-0.25,0.29-0.42,0.44-0.57c0.11-0.11,0.2-0.2,0.27-0.32c0.08-0.14,0.13-0.31,0.19-0.49
		c0.07-0.21,0.14-0.44,0.26-0.65c0.4-0.68,0.97-0.76,1.5-0.7c0.18,0.02,0.35,0.05,0.5,0.08c0.58,0.11,0.86,0.14,1.07-0.1
		c0.27-0.31,0.49-0.69,0.71-1.1c0.11-0.19,0.22-0.39,0.33-0.58c-0.1,0.06-0.21,0.1-0.32,0.11c-0.21,0.01-0.38-0.15-0.4-0.35
		c0,0,0,0,0,0c0,0,0,0,0,0c-0.03-0.41,0.49-0.86,0.84-1.1c0.12-0.08,0.22-0.13,0.28-0.16c0.19-0.07,0.34-0.08,0.44-0.07
		c0.02-0.04,0.06-0.11,0.11-0.23c0.07-0.16,0.25-0.25,0.42-0.22c0.26-0.46,0.61-0.87,1.02-1.05c0.11-0.05,0.23-0.05,0.34,0.01
		c0.1,0.06,0.18,0.16,0.2,0.28c0.03,0.19,0.08,0.35,0.12,0.51c0.08,0.31,0.17,0.62,0.14,1.01c-0.01,0.11-0.03,0.22-0.05,0.33
		c-0.01,0.06-0.03,0.12-0.03,0.17c0,0.05,0.02,0.18,0.03,0.3c0.03,0.25,0.04,0.31,0.02,0.39c-0.07,0.36-0.27,0.63-0.44,0.87
		c-0.12,0.16-0.23,0.31-0.28,0.46c0.01-0.02,0.01-0.01,0.01,0.02c0.01,0.06,0.01,0.13,0.01,0.21c0,0.08-0.02,0.17-0.06,0.26
		c-0.1,0.22-0.29,0.3-0.4,0.34c0.07,0.16,0.55,0.37,0.79,0.47c0.44,0.19,0.82,0.36,0.97,0.68c0.03,0.07,0.05,0.14,0.04,0.21
		c0,0.06-0.02,0.12-0.04,0.17c0.09,0.04,0.19,0.12,0.26,0.25c0.03,0.05,0.14,0.39,0.2,0.64c0.02,0.01,0.05,0.02,0.07,0.04
		c0,0,0.01,0.01,0.01,0.01c0.01-0.03,0.03-0.05,0.05-0.08c0.11-0.17,0.23-0.32,0.35-0.46c0.17-0.2,0.33-0.39,0.41-0.6
		c0.18-0.49,0.29-1.19,0.38-1.75l0.03-0.17c0.02-0.11,0.03-0.22,0.04-0.34c0.03-0.33,0.07-0.7,0.23-1.03
		c0.16-0.32,0.38-0.54,0.58-0.73c0.21-0.21,0.38-0.37,0.46-0.63l0.06-0.19c0.19-0.62,0.37-1.21,0.51-1.85
		c0.07-0.32,0.2-0.61,0.32-0.88c0.04-0.09,0.08-0.19,0.12-0.28c-0.01-0.03-0.02-0.07-0.02-0.1c-0.03-0.36,0.18-0.57,0.32-0.71
		c0.04-0.04,0.09-0.09,0.12-0.14c0.15-0.22,0.18-0.42,0.24-0.7l0.03-0.15c0.11-0.55,0.35-0.96,0.63-1.31
		c-0.02-0.04-0.04-0.09-0.04-0.14c0-0.05,0-0.1,0-0.14c-0.1,0.09-0.18,0.23-0.26,0.4c-0.14,0.26-0.31,0.59-0.67,0.75
		c-0.42,0.19-0.66,0.19-1.01-0.27c-0.22-0.29-0.32-0.55-0.08-0.86c0.03-0.04,0.04-0.06,0.05-0.1c0.01-0.04,0.02-0.08,0.04-0.11
		c-0.07-0.11-0.08-0.26-0.04-0.38c0.05-0.12,0.11-0.2,0.18-0.26c0-0.19,0.07-0.42,0.28-0.67c0.41-0.48,1.26-0.88,1.8-0.83
		c0.11,0.01,0.2,0.06,0.27,0.15c0.01,0.02,0.02,0.03,0.03,0.05c0.2-0.13,0.39-0.28,0.5-0.41c0.11-0.12,0.17-0.27,0.22-0.44
		c-0.09-0.01-0.17-0.03-0.25-0.08c-0.01,0-0.01-0.01-0.02-0.01c-0.07,0.04-0.15,0.06-0.22,0.06c-0.01,0-0.03,0-0.04,0
		c-0.12-0.01-0.23-0.08-0.31-0.13l0,0c-0.05-0.01-0.09-0.03-0.15-0.05c-0.09-0.04-0.26-0.12-0.37-0.1c-0.1,0-0.32,0.18-0.46,0.28
		c-0.29,0.22-0.61,0.47-0.96,0.3c-0.13-0.06-0.21-0.19-0.21-0.33c-0.01-0.23,0.06-0.39,0.11-0.51c-0.34-0.19-0.65-0.57-0.81-0.95
		c-0.22-0.52-0.14-1,0.22-1.28c0.27-0.21,0.95,0.02,0.96,0.03c0.05,0.02,0.07,0.02,0.09,0.01c0.03-0.01,0.07-0.01,0.11-0.01
		c0.02-0.04,0.04-0.08,0.06-0.13c0.17-0.34,0.36-0.73,0.58-0.99c0.2-0.23,0.26-0.29,0.47-0.36c0.02-0.01,0.03-0.01,0.06-0.02
		c0.06-0.1,0.17-0.23,0.42-0.27c0.1-0.01,0.24,0.02,0.32,0.09c0.08-0.09,0.18-0.22,0.27-0.35c-0.07-0.32-0.1-1.14,0.09-1.44
		c0.04-0.06,0.09-0.11,0.15-0.14c-0.04-0.22-0.06-0.47-0.04-0.77c0.04-0.71,0.18-1.28,0.46-1.91c0.12-0.28,0.26-0.58,0.48-0.83
		c0.32-0.36,0.53-0.58,0.68-0.71c0.3-0.78,1.9-2.54,1.92-2.56c0.45-0.46,0.82-0.75,1.32-1.05c0.56-0.33,0.85-0.64,1.25-1.08
		c0.11-0.12,0.22-0.25,0.35-0.38c0.22-0.23,0.47-0.45,0.71-0.66c0.42-0.37,0.85-0.74,1.11-1.17c0.17-0.28,0.29-0.63,0.43-1
		c0.11-0.31,0.22-0.62,0.36-0.91c0-0.01,0-0.03,0-0.05c0.01-0.1,0.03-0.28,0.17-0.46c0.11-0.14,0.27-0.19,0.39-0.2
		c0-0.1,0.01-0.22,0.05-0.35c0.01-0.02,0.02-0.04,0.03-0.06c0.01-0.02,0.02-0.04,0.03-0.06c0.05-0.09,0.09-0.19,0.13-0.29
		c0.04-0.09,0.07-0.19,0.12-0.28C379.03,196.59,378.88,196.26,378.82,195.97z"
          />
          <path
            id="Colima"
            d="M168.32,210.82c0.05-0.11,0.11-0.23,0.23-0.33c0.19-0.17,0.39-0.19,0.5-0.21c0.02,0,0.04,0,0.07-0.01
		c0.11-0.08,0.15-0.18,0.22-0.4c0.03-0.1,0.07-0.2,0.11-0.31c0.01-0.04,0.02-0.09,0.03-0.13c-0.02-0.12-0.02-0.31,0.16-0.48
		c0.1-0.1,0.29-0.22,0.59-0.05c0.05-0.06,0.11-0.12,0.18-0.17c0.1-0.06,0.17-0.1,0.24-0.13c0.02-0.05,0.05-0.1,0.09-0.14
		c0.05-0.05,0.11-0.08,0.18-0.1c0.05-0.07,0.11-0.14,0.17-0.2l0.05-0.06c0.01-0.01,0.01-0.02,0.02-0.02c0-0.08,0-0.14,0-0.23
		c0-0.14-0.06-0.29-0.14-0.46c-0.06-0.15-0.13-0.3-0.17-0.48c-0.02-0.06-0.03-0.14-0.03-0.22c0-0.01,0-0.02,0-0.03
		c-0.01-0.35,0.13-0.68,0.26-0.99c0.06-0.14,0.12-0.28,0.16-0.41c0-0.12,0-0.24-0.01-0.36c-0.01-0.23-0.02-0.46,0.02-0.72
		c0.02-0.14,0.08-0.25,0.13-0.34c0.02-0.07,0.05-0.13,0.08-0.18c0.01-0.02,0.02-0.03,0.02-0.05c-0.01-0.07-0.05-0.17-0.11-0.27
		c-0.11-0.13-0.26-0.3-0.35-0.39c-0.13-0.14-0.25-0.27-0.31-0.36c-0.12-0.16-0.23-0.32-0.35-0.49c-0.08-0.11-0.16-0.22-0.24-0.33
		c-0.28,0.2-0.65,0.4-1.04,0.54c-0.34,0.15-0.67,0.26-0.88,0.28c-0.8,0.09-1.81-0.71-2.48-1.23l-0.21-0.16
		c-0.1-0.08-0.19-0.15-0.27-0.22c-0.18-0.15-0.27-0.22-0.35-0.23c-0.1,0.07-0.22,0.14-0.3,0.21c-0.01,0.01-0.03,0.03-0.05,0.05
		c-0.06,0.06-0.1,0.1-0.14,0.13c0,0.02,0.01,0.05,0.01,0.09l0.02,0.18c0,0.01,0,0.01,0,0.02c0.06,0.22,0.14,0.49,0.02,0.74
		c-0.22,0.48-0.98,0.75-1.96,1.05c-0.16,0.05-0.29,0.09-0.4,0.13c-0.06,0.02-0.12,0.04-0.18,0.07c-0.03,0.01-0.07,0.03-0.1,0.04
		c-0.08,0.03-0.16,0.07-0.26,0.09c-0.19,0.06-0.4,0.1-0.62,0.09c-0.08,0.01-0.18,0.02-0.27,0.01c-0.15-0.02-0.26-0.09-0.32-0.16
		c0,0.01-0.01,0.01-0.01,0.02c-0.04,0.08-0.09,0.19-0.19,0.28c-0.09,0.15-0.23,0.3-0.48,0.32c-0.15,0.02-0.3-0.06-0.37-0.19
		c-0.04-0.03-0.1-0.07-0.14-0.11c-0.02,0.03-0.04,0.06-0.07,0.1c0,0-0.01,0.01-0.01,0.01c-0.04,0.12-0.1,0.26-0.2,0.4
		c-0.08,0.1-0.16,0.17-0.23,0.22c0.06,0.03,0.13,0.05,0.2,0.07c0.08,0.02,0.15,0.05,0.21,0.06c0.22,0.07,0.42,0.14,0.59,0.24
		c0.02,0.01,0.05,0.02,0.07,0.04c0.12,0.09,0.2,0.16,0.27,0.23c0.04,0.03,0.08,0.07,0.1,0.08c0,0,0.04,0.01,0.21-0.02l0.04-0.02
		c0.19-0.07,0.54-0.2,0.81,0.01c0.02,0.02,0.05,0.04,0.07,0.07c0.04-0.01,0.07-0.01,0.11,0c0.28,0.04,0.42,0.19,0.48,0.31
		c0.16,0.3,0,0.61-0.12,0.85c-0.02,0.05-0.05,0.09-0.06,0.12c0.01,0.04,0.06,0.07,0.25,0.15c0.08,0.03,0.15,0.07,0.23,0.1
		c0.33,0.17,0.69,0.33,1.06,0.49c1.01,0.45,2.06,0.92,2.83,1.68c0.27,0.27,0.53,0.56,0.77,0.85c0.18,0.2,0.35,0.41,0.53,0.6
		c0.1,0.11,0.2,0.25,0.31,0.39c0.04,0.06,0.1,0.14,0.17,0.22c0,0.01,0.01,0.01,0.01,0.02c0.02,0.02,0.03,0.04,0.05,0.06
		c0.02,0.02,0.04,0.05,0.06,0.07c0.01,0.02,0.03,0.03,0.04,0.04c0.02,0.02,0.04,0.04,0.06,0.05c0.01,0.01,0.02,0.01,0.02,0.01
		c0.06-0.07,0.11-0.15,0.16-0.24C168.22,210.98,168.26,210.9,168.32,210.82z"
          />
          <path
            id="Jalisco"
            className={`${styles.clickable} ${estado=== "Jalisco" ? " fill-[#9a3324]" : "fill-[#154734]"}`}
            onClick={(e) => setEstado(e.target.id)}
            d="M158.24,203.79c0.04-0.06,0.09-0.15,0.13-0.22c0,0,0-0.01,0-0.01c0.04-0.12,0.11-0.33,0.27-0.46
		c0.18-0.24,0.46-0.16,0.7-0.1c0,0,0,0,0,0c0.11,0.03,0.2,0.06,0.27,0.1c0.08-0.14,0.19-0.3,0.4-0.4c0.1-0.05,0.23-0.09,0.39-0.1
		c0.2-0.01,0.35,0.09,0.46,0.15c0.03,0.02,0.06,0.04,0.09,0.05c0.07-0.01,0.14-0.02,0.2-0.02c0.08,0,0.14-0.01,0.2-0.02
		c0.06-0.02,0.11-0.04,0.17-0.06c0.06-0.03,0.13-0.06,0.2-0.1c0.12-0.06,0.24-0.13,0.39-0.19c0.18-0.07,0.37-0.13,0.55-0.19
		c0.22-0.07,0.43-0.13,0.63-0.23l0.16-0.08c0.09-0.04,0.19-0.09,0.24-0.13c0-0.06-0.01-0.17-0.02-0.27
		c-0.05-0.17-0.09-0.36-0.04-0.55c0.01-0.06,0.02-0.11,0.05-0.16c0.03-0.1,0.08-0.17,0.17-0.25c0.01-0.01,0.02-0.02,0.03-0.03
		c0.01-0.01,0.07-0.07,0.16-0.14c0.21-0.2,0.39-0.33,0.56-0.39c0.17-0.1,0.35-0.16,0.47-0.15c0.23,0.03,0.47,0.23,0.99,0.72
		c0.13,0.12,0.3,0.28,0.35,0.32l0.28,0.19c0.33,0.23,0.68,0.47,1.01,0.62c0.24,0.11,0.7,0.03,1.15-0.14c0.44-0.2,0.9-0.46,1.06-0.63
		c0.08-0.08,0.19-0.13,0.3-0.12c0.11,0.01,0.22,0.06,0.28,0.15c0.11,0.14,0.27,0.33,0.44,0.53c0.45,0.52,0.83,0.98,1.06,1.39
		c0.14,0.18,0.23,0.35,0.25,0.51c0.02,0.12,0.01,0.22-0.01,0.31c-0.02,0.18-0.09,0.31-0.15,0.41c-0.03,0.16-0.03,0.31-0.02,0.49
		c0,0.12,0,0.23,0,0.36c-0.01,0.13-0.03,0.26-0.06,0.38c-0.01,0.14-0.03,0.28-0.07,0.42c-0.04,0.15-0.11,0.3-0.18,0.44
		c-0.07,0.14-0.15,0.31-0.15,0.4c0,0,0,0,0,0.01c0,0.03,0,0.06,0.01,0.09c0.09,0.13,0.16,0.24,0.21,0.44
		c0.02,0.07,0.04,0.15,0.06,0.23c0.04,0.14,0.08,0.28,0.11,0.43c0.22-0.02,0.44,0.02,0.64,0.05c0.15,0.02,0.36,0.05,0.46,0.03
		c0.07-0.1,0.15-0.24,0.21-0.33c0.22-0.36,0.42-0.71,0.76-0.76c0.55-0.1,0.88,0.46,1.21,0.99c0.03,0.04,0.06,0.09,0.09,0.14
		c0.14,0.18,0.3,0.37,0.41,0.44c0.01-0.05,0.04-0.13,0.06-0.19c0.06-0.18,0.12-0.38,0.27-0.53c0.14-0.14,0.24-0.2,0.35-0.24
		c0.02-0.01,0.04-0.02,0.06-0.03c0.19-0.1,0.39-0.14,0.59-0.19c0.06-0.01,0.11-0.03,0.16-0.04c0.31-0.13,0.39-0.25,0.43-0.64
		c-0.11-0.19-0.09-0.38,0.04-0.6c0.14-0.23,0.44-0.35,0.83-0.47c0.03-0.01,0.05-0.02,0.07-0.02c0.25-0.11,0.53-0.23,0.82-0.29
		c0.19-0.03,0.38-0.01,0.56,0.02c0.07,0.01,0.17,0.02,0.24,0.02c0.02,0,0.03-0.01,0.05-0.01c0.1-0.15,0.26-0.34,0.65-0.38
		c0.27-0.02,0.54,0.05,0.75,0.19c0.04,0.02,0.07,0.05,0.11,0.07c0.05-0.06,0.09-0.12,0.14-0.18c0.32-0.42,0.65-0.85,0.83-1.28
		c0.07-0.16,0.1-0.42,0.1-0.65c0-0.21-0.03-0.4-0.09-0.44c-0.07-0.01-0.29,0.06-0.43,0.11c-0.3,0.1-0.64,0.22-0.97,0.12
		c-0.52-0.17-0.63-0.59-0.63-1c0-0.16,0.02-0.31,0.03-0.45c0.01-0.14,0.03-0.27,0.03-0.38c0-0.03,0-0.07,0-0.1
		c-0.01-0.16-0.11-0.45-0.2-0.74c-0.16-0.5-0.32-1-0.32-1.44c0-0.22,0.04-0.41,0.14-0.58c0.2-0.33,0.54-0.44,0.79-0.53
		c0.27-0.09,0.34-0.12,0.34-0.23c0-0.03-0.01-0.08-0.02-0.14c-0.04-0.25-0.33-0.36-0.81-0.51c-0.13-0.04-0.26-0.08-0.38-0.13
		c-0.08-0.03-0.16-0.04-0.25-0.06c-0.18-0.03-0.43-0.08-0.63-0.26c-0.09,0.08-0.19,0.15-0.29,0.22c-0.27,0.17-0.59,0.28-0.9,0.16
		c-0.42-0.14-0.52-0.41-0.52-0.64c0-0.16,0.04-0.31,0.07-0.39c0.01-0.04,0.03-0.1,0.04-0.15c-0.01,0-0.02,0-0.03,0
		c-0.15-0.03-0.44-0.08-0.61-0.41c-0.09-0.17-0.09-0.36-0.04-0.54c0.04-0.27,0.19-0.52,0.35-0.72c0.02-0.02,0.03-0.04,0.03-0.04
		c0.86-1.08,2.42-1.23,3.67-1.35l0.26-0.03c0.09-0.01,0.18-0.02,0.27-0.02c0.15-0.02,0.3-0.03,0.46-0.05
		c0.52-0.05,1.02-0.09,1.44-0.28c0.06-0.04,0.13-0.08,0.2-0.13c0.06-0.11,0.16-0.22,0.35-0.3c0.18-0.07,0.34-0.05,0.47-0.03
		c0.02,0,0.05,0,0.07,0c0.03,0,0.07,0,0.09,0c0.06-0.03,0.13-0.11,0.2-0.18c0.1-0.1,0.22-0.22,0.36-0.3c0,0,0,0,0,0
		c0.02-0.01,0.03-0.02,0.05-0.03c0.47-0.25,1.05-0.31,1.57-0.3c0.23-0.02,0.46-0.04,0.63-0.02c0.23,0.02,0.42,0.1,0.6,0.16
		c0.05,0.02,0.1,0.04,0.14,0.05c0.35,0.07,0.55-0.01,0.94-0.16l0.14-0.06c0.07-0.03,0.13-0.05,0.18-0.08
		c0.03-0.01,0.06-0.03,0.1-0.04c0.02-0.14,0.05-0.27,0.09-0.39c0.01-0.04,0.02-0.08,0.03-0.12c0.03-0.1,0.07-0.2,0.11-0.29
		c0.08-0.2,0.18-0.39,0.3-0.56c0.19-0.27,0.43-0.47,0.67-0.66c0.2-0.17,0.4-0.32,0.51-0.49c0,0,0.01-0.01,0.01-0.01c0,0,0,0,0,0
		c0.05-0.09,0.06-0.2,0.04-0.32c-0.04-0.18-0.16-0.4-0.32-0.66c-0.08-0.14-0.16-0.27-0.22-0.4c-0.02-0.05-0.05-0.1-0.07-0.14
		c-0.12-0.21-0.23-0.42-0.3-0.63c-0.09-0.26-0.13-0.52-0.02-0.85c0.21-0.86,1.03-1.69,1.68-2.35c0.12-0.12,0.23-0.24,0.33-0.34
		c0.59-0.64,1.01-1.14,1.35-1.62c0.02-0.02,0.03-0.04,0.03-0.05c0,0,0,0,0,0c-0.01-0.12,0.04-0.22,0.13-0.31
		c0.09-0.09,0.23-0.17,0.4-0.27c0.28-0.16,0.61-0.31,0.95-0.46c1.33-0.59,2.19-1.05,1.97-2.07c-0.04-0.18-0.1-0.37-0.18-0.57
		c-0.08-0.22-0.16-0.45-0.21-0.68c-0.02-0.09-0.04-0.19-0.05-0.29c-0.08-0.72,0.35-1.16,0.68-1.52c0.24-0.25,0.41-0.44,0.46-0.66
		c-0.01-0.11-0.06-0.24-0.12-0.4c-0.09-0.25-0.21-0.55-0.22-0.96c-0.01-0.29,0.08-0.52,0.21-0.71c0.02-0.05,0.05-0.11,0.07-0.16
		c0.06-0.11,0.14-0.21,0.23-0.29c-0.15-0.09-0.29-0.21-0.43-0.33c-0.2-0.16-0.4-0.32-0.6-0.49c-0.21-0.17-0.41-0.34-0.62-0.5
		l-0.23-0.18c-0.16-0.13-0.33-0.26-0.5-0.38c-0.34-0.24-0.38-0.28-0.63-0.06c-0.09,0.1-0.19,0.18-0.29,0.26
		c-0.03,0.03-0.06,0.05-0.09,0.08c-0.12,0.1-0.23,0.21-0.33,0.32c-0.04,0.05-0.09,0.11-0.14,0.16c-0.18,0.22-0.37,0.45-0.61,0.61
		c-0.14,0.09-0.28,0.14-0.41,0.16c-0.04,0.02-0.08,0.03-0.12,0.05l-0.06,0.02c-0.14,0.05-0.26,0.1-0.29,0.12
		c0.01-0.01,0,0.02-0.01,0.06c-0.03,0.08-0.08,0.19-0.15,0.33c-0.02,0.05-0.04,0.1-0.08,0.16c-0.04,0.08-0.1,0.15-0.16,0.22
		c-0.46,0.6-1.39,1.3-2.17,1.6c-0.18,0.07-0.37,0.12-0.56,0.13c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.28,0.02-0.56-0.02-0.84-0.08
		c-0.38-0.08-0.78-0.21-1.15-0.34c-0.23-0.08-0.45-0.15-0.65-0.2c-0.04-0.01-0.08-0.02-0.12-0.03c-0.04-0.01-0.09-0.01-0.13-0.02
		c-0.42-0.08-0.72-0.29-0.98-0.51c-0.05-0.04-0.1-0.07-0.15-0.11c-0.08-0.06-0.16-0.14-0.24-0.21c-0.16-0.14-0.29-0.26-0.42-0.33
		c-0.22-0.12-0.34-0.14-0.53-0.08c-0.01,0.01-0.07,0.06-0.11,0.09c-0.09,0.08-0.2,0.17-0.33,0.22c-0.08,0.08-0.15,0.17-0.21,0.26
		c0,0,0,0.01,0,0.01c-0.03,0.07-0.06,0.15-0.09,0.22c-0.09,0.21-0.12,0.32-0.11,0.41c0.04,0.13,0.18,0.22,0.49,0.37
		c0.11,0.05,0.22,0.11,0.34,0.18c0.23,0.1,0.46,0.24,0.6,0.48c0.31,0.51-0.02,1.24-0.24,1.73c-0.04,0.11-0.08,0.21-0.13,0.31
		c-0.08,0.21-0.17,0.45-0.33,0.61c-0.21,0.21-0.4,0.25-0.57,0.25c-0.04,0.02-0.1,0.06-0.15,0.1c-0.11,0.08-0.22,0.15-0.33,0.2
		c-0.11,0.04-0.2,0.05-0.29,0.05c-0.14,0.08-0.31,0.16-0.51,0.2c-0.06,0.01-0.12,0.02-0.18,0.02c-0.12,0.04-0.27,0.06-0.47,0.05
		c-0.16-0.01-0.28-0.07-0.37-0.12c-0.03-0.01-0.07-0.04-0.09-0.04c0,0,0,0-0.01,0c-0.02,0.02-0.08,0.09-0.12,0.14
		c-0.09,0.11-0.22,0.25-0.39,0.33c-0.05,0.03-0.11,0.05-0.18,0.06c-0.47,0.1-0.69-0.09-0.83-0.3c-0.06-0.01-0.12,0-0.16,0
		c-0.11,0.12-0.24,0.36-0.34,0.64c-0.05,0.5,0.02,1.18,0.07,1.61c0.01,0.1,0.02,0.19,0.03,0.27c0.02,0.14,0.03,0.29,0.03,0.44
		c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0.05-0.01,0.09-0.03,0.13c-0.03,0.07-0.08,0.13-0.15,0.18c-0.02,0.01-0.05,0.03-0.07,0.04
		c-0.09,0.03-0.19,0.03-0.28,0c0,0,0,0,0,0h0c0,0-0.01,0-0.01,0c0,0,0,0,0,0c-0.66-0.28-1.32-0.55-1.98-0.83l-0.74-0.31
		c-0.31-0.13-0.64-0.29-0.98-0.46c-0.2-0.1-0.41-0.2-0.61-0.3c-0.44-0.2-0.88-0.38-1.3-0.48c0,0,0,0,0,0
		c-0.05-0.01-0.19,0.01-0.28,0.02c-0.27,0.04-0.53,0.07-0.73-0.08c-0.18-0.13-0.26-0.31-0.31-0.47c-0.02-0.04-0.04-0.09-0.05-0.13
		c-0.02-0.06-0.06-0.16-0.09-0.18c-0.01,0-0.02,0-0.03,0c-0.02,0-0.12,0.08-0.18,0.12c-0.07,0.06-0.14,0.12-0.24,0.18
		c-0.12,0.09-0.26,0.16-0.44,0.15c-0.37,0-0.55-0.2-0.61-0.31c-0.25-0.43,0.08-1.02,0.4-1.6c0.06-0.11,0.13-0.23,0.17-0.31
		c0.28-0.74,0.22-1.34,0.01-2.22c0-0.02-0.01-0.03-0.01-0.05c-0.02-0.07-0.01-0.14,0.01-0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.02-0.06,0.06-0.11,0.11-0.15
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0h0c0,0,0,0,0,0c0,0,0,0,0.01,0c0.14-0.09,0.32-0.07,0.45,0.04
		c0,0,0.01,0.01,0.01,0.01c0.05,0.05,0.11,0.1,0.17,0.16c0.02,0.02,0.04,0.04,0.06,0.06c0.13,0.13,0.28,0.28,0.39,0.34c0,0,0,0,0,0
		c0.03,0.01,0.06,0.03,0.08,0.04c0.01,0,0.01-0.01,0.02-0.01c0.02-0.01,0.04-0.02,0.07-0.03c0,0,0.01,0,0.01,0
		c0.36-0.18,1.24-0.93,1.37-1.21c0.06-0.13,0.08-0.63,0.09-0.88c0.01-0.17,0.02-0.33,0.03-0.43l0.02-0.15
		c0.01-0.05,0.01-0.09,0.02-0.12c0-0.09-0.02-0.15-0.07-0.29c-0.01-0.01-0.02-0.03-0.03-0.04c-0.16-0.21-0.34-0.45-0.24-0.72
		c0.09-0.25,0.36-0.4,0.92-0.69c0.23-0.12,0.6-0.31,0.69-0.41c0,0,0,0,0,0c0,0,0.01-0.01,0.01-0.01c0.03-0.03,0.05-0.07,0.08-0.1
		c0.07-0.1,0.13-0.19,0.19-0.27c0.16-0.25,0.32-0.5,0.74-0.72c0.26-0.15,0.51-0.28,0.74-0.37c0.13-0.05,0.24-0.07,0.35-0.08
		c0.27-0.02,0.47,0.08,0.63,0.16c0.02,0.01,0.05,0.02,0.07,0.03c0.18,0.09,0.27,0.13,0.41,0.11c0.05-0.01,0.08-0.01,0.11-0.02
		c0.08-0.03,0.1-0.04,0.11-0.04c0,0,0,0,0,0c0,0,0,0,0,0c-0.01,0,0-0.02,0.01-0.05c0.03-0.07,0.06-0.15,0.12-0.25
		c0.01-0.01,0.01-0.02,0.02-0.04c0-0.01,0-0.02,0.01-0.03c0.03-0.12,0.06-0.27,0.15-0.42c0.02-0.03,0.04-0.05,0.06-0.08
		c0.01-0.01,0.02-0.03,0.03-0.04c0.1-0.13,0.24-0.22,0.39-0.32c0.02-0.01,0.05-0.03,0.07-0.05c0,0-0.01-0.01-0.01-0.01
		c-0.04-0.05-0.08-0.1-0.13-0.18c-0.11-0.16-0.19-0.42-0.01-0.78c0.11-0.22,0.28-0.39,0.44-0.55c0.15-0.15,0.29-0.29,0.35-0.44
		c0-0.01,0.01-0.02,0.01-0.03c-0.03-0.02-0.07-0.03-0.1-0.05c-0.05-0.01-0.11-0.02-0.15-0.05c-0.09-0.05-0.16-0.12-0.22-0.19
		c-0.07-0.05-0.15-0.11-0.22-0.19c-0.06-0.01-0.12-0.01-0.21-0.01c-0.11,0-0.25,0-0.45-0.01c-0.02,0-0.04,0-0.05-0.01h0c0,0,0,0,0,0
		h0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c-0.02-0.01-0.03-0.01-0.05-0.02c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c-0.01,0-0.01-0.01-0.02-0.01c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.01-0.01-0.02-0.02-0.03-0.02
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c-0.02-0.02-0.03-0.03-0.04-0.05c-0.03-0.05-0.05-0.1-0.06-0.16v0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0v0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0-0.01,0-0.01c0,0,0,0,0,0c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0,0,0,0,0c0-0.02,0-0.04,0-0.06v0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0-0.02,0.01-0.04,0.02-0.06c0-0.01,0-0.01,0.01-0.02c0.06-0.16,0.12-0.28,0.16-0.38c0.01-0.03,0.03-0.06,0.04-0.08
		c0.01-0.02,0.01-0.03,0.01-0.04c-0.03-0.05-0.07-0.12-0.11-0.18c-0.07-0.12-0.25-0.25-0.41-0.38c-0.07-0.06-0.15-0.12-0.21-0.17
		c-0.02-0.02-0.04-0.03-0.06-0.05c0,0,0,0,0,0c-0.1-0.09-0.22-0.19-0.36-0.29c-0.19-0.15-0.4-0.32-0.58-0.49
		c-0.04,0.06-0.09,0.13-0.12,0.18c0.08,0.07,0.18,0.16,0.22,0.3c0.08,0.27,0.16,0.57,0.14,0.88c-0.02,0.34-0.12,0.62-0.26,0.87
		c-0.17,0.32-0.41,0.61-0.62,0.88c-0.12,0.15-0.23,0.29-0.33,0.43c-0.03,0.06-0.06,0.11-0.1,0.18l-0.14,0.25
		c-0.24,0.41-0.46,0.61-0.92,0.81c-0.05,0.02-0.11,0.04-0.16,0.04c-0.13,0.05-0.27,0.1-0.4,0.14c-0.04,0.03-0.1,0.06-0.16,0.08
		c-0.45,0.14-1.38,0.45-1.77-0.7c-0.02-0.06-0.04-0.1-0.06-0.13c-0.01-0.02-0.02-0.05-0.03-0.07c-0.03-0.05-0.05-0.11-0.06-0.17
		c-0.01-0.06-0.02-0.14-0.02-0.23c0-0.19,0.06-0.37,0.13-0.55c0-0.01,0.01-0.03,0.01-0.04c0.01-0.02,0.01-0.04,0.02-0.07
		c0.02-0.1,0.05-0.2,0.06-0.3c0.04-0.31,0.08-0.6,0.14-0.89c0.04-0.23,0.09-0.47,0.17-0.72c0.13-0.41,0.27-0.84,0.44-1.27
		c0.06-0.17,0.13-0.33,0.2-0.5c0,0,0-0.01,0-0.01c0.03-0.06,0.06-0.13,0.1-0.2c0.01-0.01,0.01-0.02,0.02-0.03
		c0.02-0.03,0.04-0.08,0.06-0.11c-0.11-0.04-0.2-0.14-0.23-0.26c-0.01-0.05-0.03-0.1-0.04-0.15c-0.05-0.05-0.1-0.11-0.14-0.17
		c-0.02-0.01-0.04-0.02-0.06-0.03c-0.08-0.02-0.2-0.03-0.25-0.03c-0.04,0.01-0.09,0.07-0.14,0.17c-0.09,0.19-0.21,0.49-0.35,0.92
		l-0.01,0.02c-0.01,0.05-0.03,0.09-0.04,0.13h0c-0.09,0.28-0.18,0.56-0.27,0.84c-0.25,0.78-0.5,1.58-0.87,2.32
		c-0.1,0.2-0.2,0.39-0.32,0.59c0,0-0.01,0.01-0.01,0.01c-0.04,0.06-0.09,0.11-0.15,0.14h0c0,0,0,0,0,0h0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0h0c0,0,0,0,0,0c0,0,0,0,0,0
		c-0.08,0.03-0.18,0.04-0.27,0.01c0,0,0,0,0,0h0c0,0,0,0,0,0c-0.13-0.05-0.22-0.16-0.25-0.29c0,0,0,0,0,0c0,0,0,0,0,0l0,0
		c-0.01-0.04-0.01-0.07,0-0.11c0.09-0.81,0.1-1.64,0.11-2.45l0-0.24c0-0.06,0-0.12-0.01-0.2c-0.07,0.22-0.18,0.42-0.37,0.58
		c-0.14,0.14-0.33,0.22-0.59,0.27c-0.09,0.02-0.18,0.03-0.28,0.04c-0.13,0.01-0.25,0.01-0.38,0.01c-0.05,0-0.1,0-0.14,0
		c-1.04,0-1.89,0.29-2.88,0.63c-0.01,0-0.02,0.01-0.03,0.01c-0.26,0.11-0.37,0.19-0.48,0.4c-0.11,0.24-0.22,0.49-0.33,0.73
		l-0.25,0.55c-0.03,0.06-0.07,0.13-0.12,0.22c-0.02,0.03-0.04,0.06-0.06,0.1c-0.04,0.12-0.06,0.23-0.04,0.31
		c0,0.01,0.01,0.02,0.01,0.02c0.12,0.12,0.33,0.3,0.44,0.39c0.09,0.08,0.18,0.15,0.24,0.21c0.11,0.1,0.21,0.21,0.3,0.32
		c0.11,0.09,0.2,0.18,0.27,0.26c0.39,0.45,0.74,0.95,1.03,1.44c0.07,0.11,0.22,0.29,0.37,0.46c0.36,0.41,0.71,0.8,0.7,1.2
		c-0.01,0.3-0.22,0.64-0.45,1.03c-0.12,0.2-0.26,0.42-0.31,0.56c0,0.01-0.01,0.02-0.01,0.03c0,0,0,0,0,0l-0.06,0.23
		c-0.01,0.04-0.02,0.09-0.03,0.13c-0.01,0.04-0.01,0.09-0.02,0.12c-0.02,0.16-0.05,0.35-0.2,0.57c-0.08,0.12-0.19,0.18-0.28,0.22
		c-0.06,0.06-0.11,0.11-0.16,0.16c0,0-0.01,0.01-0.01,0.01c-0.01,0.01-0.01,0.02-0.02,0.03c0.02,0.03,0.05,0.07,0.08,0.13
		c0.03,0.03,0.06,0.06,0.11,0.11c0.69,0.65,1.45,1.25,2.55,2c0.1,0.07,0.16,0.18,0.17,0.3c0,0.12-0.05,0.24-0.15,0.31
		c-0.01,0.01-0.02,0.02-0.03,0.02c-0.05,0.05-0.05,0.1-0.05,0.28c0,0.16,0,0.36-0.1,0.57c-0.12,0.26-0.27,0.39-0.44,0.54
		c-0.23,0.26-0.53,0.5-0.92,0.75c-0.01,0.01-0.03,0.02-0.04,0.03c0,0-0.01,0-0.01,0.01c-0.1,0.06-0.2,0.11-0.3,0.16
		c-0.38,0.17-0.79,0.26-1.31,0.27c-0.07,0-0.17,0.01-0.26,0.02c0,0.04,0.01,0.08,0.01,0.12c0.01,0.06,0.01,0.14,0.02,0.22
		c0,0.01,0,0.03,0,0.04c0.01,0.08,0.01,0.16,0.01,0.24c0.03,0.96-0.17,1.68-0.72,2.55c0,0.01-0.01,0.01-0.01,0.02
		c-0.32,0.53-0.39,0.99-0.46,1.52c0,0.03-0.01,0.07-0.01,0.1c-0.04,0.31-0.09,0.64-0.2,1.01c-0.02,0.08-0.08,0.16-0.15,0.2
		c0,0,0,0,0,0c0,0,0,0,0,0c-0.03,0.02-0.07,0.04-0.11,0.05c-0.03,0.01-0.05,0.01-0.08,0.01c-0.1,0-0.2-0.03-0.28-0.09
		c-0.07-0.06-0.22-0.15-0.35-0.22c-0.21-0.12-0.4-0.23-0.51-0.36c-0.01-0.01-0.02-0.02-0.03-0.03c-0.12-0.14-0.21-0.27-0.28-0.39
		c-0.11-0.16-0.19-0.3-0.35-0.42c-0.11-0.06-0.22-0.13-0.32-0.21c-0.19-0.15-0.31-0.31-0.4-0.48c-0.1-0.14-0.18-0.3-0.29-0.5
		l-0.04-0.08c-0.17-0.32-0.18-0.32-0.38-0.33c-0.13-0.01-0.3-0.02-0.54-0.08c-0.18-0.04-0.33-0.12-0.47-0.21
		c0,0-0.01-0.01-0.01-0.01c-0.35-0.22-0.57-0.52-0.77-0.79c-0.17-0.22-0.28-0.38-0.43-0.46c-0.01-0.01-0.03-0.01-0.04-0.02
		c-0.06-0.02-0.12-0.04-0.17-0.04c-0.1,0-0.21,0.02-0.35,0.07c-0.15,0.07-0.3,0.19-0.5,0.35c-0.23,0.18-0.46,0.37-0.74,0.49
		c-0.37,0.16-1.01,0.25-1.46,0.16c-0.09,0.04-0.2,0.07-0.33,0.04c0,0,0,0,0,0c-0.01,0-0.01,0-0.02,0c-0.06-0.01-0.14-0.05-0.2-0.13
		c-0.02-0.03-0.04-0.06-0.06-0.09c-0.07,0.03-0.14,0.07-0.21,0.11c-0.12,0.09-0.23,0.2-0.31,0.29l-0.05,0.05
		c-0.04,0.04-0.08,0.08-0.12,0.12c-0.05,0.06-0.1,0.11-0.14,0.16l-0.06,0.07c-0.07,0.08-0.14,0.18-0.22,0.26
		c0,0.01,0,0.01-0.01,0.02c-0.21,0.26-0.42,0.53-0.62,0.79c-0.12,0.15-0.24,0.31-0.36,0.46c-0.01,0.01-0.02,0.02-0.03,0.03
		c0,0-0.01,0.01-0.01,0.01c0,0,0,0.01-0.01,0.01c-0.04,0.05-0.07,0.09-0.09,0.13c0,0,0,0,0,0.01c-0.01,0.02-0.03,0.05-0.03,0.07
		c-0.02,0.07,0,0.13,0.05,0.26c0.05,0.15,0.13,0.35,0.15,0.63c0.08,0.86-0.3,1.08-0.77,1.26c-0.08,0.03-0.18,0.07-0.28,0.12
		c-0.04,0.02-0.08,0.07-0.14,0.12c-0.09,0.1-0.21,0.21-0.38,0.29c-0.62,0.28-1.28,0.23-1.86,0.19c-0.33-0.02-0.64-0.04-0.93-0.01
		c-0.12,0.01-0.26,0.05-0.41,0.1c-0.43,0.15-0.91,0.44-0.97,0.59c-0.01,0.03-0.02,0.07-0.03,0.11c-0.01,0.2,0.1,0.48,0.24,0.74
		c0.05,0.1,0.11,0.2,0.16,0.29c0.08,0.14,0.15,0.27,0.21,0.38c0.06,0.12,0.14,0.23,0.24,0.35c0.11,0.14,0.23,0.29,0.31,0.47
		c0.19,0.41,0.17,0.8,0.15,1.14c-0.01,0.11-0.01,0.22-0.01,0.33c0,0.75,0.27,1.42,0.63,2.23c0.21,0.48,0.51,0.9,0.83,1.34
		c0.24,0.33,0.49,0.68,0.7,1.05c0.13,0.24,0.24,0.46,0.34,0.67c0.21,0.44,0.39,0.81,0.73,1.18c0.1,0.11,0.19,0.24,0.28,0.37
		c0.05,0.07,0.09,0.13,0.14,0.19c0.11,0.07,0.23,0.17,0.37,0.28c0.03,0.02,0.05,0.05,0.07,0.08c0.05,0.03,0.1,0.06,0.14,0.09
		c0.04,0.02,0.08,0.04,0.12,0.07c0,0,0,0,0,0c0.11,0.04,0.25,0.1,0.39,0.26c0.29,0.36,0.36,0.9,0.42,1.38
		c0.03,0.22,0.05,0.44,0.1,0.55c0.02,0.04,0.06,0.11,0.11,0.17c0.09,0.12,0.2,0.28,0.26,0.48c0.05,0.16,0.06,0.25,0.06,0.32
		c0-0.02,0.03,0.03,0.07,0.11c0.12,0.21,0.34,0.36,0.57,0.52c0.09,0.06,0.17,0.12,0.26,0.18c0.29,0.22,0.29,0.22,0.66,0.25l0.04,0
		c0.27-0.27,0.63-0.05,0.83,0.08c0.11,0.07,0.18,0.2,0.18,0.33c-0.01,0.2-0.07,0.37-0.13,0.51c0.05-0.02,0.11-0.03,0.17-0.04
		c0.06-0.01,0.13,0,0.2,0.01c0.19,0.05,0.29,0.15,0.36,0.24c0.23,0.11,0.34,0.22,0.45,0.33l0.09,0.09c0.1,0.1,0.19,0.18,0.28,0.25
		c0.03,0.02,0.06,0.04,0.09,0.06c0.05,0.04,0.1,0.07,0.15,0.1c0.01-0.02,0.02-0.04,0.03-0.06
		C157.97,203.88,158.13,203.82,158.24,203.79z"
          />
          <path
            id="Tamaulipas"
            d="M228.06,95.08c-0.12,0.1-0.26,0.18-0.37,0.25c-0.09,0.05-0.18,0.1-0.27,0.16c-0.11,0.07-0.21,0.14-0.33,0.21
		c-0.1,0.07-0.2,0.14-0.3,0.21c-0.13,0.09-0.26,0.16-0.39,0.24c-0.02,0.01-0.03,0.02-0.05,0.03c-0.1,0.07-0.19,0.13-0.27,0.2
		c-0.07,0.06-0.13,0.1-0.17,0.14c-0.01,0.01-0.02,0.01-0.02,0.02c0,0.01,0,0.02,0,0.03l-0.01,0.14c0,0.04,0,0.08-0.01,0.11
		c0.01,0.03,0.02,0.07,0.04,0.1c0.01,0.03,0.02,0.05,0.03,0.07c0.04,0,0.09,0,0.14,0c0.09-0.01,0.21-0.01,0.36,0
		c0.81,0.06,1.23,0.43,1.32,1.22c0,0.03,0.01,0.06,0.01,0.09c0,0,0,0.01,0,0.01c0,0.01,0,0.01,0,0.02c0,0,0,0,0,0c0,0,0,0,0,0
		c0.02,0.25-0.01,0.48-0.03,0.71c-0.02,0.2-0.04,0.39-0.03,0.57c0.02,0.22,0.09,0.44,0.15,0.67c0.07,0.24,0.14,0.49,0.18,0.77
		c0.09,0.78-0.13,1.48-0.35,2.15c-0.08,0.24-0.15,0.47-0.21,0.71c-0.02,0.08-0.04,0.15-0.05,0.21c-0.02,0.08-0.03,0.15-0.05,0.21
		c-0.03,0.18-0.04,0.29,0,0.37c0.04,0.07,0.13,0.12,0.28,0.17c0.04,0.01,0.08,0.02,0.13,0.04c0.56,0.17,1.01,0.43,1.32,0.78
		c0.05,0.05,0.08,0.11,0.09,0.17c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0v0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0.01,0,0.02,0,0.04c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0v0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c-0.01,0.03-0.03,0.05-0.04,0.07c-0.01,0.01-0.01,0.01-0.01,0.02c-0.11,0.16-0.14,0.31-0.16,0.5c0,0.03-0.01,0.07-0.01,0.11
		c-0.01,0.17-0.02,0.36-0.08,0.58c-0.05,0.2-0.15,0.38-0.25,0.54c0.02,0,0.03,0,0.05,0c0.39-0.04,0.63-0.06,0.8-0.03
		c0.72,0.15,1.17,0.48,1.48,1.05c0.01,0.02,0.02,0.04,0.03,0.06c0,0,0,0,0,0c0,0,0,0,0,0c0.15,0.32,0.26,0.73,0.26,1.14
		c0.01,0.34-0.05,0.69-0.22,0.99c-0.01,0.01-0.01,0.02-0.02,0.03c-0.04,0.07-0.09,0.13-0.14,0.18c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c-0.1,0.11-0.22,0.19-0.36,0.25c-0.01,0-0.02,0.01-0.03,0.01c0.07,0.23,0.15,0.48,0.26,0.61
		c0.02,0.03,0.04,0.05,0.06,0.07c0.11-0.02,0.24-0.01,0.42,0.05c0.09,0.03,0.15,0.05,0.21,0.06c0.32,0.09,0.44,0.16,0.73,0.57
		c0.04,0.06,0.08,0.13,0.12,0.21c0.03,0.07,0.09,0.17,0.12,0.2c0.09-0.01,0.21-0.01,0.34,0.05c0.05,0.02,0.08,0.03,0.1,0.04
		c0.08-0.02,0.17-0.03,0.25-0.01c0.17,0.05,0.23,0.17,0.32,0.35l0.06,0.11c0.18,0.33,0.2,0.75,0.21,1.12
		c0.01,0.19,0.02,0.37,0.05,0.51c0.02,0.11,0.07,0.24,0.11,0.36c0.04,0.12,0.08,0.24,0.12,0.37c0.08-0.05,0.2-0.09,0.34-0.12
		c0.28-0.04,0.51-0.01,0.71,0.07c0.21,0.06,0.43,0.19,0.64,0.44c0.14,0.17,0.22,0.37,0.29,0.56c0,0.01,0.01,0.01,0.01,0.02
		c0.2,0.38,0.29,0.45,0.38,0.46c0.03-0.02,0.09-0.12,0.12-0.18c0.12-0.19,0.3-0.48,0.68-0.57c0.25-0.06,0.5,0.03,0.72,0.12
		c0.07,0.03,0.14,0.06,0.19,0.07c0.01,0,0.02,0,0.03,0.01c0.12,0.01,0.24,0.02,0.37,0.02c0.09,0,0.17,0,0.27-0.01
		c0.55-0.02,1.17-0.04,1.61,0.37c0.29,0.27,0.38,0.62,0.4,0.96c0.06,0.52-0.04,1.09-0.13,1.6c-0.03,0.18-0.07,0.36-0.09,0.53
		c-0.02,0.19-0.04,0.38-0.07,0.57c-0.09,0.81-0.18,1.63-0.26,2.45c-0.01,0.07-0.03,0.19-0.06,0.33c-0.02,0.09-0.04,0.22-0.06,0.36
		c0,0.15,0.02,0.26,0.06,0.31c0.01,0.01,0.03,0.02,0.04,0.03c0.07,0.02,0.15,0.03,0.21,0.05c0.22,0.05,0.43,0.09,0.58,0.19
		c0.1,0.06,0.13,0.07,0.14,0.07c0.14,0.04,0.24,0.13,0.29,0.26c0.08,0.08,0.15,0.18,0.2,0.29c0.11,0.28,0.05,0.61-0.17,1
		c-0.07,0.13-0.19,0.23-0.33,0.32c-0.35,0.37-0.87,0.68-1.33,0.95c-0.22,0.13-0.44,0.26-0.62,0.39c-0.16,0.11-0.32,0.23-0.49,0.34
		l-0.3,0.22c-0.61,0.43-1.22,0.86-1.83,1.28c-0.08,0.06-0.16,0.11-0.24,0.17c-0.33,0.24-0.7,0.52-1.1,0.65
		c-0.36,0.14-0.75,0.19-1.2,0.04c-0.3-0.11-0.46-0.38-0.56-0.6c0,0,0,0,0,0c0,0,0,0,0,0c-0.06,0.1-0.11,0.19-0.17,0.26
		c-0.07,0.08-0.15,0.12-0.23,0.14c-0.12,0.17-0.2,0.24-0.31,0.28c-0.03,0.01-0.06,0.02-0.09,0.02c-0.02,0.01-0.03,0.02-0.05,0.03
		c-0.06,0.03-0.11,0.06-0.17,0.07c0.01,0.04,0.01,0.08,0,0.12c-0.01,0.16-0.07,0.32-0.14,0.48c-0.09,0.3-0.25,0.59-0.38,0.84
		l-0.04,0.07c-0.07,0.14-0.12,0.23-0.15,0.31c-0.02,0.05-0.03,0.09-0.04,0.14c-0.01,0.09,0.01,0.19,0.05,0.37
		c0.01,0.06,0.03,0.13,0.05,0.19c0.05,0.19,0.1,0.41,0.11,0.64c0,0.03,0,0.06,0,0.09c0,0.09-0.04,0.17-0.1,0.24c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.01,0.01-0.03,0.02-0.04,0.04c-0.03,0.02-0.05,0.04-0.08,0.05
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.07,0.02-0.14,0.03-0.21,0.01
		c-0.17-0.04-0.32-0.09-0.45-0.16c-0.07-0.03-0.14-0.07-0.21-0.1c-0.21-0.11-0.37-0.2-0.57-0.2c-0.42,0-0.93,0.2-1.38,0.37
		l-0.13,0.05c-0.41,0.16-0.71,0.39-1.06,0.67c-0.14,0.11-0.28,0.22-0.43,0.33c-0.02,0.02-0.05,0.03-0.07,0.05
		c-0.2,0.15-0.42,0.28-0.7,0.37c-0.07,0.02-0.29,0.09-0.48-0.02c-0.04,0.03-0.09,0.06-0.13,0.09c-0.34,0.25-0.88,0.68-1.11,0.92
		c0.02,0.03,0.04,0.05,0.05,0.08c0.07,0.1,0.16,0.2,0.2,0.23c0.02,0.01,0.03,0.01,0.04,0.02c0.01,0,0.01,0,0.02,0
		c-0.01,0,0.06-0.04,0.11-0.06c0.05-0.03,0.12-0.06,0.19-0.09c0,0,0.01,0,0.01,0c0.01,0,0.02-0.01,0.02-0.01
		c0.08-0.03,0.16-0.03,0.24-0.01c0.02,0,0.03,0.01,0.05,0.02c0.03,0.01,0.06,0.03,0.08,0.05c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0.03,0.02,0.05,0.05,0.07,0.08c0,0,0,0,0,0c0,0,0,0,0,0h0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0.01,0.02,0.01,0.03,0.02,0.05c0,0,0,0,0,0c0.05,0.14,0.15,0.35,0.26,0.57c0.25,0.48,0.5,0.98,0.46,1.4
		c-0.03,0.32-0.23,0.66-0.43,0.99c-0.13,0.21-0.26,0.43-0.3,0.59c-0.07,0.22-0.12,0.46-0.16,0.69c-0.03,0.22-0.06,0.44-0.07,0.67
		c0,0.08-0.01,0.15-0.01,0.22c0,0.07,0,0.13,0,0.18c0.01,0.19,0.05,0.32,0.16,0.46c0.08,0.09,0.19,0.19,0.34,0.33l0.05,0.04
		c0.5,0.45,1.26,1.14,1.2,1.99c0,0.02,0,0.03,0,0.05c0,0.1,0,0.19-0.04,0.28c-0.03,0.1-0.07,0.18-0.16,0.24
		c-0.06,0.04-0.14,0.06-0.22,0.07c0,0-0.01,0-0.01,0.01c-0.15,0.08-0.29,0.06-0.4,0.03c-0.02,0-0.03-0.01-0.05-0.01
		c-0.06,0.08-0.14,0.19-0.18,0.24c-0.04,0.06-0.08,0.11-0.1,0.14c0,0,0,0,0,0.01c-0.05,0.07-0.12,0.16-0.19,0.27
		c-0.31,0.46-0.57,0.82-0.88,0.95c-0.13,0.05-0.3,0.06-0.54,0.05c-0.15,0-0.34-0.01-0.54-0.02c-0.15-0.01-0.28-0.02-0.37-0.01
		c-0.2,0.01-0.41-0.02-0.62-0.04c-0.11-0.01-0.23-0.03-0.34-0.03c-0.12,0-0.23,0-0.32,0.03c-0.14,0.03-0.18,0.05-0.18,0.05
		c-0.01,0.01-0.06,0.08-0.1,0.13l-0.1,0.13c-0.04,0.05-0.08,0.1-0.11,0.15c-0.37,0.53-1.13,1.61-1.14,2.12
		c0,0.14,0.08,0.34,0.16,0.52c0.04,0.1,0.09,0.21,0.12,0.3c0.07,0.21,0.11,0.41,0.15,0.61c0.04,0.18,0.07,0.36,0.12,0.51
		c0,0,0,0,0,0c0,0,0,0,0,0c0.02,0.06,0.02,0.12,0.02,0.17c-0.01,0.05-0.02,0.1-0.05,0.15c-0.01,0.01-0.02,0.02-0.02,0.03
		c-0.06,0.07-0.14,0.12-0.23,0.14c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.01,0-0.01,0c-0.01,0-0.01,0-0.02,0
		c-0.45,0.06-0.84-0.17-1.17-0.35c-0.16-0.09-0.37-0.21-0.49-0.22c-0.07,0.08-0.13,0.19-0.17,0.28c0.13,0.22,0.16,0.38,0.15,0.58
		c0.01,0.04,0.01,0.08,0.01,0.13c0,0.16-0.04,0.31-0.09,0.46c-0.03,0.15-0.06,0.28-0.12,0.37c-0.01,0.02-0.01,0.04-0.01,0.06
		c-0.03,0.14-0.11,0.54-0.23,0.93c-0.02,0.07-0.04,0.14-0.06,0.21c0.15,0.01,0.32,0.04,0.52,0.16c0.28,0.16,0.48,0.48,0.67,0.76
		c0.05,0.07,0.09,0.14,0.13,0.2c0.27,0.37,0.52,0.72,0.52,1.17c0,0.21-0.06,0.44-0.2,0.7c-0.15,0.28-0.5,0.51-1.05,0.86
		c-0.2,0.13-0.48,0.3-0.61,0.42c0.1,0.13,0.37,0.38,0.79,0.67c0.78,0.53,1.75,1.03,2.61,1.48l0.18,0.09
		c0.37,0.19,1.36,0.66,1.85,0.84c-0.01-0.16-0.06-0.44-0.18-0.92l-0.03-0.12c-0.01-0.03-0.01-0.07-0.01-0.1
		c0-0.1,0.04-0.19,0.11-0.26c0,0,0,0,0,0c0,0,0,0,0,0c0.03-0.03,0.06-0.05,0.09-0.07c0.06-0.03,0.14-0.05,0.21-0.04c0,0,0,0,0,0
		c0.02,0,0.04,0,0.06,0.01c0,0,0.01,0,0.01,0c0.55,0.14,0.79,0.54,0.98,0.86c0.09,0.16,0.18,0.29,0.28,0.39
		c0.04-0.02,0.09-0.04,0.15-0.06c0.07-0.11,0.17-0.21,0.34-0.25c0.03-0.01,0.06-0.01,0.09-0.01c0.08-0.03,0.16-0.03,0.24,0
		c0.11,0.03,0.2,0.12,0.24,0.23c0,0,0,0,0.01,0.01c0.24,0.23,0.45,0.73,0.65,1.27l0.05,0.13c0.09,0.24,0.16,0.46,0.23,0.66
		c0.19,0.62,0.29,0.93,0.85,1.14c0.1,0.03,0.21,0.06,0.34,0.1l0.21,0.06c0.4,0.11,0.78,0.26,1.18,0.43c0.37,0.15,0.73,0.32,1.09,0.5
		c0.15,0.08,0.28,0.16,0.4,0.23c0.34,0.2,0.45,0.27,0.76,0.17c0.22-0.08,0.45-0.17,0.68-0.28c0.25-0.11,0.51-0.23,0.77-0.32
		c0.36-0.15,0.75-0.24,1.17-0.16c0.1,0.02,0.19,0.04,0.28,0.08c0.07,0.01,0.14,0.03,0.22,0.03c0.14,0.01,0.35-0.04,0.55-0.08
		c0.3-0.07,0.62-0.14,0.92-0.08c0.08,0.02,0.15,0.05,0.22,0.1c0.09,0.01,0.16,0.03,0.24,0.06c0.14-0.03,0.25,0.03,0.28,0.04
		c0.12,0.06,0.2,0.14,0.26,0.21c0.11-0.06,0.22-0.1,0.35-0.07c0.21,0.04,0.3,0.21,0.34,0.28c0.01,0.02,0.02,0.04,0.03,0.05
		c0.04,0.01,0.08,0.02,0.13,0.03c0.02,0,0.03,0.01,0.05,0.01c0-0.01,0-0.02,0-0.03c0-0.1,0.01-0.21,0.07-0.32
		c0.05-0.09,0.06-0.14,0.07-0.16c0.06-0.2,0.31-0.35,0.52-0.28c0.05,0.01,0.16,0,0.2,0c0.04,0,0.08-0.01,0.13-0.02
		c0.18-0.03,0.41-0.07,0.66,0.01c0.37,0.13,0.57,0.44,0.76,0.71c0.12,0.17,0.23,0.34,0.36,0.43c0.38,0.27,0.71,0.47,1.21,0.61
		c0.51,0.15,1.45,0.43,1.44,1.29c0,0.02,0,0.04,0,0.06c-0.01,0.12-0.05,0.2-0.09,0.27c0.05,0.04,0.1,0.08,0.14,0.12
		c0.07-0.03,0.16-0.1,0.28-0.19c0.09-0.07,0.19-0.16,0.31-0.23c0-0.01-0.01-0.03-0.01-0.04c-0.01-0.04-0.02-0.08-0.03-0.12
		c-0.02-0.08-0.03-0.16-0.05-0.24c-0.01-0.04-0.01-0.09-0.02-0.13c-0.01-0.08-0.02-0.17-0.03-0.25c0-0.04-0.01-0.08-0.01-0.12
		c-0.01-0.12-0.02-0.25-0.02-0.38c-0.01-0.23-0.05-0.46-0.1-0.71c-0.07-0.4-0.14-0.81-0.1-1.23c0.01-0.06,0.02-0.13,0.05-0.23
		c0,0,0,0-0.01,0c-0.26-0.02-0.43-0.21-0.51-0.59c-0.11-0.49,0.18-0.86,0.39-1.13c0.07-0.09,0.2-0.27,0.2-0.32
		c0,0-0.01-0.04-0.09-0.1c-0.14-0.12-0.17-0.32-0.08-0.48c0.08-0.14,0.23-0.21,0.38-0.19c0.01,0,0.03,0,0.04,0
		c0.04-0.06,0.09-0.11,0.16-0.17c0.18-0.13,0.23-0.21,0.39-0.45c0.08-0.12,0.15-0.39,0.22-0.62c0.14-0.5,0.28-1.01,0.65-1.25
		c0,0,0.01-0.01,0.01-0.01c0.06-0.47,0.09-0.98,0.06-1.49c-0.07-1.67-0.02-3.02,0.17-4.39c0.15-1.11,0.33-2.12,0.55-3.09
		c0-0.02,0.01-0.05,0.02-0.07c-0.08-0.05-0.14-0.13-0.17-0.23c-0.11-0.43-0.03-0.81,0.02-1.08c0.08-0.41,0.08-0.41-0.38-0.51
		c-0.16-0.04-0.28-0.17-0.3-0.34c-0.02-0.17,0.08-0.32,0.23-0.39c0.26-0.11,0.54-0.04,0.76,0.02c0.06,0.02,0.15,0.04,0.22,0.05
		c0.02-0.14,0.03-0.37,0.04-0.47c0-0.09,0.01-0.16,0.01-0.21l0.06-0.51c0.09-0.78,0.19-1.58,0.31-2.37
		c0.48-3.08,1.26-6.17,2.38-9.44c0.93-2.72,1.77-4.98,3.34-7.18c0.72-1.01,1.46-2.1,2.01-3.21c0.17-0.34,0.34-0.7,0.5-1.05
		l0.27-0.57c0.05-0.1,0.08-0.19,0.11-0.29c0.09-0.29,0.13-0.58,0.16-0.91c0.03-0.26,0.06-0.54,0.13-0.83v0
		c0.05-0.21,0.12-0.42,0.18-0.63c0.05-0.17,0.11-0.33,0.15-0.49c-0.1,0.02-0.21,0.04-0.33,0.04c0,0,0,0,0,0
		c-0.11,0-0.24-0.03-0.44-0.09c-0.02-0.01-0.04-0.01-0.06-0.02c-0.02,0.04-0.05,0.08-0.09,0.12c-0.01,0.02-0.03,0.03-0.05,0.05
		c-0.04,0.04-0.09,0.08-0.15,0.12c-0.07,0.04-0.14,0.07-0.2,0.09c-0.19,0.07-0.35,0.08-0.47,0.08c-0.03,0-0.07,0-0.09,0.01
		c0,0.01-0.01,0.02-0.02,0.05c0,0.01,0,0.04,0,0.08c0,0.03,0.01,0.07,0.01,0.1c0,0.05,0.01,0.1,0.01,0.15
		c0.01,0.16-0.02,0.35-0.18,0.5c-0.22,0.2-0.51,0.15-0.61,0.13c-0.09-0.01-0.15-0.05-0.2-0.1c-0.1,0.05-0.19,0.07-0.25,0.06
		c-0.19,0-0.36-0.11-0.5-0.31c-0.06-0.08-0.1-0.17-0.13-0.27c-0.01-0.03-0.02-0.06-0.03-0.1c-0.09,0.02-0.18,0-0.26-0.04
		c-0.11-0.06-0.21-0.14-0.3-0.21c-0.04-0.03-0.07-0.06-0.11-0.09l-0.13-0.1c-0.38-0.3-0.62-0.65-0.84-0.95
		c-0.18-0.25-0.35-0.49-0.57-0.68c-0.05-0.04-0.1-0.07-0.14-0.1c-0.14-0.07-0.31-0.09-0.59-0.11c-0.08-0.01-0.15-0.01-0.22-0.02
		c-0.23-0.03-0.4-0.07-0.54-0.14c-0.09-0.04-0.16-0.12-0.2-0.21c-0.01-0.01-0.01-0.03-0.02-0.04c-0.03-0.01-0.06-0.01-0.08-0.02
		c-0.04-0.02-0.1-0.03-0.15-0.04c-0.22-0.05-0.51-0.05-0.79-0.04c-0.03,0-0.06,0-0.09,0c-0.27,0.01-0.56,0.01-0.8-0.02
		c-0.08-0.01-0.18-0.03-0.29-0.06c-0.09-0.02-0.24-0.05-0.33-0.06c-0.01,0.01-0.02,0.02-0.03,0.04c-0.11,0.13-0.37,0.37-0.78,0.19
		c-0.3-0.13-0.36-0.37-0.4-0.49c0,0,0,0,0,0c-0.06,0.06-0.13,0.12-0.21,0.13c-0.21,0.04-0.4,0.01-0.55-0.1
		c-0.04,0.01-0.08,0.03-0.12,0.05c-0.06,0.03-0.12,0.03-0.19,0.02c-0.04,0-0.08-0.01-0.11-0.02c-0.05-0.02-0.09-0.05-0.13-0.09
		c-0.02-0.02-0.03-0.04-0.04-0.07c-0.01-0.02-0.03-0.04-0.04-0.06l0,0l-0.04-0.08c-0.06-0.02-0.11-0.03-0.17-0.05
		c-0.02-0.01-0.05-0.01-0.07-0.02c-0.01,0-0.03-0.01-0.04-0.02c-0.07-0.02-0.14-0.05-0.21-0.08c-0.17-0.07-0.34-0.17-0.5-0.32
		c-0.1-0.09-0.16-0.19-0.22-0.28c-0.02-0.04-0.05-0.08-0.07-0.11c-0.04-0.07-0.08-0.14-0.11-0.17c-0.03-0.03-0.05-0.04-0.1-0.05
		c-0.01,0-0.01,0-0.02,0c-0.01,0-0.02,0-0.03,0c-0.11-0.02-0.27-0.05-0.45-0.19c-0.08-0.06-0.14-0.16-0.15-0.26c0,0,0,0,0,0
		c0,0,0,0,0,0c-0.02-0.14,0.02-0.26,0.06-0.35c-0.02-0.02-0.05-0.03-0.07-0.05c-0.08-0.06-0.22-0.15-0.31-0.19
		c-0.03,0.04-0.06,0.08-0.1,0.11c-0.03,0.02-0.06,0.04-0.1,0.06c-0.05,0.02-0.09,0.02-0.14,0.03c0,0-0.01,0-0.01,0
		c-0.05,0-0.11-0.01-0.15-0.03c-0.04-0.02-0.09-0.05-0.13-0.08c-0.06-0.04-0.12-0.09-0.17-0.14c-0.05-0.05-0.11-0.1-0.17-0.13
		c-0.05-0.03-0.09-0.07-0.13-0.12c-0.01-0.02-0.03-0.03-0.04-0.05c-0.01-0.01-0.01-0.02-0.02-0.02c-0.1,0.04-0.21,0.05-0.3,0.02
		c-0.15-0.06-0.24-0.15-0.29-0.21c-0.05,0-0.1,0.02-0.16,0.02c-0.18,0.03-0.3,0.05-0.41,0.03c-0.04-0.01-0.09-0.02-0.15-0.03
		c-0.29-0.07-0.79-0.25-1-0.5c-0.07-0.08-0.11-0.2-0.1-0.31c-0.05-0.02-0.11-0.05-0.16-0.07c-0.06-0.03-0.11-0.08-0.15-0.13
		c-0.01-0.02-0.01-0.04-0.02-0.05c-0.02-0.04-0.04-0.08-0.04-0.12c0,0,0-0.01,0-0.01c0,0,0,0,0,0c0,0,0-0.01,0-0.01
		c0-0.07,0.01-0.13,0.04-0.19c-0.1-0.08-0.2-0.16-0.27-0.21c0,0,0,0,0,0c-0.02-0.02-0.04-0.03-0.05-0.03
		c-0.14-0.08-0.14-0.08-0.21-0.1c-0.04-0.01-0.1-0.02-0.19-0.04c-0.02-0.01-0.06-0.01-0.1-0.01c-0.04,0-0.09,0-0.13,0
		c-0.22,0-0.5-0.01-0.75-0.15c-0.03-0.02-0.05-0.04-0.07-0.05c-0.04-0.04-0.08-0.08-0.11-0.12c-0.07,0.02-0.15,0.02-0.21,0.02
		c-0.06-0.01-0.12-0.03-0.17-0.06c-0.02-0.01-0.03-0.03-0.04-0.04c-0.02-0.02-0.05-0.04-0.07-0.07c-0.01-0.01-0.01-0.03-0.02-0.04
		c0,0,0,0,0,0c0,0-0.01-0.02-0.01-0.03c0,0,0,0,0-0.01c-0.18,0.05-0.38,0.03-0.48,0.02c-0.02,0-0.04-0.01-0.06-0.01
		c-0.02,0-0.04-0.01-0.06-0.01c-0.02-0.01-0.04-0.02-0.06-0.03c-0.01-0.01-0.03-0.01-0.04-0.02c-0.02-0.02-0.04-0.03-0.06-0.05
		c-0.01-0.01-0.02-0.02-0.03-0.03c-0.02-0.03-0.04-0.05-0.06-0.08c-0.1,0.05-0.25,0.11-0.42,0.08c-0.04-0.01-0.08-0.01-0.13-0.03
		c-0.24-0.09-0.41-0.24-0.49-0.43c-0.13-0.32,0.02-0.63,0.14-0.86c0.04-0.07,0.09-0.19,0.1-0.23c0-0.11-0.06-0.16-0.3-0.32
		c-0.24-0.16-0.57-0.38-0.65-0.82c-0.02-0.1-0.02-0.2-0.01-0.29c0-0.01,0-0.02,0-0.03c0.01-0.08,0.02-0.15,0.03-0.21
		c0.01-0.07,0.03-0.14,0.02-0.19c0-0.05-0.01-0.1-0.03-0.15c-0.01-0.05-0.03-0.11-0.06-0.17c-0.04-0.11-0.08-0.23-0.11-0.36
		c-0.04-0.19-0.07-0.37-0.11-0.55c-0.09-0.52-0.18-1.01-0.44-1.47c-0.06-0.1-0.11-0.19-0.16-0.28c-0.07-0.11-0.13-0.22-0.19-0.33
		c0,0,0,0,0-0.01c-0.06-0.12-0.12-0.24-0.17-0.39c-0.06-0.18-0.17-0.29-0.3-0.42c-0.01-0.01-0.02-0.02-0.03-0.03
		c-0.01-0.01-0.01-0.01-0.02-0.02c-0.06-0.06-0.12-0.13-0.19-0.2c-0.09-0.11-0.18-0.23-0.25-0.38c-0.01-0.03-0.02-0.06-0.03-0.08
		c-0.04-0.13-0.02-0.25,0-0.34c-0.03-0.01-0.05-0.02-0.08-0.02c-0.11-0.03-0.2-0.1-0.24-0.21c-0.1-0.2-0.15-0.44-0.18-0.67
		c-0.03-0.19-0.05-0.39-0.06-0.57c-0.01-0.09-0.01-0.18-0.02-0.26c-0.04-0.44,0.02-0.76,0.08-1.08c0.03-0.13,0.05-0.27,0.07-0.42
		c0.01-0.04,0.01-0.07,0.01-0.1c0-0.09-0.03-0.14-0.09-0.24c-0.06-0.09-0.13-0.21-0.17-0.37c-0.1-0.35-0.11-0.85-0.11-1.28
		c0-0.14,0.01-0.28,0.01-0.4c0-0.15,0.04-0.32,0.08-0.49c0.03-0.14,0.09-0.4,0.07-0.48c-0.01-0.04-0.02-0.05-0.02-0.06
		c0,0,0-0.01,0-0.01c-0.06-0.02-0.21-0.1-0.33-0.34c-0.03-0.07-0.05-0.15-0.06-0.22c-0.03-0.22,0.04-0.42,0.09-0.59
		c0.03-0.08,0.07-0.22,0.06-0.27c-0.04-0.15-0.13-0.21-0.35-0.32c-0.1-0.05-0.2-0.1-0.29-0.17c-0.08-0.06-0.13-0.13-0.17-0.19
		c-0.01-0.01-0.01-0.02-0.02-0.03c-0.11,0.05-0.23,0.08-0.37,0.01c0,0,0,0,0,0h0c-0.18-0.08-0.29-0.16-0.37-0.23
		c-0.09-0.07-0.14-0.11-0.25-0.14c-0.1-0.03-0.18-0.05-0.23-0.08C228.08,95.06,228.07,95.07,228.06,95.08z"
          />
          <path
            id="Sinaloa"
            d="M145.57,153.46c0.03,0.03,0.08,0.06,0.12,0.09c0.14,0.1,0.31,0.22,0.47,0.41c0.19,0.22,0.34,0.45,0.49,0.67
		c0.16,0.24,0.31,0.46,0.5,0.66c0.48,0.5,0.81,1.16,0.98,1.92c0.04,0.01,0.08,0.02,0.11,0.04c0.27,0.13,0.41,0.47,0.34,0.84
		c-0.01,0.03-0.02,0.07-0.03,0.1c0.05-0.01,0.11-0.01,0.16-0.02c0.09-0.03,0.18-0.05,0.24-0.07c0.14-0.05,0.27-0.08,0.34-0.09
		c0.13-0.02,0.32-0.04,0.53-0.06c0.15-0.01,0.41-0.03,0.66-0.07c0.37-0.11,0.65-0.26,0.67-0.46c0-0.02,0-0.04-0.01-0.06
		c-0.01-0.05-0.05-0.11-0.07-0.17c-0.07-0.13-0.15-0.3-0.16-0.49c-0.01-0.15,0.03-0.29,0.06-0.4c-0.01-0.2-0.03-0.21-0.17-0.29
		c-0.06-0.03-0.14-0.08-0.22-0.14c-0.16-0.06-0.33-0.15-0.46-0.31c-0.21-0.27-0.3-0.58-0.24-0.9c0.14-0.81,1.12-1.5,1.54-1.76
		c0,0,0.01,0,0.01-0.01c0.05-0.03,0.09-0.06,0.12-0.08c-0.06-0.11-0.14-0.25-0.2-0.46c-0.14-0.47-0.02-0.99,0.31-1.34
		c0.2-0.22,0.46-0.35,0.74-0.38c0-0.07,0-0.16,0-0.26c-0.01-0.06-0.03-0.12-0.05-0.19c-0.01-0.05-0.03-0.08-0.04-0.1
		c-0.01,0-0.02,0-0.03,0c-0.12,0-0.31,0.01-0.56-0.04c-0.14-0.02-0.21-0.03-0.26-0.03c-0.26-0.01-0.35-0.1-0.51-0.28
		c-0.02-0.02-0.05-0.05-0.08-0.09l-0.09-0.09c-0.21-0.23-0.37-0.48-0.52-0.73c-0.16-0.26-0.31-0.5-0.5-0.69
		c-0.05-0.05-0.09-0.07-0.14-0.1c-0.12-0.07-0.3-0.17-0.42-0.46c-0.03-0.09-0.05-0.17-0.05-0.25c-0.03-0.13-0.05-0.28-0.08-0.43
		c-0.01-0.06-0.02-0.13-0.03-0.18c-0.06-0.1-0.11-0.22-0.13-0.37c-0.02-0.14,0.03-0.29,0.08-0.45c-0.01-0.03-0.02-0.06-0.03-0.1
		c-0.03-0.09-0.07-0.21-0.09-0.37c0-0.01,0-0.01,0-0.02c-0.01-0.05,0-0.1,0.01-0.15c0.01-0.09,0.04-0.16,0.07-0.21
		c0.01-0.01,0.01-0.03,0.02-0.04c-0.01-0.03-0.03-0.05-0.04-0.08c-0.02-0.02-0.03-0.05-0.05-0.07c-0.05-0.07-0.1-0.14-0.15-0.22
		c-0.07-0.1-0.14-0.22-0.18-0.35c-0.04-0.11-0.07-0.21-0.1-0.32c-0.02-0.04-0.03-0.08-0.05-0.12c-0.18-0.43-0.36-0.87-0.57-1.32
		c-0.38-0.78-0.61-1.52-0.69-2.2c-0.01-0.1-0.02-0.2-0.02-0.3c-0.01-0.1-0.01-0.21-0.01-0.31c0-0.37,0.06-0.72,0.12-1.06
		c0-0.01,0-0.02,0-0.03c0.03-0.21,0.06-0.42,0.08-0.63c0.04-0.38,0.03-0.61-0.04-0.85c-0.05-0.12-0.13-0.25-0.26-0.44
		c-0.23-0.36-0.33-0.73-0.43-1.1c-0.11-0.39-0.21-0.76-0.47-1.06c0,0,0-0.01-0.01-0.01c-0.2-0.22-0.44-0.44-0.68-0.65
		c-0.15-0.13-0.29-0.26-0.43-0.39c-0.02-0.02-0.04-0.04-0.07-0.07c-0.05-0.05-0.11-0.1-0.16-0.15c-0.07-0.07-0.15-0.15-0.23-0.24
		c-0.22-0.23-0.54-0.57-0.75-0.6c-0.07-0.01-0.13-0.01-0.18,0c-0.15,0.06-0.31,0.18-0.48,0.35c-0.03,0.04-0.06,0.07-0.1,0.12
		c-0.15,0.18-0.31,0.37-0.52,0.51c-0.28,0.2-0.62,0.29-0.97,0.27c-0.46,0.03-0.92-0.14-1.33-0.5c-0.07-0.06-0.13-0.13-0.19-0.19
		c-0.47-0.56-0.56-1.37-0.65-2.08c-0.02-0.14-0.03-0.29-0.05-0.42c-0.19-0.86-0.49-1.29-1.12-2.05c-0.12-0.14-0.22-0.29-0.3-0.44
		c-0.04-0.05-0.1-0.1-0.16-0.17c-0.18-0.18-0.3-0.31-0.36-0.44c-0.12-0.23-0.14-0.42-0.15-0.55c0,0,0-0.01,0-0.01
		c-0.05-0.02-0.14-0.04-0.28-0.07c-0.22-0.04-0.5-0.09-0.71-0.33c-0.01-0.01-0.02-0.02-0.03-0.03c-0.07-0.08-0.1-0.17-0.11-0.26
		c-0.01-0.03-0.02-0.06-0.03-0.1c-0.02-0.06-0.04-0.16-0.07-0.23c-0.13-0.09-0.28-0.19-0.41-0.35c-0.33-0.42-0.59-1.11-0.81-1.67
		l-0.05-0.12c-0.51-1.14-0.99-2.67-0.94-4.24c0.03-0.8,0.25-1.54,0.47-2.26c0.14-0.46,0.27-0.91,0.36-1.36
		c0.01-0.07,0.02-0.15,0.03-0.22c0.02-0.16,0.02-0.29,0.03-0.4c0-0.1,0.01-0.2,0.03-0.28c0-0.01,0-0.01,0-0.02c0-0.03,0-0.07,0-0.1
		c0-0.01,0-0.02-0.01-0.03c-0.25-1.41-0.59-2.44-1.54-3.55c-0.42-0.49-0.69-1.03-0.86-1.69c-0.06-0.22-0.08-0.46-0.1-0.68
		c-0.03-0.36-0.07-0.69-0.22-0.92c-0.15-0.24-0.44-0.33-0.79-0.41c-0.15-0.03-0.3-0.05-0.42-0.09c-0.52-0.15-1.04-0.31-1.55-0.47
		l-0.32-0.1c-0.12-0.04-0.26-0.07-0.42-0.11c-0.35-0.09-0.73-0.18-1.04-0.32c-0.26-0.09-0.5-0.24-0.65-0.55c0,0,0,0,0,0
		c-0.19-0.4-0.17-1.01-0.15-1.55c0.01-0.2,0.02-0.38,0.01-0.53c0-0.05,0-0.09,0-0.14c-0.03-0.6-0.05-1.2-0.07-1.8
		c-0.01-0.18-0.01-0.36-0.01-0.52c0-0.7,0-1.2-0.41-1.72c-0.31-0.39-0.56-0.81-0.8-1.23c-0.17-0.24-0.32-0.48-0.44-0.73
		c-0.15-0.32-0.15-0.69-0.14-1.04c0-0.04,0-0.08,0-0.11c-0.05-0.43-0.13-0.68-0.44-0.82c-0.04-0.02-0.08-0.03-0.13-0.04
		c-0.13-0.02-0.28-0.02-0.43-0.02c-0.38-0.01-0.82-0.01-1.17-0.25c-0.33-0.23-0.37-0.54-0.4-0.75c0,0,0,0,0-0.01
		c-0.03-0.15-0.06-0.26-0.17-0.37c-0.2-0.16-0.54-0.17-0.81-0.11c0,0-0.01,0-0.01,0c-0.18,0.04-0.37,0.11-0.53,0.19
		c-0.13,0.07-0.26,0.23-0.38,0.39c-0.09,0.11-0.18,0.23-0.28,0.33c-0.02,0.05-0.04,0.11-0.05,0.16c0,0.52-0.09,1.22-0.43,1.66
		c-0.36,0.46-1,0.82-1.56,1.14c-0.26,0.15-0.5,0.28-0.69,0.41c-0.18,0.12-0.36,0.24-0.53,0.37c-0.64,0.44-1.28,0.89-1.92,1.33
		c-0.34,0.24-0.69,0.48-1.03,0.72l-0.46,0.32c-0.26,0.18-0.53,0.37-0.79,0.56c-0.49,0.35-1,0.72-1.52,1.05
		c0,0.01,0.01,0.03,0.01,0.05c0,0.01,0,0.01,0.01,0.02c0.02,0.08,0.05,0.09,0.15,0.15c0.06,0.04,0.16,0.09,0.25,0.18
		c0.08,0.09,0.16,0.22,0.17,0.4c0,0,0,0,0,0c0,0,0,0,0,0c0.01,0.19-0.09,0.3-0.24,0.39c0,0.03,0.01,0.05,0.01,0.08
		c0.02,0.03,0.03,0.07,0.04,0.11c0.02,0.11-0.01,0.23-0.1,0.32c-0.2,0.21-0.39,0.24-0.52,0.23c-0.3-0.03-0.45-0.29-0.57-0.5
		c-0.04-0.08-0.13-0.22-0.17-0.25c-0.13-0.01-0.24-0.08-0.3-0.19c-0.08,0.15-0.16,0.29-0.25,0.42h0c0,0-0.02,0.06-0.05,0.13
		c-0.22,0.53-0.42,1.05-0.85,1.02c0,0,0,0,0,0c0,0,0,0,0,0c-0.04,0-0.08-0.02-0.12-0.03c-0.02-0.01-0.03-0.01-0.05-0.01
		c-0.05-0.03-0.09-0.06-0.13-0.11c-0.12,0.14-0.24,0.27-0.36,0.4c-0.23,0.25-0.45,0.49-0.62,0.73c-0.06,0.08-0.11,0.16-0.16,0.25
		c-0.22,0.38-0.34,0.8-0.31,1.13c0.06,0.04,0.11,0.1,0.15,0.17c0.02,0.03,0.04,0.06,0.05,0.1c0.02,0.07,0.02,0.14,0.03,0.2
		c0,0.06,0,0.12-0.01,0.17c0,0.03-0.01,0.07,0,0.1c0,0.01,0.01,0.02,0.01,0.03c0.01,0.02,0.01,0.04,0.02,0.07
		c0.06,0.05,0.09,0.12,0.12,0.19c0.01,0.05,0.03,0.1,0.02,0.15c0,0,0,0,0,0c0.01,0.38-0.24,0.51-0.4,0.55
		c-0.02,0.01-0.04,0.01-0.06,0.01c-0.02,0-0.04,0-0.06,0c0.01,0.1,0.04,0.24,0.09,0.39c0,0.01,0.01,0.03,0.01,0.04
		c0.13-0.06,0.29-0.07,0.48-0.05h0c0.09,0.01,0.23,0.07,0.37,0.15c0.32,0.18,0.69,0.49,0.75,0.74c0.03,0.11,0.04,0.22,0.02,0.31
		c0.16-0.01,0.29,0.08,0.36,0.12c0.04,0.02,0.08,0.05,0.11,0.06c0.57,0.2,0.8,0.56,0.99,0.9c0.08,0.15,0.13,0.28,0.17,0.36
		c0.03,0,0.07,0,0.12,0c0.01,0,0.02,0,0.03,0c0.11,0,0.22-0.01,0.3-0.03c0.09-0.02,0.16-0.04,0.23-0.08
		c0.04-0.02,0.06-0.04,0.09-0.07c0.03-0.03,0.05-0.07,0.09-0.13c0.07-0.11,0.17-0.27,0.35-0.4c0.22-0.16,0.45-0.21,0.66-0.25
		c0.02,0,0.03-0.01,0.05-0.01c0.15-0.03,0.29-0.05,0.39-0.11c0.09-0.07,0.34-0.47,0.49-0.7c0.02-0.03,0.04-0.06,0.06-0.09
		c0.35-0.55,0.54-0.81,0.85-0.76c0.02,0,0.03,0,0.05,0.01c0.22,0.05,0.32,0.18,0.37,0.28c0.02,0.03,0.03,0.06,0.03,0.08
		c0.1,0.34-0.19,0.66-0.38,0.86c-0.03,0.03-0.05,0.05-0.06,0.07c-0.07,0.08-0.13,0.15-0.19,0.2c-0.07,0.07-0.09,0.09-0.12,0.15
		c0,0.02,0,0.08,0,0.12c0,0.03,0,0.07,0,0.1c0,0.11-0.01,0.25-0.07,0.38c-0.05,0.11-0.15,0.19-0.27,0.21c-0.01,0-0.03,0-0.04,0.01
		c-0.02,0.06-0.04,0.11-0.06,0.17c-0.08,0.17-0.21,0.34-0.48,0.52c0,0-0.01,0.01-0.01,0.01c0.02-0.01,0.06-0.01,0.09-0.01
		c0.13-0.01,0.33,0.02,0.66,0.14c0.01,0,0.01,0,0.02,0c0.02,0.01,0.04,0.01,0.07,0.02c0,0,0.01,0,0.01,0
		c0.03,0.01,0.05,0.01,0.08,0.02c0.09,0.02,0.16,0.04,0.21,0.05c0.1,0.04,0.18,0.11,0.22,0.2c0.01,0,0.01,0,0.02-0.01
		c0,0,0.01,0,0.01,0c0,0,0,0,0,0c0,0,0,0,0,0c0.01,0,0.02-0.01,0.03-0.01c0.08-0.02,0.21-0.05,0.34-0.02c0.02,0,0.04,0,0.07,0.01
		c0.02,0.01,0.03,0.02,0.05,0.03c0.04,0.02,0.08,0.03,0.11,0.06c0,0,0,0,0,0c0,0,0,0,0,0c0.23,0.21,0.21,0.5,0.17,0.71
		c-0.01,0.04-0.02,0.08-0.02,0.11c-0.01,0.05-0.02,0.09-0.02,0.13c-0.01,0.14-0.04,0.27-0.07,0.4c0.04,0.03,0.07,0.07,0.1,0.12
		c0.01,0.01,0.01,0.01,0.02,0.02c0,0,0,0,0,0h0c0.07,0.08,0.11,0.18,0.11,0.29c0,0,0,0.01,0,0.01c0.07,0.05,0.11,0.12,0.13,0.21
		c0.65-0.02,0.95,0.29,1.23,0.57c0.09,0.09,0.18,0.19,0.3,0.29c0.06,0.05,0.09,0.12,0.11,0.18c0.01,0.03,0.02,0.06,0.02,0.09
		c0.08,0.05,0.17,0.1,0.25,0.15c0.04,0.02,0.08,0.05,0.12,0.07c0.21,0.13,0.43,0.27,0.66,0.39c0.31,0.16,0.65,0.34,0.9,0.67
		c0.02-0.02,0.04-0.05,0.06-0.07c0,0,0,0,0,0c0,0,0,0,0,0c0.01-0.01,0.02-0.02,0.03-0.03c0.17-0.14,0.36-0.2,0.55-0.17
		c0.03,0.01,0.06,0.01,0.1,0.02c0.35,0.11,0.54,0.5,0.6,0.83c0.02,0.12,0.03,0.23,0.02,0.34s-0.03,0.21-0.06,0.3
		c0.09,0,0.18,0,0.23,0c0.06,0,0.12,0,0.17,0c0.03-0.03,0.06-0.05,0.1-0.06c0.21-0.09,0.36,0.01,0.55,0.14
		c0.04,0.03,0.07,0.06,0.1,0.09c0,0,0,0,0.01,0c0.01,0.01,0.02,0.03,0.03,0.04c0.08,0.1,0.14,0.23,0.19,0.35
		c0.02,0.06,0.06,0.14,0.08,0.17c0,0,0.01,0.01,0.01,0.01c0.01-0.01,0.01-0.02,0.02-0.03c0,0,0,0,0,0c0,0,0,0,0,0
		c0.02-0.02,0.05-0.04,0.08-0.06c0.02-0.01,0.03-0.03,0.05-0.04c0.02-0.01,0.05-0.01,0.08-0.01c0.03,0,0.05-0.02,0.08-0.01
		c0,0,0,0,0,0c0,0,0,0,0,0c0.66,0.02,1.4,0.97,1.58,1.29c0.11,0.19,0.25,0.43,0.29,0.66c0,0.01,0,0.03,0,0.04
		c0,0.03,0.01,0.07,0.01,0.1c0,0.09-0.03,0.17-0.08,0.25c0,0,0,0.01-0.01,0.01c0,0,0,0,0,0c0,0,0,0,0,0
		c-0.01,0.02-0.03,0.05-0.05,0.07c0.1,0.15,0.17,0.34,0.16,0.6c0,0.08-0.02,0.17-0.04,0.27c-0.02,0.1-0.05,0.21-0.08,0.32
		c-0.02,0.07-0.04,0.14-0.05,0.2c-0.01,0.05-0.02,0.11-0.03,0.15c-0.01,0.04-0.01,0.08-0.01,0.12c0,0.05-0.01,0.1-0.01,0.15
		c-0.01,0.06-0.02,0.11-0.05,0.17c0.06,0.09,0.11,0.2,0.16,0.31c0.11,0.28,0.15,0.59,0.04,0.83c-0.09,0.19-0.25,0.31-0.45,0.34
		c0.02,0.16,0.01,0.31,0.01,0.44c-0.01,0.15-0.02,0.27,0.02,0.37c0.03,0.07,0.09,0.15,0.16,0.23c0.01-0.01,0.02-0.02,0.04-0.04
		c0.03-0.02,0.06-0.04,0.09-0.05c0.01-0.01,0.03-0.02,0.04-0.02c0.02-0.01,0.05-0.01,0.08-0.01c0.02,0,0.05-0.01,0.07-0.01h0
		c0,0,0,0,0,0c0.18,0.02,0.39,0.13,0.55,0.22c0.12,0.07,0.21,0.14,0.22,0.14c0.09,0.06,0.18,0.12,0.28,0.18
		c0.05,0.03,0.09,0.06,0.14,0.09c0.02,0.01,0.04,0.03,0.06,0.04c0.06,0.04,0.12,0.08,0.17,0.13c0.05,0.04,0.12,0.09,0.19,0.14
		c0,0,0,0,0,0c0.23,0.16,0.52,0.37,0.67,0.61c0.02,0.03,0.03,0.07,0.05,0.1c0.03,0.08,0.07,0.18,0.11,0.32
		c0.02,0.06,0.04,0.12,0.05,0.16c0.04,0.03,0.1,0.05,0.22,0.08c0,0,0,0,0,0c0.04-0.02,0.07-0.02,0.11-0.04
		c0.1-0.03,0.2-0.04,0.28-0.01c0.2,0.04,0.35,0.17,0.43,0.37c0.01,0.01,0.03,0.02,0.04,0.04c0-0.05,0-0.1,0.02-0.14
		c0.01-0.03,0.03-0.06,0.05-0.09c0.01-0.01,0.01-0.03,0.02-0.04c0.02-0.02,0.04-0.04,0.06-0.06c0.02-0.02,0.03-0.04,0.05-0.05
		c0.01-0.01,0.03-0.02,0.04-0.02c0.04-0.03,0.08-0.05,0.12-0.08c0.04-0.02,0.09-0.04,0.13-0.06c0.32-0.13,0.75-0.19,1.05,0.06
		c0.21,0.17,0.31,0.5,0.43,1.15c0.01,0.07,0.02,0.12,0.03,0.16c0.01,0.02,0.02,0.06,0.04,0.1c0.02,0.05,0.04,0.09,0.05,0.14
		c0,0,0,0,0,0c0.05,0.14,0.09,0.29,0.05,0.44c-0.04,0.18-0.11,0.3-0.19,0.38c0.01,0.05,0.01,0.09,0,0.14
		c-0.02,0.1-0.07,0.18-0.14,0.24c0,0.1-0.03,0.2-0.1,0.27c0.11,0.14,0.22,0.27,0.35,0.38c0.03,0.03,0.15,0.09,0.22,0.13
		c0.15,0.08,0.28,0.16,0.37,0.25c0.01,0.01,0.03,0.02,0.04,0.03c0,0,0,0,0,0c0,0,0,0,0,0c0.04,0.05,0.07,0.09,0.1,0.13
		c0.08,0.11,0.15,0.21,0.21,0.31c0.12,0.2,0.22,0.36,0.47,0.55c0.64,0.49,1.24,1.03,1.81,1.57c0.05,0.05,0.1,0.1,0.15,0.14
		c0.14,0.13,0.28,0.26,0.42,0.39c0.22,0.21,0.45,0.43,0.68,0.64c0.43,0.41,0.76,0.82,1.09,1.27c0.12,0.16,0.25,0.33,0.39,0.49
		c0.41,0.51,0.56,0.91,0.75,1.41c0.04,0.12,0.09,0.25,0.15,0.38c0.04,0.11,0.13,0.23,0.22,0.34c0.1,0.13,0.21,0.29,0.29,0.46
		c0.09,0.22,0.1,0.42,0.11,0.58c0.01,0.18,0.02,0.27,0.12,0.4c0.06,0.07,0.17,0.13,0.29,0.19c0.17,0.08,0.36,0.18,0.51,0.36
		c0.16,0.18,0.25,0.39,0.33,0.57c0.04,0.1,0.08,0.2,0.14,0.29c0.38,0.58,0.72,1.03,1.2,1.48c0.36,0.34,0.8,0.85,0.9,1.44
		c0.08,0.09,0.11,0.18,0.13,0.25c0.02,0.11,0,0.2-0.04,0.28c0.04,0.07,0.08,0.15,0.1,0.23c0.03,0.11,0.1,0.23,0.17,0.35
		c0.09,0.15,0.19,0.33,0.25,0.52c0.03,0.11,0.04,0.2,0.04,0.29c0.07,0,0.14,0.03,0.21,0.07c0.11,0.07,0.22,0.15,0.33,0.23
		c0.28,0.2,0.45,0.51,0.6,0.79c0.07,0.12,0.14,0.25,0.21,0.34c0.13,0.17,0.28,0.29,0.46,0.44c0.14,0.11,0.29,0.23,0.43,0.38
		c0.33,0.34,0.59,0.77,0.83,1.18c0.13,0.21,0.25,0.42,0.38,0.61c0.11,0.16,0.22,0.36,0.33,0.57c0.1,0.18,0.2,0.37,0.3,0.52
		c0.14-0.09,0.33-0.16,0.6-0.11c0.13,0.03,0.24,0.12,0.28,0.24c0.05,0.12,0.03,0.26-0.05,0.37
		C145.61,153.39,145.59,153.43,145.57,153.46z"
          />
          <path
            id="Michoacan"
            d="M186.8,220.76C186.8,220.76,186.8,220.76,186.8,220.76C186.8,220.75,186.8,220.75,186.8,220.76
		C186.8,220.75,186.8,220.75,186.8,220.76c0.37-0.57,0.95-0.6,1.46-0.62c0.11-0.01,0.22-0.01,0.33-0.02c0.03,0,0.07-0.01,0.1-0.01
		l0.07-0.01c0.1-0.01,0.19-0.03,0.28-0.05c0.07-0.18,0.27-0.28,0.45-0.23c0.06,0.02,0.12,0.03,0.18,0.04
		c0.68-0.36,0.66-1.02,0.58-2.06c-0.07-0.9-0.15-1.91,0.62-2.59c0.29-0.26,0.7-0.41,1.12-0.48c0.68-0.13,1.45-0.08,1.89,0.08
		c0.49,0.18,0.72,0.62,0.92,1c0.14,0.27,0.27,0.53,0.48,0.68c0.43,0.28,1.01,0.17,1.62,0.04c0.26-0.06,0.53-0.11,0.8-0.14
		c0.09-0.01,0.18-0.02,0.27-0.01h0c0.15,0,0.3,0.02,0.44,0.05c0.15,0.03,0.29,0.08,0.43,0.13c0.1,0.04,0.2,0.07,0.3,0.11
		c0.16,0.06,0.32,0.12,0.48,0.17c0,0,0.01,0,0.01,0c0.39,0.1,0.7,0.18,1.02,0.17c0.03-0.01,0.07-0.02,0.11-0.03
		c0.19-0.05,0.38-0.11,0.55-0.09c0.08,0,0.15,0.02,0.22,0.06c0.01,0,0.01,0.01,0.02,0.01c0.02,0.01,0.04,0.02,0.05,0.04c0,0,0,0,0,0
		h0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0.02,0.03,0.05,0.07,0.06,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0v0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0v0c0,0,0,0,0,0
		c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0.04,0,0.07,0,0.11c0,0,0,0,0,0c0,0.02,0,0.03,0.01,0.04
		c0,0,0,0.01,0.01,0.01c0.1,0,0.2,0.03,0.29,0.09c0.02,0.01,0.04,0.03,0.06,0.05c0.11,0.1,0.17,0.24,0.16,0.42
		c0.09,0,0.17-0.05,0.33-0.14c0.2-0.12,0.45-0.26,0.78-0.26c0.55,0.01,0.84,0.36,1.05,0.61c0.06,0.07,0.11,0.14,0.18,0.2
		c0.01,0.01,0.02,0.02,0.02,0.02c0.07,0.07,0.13,0.14,0.18,0.22c0,0,0,0,0,0c0.01,0.01,0.01,0.02,0.02,0.03
		c0.01,0.02,0.02,0.03,0.04,0.05c0.07-0.03,0.16-0.05,0.24-0.04c0.06,0.01,0.11,0.03,0.16,0.06c0.12-0.02,0.29-0.01,0.47,0.09
		c-0.04-0.04-0.09-0.08-0.13-0.12c-0.13-0.13-0.25-0.27-0.36-0.42c-0.12-0.12-0.23-0.23-0.3-0.34c-0.2-0.32-0.28-0.64-0.33-0.97
		c-0.23-0.82-0.22-1.65-0.17-2.31c0.01-0.08,0.03-0.14,0.06-0.2c-0.01-0.07-0.02-0.14-0.04-0.22c-0.05-0.25-0.11-0.55-0.07-0.85
		c0.01-0.13,0.04-0.27,0.1-0.42c0.09-0.24,0.25-0.53,0.56-0.63c0.23-0.08,0.44-0.07,0.61,0.03c0.07,0.04,0.12,0.09,0.17,0.14
		c0.25,0.19,0.3,0.46,0.31,0.66l0.04-0.02c0.02-0.01,0.03-0.02,0.05-0.03c0.03-0.04,0.06-0.08,0.1-0.11
		c0.09-0.11,0.18-0.23,0.31-0.32c0.01-0.05,0.03-0.1,0.05-0.15c0.04-0.4,0.38-0.61,0.59-0.74c0.02-0.01,0.04-0.03,0.06-0.04
		c0.03-0.02,0.05-0.05,0.08-0.07c-0.02-0.07-0.03-0.15-0.04-0.22c-0.01-0.07,0-0.14,0.03-0.2c0.04-0.08,0.08-0.15,0.12-0.21
		c0.13-0.26,0.4-0.46,0.63-0.64c0.09-0.07,0.17-0.13,0.23-0.19c0.78-0.74,1.42-1.42,1.97-2.09c0.08-0.1,0.16-0.18,0.22-0.25
		c0.19-0.21,0.25-0.28,0.27-0.52c0-0.01,0-0.01,0-0.02c-0.06-0.25-0.14-0.56,0.02-0.82c0.11-0.18,0.34-0.27,0.93-0.46
		c0.11-0.04,0.21-0.07,0.25-0.09l0.11-0.05c0.74-0.34,0.74-0.34,0.55-1.09c0,0,0,0,0,0c-0.01-0.02-0.01-0.04-0.02-0.06
		c-0.16-0.62-0.34-1.38-0.12-2.14c0.07-0.26,0.17-0.49,0.26-0.72c0.09-0.22,0.18-0.44,0.24-0.66c0-0.06,0.01-0.15,0.01-0.22
		c0-0.19,0.01-0.39,0.06-0.56c0.02-0.13,0.05-0.26,0.14-0.39c0.22-0.32,0.59-0.35,0.83-0.37c0.03,0,0.06,0,0.09-0.01
		c0.02-0.01,0.04-0.02,0.06-0.03c0.02-0.01,0.03-0.02,0.05-0.03c-0.02-0.02-0.04-0.03-0.05-0.05c-0.13-0.13-0.3-0.29-0.4-0.51
		c0,0,0-0.01,0-0.01c-0.11-0.07-0.18-0.18-0.19-0.31c0-0.01,0-0.01,0-0.02c-0.14-0.33-0.23-0.68-0.33-1.03
		c-0.1-0.38-0.21-0.77-0.36-1.1l-0.09-0.2c-0.06-0.14-0.12-0.26-0.17-0.37c-0.12-0.2-0.25-0.39-0.38-0.56
		c-0.1,0.13-0.17,0.34-0.24,0.6c-0.02,0.08-0.04,0.15-0.07,0.22c-0.07,0.22-0.16,0.43-0.27,0.62c-0.35,0.74-0.92,1.63-1.64,2.03
		c-0.27,0.19-0.58,0.3-0.92,0.23c-0.21-0.04-0.4-0.16-0.57-0.29c-0.21-0.12-0.4-0.26-0.57-0.39c-0.11-0.08-0.23-0.17-0.34-0.24
		c-0.52-0.34-0.52-0.34-1.08-0.1c-0.02,0.01-0.05,0.02-0.07,0.03c-0.57,0.25-1.47,0.6-2.05,0.23c-0.13-0.08-0.25-0.2-0.33-0.37
		c-0.14-0.25-0.06-0.53,0.01-0.75c0.02-0.09,0.07-0.23,0.06-0.28c0,0,0,0,0,0c0-0.01-0.01-0.02-0.01-0.03
		c-0.01-0.03-0.03-0.06-0.05-0.09c-0.13-0.17-0.4-0.25-0.76-0.35l-0.12-0.03c-0.01,0-0.01,0-0.02-0.01
		c-0.03-0.01-0.07-0.01-0.1-0.02c-0.1-0.02-0.2-0.04-0.29-0.07c-0.15-0.04-0.32-0.1-0.49-0.16c-0.1-0.04-0.21-0.08-0.32-0.11
		c-0.09-0.02-0.17-0.03-0.24-0.04c-0.11-0.01-0.18-0.02-0.22-0.02c-0.06,0.03-0.14,0.07-0.27,0.12c-0.02,0.01-0.05,0.02-0.07,0.02
		c-0.02,0.01-0.04,0.02-0.05,0.03c-0.1,0.06-0.2,0.11-0.3,0.16c-0.19,0.09-0.37,0.16-0.54,0.2c-0.34,0.12-0.73,0.19-1.19,0.02
		c-0.48-0.17-0.77-0.41-0.93-0.69c-0.31-0.43-0.27-0.83-0.18-1.16c0.01-0.1,0.02-0.19,0.03-0.29c0.03-0.26,0.06-0.51,0.04-0.74
		c-0.01-0.07-0.04-0.23-0.08-0.42c-0.06-0.17-0.12-0.32-0.17-0.44l-0.02-0.06c-0.03-0.09-0.06-0.15-0.07-0.21l-0.13-0.05
		c-0.21-0.08-0.51-0.06-0.78,0.05l-0.07,0.03c-0.04,0.02-0.07,0.03-0.1,0.04c-0.04,0.08-0.08,0.15-0.15,0.25
		c-0.04,0.06-0.08,0.13-0.13,0.21c-0.07,0.12-0.15,0.26-0.24,0.39c-0.13,0.25-0.31,0.48-0.59,0.62c-0.31,0.15-0.67,0.06-0.99-0.02
		c-0.14-0.03-0.28-0.07-0.37-0.07c-0.06,0-0.11-0.01-0.17-0.03c-0.05,0-0.09,0.01-0.13,0.01c-0.03,0-0.06,0.01-0.09,0.01
		c-0.16,0.04-0.32,0.03-0.49-0.03c-0.05-0.02-0.1-0.05-0.14-0.09c-0.02-0.02-0.04-0.03-0.06-0.05c-0.03,0.01-0.07,0.02-0.11,0.03
		c-0.11,0.04-0.26,0.09-0.42,0.08c-0.06,0-0.12-0.01-0.18-0.02c-0.25-0.01-0.51-0.11-0.74-0.43c-0.13-0.17-0.18-0.42-0.2-0.7
		c-0.01-0.08-0.02-0.15-0.03-0.21l-0.04-0.3c-0.01-0.1-0.03-0.2-0.04-0.31c-0.04,0-0.07-0.01-0.11-0.02
		c-0.14-0.03-0.28-0.09-0.39-0.19c-0.15-0.13-0.29-0.34-0.31-0.61c-0.06-0.01-0.11-0.03-0.16-0.04c-0.07-0.02-0.12-0.03-0.16-0.04
		c-0.02,0-0.04,0-0.06,0.01c-0.4,0.3-1.22,0.5-1.46,0.52c-0.23,0.01-0.42-0.03-0.6-0.09c-0.02,0-0.04-0.01-0.06-0.01
		c-0.33-0.08-0.7-0.14-1.06-0.16c-0.02,0-0.05,0-0.07,0.01c-0.13,0.01-0.25,0.02-0.35,0.03c-0.44,0.02-0.69,0.1-0.83,0.17
		c-0.01,0.01-0.02,0.01-0.03,0.02c-0.05,0.03-0.13,0.11-0.2,0.18c-0.12,0.13-0.27,0.27-0.44,0.35c-0.19,0.09-0.37,0.08-0.51,0.06
		c0,0,0,0,0,0c-0.05,0.02-0.08,0.04-0.13,0.08c-0.03,0.02-0.07,0.05-0.11,0.07c-0.03,0.03-0.06,0.05-0.1,0.08
		c-0.08,0.05-0.17,0.1-0.25,0.14c-0.15,0.1-0.31,0.19-0.6,0.25c-0.42,0.08-0.86,0.11-1.29,0.14c-0.11,0.01-0.21,0.02-0.32,0.02
		c-0.04,0-0.07,0.01-0.11,0.01c-0.18,0.02-0.37,0.05-0.55,0.07c-1.04,0.12-2.02,0.23-2.85,0.88c-0.06,0.04-0.11,0.1-0.17,0.16
		c-0.08,0.11-0.17,0.28-0.21,0.4c0,0.02,0.01,0.04,0.01,0.05c0-0.02,0.02-0.01,0.04-0.01c0.15,0.03,0.46,0.1,0.59,0.47
		c0.11,0.27,0.03,0.52-0.03,0.69c-0.01,0.02-0.02,0.05-0.02,0.08c0.06,0.01,0.24-0.02,0.65-0.45c0.03-0.03,0.05-0.06,0.08-0.09
		c0,0,0,0,0,0c0.1-0.1,0.25-0.14,0.38-0.1c0.14,0.04,0.24,0.15,0.27,0.29c0.03,0.14,0.03,0.15,0.31,0.2
		c0.12,0.02,0.25,0.05,0.38,0.1c0.1,0.04,0.22,0.08,0.34,0.11c0.51,0.16,1.21,0.38,1.34,1.11c0.02,0.09,0.02,0.18,0.02,0.25
		c0,0.68-0.54,0.86-0.85,0.97c-0.18,0.06-0.33,0.11-0.38,0.2c-0.02,0.04-0.03,0.1-0.03,0.17c0,0.31,0.18,0.88,0.29,1.21
		c0.11,0.36,0.21,0.66,0.23,0.91c0,0.05,0.01,0.11,0.01,0.16c0,0.15-0.02,0.31-0.03,0.46c-0.02,0.17-0.03,0.3-0.03,0.39
		c0,0.18,0.03,0.23,0.1,0.25c0.08,0.03,0.32-0.06,0.48-0.11c0.32-0.11,0.68-0.23,1.01-0.08c0.41,0.2,0.53,0.67,0.53,1.13
		c0,0.4-0.09,0.79-0.16,0.95c-0.22,0.52-0.58,0.99-0.93,1.45c-0.16,0.2-0.31,0.4-0.44,0.6c-0.06,0.09-0.15,0.15-0.26,0.16
		c0,0,0,0,0,0c0,0-0.01,0-0.01,0c0,0,0,0-0.01,0c0,0,0,0-0.01,0c0,0,0,0,0,0c-0.04,0-0.07,0-0.11,0c-0.05-0.01-0.1-0.03-0.14-0.05
		c0,0,0,0,0,0c0,0,0,0,0,0h0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0h0c0,0,0,0,0,0c-0.06-0.05-0.11-0.13-0.13-0.21c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0
		c-0.02-0.06-0.06-0.12-0.13-0.17c-0.08-0.05-0.17-0.06-0.24-0.07c-0.07-0.01-0.09,0-0.09,0c-0.04,0.07-0.1,0.15-0.19,0.22
		c-0.06,0.05-0.13,0.09-0.22,0.12c-0.07,0.02-0.15,0.04-0.22,0.04c-0.2,0.04-0.4,0.05-0.6,0.06c-0.28,0.01-0.53,0.02-0.76,0.11
		c-0.02,0.01-0.04,0.02-0.07,0.02c0,0,0,0-0.01,0l-0.15,0.07c-0.13,0.06-0.23,0.1-0.29,0.13c0.02,0.1,0.03,0.25,0.02,0.46
		c-0.05,0.74-0.57,0.98-1,1.09c-0.12,0.05-0.24,0.09-0.38,0.14l-0.12,0.04c-0.07,0.02-0.13,0.04-0.18,0.05
		c-0.07,0.09-0.16,0.27-0.21,0.37c-0.17,0.33-0.34,0.67-0.68,0.73c-0.52,0.1-0.88-0.37-1.18-0.85c-0.05-0.07-0.1-0.14-0.15-0.2
		c-0.12-0.16-0.25-0.34-0.33-0.41c-0.02-0.02-0.04-0.03-0.06-0.05c-0.02,0.04-0.05,0.09-0.07,0.13c-0.11,0.21-0.26,0.48-0.55,0.67
		c-0.09,0.09-0.19,0.16-0.3,0.2c-0.16,0.05-0.37,0.04-1.07-0.04c-0.1-0.01-0.22-0.03-0.32-0.04c-0.06,0.04-0.12,0.07-0.19,0.07
		c-0.02,0.07-0.05,0.14-0.1,0.21c-0.04,0.06-0.11,0.1-0.17,0.13c-0.01,0-0.03,0.01-0.04,0.01c-0.03,0.08-0.08,0.15-0.14,0.2
		c0,0-0.42,0.27-0.67,0.41c-0.05,0.03-0.1,0.06-0.15,0.07c-0.01,0-0.01,0-0.02,0.01c-0.06,0.02-0.12,0.02-0.18,0.01
		c-0.01,0.01-0.01,0.03-0.02,0.04c0,0,0,0.01-0.01,0.01c-0.01,0.04-0.01,0.08-0.02,0.11c-0.05,0.29-0.11,0.65-0.38,0.93
		c-0.19,0.19-0.4,0.21-0.54,0.23c-0.12,0.01-0.15,0.02-0.2,0.08c-0.01,0.01-0.02,0.03-0.03,0.04c-0.01,0.03-0.01,0.05-0.02,0.08
		c-0.05,0.18-0.12,0.43-0.35,0.62c0,0,0,0,0,0c0.05,0.07,0.1,0.14,0.15,0.22c0.01,0.02,0.02,0.04,0.03,0.06
		c0.05,0.08,0.09,0.17,0.12,0.26c0.12,0.16,0.17,0.33,0.15,0.49c-0.01,0.09-0.04,0.2-0.13,0.32c0.09,0.09,0.2,0.18,0.26,0.23
		c0.08,0.07,0.14,0.13,0.19,0.17c0.58,0.55,0.93,0.89,1.26,1.78c0.04,0.11,0.08,0.22,0.12,0.33c0.04,0.12,0.08,0.23,0.12,0.33
		c0.12,0.31,0.25,0.55,0.51,0.75c0.37,0.29,0.75,0.47,1.16,0.64c0.14,0.05,0.28,0.11,0.43,0.16l0.26,0.1
		c0.38,0.14,0.76,0.3,1.13,0.46c0.57,0.24,1.11,0.47,1.69,0.65c0.79,0.25,1.49,0.72,2.16,1.18c0.55,0.37,1.06,0.72,1.62,0.96
		c0.28,0.12,0.59,0.16,0.92,0.21c0.32,0.04,0.66,0.09,1,0.21c0.41,0.15,0.8,0.31,1.19,0.48l0.33,0.14c0.25,0.1,0.5,0.21,0.74,0.32
		c0.41,0.18,0.81,0.35,1.23,0.51c0.21,0.08,0.43,0.22,0.63,0.35c0.12,0.08,0.24,0.15,0.37,0.22c0.01-0.45,0.04-1.1,0.18-1.69
		c0-0.01,0.01-0.02,0.01-0.03c0.03-0.11,0.05-0.21,0.08-0.31c0.01-0.02,0.02-0.05,0.03-0.07c0.03-0.09,0.06-0.17,0.09-0.25
		c0.01-0.03,0.03-0.06,0.05-0.09C186.73,220.88,186.76,220.82,186.8,220.76z"
          />

          <path
            id="NuevoLeon"
            className={`${styles.clickable} ${estado=== "NuevoLeon" ? " fill-[#9a3324]" : "fill-[#154734]"}`}
            onClick={(e) => setEstado(e.target.id)}
            d="M227.42,94.15c-0.04-0.05-0.08-0.09-0.12-0.14c-0.01-0.02-0.03-0.03-0.04-0.05c-0.04-0.06-0.1-0.14-0.16-0.24
		c-0.02-0.03-0.03-0.05-0.05-0.08c-0.33,0.24-0.66,0.47-0.99,0.72c-0.06,0.04-0.11,0.09-0.17,0.14c-0.08,0.07-0.17,0.14-0.26,0.21
		c-0.14,0.12-0.31,0.25-0.5,0.3c-0.21,0.1-0.37,0.04-0.55-0.09c-0.04-0.03-0.08-0.06-0.15-0.11c-0.29-0.19-0.53-0.47-0.77-0.73
		c-0.12-0.13-0.23-0.26-0.35-0.37c-0.05-0.05-0.14-0.15-0.24-0.26c-0.14-0.15-0.48-0.54-0.64-0.65c-0.02,0.01-0.07,0.04-0.17,0.1
		c-0.15,0.1-0.37,0.25-0.66,0.46l-0.15,0.11c-0.45,0.32-0.64,0.77-0.88,1.34c-0.05,0.13-0.11,0.26-0.17,0.39
		c-0.06,0.14-0.14,0.36-0.22,0.6c-0.38,1.1-0.67,1.83-1.14,2.01c-0.36,0.14-0.75,0.07-1.13,0c-0.23-0.04-0.47-0.08-0.63-0.06
		c-0.45,0.07-1.03,0.49-1.55,0.86c-0.24,0.17-0.47,0.34-0.68,0.46c-0.01,0.01-0.03,0.02-0.05,0.03c-0.04,0.03-0.08,0.05-0.12,0.08
		c-0.06,0.04-0.12,0.07-0.17,0.1c-0.25,0.14-0.3,0.18-0.34,0.31c0,0.02-0.01,0.04-0.01,0.07c0.01,0.06,0.02,0.12,0.02,0.16
		c0.01,0.1,0.03,0.19,0.03,0.26c0.02,0.47,0.03,0.93,0.04,1.4c0.28,0.04,0.52,0.27,0.71,0.45c0,0,0,0,0,0
		c0.03-0.05,0.07-0.1,0.12-0.14c-0.02,0.02,0-0.03,0.02-0.07c0.1-0.19,0.21-0.39,0.43-0.43c0.17-0.04,0.34,0.03,0.55,0.16
		c0.39,0.15,0.98,0.47,1.12,0.65c0.29,0.37,0.26,1.03,0.19,1.71c-0.01,0.26-0.03,0.53-0.09,0.79c-0.02,0.13-0.05,0.26-0.11,0.39
		c-0.03,0.05-0.06,0.1-0.09,0.15c-0.07,0.12-0.16,0.26-0.25,0.38c-0.01,0.01-0.01,0.02-0.02,0.03c0,0.05-0.01,0.09-0.02,0.14
		c0,0.13-0.02,0.27-0.13,0.39c-0.1,0.11-0.23,0.14-0.32,0.16c-0.08,0.07-0.16,0.12-0.23,0.14c-0.1,0.08-0.18,0.18-0.26,0.36
		l-0.03,0.07c-0.03,0.06-0.06,0.13-0.09,0.21c-0.05,0.14-0.12,0.28-0.22,0.41c-0.23,0.35-0.56,0.66-1.04,0.58
		c-0.47-0.08-0.63-0.51-0.74-0.79c-0.04-0.1-0.09-0.23-0.12-0.27c0,0,0,0,0,0c-0.04,0.02-0.08,0.04-0.13,0.06
		c-0.12,0.05-0.24,0.11-0.37,0.18c-0.09,0.08-0.17,0.15-0.23,0.2c-0.15,0.13-0.28,0.25-0.36,0.3l-2.01,1.43
		c-0.52,0.37-1.05,0.75-1.57,1.13c-0.06,0.04-0.14,0.09-0.23,0.14c-0.09,0.05-0.27,0.16-0.33,0.22c0.02,0.04,0.05,0.09,0.07,0.15
		c0.03,0.09,0.09,0.18,0.14,0.28c0.03,0.04,0.06,0.08,0.08,0.11c0.09,0.11,0.16,0.21,0.19,0.26c0.3,0.47,0.59,0.95,0.88,1.42
		c0.01,0.02,0.02,0.03,0.03,0.05c0.03,0.04,0.05,0.09,0.08,0.13c0.03,0.04,0.06,0.11,0.1,0.19c0.01,0.02,0.02,0.04,0.03,0.06
		c0.08-0.01,0.18,0.01,0.29,0.07c0.05,0.01,0.1,0.03,0.15,0.06c0.48,0.27,1.07,0.98,1.18,1.59c0.04,0.21,0,0.4-0.03,0.56
		c0,0.01,0,0.02,0,0.02c-0.01,0.11-0.02,0.28-0.01,0.33c0-0.01,0.07,0.05,0.13,0.09c0.06,0.05,0.12,0.1,0.2,0.16
		c0.23,0.19,0.37,0.39,0.46,0.58c0.1,0.17,0.17,0.39,0.17,0.69c0,0.26-0.06,0.5-0.12,0.73c-0.06,0.4-0.11,0.72-0.02,1.05
		c0.08,0.23,0.22,0.44,0.38,0.68c0.09,0.13,0.18,0.27,0.26,0.41c0.05,0.08,0.09,0.18,0.15,0.28c0.03,0.06,0.07,0.14,0.11,0.21
		c0.1,0.12,0.18,0.2,0.24,0.25c0.02,0,0.03,0,0.05,0c0.12-0.01,0.31-0.02,0.5,0.08c0.11,0.03,0.22,0.09,0.31,0.2
		c0.18,0.22,0.14,0.47,0.1,0.63c0.17,0.02,0.4,0.05,0.56,0.24c0.21,0.25,0.18,0.56,0.16,0.76c0,0.04-0.01,0.08-0.01,0.12
		c0,0.05-0.01,0.09-0.03,0.14c0,0,0,0,0,0c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.06,0.12-0.17,0.21-0.31,0.22c-0.01,0-0.02,0.01-0.04,0c0,0,0,0,0.01,0.01
		c0.01,0.01,0.01,0.02,0.02,0.02c0.13,0.14,0.2,0.28,0.25,0.4c0.01,0.01,0.01,0.03,0.02,0.04c0.07,0.15,0.11,0.24,0.24,0.33
		c0,0,0.01,0.01,0.02,0.01c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0h0
		c0.04,0.02,0.1,0.05,0.16,0.07c0.05,0.02,0.1,0.04,0.15,0.06c0.07,0.03,0.14,0.07,0.21,0.12c0.01,0.01,0.03,0.02,0.04,0.03
		c0.15,0.13,0.24,0.29,0.31,0.41c0.04,0.07,0.09,0.16,0.12,0.18c0.12,0.08,0.48,0.09,0.74,0.09c0.3,0,0.62,0.01,0.88,0.09
		c0.02,0.01,0.03,0.01,0.05,0.02c0.04,0.01,0.07,0.03,0.11,0.04c0.89,0.33,1.22,0.6,1.35,0.81c0.04,0.06,0.06,0.11,0.07,0.15
		c0,0,0,0,0,0.01c0.04,0.12,0.14,0.44-0.09,0.65c-0.07,0.07-0.15,0.1-0.23,0.12c0.14,0.14,0.25,0.29,0.27,0.49c0,0,0,0,0,0
		c0,0,0,0,0,0v0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0.08-0.02,0.16-0.07,0.22c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0h0c-0.04,0.04-0.09,0.08-0.15,0.1
		c-0.11,0.04-0.24,0.02-0.33-0.04c0,0,0,0,0,0c-0.04-0.03-0.08-0.06-0.11-0.11c-0.06-0.09-0.28-0.17-0.61-0.16
		c-0.44,0.02-0.85,0.18-1,0.34c-0.01,0.01-0.03,0.03-0.03,0.04c0,0,0,0.01,0,0.01c-0.03,0.05-0.07,0.1-0.12,0.13
		c-0.06,0.04-0.13,0.06-0.2,0.06c0,0-0.01,0-0.01,0c0,0,0,0-0.01,0c0,0-0.01,0-0.01,0c0,0,0,0,0,0c0,0-0.01,0-0.02,0
		c-0.12-0.01-0.22-0.08-0.29-0.18c-0.27-0.45-0.85-0.78-1.55-0.93c-0.13-0.01-0.26-0.01-0.4-0.01l-0.06,0
		c-0.55,0.01-1.2,0.02-1.71,0.22c-0.26,0.12-0.47,0.28-0.63,0.47c-0.34,0.4-0.69,0.86-0.54,1.24c0.06,0.15,0.34,0.35,0.57,0.51
		c0.33,0.24,0.64,0.46,0.73,0.79c0.07,0.25-0.01,0.42-0.08,0.52c-0.19,0.25-0.52,0.25-0.78,0.25c-0.03,0-0.06,0-0.1,0
		c-0.19,0.07-0.2,0.11-0.26,0.53l-0.01,0.08c-0.05,0.34-0.07,0.73-0.08,1.13c-0.03,0.79-0.06,1.67-0.42,2.4
		c-0.02,0.06-0.03,0.11-0.03,0.13c0.01,0.05,0.16,0.24,0.32,0.43l0.17,0.21c0.88,1.1,1.59,2.33,1.95,3.37
		c0.37,1.06,0.17,2.13-0.06,2.84c-0.06,0.17-0.14,0.36-0.22,0.54c-0.13,0.29-0.25,0.58-0.28,0.83c0,0,0,0,0,0c0,0,0,0,0,0.01
		c0,0,0,0.01,0,0.01c-0.03,0.47,0.39,1.08,0.64,1.45c0.04,0.06,0.09,0.14,0.13,0.22c0.06,0.08,0.12,0.15,0.16,0.2
		c0.09,0.11,0.22,0.21,0.35,0.32c0.04,0.03,0.08,0.07,0.12,0.1c0.09,0.04,0.18,0.09,0.26,0.18c0.44,0.44,0.46,0.87,0.34,1.25
		c-0.07,0.35-0.27,0.61-0.46,0.84c-0.04,0.05-0.08,0.1-0.12,0.15c-0.1,0.15-0.18,0.29-0.2,0.41c-0.06,0.34,0.01,0.79,0.09,1.23
		c0.05,0.28,0.09,0.56,0.11,0.83c0.02,0.21,0.03,0.42,0.01,0.62c-0.02,0.21-0.07,0.42-0.13,0.63c-0.02,0.07-0.03,0.13-0.05,0.19
		c-0.08,0.34-0.3,1.17-0.14,1.56c0.07,0.08,0.16,0.14,0.26,0.18c0.03,0.01,0.07,0.02,0.1,0.03c0.05,0.01,0.11,0,0.17-0.01
		c0.1-0.01,0.13-0.05,0.23-0.21c0.08-0.13,0.18-0.3,0.39-0.39c0.25-0.12,0.6-0.07,1.05,0.02c0.26,0.02,0.51,0.07,0.76,0.12
		c0,0,0.01,0,0.01,0c0.32,0.04,0.64,0.07,0.95,0.12l0.08,0c0.02,0,0.04,0,0.05,0c0.01-0.03,0.02-0.05,0.03-0.08
		c0.01-0.05,0.01-0.1,0.02-0.13l0.02-0.14c0-0.03,0.01-0.06,0.01-0.08c-0.01-0.03-0.02-0.06-0.04-0.1c-0.04-0.09-0.09-0.2-0.11-0.33
		c0-0.01-0.01-0.01-0.01-0.02c-0.08-0.11-0.1-0.25-0.05-0.37c0.16-0.4,0.37-0.66,0.63-0.78c0.11-0.09,0.24-0.15,0.37-0.18
		c0.4-0.07,0.73,0.17,1.03,0.39c0.01,0.01,0.03,0.02,0.04,0.03c-0.04-0.16-0.08-0.32-0.13-0.47c-0.02-0.05-0.05-0.12-0.07-0.18
		c-0.12-0.29-0.25-0.61-0.23-0.89c0.04-0.64,0.71-1.66,1.32-2.46c0,0,0,0,0,0l0.08-0.11c0.19-0.27,0.36-0.52,0.77-0.63
		c0.19-0.05,0.4-0.06,0.61-0.05c0.16,0,0.33,0.02,0.5,0.03c0.21,0.02,0.41,0.04,0.57,0.03c0.1-0.01,0.28,0.01,0.47,0.02
		c0.09,0.01,0.21,0.02,0.33,0.02c0.07,0,0.13-0.01,0.16-0.02c0.17-0.06,0.45-0.45,0.62-0.69c0.08-0.1,0.15-0.2,0.21-0.28
		c0.02-0.03,0.05-0.08,0.09-0.13c0.18-0.27,0.32-0.47,0.52-0.55c0.11-0.04,0.21-0.03,0.29-0.02c0.03,0,0.05,0.01,0.08,0.02
		c-0.04-0.52-0.5-0.93-0.94-1.32l-0.18-0.16c-0.14-0.12-0.24-0.24-0.33-0.35c-0.23-0.29-0.31-0.59-0.3-1.15
		c0.01-0.32,0.04-0.64,0.08-0.94c0.05-0.35,0.13-0.68,0.24-0.99c0.07-0.22,0.19-0.43,0.3-0.64c0.14-0.26,0.29-0.53,0.27-0.71
		c-0.02-0.24-0.18-0.57-0.33-0.88c-0.04-0.09-0.09-0.18-0.13-0.28c-0.16,0.06-0.31,0.08-0.5,0.02c-0.06-0.02-0.13-0.05-0.2-0.09
		c0,0,0,0,0,0c-0.11-0.06-0.31-0.27-0.47-0.5c-0.13-0.19-0.24-0.39-0.23-0.53c0.01-0.09,0.07-0.2,0.17-0.32
		c0.41-0.55,1.58-1.35,1.79-1.44c0.07-0.03,0.27-0.11,0.46,0.01c0.02,0.01,0.03,0.02,0.05,0.03c0.07-0.03,0.13-0.07,0.19-0.1
		c0.06-0.04,0.12-0.08,0.18-0.13c0.01-0.01,0.03-0.02,0.04-0.03c0.01-0.01,0.01-0.01,0.02-0.02c0.14-0.1,0.26-0.2,0.38-0.3
		c0.33-0.26,0.67-0.54,1.12-0.73c0.8-0.35,1.43-0.52,1.92-0.52c0,0,0.01,0,0.01,0h0c0.32,0,0.57,0.1,0.78,0.21
		c-0.04-0.14-0.07-0.3-0.08-0.46c0-0.08,0-0.15,0.01-0.23c0.01-0.12,0.04-0.24,0.09-0.36c0.06-0.18,0.15-0.34,0.23-0.51
		c0.06-0.12,0.12-0.24,0.18-0.36c0.02-0.06,0.05-0.12,0.08-0.18c0.02-0.05,0.05-0.1,0.07-0.15c0.01-0.04,0.02-0.08,0.03-0.13
		c0-0.01-0.01-0.1-0.01-0.18c-0.03-0.42-0.04-0.61,0.12-0.77c0.13-0.13,0.28-0.13,0.6-0.12c0.01,0,0.03,0,0.04,0
		c0.04-0.04,0.09-0.09,0.13-0.13c0.17-0.17,0.31-0.31,0.45-0.43c0.15-0.2,0.39-0.4,0.75-0.28c0.06,0.02,0.1,0.04,0.14,0.07
		c0.14,0.1,0.21,0.26,0.28,0.41c0.01,0.02,0.02,0.04,0.03,0.07c0.05,0.09,0.1,0.18,0.14,0.22c0.01,0,0.01,0,0.02,0.01
		c0.1,0.01,0.45,0,0.56-0.02c0.04-0.01,0.08-0.02,0.12-0.03c0.19-0.08,0.38-0.21,0.61-0.37c0.18-0.12,0.35-0.24,0.52-0.36
		c0.02-0.01,0.04-0.03,0.06-0.04c0.68-0.47,1.35-0.95,2.02-1.42l2.1-1.49c0.05-0.03,0.12-0.08,0.2-0.12
		c0.03-0.02,0.07-0.04,0.12-0.07c0.01-0.01,0.02-0.02,0.03-0.03c0.05-0.06,0.08-0.1,0.1-0.12c0,0,0,0,0,0c0,0,0.01-0.02,0.02-0.05
		c0-0.03,0-0.22-0.01-0.28c-0.01-0.02-0.01-0.05-0.02-0.07c-0.11-0.05-0.25-0.09-0.34-0.12c-0.16-0.05-0.32-0.1-0.47-0.18
		c-0.22-0.06-0.41-0.15-0.53-0.31c-0.12-0.17-0.13-0.4-0.06-0.82c0-0.39,0.07-0.87,0.17-1.42c0.04-0.21,0.07-0.4,0.09-0.55
		c0.04-0.39,0.08-0.78,0.13-1.17c0.02-0.17,0.04-0.35,0.06-0.52c0-0.03,0.01-0.06,0.01-0.09c0.05-0.39,0.09-0.78,0.14-1.18
		l0.02-0.18c0.03-0.19,0.06-0.41,0.05-0.61c-0.01-0.06-0.02-0.11-0.03-0.16c-0.11-0.4-0.25-0.42-0.87-0.45l-0.09,0
		c-0.12-0.01-0.25-0.01-0.37-0.01c-0.05,0-0.11,0-0.16,0c-0.15,0-0.31,0-0.46-0.02c-0.08-0.01-0.16-0.02-0.24-0.03
		c-0.12-0.02-0.23-0.07-0.32-0.11c-0.04-0.02-0.1-0.05-0.13-0.06c-0.01,0.02-0.06,0.11-0.09,0.17c-0.09,0.16-0.21,0.38-0.45,0.49
		c-0.08,0.04-0.16,0.06-0.24,0.08c-0.07,0.02-0.14,0.03-0.22,0.03c-0.07,0-0.13-0.01-0.19-0.02c-0.03-0.01-0.06-0.01-0.09-0.02
		c-0.49-0.14-0.66-0.57-0.78-0.89c-0.02-0.04-0.04-0.08-0.07-0.12c-0.14-0.24-0.27-0.39-0.4-0.46c-0.1-0.02-0.22,0.02-0.39,0.07
		c-0.23,0.07-0.52,0.16-0.84,0.08c-0.04-0.01-0.08-0.03-0.12-0.05h0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0
		c-0.06-0.04-0.1-0.09-0.13-0.15c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c-0.02-0.05-0.03-0.11-0.03-0.17c0-0.01,0-0.01,0-0.02c0-0.15-0.07-0.36-0.14-0.56
		c-0.05-0.16-0.1-0.31-0.13-0.45c-0.04-0.2-0.05-0.42-0.06-0.63c-0.01-0.3-0.03-0.62-0.13-0.8l-0.02-0.04
		c-0.1-0.01-0.22-0.05-0.35-0.11c-0.11,0-0.27,0-0.42-0.09c-0.2-0.12-0.31-0.33-0.4-0.49c-0.02-0.05-0.05-0.09-0.07-0.13
		c-0.17-0.24-0.17-0.24-0.31-0.28c-0.07-0.02-0.15-0.04-0.25-0.08c-0.05-0.02-0.06-0.02-0.06-0.02c-0.36,0.14-0.58-0.12-0.75-0.33
		c-0.21-0.25-0.31-0.58-0.4-0.88c-0.04-0.13-0.08-0.26-0.13-0.36c-0.02-0.05-0.03-0.1-0.03-0.15c0-0.07,0.02-0.15,0.06-0.21
		c0.07-0.11,0.2-0.19,0.32-0.17c0.03,0,0.07,0,0.1,0c0.17-0.02,0.25-0.1,0.29-0.18c0.05-0.07,0.08-0.17,0.1-0.27
		c0.06-0.34-0.01-0.81-0.16-1.13c0-0.01-0.01-0.01-0.01-0.02c-0.01-0.01-0.01-0.03-0.02-0.04c-0.19-0.36-0.47-0.56-0.94-0.66
		c-0.01,0-0.01,0-0.02,0c-0.07-0.01-0.3,0.01-0.49,0.03c-0.01,0-0.02,0-0.03,0c0,0,0,0-0.01,0c0,0-0.01,0-0.01,0c0,0,0,0-0.01,0
		c0,0-0.01,0-0.01,0c0,0-0.01,0-0.01,0c-0.62,0.06-0.89,0.08-1.07-0.09c-0.04-0.04-0.07-0.08-0.09-0.12c0,0,0,0,0-0.01v0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0v0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0v0c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		s0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0-0.05,0.01-0.1,0.03-0.15
		c0.05-0.1,0.1-0.19,0.15-0.28c0.04-0.06,0.08-0.13,0.12-0.19c0.11-0.18,0.22-0.34,0.27-0.5c0.04-0.14,0.05-0.28,0.06-0.42
		c0-0.04,0.01-0.08,0.01-0.12c0.02-0.19,0.04-0.39,0.14-0.61c-0.19-0.15-0.43-0.26-0.69-0.35c-0.07-0.02-0.13-0.05-0.19-0.07
		c-0.17-0.06-0.3-0.13-0.42-0.2c-0.22-0.14-0.35-0.31-0.41-0.53c-0.09-0.3-0.03-0.65,0.06-0.98c0-0.01,0.01-0.03,0.01-0.04
		c0.07-0.31,0.17-0.61,0.26-0.9c0.2-0.62,0.4-1.21,0.32-1.82c0,0,0,0,0,0c0,0,0,0,0,0c-0.02-0.2-0.08-0.4-0.15-0.61
		c-0.08-0.26-0.16-0.52-0.18-0.82c0-0.01,0-0.02,0-0.03c0-0.04-0.01-0.09-0.01-0.13c-0.01-0.21,0.01-0.42,0.04-0.62
		c0.02-0.19,0.04-0.37,0.03-0.54c0-0.01,0-0.02,0-0.03c0,0,0-0.01,0-0.01c0-0.03-0.01-0.06-0.01-0.09c-0.05-0.4-0.19-0.49-0.7-0.5
		c-0.07,0-0.13,0-0.19,0c-0.03,0-0.05,0.01-0.08,0.01c-0.2,0.02-0.36,0-0.48-0.07c-0.09-0.03-0.17-0.08-0.24-0.16
		c-0.16-0.17-0.2-0.39-0.21-0.63c-0.04-0.22-0.03-0.41,0.03-0.57c0.03-0.13,0.08-0.21,0.18-0.31c0.17-0.2,0.41-0.35,0.62-0.47
		c0.19-0.13,0.38-0.25,0.57-0.37c0.05-0.03,0.1-0.06,0.14-0.09c0.02-0.01,0.04-0.03,0.06-0.04c0.24-0.17,0.49-0.36,0.76-0.5
		c0.05-0.03,0.09-0.05,0.13-0.07c0.01-0.01,0.02-0.02,0.02-0.02c0,0,0-0.01,0-0.02l0,0C227.56,94.31,227.49,94.23,227.42,94.15z"
          />

          <path
            id="SanLuisPotosi"
            d="M212.86,142.19c0.17-0.41,0.32-0.81,0.35-1.22c0.11-1.55-0.85-2.83-1.78-4.08c-0.18-0.24-0.37-0.49-0.54-0.74
		c-0.27,0.4-0.69,0.67-1.06,0.92c-0.02,0.02-0.05,0.04-0.07,0.05c-0.31,0.23-0.62,0.47-0.89,0.75c-0.35,0.4-0.64,0.85-0.91,1.26
		l-0.13,0.2c-0.41,0.64-0.84,1.31-1.39,1.89c-0.35,0.37-0.74,0.68-1.12,0.98c-0.37,0.3-0.73,0.61-1.09,0.92
		c-0.35,0.3-0.7,0.6-1.06,0.89c-0.79,0.66-1.5,1.18-2.21,1.63c-0.17,0.11-0.37,0.21-0.56,0.32c-0.09,0.05-0.18,0.09-0.26,0.14
		c-0.14,0.09-0.28,0.17-0.42,0.27c-0.2,0.13-0.25,0.24-0.33,0.42c-0.04,0.08-0.08,0.17-0.14,0.27c-0.08,0.16-0.18,0.31-0.34,0.45
		c-0.25,0.21-0.55,0.25-0.82,0.29c-0.18,0.03-0.35,0.05-0.47,0.12c-0.45,0.27-0.86,0.58-1.14,0.85c-0.12,0.12-0.26,0.28-0.4,0.44
		c-0.63,0.72-1.48,1.69-2.55,1.46c-0.11-0.01-0.22-0.03-0.32-0.08c-0.09-0.04-0.17-0.1-0.22-0.17c-0.01,0.05-0.02,0.11-0.04,0.16
		c-0.13,0.37-0.38,0.6-0.6,0.79c0,0,0,0-0.01,0.01c-0.06,0.07-0.13,0.14-0.2,0.22c-0.25,0.26-0.32,0.35-0.25,0.48
		c0.03,0.04,0.06,0.08,0.09,0.13c0.03,0.03,0.06,0.07,0.1,0.12c0.22,0.25,0.53,0.59,0.59,1.14c0.02,0.23-0.03,0.43-0.11,0.6
		c-0.01,0.02-0.02,0.05-0.03,0.08c-0.09,0.24-0.22,0.42-0.33,0.57c-0.15,0.21-0.2,0.28-0.17,0.42c0.01,0.06,0.03,0.13,0.06,0.2
		c0.05,0.1,0.1,0.2,0.14,0.27c0.12,0.23,0.24,0.44,0.29,0.62c0.18,0.66,0.44,1.15,0.77,1.63c0.1,0.13,0.22,0.26,0.34,0.41l0.23,0.28
		c0.4,0.48,0.78,0.94,1.12,1.45c0.21,0.28,0.4,0.59,0.55,0.97c0.02,0.04,0.03,0.09,0.05,0.14c0.06,0.15,0.12,0.3,0.17,0.46
		l0.05,0.15c0.01,0.03,0.02,0.06,0.03,0.09l0.04,0.06c0.02,0.03,0.03,0.05,0.04,0.07c0.02,0.01,0.05,0.03,0.09,0.05l0.08,0.05
		c0.01,0.01,0.03,0.02,0.04,0.02c0.21,0.12,0.55,0.21,0.84,0.28l0.16,0.04c0.17,0.05,0.26,0.06,0.35,0.01
		c0.01-0.01,0.02-0.03,0.03-0.04c0.04-0.04,0.09-0.1,0.14-0.16c0.13-0.14,0.26-0.28,0.39-0.41c0.03-0.03,0.06-0.06,0.09-0.09
		c0.03-0.02,0.05-0.05,0.08-0.07c0.02-0.02,0.05-0.05,0.07-0.07c0.47-0.44,0.99-0.83,1.72-0.91c0.22-0.03,0.46-0.03,0.71,0.01
		c0.17,0.02,0.35,0.07,0.54,0.14c0.5,0.17,0.97,0.46,1.29,0.87c0.09,0.1,0.17,0.21,0.24,0.33c0.28,0.47,0.34,0.98,0.16,1.47
		c-0.13,0.36-0.4,0.53-0.61,0.66c-0.12,0.11-0.21,0.21-0.26,0.36c-0.1,0.3-0.1,0.73-0.08,1.18c0.01,0.15,0.02,0.29,0.03,0.42
		c0.03,0.3,0.05,0.58,0.05,0.78c0,0.16,0.02,0.33,0.03,0.5c0.01,0.06,0.01,0.11,0.02,0.17c0.04,0.3,0.08,0.6,0.03,0.86
		c-0.06,0.3-0.27,0.58-0.52,0.83c-0.29,0.35-0.64,0.67-0.98,0.98c-0.23,0.21-0.45,0.41-0.65,0.62c-0.06,0.07-0.12,0.13-0.17,0.2
		c-0.03,0.04-0.07,0.09-0.11,0.14c0.1,0.03,0.2,0.06,0.29,0.08c0.2,0.03,0.4,0.03,0.61-0.01c0.05-0.01,0.1-0.04,0.16-0.06
		c0.17-0.07,0.35-0.14,0.57-0.13c0.03,0,0.06,0,0.09,0.01c0.17,0,0.35,0.05,0.53,0.19c0.27,0.17,0.48,0.42,0.67,0.65
		c0.2,0.23,0.39,0.45,0.61,0.58c0.11,0.05,0.24,0.08,0.38,0.11c0,0,0,0,0,0c0.19,0.03,0.38,0.05,0.58,0.07
		c0.03,0,0.07,0.01,0.1,0.01c0.57,0.04,1.2,0.1,1.7,0.42c0.33,0.21,0.58,0.55,0.82,0.88c0.02,0.03,0.05,0.07,0.07,0.1c0,0,0,0,0,0
		c0.11,0.14,0.23,0.29,0.35,0.42l0.02,0.02c0.45,0.48,0.92,0.97,1.44,1.37l0.06,0.04c0.05,0.04,0.11,0.09,0.18,0.14
		c0.19,0.14,0.39,0.26,0.6,0.37l0.07,0.04c0.15,0.08,0.37,0.19,0.54,0.22c0.02,0,0.03,0,0.04,0c0.12-0.01,0.12-0.01,0.21-0.17
		c0.07-0.13,0.16-0.31,0.36-0.46c0.17-0.13,0.35-0.18,0.49-0.21c0.1-0.02,0.16-0.04,0.2-0.07c0.02-0.03,0.04-0.07,0.06-0.13
		c0-0.01,0.01-0.02,0.01-0.03c0.04-0.15,0.09-0.35,0.27-0.51c0.04-0.04,0.09-0.07,0.13-0.1c0.06-0.05,0.14-0.09,0.23-0.12
		c0.54-0.21,1.25-0.05,1.84,0.21c0.24,0.07,0.48,0.17,0.76,0.4c0.63,0.5,1.32,0.97,2.1,1.42c0.21,0.11,0.43,0.22,0.65,0.34
		c0.21,0.11,0.41,0.21,0.62,0.32c0.17,0.09,0.35,0.18,0.54,0.26c0.24,0.11,0.47,0.22,0.69,0.34c0.15,0.08,0.28,0.16,0.39,0.24
		c0.19-0.54,0.49-1.1,0.93-1.34c0.06-0.04,0.13-0.07,0.21-0.09c0.08-0.02,0.16-0.04,0.25-0.04c0.68-0.05,0.99,0.49,1.16,0.97
		c0.04,0.09,0.07,0.17,0.1,0.25c0.08,0.2,0.16,0.41,0.24,0.52c0.03,0.04,0.06,0.08,0.08,0.11c0.06,0,0.12,0,0.19,0.01
		c0.29,0.02,0.62-0.07,0.95-0.15l0.15-0.04c0.02-0.01,0.04-0.01,0.06-0.02c0,0,0.01,0,0.01,0c0.13-0.03,0.22-0.06,0.28-0.09
		c0.08-0.04,0.11-0.1,0.17-0.25c0.05-0.11,0.1-0.25,0.19-0.42c0.31-0.59,0.73-0.58,0.98-0.57c0.02,0,0.04,0,0.06,0
		c0.11-0.06,0.3-0.26,0.43-0.39c0.01-0.01,0.02-0.02,0.03-0.03c0.17-0.31,0.49-0.78,1.17-0.57c0.38,0.12,0.55,0.63,0.8,2.07
		c0.02,0.06,0.04,0.13,0.06,0.19c0.29,0.95,0.58,1.93,0.53,2.81c0.02,0.01,0.05,0.03,0.07,0.04c0,0,0.01-0.01,0.01-0.01
		c0.04-0.02,0.07-0.04,0.11-0.06c0.2-0.16,0.69-0.42,1.37,0.02c0.35,0.23,0.44,0.59,0.51,0.85c0.02,0.09,0.04,0.17,0.07,0.24
		c0.22,0.35,0.59,0.45,1.28,0.34c0.02,0,0.04-0.01,0.06-0.01c0.18-0.04,0.37-0.08,0.57-0.1c0.21-0.03,0.41-0.01,0.59,0.01
		c0.33,0.03,0.39,0.02,0.42-0.03c0-0.07-0.01-0.16-0.01-0.22c-0.01-0.13-0.03-0.28-0.01-0.42c0.03-0.23,0.11-0.48,0.24-0.72
		c0.02-0.05,0.05-0.09,0.08-0.14c0.24-0.37,0.58-0.68,1-0.79c0-0.18,0.04-0.37,0.07-0.55c0.02-0.11,0.04-0.24,0.06-0.35
		c-0.01-0.07-0.02-0.14-0.03-0.18c-0.04-0.11-0.18-0.19-0.41-0.3c-0.26-0.13-0.58-0.29-0.77-0.62c-0.02-0.04-0.23-0.42-0.22-0.65
		c0.01-0.16,0.12-0.3,0.27-0.35c0.02-0.01,0.04-0.01,0.05-0.02c-0.01-0.09-0.01-0.19,0-0.29c0-0.01,0-0.03,0.01-0.04
		c-0.01-0.24,0.02-0.55,0.33-0.72c0.15-0.09,0.29-0.08,0.42-0.05c0.01-0.18,0.11-0.29,0.21-0.35c0.11-0.08,0.27-0.13,0.46-0.07
		c0.09,0.02,0.16,0.07,0.21,0.1c0.12-0.08,0.3-0.21,0.35-0.25l0.12-0.11c-0.03-0.07-0.05-0.14-0.08-0.23l-0.02-0.07
		c-0.01-0.02-0.01-0.04-0.02-0.06c-0.07,0-0.15-0.02-0.21-0.04c-0.12-0.02-0.2-0.07-0.25-0.12c-0.04-0.03-0.07-0.07-0.09-0.11
		c-0.04-0.05-0.08-0.11-0.11-0.18c-0.09-0.14-0.21-0.31-0.24-0.36c-0.05-0.06-0.07-0.13-0.08-0.21c-0.01-0.18,0-0.36,0.11-0.5
		c-0.01-0.01-0.01-0.03-0.02-0.05c-0.24-0.16-0.36-0.58-0.33-0.88c0-0.05,0-0.08,0-0.08c0.01-0.11,0.03-0.27,0.16-0.38
		c0.2-0.18,0.47-0.13,1.01,0l0.07,0.02c0.05-0.02,0.1-0.03,0.12-0.03c0.07-0.02,0.13-0.02,0.18-0.03c0.03-0.03,0.07-0.09,0.13-0.16
		c0.01-0.01,0.01-0.01,0.02-0.02c0.02-0.03,0.04-0.06,0.06-0.09c0.06-0.09,0.12-0.18,0.19-0.29c0.15-0.21,0.33-0.42,0.51-0.62
		c0.73-0.83,1.03-1.28,0.55-1.79c-0.13-0.14-0.27-0.27-0.43-0.4c-0.13-0.09-0.26-0.18-0.38-0.26c-0.22-0.15-0.43-0.28-0.6-0.43
		c-0.12-0.1-0.24-0.22-0.36-0.33c-0.05-0.04-0.1-0.08-0.15-0.12c-0.07-0.06-0.13-0.11-0.19-0.17c-0.19-0.17-0.38-0.34-0.57-0.43
		c-0.14-0.03-0.26-0.05-0.39-0.05c-0.19,0.01-0.38,0.06-0.57,0.12c-0.19,0.08-0.38,0.18-0.57,0.29c-0.48,0.26-0.97,0.52-1.51,0.48
		c-0.36-0.03-0.75-0.29-1.12-0.53c-0.16-0.1-0.3-0.2-0.41-0.25c-0.23-0.11-0.44-0.21-0.65-0.3c-0.46-0.19-0.93-0.35-1.42-0.49
		c-0.09-0.03-0.18-0.06-0.26-0.09c-0.5-0.16-0.88-0.4-1.18-1.12c-0.1-0.24-0.18-0.5-0.26-0.76c-0.14-0.47-0.28-0.92-0.55-1.27
		c-0.12,0.16-0.32,0.3-0.64,0.23c-0.33-0.07-0.55-0.32-0.72-0.58c0,0.29-0.08,0.53-0.29,0.67c-0.11,0.08-0.4,0.28-2.68-0.92
		l-0.18-0.09c-0.87-0.45-1.86-0.97-2.69-1.52c-1.06-0.72-1.17-1.13-1.17-1.32c0-0.03,0-0.06,0.01-0.08
		c0.04-0.34,0.37-0.57,1.01-0.97c0.27-0.17,0.72-0.45,0.79-0.58c0.07-0.13,0.11-0.24,0.11-0.34c0-0.18-0.12-0.36-0.36-0.69
		c-0.07-0.1-0.12-0.18-0.18-0.26c-0.12-0.18-0.3-0.45-0.42-0.53c-0.1-0.06-0.17-0.06-0.32-0.05c-0.13,0.01-0.3,0.02-0.51-0.03
		c-0.13-0.03-0.24-0.13-0.28-0.26c-0.01-0.02-0.01-0.05-0.02-0.07c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0v0c0,0,0,0,0,0
		c-0.01-0.1,0.03-0.2,0.1-0.28c0.06-0.07,0.14-0.29,0.22-0.54c0.05-0.16,0.09-0.33,0.12-0.47c-0.34,0-0.68-0.06-1-0.12
		c-0.1-0.01-0.19-0.02-0.29-0.03c-0.11-0.01-0.26-0.04-0.42-0.07c-0.02,0-0.04-0.01-0.07-0.01c-0.11-0.01-0.22-0.01-0.32,0
		c-0.02,0-0.04,0-0.07,0l-0.12-0.01c-0.01,0.02-0.02,0.03-0.03,0.05c-0.03,0.05-0.07,0.09-0.12,0.12c-0.01,0.01-0.02,0.03-0.04,0.05
		c-0.06,0.07-0.15,0.17-0.27,0.24c-0.25,0.15-0.55,0.2-0.84,0.16c-0.33-0.02-0.6-0.14-0.79-0.36c-0.05-0.06-0.1-0.13-0.14-0.2
		c-0.07-0.09-0.12-0.2-0.16-0.31c-0.21-0.59-0.01-1.22,0.16-1.77c0.03-0.1,0.06-0.19,0.09-0.28c0.07-0.3,0.11-0.58,0.1-0.92
		c0-0.03,0-0.05,0-0.08c-0.02-0.21-0.06-0.43-0.1-0.66c-0.1-0.58-0.21-1.17-0.06-1.72c0.06-0.23,0.18-0.42,0.3-0.59
		c0.05-0.07,0.1-0.14,0.14-0.21c0.16-0.22,0.26-0.39,0.31-0.53c0.03-0.24-0.05-0.39-0.24-0.56c0,0,0,0,0,0
		c-0.11-0.03-0.25-0.06-0.39-0.18c-0.2-0.16-0.35-0.43-0.48-0.66c-0.01-0.02-0.02-0.04-0.03-0.05c-0.5-0.66-0.95-1.4-0.9-2.13
		c0-0.01,0-0.02,0-0.03C212.51,143.07,212.69,142.62,212.86,142.19z"
          />
          <path
            id="CDMX"
            className={`${styles.clickable} ${estado=== "CDMX" ? " fill-[#9a3324]" : "fill-[#154734]"}`}
            onClick={(e) => setEstado(e.target.id)}
            d="M226.45,206.68c-0.05-0.03-0.1-0.08-0.16-0.15c-0.02-0.02-0.05-0.05-0.1-0.08c-0.09-0.07-0.22-0.16-0.32-0.34
		c0,0-0.01,0.01-0.01,0.01c-0.06,0.07-0.13,0.13-0.2,0.18c-0.14,0.11-0.28,0.18-0.41,0.23c-0.06,0.03-0.12,0.05-0.17,0.08
		c-0.03,0.04-0.06,0.11-0.09,0.17c-0.08,0.16-0.17,0.37-0.39,0.5c0,0,0,0,0,0c0,0,0,0,0,0c-0.3,0.19-0.59,0.18-0.83,0.18
		c-0.18,0-0.27,0-0.34,0.03c-0.02,0.01-0.03,0.02-0.05,0.03c-0.02,0.02-0.05,0.05-0.07,0.08c-0.03,0.04-0.05,0.08-0.06,0.12
		c-0.08,0.3,0.1,0.82,0.26,1.28c0.02,0.06,0.04,0.11,0.06,0.16c0.11,0.28,0.21,0.57,0.24,0.87c0,0,0.01,0,0.01,0
		c0.21,0.02,0.38,0.09,0.52,0.18c0.11,0.05,0.21,0.12,0.3,0.22c0.07,0.06,0.14,0.1,0.2,0.12c0.11,0.03,0.2,0.06,0.29,0.09
		c0,0,0,0,0.01,0c0.21,0.02,0.44,0.06,0.64,0.19c0.09,0.05,0.17,0.12,0.26,0.2c0.02,0.01,0.04,0.03,0.06,0.04
		c0.06,0.04,0.12,0.09,0.18,0.14c0.07,0.06,0.23,0.18,0.27,0.18c0,0,0.05-0.04,0.08-0.06c0.1-0.07,0.24-0.16,0.42-0.2
		c0.03-0.05,0.07-0.1,0.11-0.15c0.02-0.03,0.05-0.07,0.08-0.11c0.02-0.05,0.03-0.1,0.03-0.14c-0.01-0.04-0.03-0.08-0.07-0.14
		c-0.06-0.11-0.14-0.26-0.16-0.44c-0.04-0.26,0.02-0.48,0.08-0.68c0.01-0.03,0.01-0.05,0.02-0.08c0.02-0.15,0.04-0.27,0.04-0.39
		c-0.02-0.22-0.13-0.37-0.28-0.56c-0.15-0.2-0.32-0.42-0.42-0.76c-0.08-0.27-0.05-0.52-0.03-0.72
		C226.45,206.83,226.46,206.74,226.45,206.68z"
          />
          <path
            id="Morelos"
            d="M221.14,217.27c0.1,0.2,0.19,0.38,0.29,0.52l0.22,0.32c0.2,0.28,0.4,0.56,0.6,0.82
		c0.18,0.17,0.35,0.28,0.5,0.31c0.03,0.01,0.06,0.01,0.09,0c0.02-0.05,0.03-0.12,0.04-0.16c0.04-0.19,0.09-0.45,0.32-0.6
		c0.03-0.02,0.1-0.04,0.21-0.05c0.13-0.03,0.4-0.05,0.65-0.04c0.07,0,0.14,0,0.2,0.01c0.16,0.02,0.29,0.05,0.35,0.09
		c0.02,0.02,0.04,0.03,0.05,0.05c0,0,0,0,0,0c0.04,0.05,0.06,0.11,0.07,0.17l0,0.02c0.02,0.09,0.08,0.33,0.13,0.48
		c0.06,0.06,0.11,0.15,0.13,0.21c0.12,0.37,0.15,0.4,0.37,0.72c0.05,0.07,0.09,0.13,0.13,0.2c0.07,0.08,0.14,0.18,0.2,0.29
		c0.09-0.02,0.18-0.04,0.26-0.06l0.08-0.02c0.22-0.05,0.44-0.11,0.62-0.2c0.16-0.08,0.31-0.23,0.47-0.4
		c0.01-0.01,0.03-0.03,0.04-0.04c0.16-0.17,0.34-0.36,0.58-0.47c0.12-0.07,0.25-0.12,0.39-0.14c0.69-0.13,1.07,0.3,1.34,0.61
		c0.06,0.07,0.12,0.13,0.17,0.19c-0.02-0.14-0.05-0.31-0.08-0.45l-0.04-0.19c-0.04-0.19-0.12-0.44-0.2-0.69
		c-0.24-0.72-0.5-1.55-0.18-2.23c0,0,0,0,0,0c0-0.01,0.01-0.01,0.01-0.02c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0s0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0l0,0c0.05-0.07,0.13-0.12,0.22-0.14
		c0.08-0.02,0.15-0.01,0.22,0.02c-0.19-0.18-0.36-0.38-0.36-0.66c0-0.02,0-0.04,0-0.06c0-0.13,0.06-0.25,0.16-0.33
		c0.1-0.12,0.25-0.26,0.47-0.48c0.16-0.15,0.41-0.39,0.51-0.51c0.02-0.04,0.04-0.07,0.06-0.11c0.08-0.17,0.11-0.32,0.12-0.48
		c-0.16,0.06-0.34,0.09-0.55,0.08c-0.75-0.03-1.57-0.65-2.06-1.08c-0.08-0.06-0.15-0.13-0.22-0.2c-0.02-0.02-0.04-0.04-0.07-0.07
		c-0.01-0.01-0.03-0.03-0.04-0.04c-0.02-0.02-0.07-0.06-0.12-0.11c-0.14-0.13-0.25-0.24-0.34-0.33c-0.25,0.14-0.52,0.21-0.78,0.19
		c-0.33-0.03-0.58-0.27-0.81-0.48c-0.01-0.01-0.03-0.03-0.04-0.04c-0.2-0.13-0.37-0.18-0.57-0.24c-0.02,0-0.03,0-0.05,0
		c-0.24-0.02-0.49-0.05-0.69-0.2c-0.06-0.04-0.1-0.09-0.13-0.16c-0.01-0.02-0.02-0.04-0.03-0.06c-0.05-0.04-0.09-0.07-0.12-0.09
		c-0.06-0.02-0.15-0.03-0.27-0.05c-0.02,0-0.05-0.01-0.07-0.01c-0.08,0.1-0.18,0.17-0.26,0.22c-0.04,0.03-0.1,0.07-0.12,0.09
		c0,0.03,0.05,0.21,0.08,0.31c0.04,0.14,0.08,0.27,0.09,0.38c0.02,0.18,0.02,0.35,0,0.5c0,0.14-0.01,0.27-0.05,0.38c0,0,0,0,0,0
		c-0.18,0.46-0.5,0.69-0.82,0.84c-0.15,0.09-0.31,0.18-0.47,0.26c-0.33,0.17-0.39,0.19-0.5,0.53c-0.01,0.05-0.03,0.11-0.04,0.17
		c-0.04,0.16-0.08,0.34-0.16,0.52c-0.09,0.23-0.22,0.43-0.35,0.63c-0.04,0.06-0.08,0.12-0.11,0.18c-0.09,0.19-0.17,0.37-0.23,0.56
		c0,0.04,0,0.08,0,0.12C220.87,216.74,221.01,217.01,221.14,217.27z"
          />
          <path
            id="Tlaxcala"
            d="M239.25,205.34C239.25,205.34,239.25,205.34,239.25,205.34C239.25,205.34,239.25,205.34,239.25,205.34
		C239.25,205.34,239.25,205.34,239.25,205.34L239.25,205.34c0-0.01,0-0.01,0-0.01c0,0,0,0,0,0c0-0.03,0-0.05,0.01-0.08
		c0,0,0-0.01,0-0.01c0.01-0.05,0.03-0.11,0.04-0.17c0.03-0.11,0.09-0.33,0.09-0.43c-0.03,0.01-0.06,0.03-0.1,0.05
		c-0.21,0.1-0.36,0.17-0.49,0.19c0,0-0.38,0.05-0.7-0.02c-0.39,0.13-0.83-0.21-1.14-0.44c-0.05-0.04-0.11-0.08-0.17-0.13
		c-0.05-0.02-0.09-0.03-0.13-0.05c-0.05,0.06-0.15,0.23-0.2,0.32c-0.04,0.08-0.07,0.16-0.1,0.23c-0.17,0.41-0.35,0.84-0.77,0.94
		c-0.19,0.04-0.31-0.04-0.63-0.25c-0.02-0.01-0.04-0.03-0.07-0.05c-0.04-0.02-0.08-0.04-0.12-0.06c-0.1-0.05-0.19-0.1-0.25-0.12
		c-0.01,0-0.06,0-0.09,0c-0.1,0-0.24,0-0.38-0.07c-0.12-0.05-0.21-0.12-0.27-0.17c-0.05-0.02-0.1-0.05-0.13-0.08
		c-0.04,0.03-0.09,0.07-0.12,0.1c-0.11,0.09-0.21,0.18-0.32,0.23c-0.54,0.24-1.1,0.11-1.51,0.18c-0.02,0-0.03,0.01-0.05,0.01
		c0.13,0.19,0.25,0.38,0.35,0.59c0.04,0.08,0.07,0.14,0.09,0.18c0,0,0,0.01,0.01,0.01c0.01,0.02,0.02,0.04,0.04,0.05
		c0.03,0.03,0.06,0.06,0.08,0.09l0.05,0.06c0.02,0.03,0.05,0.07,0.07,0.1c0,0,0,0,0,0c0.01,0,0.03,0.01,0.04,0.01
		c0.07,0.02,0.14,0.03,0.23,0.05c0.09,0.02,0.18,0.04,0.27,0.06c0.25,0.05,0.51,0.11,0.72,0.22c0.24,0.1,0.42,0.22,0.52,0.39
		c0.14,0.24,0.12,0.48,0.1,0.65c-0.01,0.07-0.02,0.15,0,0.19c0.06,0.07,0.14,0.14,0.19,0.19c0,0,0.01,0.01,0.01,0.01
		c0.01,0.01,0.03,0.02,0.04,0.03c0.12,0.09,0.25,0.19,0.35,0.33c0.2,0.26,0.31,0.54,0.42,0.81c0.1,0.25,0.2,0.49,0.36,0.68
		c0.01,0.01,0.02,0.03,0.03,0.04c0.08,0.08,0.18,0.16,0.28,0.24c0.19,0.15,0.4,0.32,0.57,0.56c0.12,0.17,0.21,0.32,0.29,0.46
		c0.16,0.28,0.21,0.37,0.35,0.36c0.08-0.07,0.2-0.15,0.37-0.18c0.12-0.03,0.21-0.04,0.29-0.06c0.09-0.02,0.14-0.03,0.19-0.04
		c0.08-0.06,0.16-0.13,0.25-0.23c0,0,0,0,0.01-0.01c0.05-0.06,0.12-0.13,0.19-0.2c0.3-0.3,0.78-0.69,1.3-0.56
		c0.13,0.03,0.27,0.1,0.4,0.2c0.01,0.01,0.02,0.01,0.03,0.02c0.19,0.16,0.28,0.33,0.33,0.46c0.02-0.01,0.05-0.02,0.08-0.03
		c0.11-0.04,0.18-0.08,0.21-0.14c0.02-0.06,0.02-0.15,0.02-0.29c0-0.24,0-0.53,0.18-0.79c0.05-0.07,0.1-0.13,0.16-0.18
		c0.03-0.03,0.07-0.06,0.1-0.08c0.42-0.29,0.95-0.14,1.42-0.01c0.19,0.05,0.4,0.11,0.57,0.13c0.02,0,0.03,0,0.04,0
		c0.23-0.02,0.47-0.19,0.55-0.33c0-0.01,0.01-0.03,0.01-0.04c0-0.02,0.01-0.08-0.07-0.14c-0.03,0-0.05,0-0.08,0
		c-0.16,0.01-0.38,0.01-0.59-0.12c0,0,0,0,0,0c-0.2-0.13-0.3-0.32-0.38-0.48c0-0.01-0.01-0.01-0.01-0.02
		c-0.03-0.05-0.05-0.11-0.08-0.15c-0.05-0.06-0.09-0.13-0.13-0.2c-0.04-0.08-0.11-0.19-0.15-0.21c-0.04-0.02-0.04-0.02-0.12,0.01
		c-0.1,0.04-0.24,0.09-0.45,0.07c-0.08-0.01-0.14-0.01-0.18,0c-0.12,0.01-0.26-0.03-0.35-0.11c-0.07-0.07-0.1-0.17-0.11-0.27
		c0,0,0,0,0-0.01c-0.01-0.02-0.02-0.04-0.05-0.08c-0.01-0.02-0.04-0.04-0.06-0.07c-0.11-0.12-0.31-0.35-0.31-0.67
		c0-0.08,0.01-0.16,0.04-0.24c0.05-0.16,0.16-0.24,0.28-0.28c-0.01-0.01-0.03-0.03-0.04-0.04l-0.11-0.12
		c-0.19-0.21-0.78-0.86-0.99-0.71c-0.08,0.06-0.17,0.08-0.26,0.08c-0.06,0-0.12-0.02-0.18-0.06c-0.04-0.03-0.08-0.06-0.11-0.1v0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0-0.01,0-0.02-0.01-0.03c0,0,0,0,0-0.01c0,0,0,0,0,0
		c0,0,0,0,0,0v0c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0C239.25,205.36,239.25,205.36,239.25,205.34
		C239.25,205.35,239.25,205.35,239.25,205.34C239.25,205.35,239.25,205.35,239.25,205.34C239.25,205.35,239.25,205.35,239.25,205.34
		z"
          />
          <path
            id="Hidalgo"
            className={`${styles.clickable} ${estado=== "Hidalgo" ? " fill-[#9a3324]" : "fill-[#154734]"}`}
            onClick={(e) => setEstado(e.target.id)}
            d="M235.32,182.18c0.03-0.17,0.09-0.32,0.14-0.45c0.08-0.2,0.12-0.31,0.08-0.44c-0.02-0.06-0.06-0.11-0.13-0.19
		c-0.01-0.01-0.01-0.01-0.02-0.02c-0.01,0-0.01,0-0.02,0c-0.05,0-0.18,0.04-0.48,0.33c-0.07,0.06-0.11,0.12-0.15,0.17
		c-0.01,0.01-0.01,0.02-0.02,0.03c-0.07,0.13-0.07,0.26-0.06,0.45c0.01,0.22,0.02,0.48-0.1,0.79c-0.04,0.21-0.15,0.43-0.42,0.55
		c-0.26,0.13-0.61,0.1-0.98,0.07c-0.2-0.02-0.42-0.03-0.54-0.01c-0.1,0.02-0.22,0.05-0.35,0.07c-0.51,0.11-1.07,0.18-1.71-0.3
		c-0.18-0.13-0.28-0.3-0.36-0.46c-0.05-0.09-0.1-0.19-0.15-0.31c-0.04-0.1-0.07-0.19-0.09-0.27c-0.07-0.21-0.07-0.21-0.22-0.25
		c-0.08-0.02-0.25,0-0.39,0.03c-0.05,0.06-0.1,0.14-0.13,0.23c0,0.16-0.02,0.31-0.04,0.44c-0.03,0.24-0.06,0.46,0,0.62
		c0.01,0.01,0.01,0.03,0.01,0.04c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.01,0.05,0.02,0.1,0.01,0.16c0,0,0,0,0,0l0,0c0,0,0,0,0,0v0
		c0,0,0,0,0,0v0c0,0,0,0.01,0,0.01c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0.01v0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0.01,0,0.01c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c-0.01,0.02-0.02,0.05-0.03,0.07c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.05,0.08-0.13,0.13-0.22,0.16
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0h0c0,0-0.01,0-0.01,0c-0.1,0.02-0.38,0.08-0.58-0.12
		c-0.03-0.03-0.05-0.06-0.07-0.09c-0.02,0.01-0.04,0.02-0.06,0.02c-0.09,0.04-0.17,0.07-0.21,0.09c-0.09,0.03-0.17,0.04-0.24,0.04
		c-0.09,0.04-0.19,0.1-0.29,0.15c-0.24,0.14-0.49,0.28-0.75,0.31c-0.35,0.04-0.65-0.1-0.89-0.28c0,0-0.01,0.01-0.01,0.01
		c-0.2,0.22-0.47,0.24-0.69,0.25c0.02,0.04,0.04,0.08,0.05,0.13c0.02,0.05,0.04,0.11,0.05,0.18c0.04,0.35-0.18,0.69-0.36,0.98
		c-0.08,0.13-0.16,0.26-0.2,0.35c0,0,0,0.01,0,0.01c0,0,0,0.01,0,0.01c-0.02,0.05-0.03,0.1-0.05,0.15
		c-0.01,0.04-0.02,0.09-0.03,0.13c-0.03,0.13-0.07,0.28-0.13,0.42c-0.1,0.24-0.24,0.48-0.51,0.65c-0.19,0.12-0.36,0.17-0.48,0.2
		c-0.14,0.04-0.18,0.05-0.23,0.12c-0.19,0.22-0.36,0.65-0.43,1.05c-0.01,0.07-0.01,0.14-0.02,0.22c-0.01,0.34,0.06,0.53,0.14,0.59
		c0.03,0.03,0.05,0.06,0.07,0.09c0.03,0.06,0.05,0.12,0.05,0.18c0,0,0,0,0,0c0,0.03,0,0.05-0.01,0.08c0,0.01-0.01,0.02-0.01,0.03
		c0,0,0,0,0,0c-0.02,0.07-0.05,0.12-0.1,0.17c-0.04,0.04-0.08,0.06-0.13,0.08c-0.01,0-0.01,0-0.01,0.01
		c-0.63,0.23-1.26,0.49-1.87,0.74c-0.39,0.16-0.78,0.32-1.17,0.47c-0.09,0.04-0.19,0.07-0.29,0.1c-0.16,0.05-0.32,0.1-0.43,0.17
		c0,0-0.01,0-0.01,0.01c-0.09,0.05-0.15,0.09-0.18,0.11c-0.01,0.06-0.02,0.13-0.05,0.21c0,0,0,0,0,0c0,0.09-0.01,0.2-0.02,0.34
		c-0.02,0.28-0.06,0.56-0.09,0.83c-0.01,0.08-0.02,0.18-0.03,0.29c-0.03,0.35-0.06,0.74-0.18,1.03l-0.02,0.05
		c0.13,0.06,0.24,0.13,0.32,0.2c0.29,0.24,0.5,0.54,0.7,0.83c0.15,0.21,0.29,0.41,0.44,0.57c0.21,0.21,0.38,0.21,0.67,0.2
		c0,0,0,0,0,0c0.27-0.03,0.59-0.07,0.94,0.14c0.42,0.24,0.69,0.58,0.81,0.96c0.07,0.17,0.08,0.34,0.07,0.51
		c-0.01,0.24-0.07,0.49-0.2,0.73c-0.04,0.08-0.09,0.17-0.15,0.25c-0.03,0.05-0.06,0.09-0.08,0.13c-0.12,0.25-0.08,0.28,0.28,0.61
		c0.1,0.09,0.19,0.18,0.26,0.27c0.17,0.14,0.32,0.3,0.42,0.52c0.08,0.17,0.1,0.39,0.12,0.61c0.01,0.09,0.02,0.24,0.05,0.37
		c0,0.01,0.01,0.01,0.01,0.02c0.03,0.05,0.05,0.1,0.08,0.14c0,0,0,0,0,0c0.2-0.1,0.56-0.31,0.74-0.43c0.06-0.06,0.11-0.14,0.17-0.23
		c0.02-0.05,0.03-0.1,0.05-0.14c0.06-0.17,0.12-0.34,0.21-0.48l0.2-0.3c0.11-0.22,0.24-0.44,0.39-0.62
		c0.24-0.29,0.43-0.39,0.65-0.45c0.13-0.04,0.26-0.07,0.39-0.09c0.11-0.02,0.21-0.04,0.33-0.07c0.09-0.04,0.19-0.09,0.28-0.14
		c0.2-0.1,0.4-0.21,0.65-0.26c0.66-0.15,1.11-0.09,1.39,0.21c0.14,0.15,0.22,0.33,0.25,0.53c0.22,0.53-0.02,1.1-0.19,1.5l-0.07,0.16
		c-0.01,0.03-0.03,0.06-0.04,0.1c-0.08,0.3-0.15,0.64-0.11,0.78c0.01,0.01,0.03,0.01,0.04,0.02c0,0,0,0,0,0
		c0.05,0,0.12-0.08,0.16-0.13c0.09-0.11,0.21-0.25,0.37-0.35c0.21-0.13,0.46-0.19,0.73-0.19c0,0,0,0,0,0
		c0.22-0.01,0.38,0.02,0.51,0.08c0.09,0.04,0.16,0.09,0.23,0.14c0.02,0.02,0.03,0.03,0.04,0.03c0.02,0.01,0.03,0.02,0.05,0.02
		c0,0,0,0,0.01,0c0.1,0.03,0.23,0.04,0.37,0.04c0.2,0.01,0.42,0.01,0.64,0.1c0.32,0.13,0.58,0.48,0.82,0.81
		c0.17,0.18,0.34,0.39,0.45,0.6c0.18,0.24,0.2,0.39,0.11,0.77c0,0,0,0,0,0c-0.09,0.37-0.32,0.7-0.54,1.02
		c-0.04,0.07-0.08,0.13-0.12,0.19c0.12,0,0.22,0,0.29-0.01c0.09-0.02,0.22-0.11,0.33-0.19c0.18-0.13,0.37-0.26,0.59-0.3
		c0.01,0,0.02,0,0.04,0c0.02,0,0.04,0,0.05,0c0.11-0.01,0.26-0.01,0.38,0.08c0.08,0.04,0.14,0.1,0.18,0.14
		c0.2,0.06,0.42,0.13,0.64,0.19c0.11,0.03,0.23,0.1,0.43,0.23c0.05,0.03,0.1,0.05,0.14,0.06c0.01,0,0.03,0,0.04,0.01
		c0.02-0.05,0.09-0.19,0.12-0.27c0.05-0.11,0.1-0.21,0.14-0.27c0.02-0.02,0.04-0.06,0.06-0.09c0.16-0.35,0.38-0.69,0.78-0.77
		c0.05-0.01,0.1-0.01,0.14-0.01c0.09-0.01,0.19,0,0.29,0.08c0.01,0.01,0.01,0.01,0.02,0.02c0.03,0.02,0.06,0.04,0.1,0.06
		c-0.11-0.25-0.2-0.49-0.28-0.7c-0.25-0.41-0.38-0.71-0.35-0.96c0.03-0.29,0.27-0.57,0.59-0.95c0.07-0.08,0.14-0.16,0.18-0.21
		c0.04-0.06,0.09-0.11,0.13-0.17c0.04-0.06,0.08-0.11,0.13-0.17c0.12-0.16,0.25-0.33,0.37-0.49c0.12-0.16,0.19-0.31,0.27-0.46
		c0-0.06,0-0.11,0-0.16c0-0.06,0.01-0.12,0.03-0.18c0.08-0.17,0.2-0.31,0.35-0.42c0.05-0.07,0.09-0.12,0.13-0.16
		c-0.01-0.04-0.02-0.08-0.02-0.12c0-0.07-0.04-0.33-0.08-0.52c-0.02-0.11-0.05-0.19-0.05-0.22c0-0.01-0.01-0.02-0.01-0.03
		c-0.02-0.06-0.04-0.07-0.04-0.07c0,0-0.07-0.01-0.12-0.01c-0.08-0.01-0.18-0.03-0.29-0.05l-0.18-0.04c-0.01,0-0.01,0-0.02,0
		c-0.25-0.02-0.57-0.11-0.75-0.53c-0.11-0.25-0.09-0.58,0.01-0.88c0.04-0.26,0.14-0.46,0.36-0.61c0.05-0.06,0.11-0.1,0.17-0.14
		c0.2-0.12,0.42-0.1,0.6-0.08c0.03,0,0.07,0,0.1,0.01c0.03,0,0.08,0.01,0.12,0.01c0.06-0.08,0.15-0.26,0.2-0.35
		c0.05-0.1,0.09-0.18,0.13-0.24c0.01-0.02,0.02-0.03,0.03-0.05c0.02-0.03,0.03-0.05,0.04-0.07c0.08-0.13,0.17-0.24,0.25-0.33
		c0.02-0.02,0.03-0.04,0.05-0.06c0.01-0.05,0.03-0.1,0.06-0.14c0.1-0.15,0.19-0.28,0.28-0.4c0.35-0.5,0.59-0.82,0.67-1.49l0.02-0.18
		c0.01-0.06,0.02-0.13,0.02-0.18c-0.01-0.02-0.01-0.05-0.02-0.07c-0.03-0.01-0.07-0.04-0.14-0.07c-0.13-0.06-0.17-0.07-0.18-0.07
		c-0.02,0-0.09,0.02-0.15,0.04l-0.06,0.02c-0.4,0.13-0.93,0.68-1.28,1.05l-0.17,0.18c-0.03,0.04-0.07,0.08-0.1,0.12
		c-0.06,0.07-0.11,0.13-0.17,0.2c-0.02,0.03-0.05,0.06-0.07,0.09c-0.25,0.32-0.52,0.67-0.84,0.87c-0.09,0.1-0.21,0.19-0.38,0.22
		c-0.23,0.04-0.42-0.06-0.56-0.13c-0.07-0.01-0.08-0.01-0.11,0.02c-0.04,0.03-0.07,0.1-0.11,0.17c0,0.01,0,0.01-0.01,0.02
		c-0.09,0.27-0.21,0.62-0.55,0.75c-0.23,0.09-0.48,0.05-0.78-0.12c-0.05-0.03-0.11-0.09-0.16-0.16c-0.1-0.1-0.17-0.23-0.23-0.38
		c-0.33-0.62-0.66-1.55-0.65-1.73c0.01-0.33,0.33-0.73,0.82-1.29c0.11-0.13,0.21-0.24,0.26-0.31c0,0,0,0,0,0l0.4-0.57
		c0.04-0.06,0.09-0.13,0.13-0.19c-0.17-0.02-0.34-0.07-0.48-0.16c-0.16-0.11-0.27-0.27-0.3-0.45c-0.04-0.21,0.02-0.38,0.12-0.51
		c0.12-0.19,0.33-0.29,0.52-0.34c0.13-0.05,0.27-0.1,0.39-0.13c0.1-0.03,0.25-0.08,0.35-0.12c0.03-0.03,0.06-0.07,0.1-0.12
		c0.01-0.13,0.04-0.32,0.19-0.47c0.19-0.19,0.44-0.29,0.69-0.32c0.22-0.04,0.41-0.02,0.59,0.04c0.02,0,0.03,0.01,0.05,0.01
		c0.09,0.03,0.17,0.07,0.26,0.12c0.09,0.05,0.17,0.1,0.25,0.14c0.11,0.06,0.2,0.13,0.27,0.18c0-0.01,0.01-0.01,0.01-0.02
		c0.1-0.19,0.11-0.46,0.12-0.74c0.02-0.46,0.04-1.03,0.51-1.46c0.01-0.01,0.02-0.02,0.03-0.03c0.08-0.09,0.17-0.17,0.27-0.25
		c0.09-0.07,0.24-0.1,0.35-0.08c0,0,0,0,0.01,0c0.02-0.06,0.05-0.12,0.07-0.17c-0.05-0.06-0.12-0.15-0.22-0.26
		c-0.01,0-0.07-0.04-0.11-0.07c-0.1-0.02-0.19-0.09-0.25-0.18c0,0,0,0,0,0h0c0,0,0,0,0-0.01c-0.02-0.03-0.03-0.06-0.04-0.09
		c-0.11,0.08-0.26,0.16-0.47,0.16c-0.09,0-0.18-0.01-0.28-0.05c-0.23-0.08-0.34-0.22-0.39-0.33c-0.03-0.08-0.05-0.15-0.05-0.23
		c0,0,0,0,0-0.01c-0.05,0.04-0.1,0.07-0.15,0.1c-0.09,0.06-0.19,0.11-0.31,0.13c-0.27,0.05-0.56-0.04-0.87-0.29
		c-0.07-0.06-0.15-0.13-0.23-0.22c-0.09-0.09-0.17-0.2-0.24-0.3c-0.24-0.34-0.45-0.73-0.52-1.02c-0.04-0.18-0.03-0.35,0-0.52
		C235.31,182.29,235.31,182.23,235.32,182.18z"
          />
          <path
            id="Queretaro"
            className={`${styles.clickable} ${estado=== "Queretaro" ? " fill-[#9a3324]" : "fill-[#154734]"}`}
            onClick={(e) => setEstado(e.target.id)}
            d="M212.72,194.73c0.03,0.04,0.07,0.08,0.1,0.13c0.24,0.21,0.43,0.54,0.59,0.86c0.36,0.59,0.67,1.26,0.85,1.95
		c0.26-0.29,0.59-0.58,0.9-0.85c0.32-0.28,0.65-0.56,0.78-0.76c0.09-0.13,0.11-0.32,0.12-0.52c0-0.02,0-0.04,0-0.05
		c-0.01-0.2-0.03-0.43,0.07-0.67c0.01-0.03,0.02-0.05,0.04-0.08c0.04-0.09,0.09-0.18,0.16-0.26c0.3-0.35,0.83-0.43,1.34-0.36
		c0.03-0.19,0.08-0.41,0.15-0.67c0.04-0.15,0.08-0.28,0.09-0.37c0.02-0.16,0.03-0.34,0.04-0.51c0.02-0.29,0.03-0.59,0.1-0.87
		c0.01-0.13,0.02-0.24,0.05-0.34c0.01-0.26,0.21-0.36,0.3-0.4c0.05-0.03,0.13-0.07,0.27-0.14c0,0,0,0,0,0
		c0.87-0.51,1.91-0.86,2.91-1.2c0.18-0.06,0.37-0.13,0.55-0.19c-0.11-0.32-0.11-0.69-0.04-1.06c0.09-0.62,0.36-1.31,0.73-1.61
		c0.18-0.14,0.37-0.18,0.53-0.22c0.13-0.03,0.21-0.05,0.27-0.09c0.09-0.07,0.14-0.15,0.18-0.23c0.04-0.09,0.07-0.2,0.1-0.32
		c0.01-0.04,0.02-0.09,0.04-0.13c0.02-0.07,0.04-0.14,0.07-0.21c0.07-0.2,0.17-0.37,0.26-0.53c0.1-0.17,0.19-0.33,0.22-0.48
		c-0.01-0.01-0.01-0.01-0.02-0.02c-0.06-0.07-0.12-0.13-0.16-0.2c-0.05-0.06-0.09-0.14-0.1-0.23c-0.05-0.19,0.01-0.37,0.16-0.53
		c0.19-0.19,0.46-0.19,0.67-0.19c0.08,0,0.21,0,0.26-0.01c0,0,0,0,0,0c0-0.01,0-0.05,0-0.1c0-0.04,0-0.09,0.01-0.13c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0v0c0,0,0,0,0,0
		c0.02-0.05,0.04-0.09,0.07-0.13c0,0,0,0,0,0c0,0,0,0,0,0h0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0.02-0.02,0.05-0.04,0.07-0.05c0.14-0.07,0.31-0.05,0.42,0.05l0,0v0l0.09,0.08
		c0.42,0.37,0.55,0.45,0.83,0.33c0.02-0.01,0.06-0.05,0.1-0.09c0.08-0.08,0.19-0.19,0.35-0.26c0.15-0.06,0.28-0.07,0.39-0.06
		c0.01,0,0.02-0.01,0.03-0.01c0,0,0,0,0,0c0.09-0.02,0.17-0.06,0.25-0.09c0.13-0.06,0.28-0.12,0.44-0.13
		c0.01-0.21,0.04-0.42,0.1-0.62c-0.01-0.03-0.02-0.05-0.03-0.06c-0.06-0.03-0.12-0.06-0.18-0.07c-0.19-0.05-0.32-0.23-0.29-0.43
		c0.07-0.47-0.08-1-0.23-1.52c-0.05-0.18-0.11-0.37-0.15-0.54c-0.03-0.12-0.07-0.35-0.12-0.64c-0.01-0.09-0.03-0.2-0.05-0.32
		c-0.13-0.43-0.24-0.83-0.31-1.21c-0.02-0.1-0.04-0.17-0.05-0.21c-0.07,0.06-0.16,0.14-0.23,0.22c-0.08,0.16-0.17,0.32-0.31,0.44
		c-0.23,0.2-0.45,0.26-0.63,0.28c-0.01,0-0.02,0.01-0.03,0.01c-0.14,0.03-0.25,0.04-0.33,0.05c-0.02,0-0.04,0-0.05,0
		c-0.04,0.07-0.1,0.21-0.14,0.31c-0.28,0.62-0.39,0.77-1.06,0.94c-0.01,0-0.02,0-0.03,0.01c0,0,0,0,0,0l-0.05,0.01
		c-0.04,0.01-0.1,0.03-0.16,0.04c-0.66,0.19-1.29,0.34-1.66,0.18c-0.43-0.19-0.59-0.68-0.73-1.11c-0.03-0.1-0.06-0.19-0.09-0.26
		c-0.14-0.34-0.26-0.47-0.41-0.48c-0.19,0.01-0.29,0.18-0.45,0.63c-0.09,0.25-0.19,0.5-0.36,0.69c-0.06,0.22-0.09,0.42-0.09,0.58
		c0,0.04,0,0.08,0,0.12l0,0.1c-0.01,0.41-0.02,0.65,0.15,0.89c0.01,0.01,0.02,0.03,0.03,0.04c0.04,0.05,0.09,0.1,0.14,0.14
		c0.03,0.03,0.05,0.05,0.08,0.08c0.14,0.13,0.19,0.26,0.2,0.39c0,0.03,0,0.06,0,0.09c-0.01,0.14-0.07,0.26-0.15,0.36
		c-0.01,0.02-0.02,0.04-0.04,0.06c-0.45,0.73-1.38,0.95-2.47,0.58c-0.33-0.11-0.7-0.24-0.82-0.18c-0.03,0.02-0.12,0.09-0.23,0.42
		c-0.03,0.21-0.07,0.46-0.21,0.69c-0.14,0.21-0.32,0.33-0.47,0.42c-0.13,0.15-0.23,0.29-0.29,0.5c-0.03,0.1-0.05,0.22-0.06,0.33
		c-0.01,0.12-0.02,0.23-0.04,0.32c-0.01,0.02-0.01,0.05-0.02,0.07c-0.1,0.53-0.33,1.17-1.25,1.05c-0.23-0.03-0.42-0.13-0.6-0.23
		c-0.03-0.01-0.05-0.02-0.08-0.03l-0.15-0.06c-0.15-0.06-0.24-0.12-0.31-0.16c-0.06-0.04-0.08-0.05-0.11-0.05
		c-0.02,0.01-0.03,0.03-0.05,0.04c-0.09,0.08-0.24,0.21-0.45,0.23c-0.15,0.01-0.27,0-0.38-0.03c-0.07-0.01-0.14-0.03-0.22-0.06
		c-0.25-0.09-0.4-0.25-0.52-0.37c-0.02-0.02-0.04-0.04-0.06-0.06c-0.43-0.2-0.65,0.03-1.11,0.84l-0.08,0.14
		c-0.03,0.06-0.07,0.12-0.12,0.18c-0.03,0.06-0.06,0.11-0.1,0.16c-0.06,0.09-0.17,0.23-0.31,0.4c-0.19,0.23-0.62,0.77-0.69,0.98
		c0,0.01-0.01,0.03-0.01,0.04c0,0.01,0.01,0.02,0.01,0.02c0.04,0.05,0.2,0.14,0.3,0.19c0.13,0.07,0.26,0.15,0.38,0.25
		c0.21,0.18,0.36,0.39,0.47,0.62c0.05,0.08,0.09,0.16,0.12,0.25c0.11,0.3,0.15,0.62,0.17,0.93c0.03,0.31,0.03,0.63,0.03,0.92
		c0,0.12,0,0.24,0,0.36c0,0.08,0,0.18-0.01,0.28c0,0.04,0.01,0.09,0.01,0.13l0.02,0.19c0.01,0.15,0.01,0.15,0.17,0.34
		c0.05,0.06,0.09,0.1,0.14,0.15c0.11,0.11,0.25,0.24,0.31,0.44c0.06,0.16,0.01,0.31-0.05,0.41c0.02,0.02,0.05,0.04,0.07,0.06
		c0.03,0.02,0.06,0.05,0.1,0.08c0.07,0.07,0.15,0.13,0.23,0.2C212.18,194.11,212.5,194.39,212.72,194.73z"
          />
          <path
            id="Yucatan"
            d="M335.38,196.98l-0.35,3.42c0.57,0.03,1.15,0.06,1.73,0.11c0.12,0.01,0.23,0.08,0.3,0.19
		c0.03,0.06,0.05,0.13,0.05,0.19c0,0.05-0.01,0.11-0.03,0.16c-0.12,0.27-0.2,0.44-0.24,0.55c0.1,0.04,0.22,0.08,0.29,0.1
		c0.09,0.03,0.16,0.05,0.19,0.07c0.06,0.03,0.15,0.07,0.24,0.12c0.14,0.07,0.47,0.24,0.57,0.25c0.09,0,0.13-0.03,0.31-0.3l0.09-0.12
		c0,0,0.01-0.01,0.01-0.01c0.07-0.09,0.18-0.15,0.3-0.15c0.01,0,0.01,0,0.02,0c0.13,0.01,0.24,0.08,0.3,0.18c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0.01,0.01,0.01,0.01,0.02l1.48,2.71c0.28,0.51,0.56,1.03,0.84,1.54c0.35,0.65,0.71,1.3,1.06,1.95
		c0.53,0.97,1.05,1.94,1.58,2.92c0.13,0.25,0.31,0.52,0.49,0.82c0.35,0.55,0.73,1.15,0.94,1.72c0.05-0.03,0.1-0.05,0.15-0.08
		l0.19-0.1c1.71-0.96,3.43-1.92,5.15-2.87c1.77-0.98,3.54-1.95,5.31-2.91l1.15-0.63c0.53-0.29,1.05-0.58,1.58-0.87
		c1.87-1.04,3.81-2.11,5.75-3.11l0.18-0.09c0.96-0.49,1.6-0.82,2.14-1.66c0.37-0.58,0.75-1.15,1.12-1.72l0.26-0.4
		c0.06-0.09,0.12-0.18,0.19-0.27c0.09-0.15,0.18-0.3,0.27-0.43c0.61-0.93,0.67-1.17,0.77-2.23c0.08-0.84,0.15-1.69,0.22-2.53
		c0.01-0.19,0.02-0.39,0.02-0.6c0.01-0.53,0.02-1.07,0.16-1.55l0-0.01c-0.09-0.03-0.19-0.07-0.28-0.12
		c-0.36,0.26-0.91,0.08-1.4-0.07c-0.12-0.04-0.22-0.07-0.29-0.08l-0.09-0.02c-0.57-0.11-1.34-0.26-1.78-0.88
		c-0.03-0.05-0.06-0.1-0.07-0.16c-0.09-0.03-0.16-0.05-0.22-0.07c-0.67-0.29-1.1-0.36-1.79-0.46c-0.17-0.02-0.32-0.06-0.47-0.09
		c-0.37-0.08-0.61-0.13-1.01-0.01c-0.12,0.04-0.25,0.11-0.38,0.18c-0.21,0.12-0.45,0.25-0.73,0.28c-0.38,0.04-0.73-0.09-1.04-0.21
		c-0.21-0.08-0.4-0.15-0.57-0.17c-0.38-0.03-0.74,0.04-1.12,0.11c-0.25,0.05-0.51,0.1-0.79,0.12c-0.39,0.03-0.61,0.22-0.91,0.47
		c-0.15,0.13-0.33,0.27-0.54,0.41c-0.46,0.29-1.01,0.35-1.49,0.4c-0.18,0.02-0.37,0.04-0.54,0.08c-2.18,0.43-4.42,0.52-6.58,0.61
		c-0.68,0.03-1.36,0.06-2.04,0.09c-0.37,0.02-0.74,0.03-1.12,0.04c-1.06,0.03-2.07,0.05-3.06,0.33c-0.35,0.1-0.62,0.25-0.94,0.42
		c-0.12,0.06-0.23,0.13-0.36,0.19c-0.34,0.17-0.7,0.23-1.01,0.27c-0.24,0.03-0.48,0.07-0.67,0.15c-0.17,0.07-0.32,0.16-0.47,0.24
		c-0.2,0.11-0.42,0.23-0.67,0.32c-0.44,0.17-0.84,0.42-1.26,0.68l-0.16,0.1c-0.21,0.13-0.37,0.27-0.5,0.42
		c0.02,0.02,0.03,0.04,0.04,0.07c0.05,0.11,0.08,0.23,0.08,0.35c0,0.31-0.14,0.64-0.29,0.96c-0.03,0.06-0.06,0.13-0.09,0.19
		c-0.04,0.09-0.09,0.18-0.13,0.26c-0.01,0.02-0.02,0.04-0.03,0.07C335.42,196.88,335.4,196.94,335.38,196.98z"
          />
          <path
            id="Veracruz"
            d="M259.82,208.95c-0.05-0.08-0.11-0.18-0.18-0.3c-0.3-0.55-0.37-1.29-0.44-1.88l-0.01-0.07
		c-0.03-0.32-0.04-0.63-0.05-0.93c-0.02-0.71-0.04-1.38-0.4-2.02c-0.1-0.17-0.23-0.34-0.37-0.51c-0.16-0.2-0.32-0.4-0.46-0.64
		c-0.09-0.16-0.17-0.32-0.25-0.47c-0.19-0.37-0.37-0.72-0.63-1.07c-0.29-0.41-0.52-0.84-0.74-1.25c-0.25-0.47-0.49-0.92-0.82-1.34
		c-0.15-0.19-0.3-0.37-0.44-0.55c-0.54-0.66-1.04-1.28-1.47-2.1c-0.21-0.4-0.52-0.73-0.86-1.08c-0.31-0.33-0.64-0.67-0.9-1.1
		l-0.08-0.13c-0.49-0.8-1-1.62-1.03-2.59c0-0.02,0.01-0.09,0.01-0.11c0.03-0.2,0.03-0.34,0.01-0.42c-0.05-0.16-0.1-0.29-0.16-0.43
		l-0.04-0.11c-0.1-0.25-0.22-0.51-0.34-0.76c-0.14-0.31-0.29-0.62-0.41-0.93c-0.35-0.91-0.66-1.73-0.88-2.56
		c-0.06-0.24-0.09-0.46-0.11-0.68c-0.02-0.15-0.03-0.3-0.06-0.45c-0.01-0.07-0.02-0.14-0.03-0.22c0-0.01,0-0.02,0-0.03
		c-0.1-0.04-0.21-0.11-0.28-0.26c-0.09-0.2-0.16-0.43-0.23-0.65c-0.05-0.17-0.11-0.34-0.17-0.5c-0.14-0.34-0.24-0.73-0.28-1.05
		c0-0.03,0-0.07,0-0.11c0.01-0.13,0.04-0.3,0.09-0.55c-0.12,0.02-0.25,0.03-0.39-0.03c-0.65-0.24-0.95-1.2-1.2-1.97
		c-0.06-0.18-0.11-0.35-0.16-0.47c-0.44-1.1-0.48-2.57-0.38-3.49c0.05-0.48-0.06-1.02-0.16-1.38c-0.02-0.07-0.1-0.23-0.17-0.38
		c-0.27-0.55-0.55-1.11-0.29-1.49c0.03-0.05,0.07-0.09,0.12-0.11c-0.07-0.39-0.02-0.77,0.02-1.14c0.02-0.2,0.04-0.39,0.05-0.58
		c0,0,0,0,0,0c-0.23,0.19-0.51,0.41-0.91,0.36c-0.16-0.02-0.83-0.54-0.86-0.8c0-0.03,0-0.05,0-0.08c0-0.22,0.11-0.35,0.2-0.43
		c-0.06-0.17-0.32-0.31-0.88-0.47c-0.6-0.18-1.01-0.42-1.44-0.73c-0.24-0.18-0.41-0.41-0.55-0.62c-0.13-0.19-0.25-0.37-0.39-0.42
		c-0.05-0.02-0.17,0-0.26,0.02c-0.06,0.01-0.13,0.02-0.19,0.03c-0.06,0.01-0.12,0.01-0.17,0.01c0,0.01-0.01,0.02-0.01,0.03
		c0.01-0.02,0.02,0.01,0.02,0.04c0,0.03,0.01,0.07,0.01,0.12c0,0.19-0.05,0.47-0.38,0.61c-0.09,0.04-0.17,0.04-0.31,0.02
		c-0.08-0.01-0.18-0.03-0.31-0.06c-0.04-0.01-0.09-0.02-0.12-0.03c0,0,0,0,0,0c-0.07,0-0.13-0.02-0.19-0.05c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0h0h0c0,0,0,0,0,0c-0.07-0.04-0.13-0.09-0.17-0.14c-0.13,0.1-0.32,0.11-0.46,0.02c-0.01-0.01-0.03-0.02-0.04-0.03
		c-0.07-0.05-0.13-0.09-0.19-0.14c-0.02-0.01-0.04-0.03-0.05-0.04c-0.1,0.02-0.18,0-0.24-0.02c-0.09-0.04-0.18-0.1-0.27-0.16
		c-0.16,0-0.35,0.02-0.54,0.04c-0.01,0-0.01,0-0.02,0c0.03,0.02,0.05,0.05,0.08,0.08c0.01,0.01,0.03,0.02,0.04,0.04
		c0.19,0.15,0.38,0.29,0.58,0.44c0.24,0.18,0.49,0.36,0.73,0.56c0.39,0.29,0.76,0.62,0.92,1.05c0.35,0.95-0.7,2.12-1.39,2.89
		c-0.1,0.11-0.19,0.22-0.27,0.3c-0.18,0.26-0.37,0.49-0.73,0.55c0.05,0.11,0.05,0.23,0.01,0.36c-0.05,0.15-0.14,0.24-0.25,0.29
		c0.01,0.07-0.01,0.13-0.04,0.19c-0.05,0.1-0.1,0.18-0.17,0.24c0.02,0.04,0.05,0.07,0.07,0.11c0.19-0.09,0.35-0.05,0.46,0.01
		c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0.06,0.17,0.16,0.18,0.28c0.06,0.16,0.3,0.72,0.36,0.85c0.04,0.07,0.06,0.14,0.05,0.22
		c-0.03,0.49-1.13,1.07-1.28,1.1c-0.04,0.01-0.08,0.01-0.11,0.01c-0.02,0.08-0.07,0.14-0.11,0.19c-0.05,0.07-0.11,0.12-0.18,0.15
		c-0.08,0.05-0.16,0.07-0.24,0.07c-0.11,0.02-0.21,0.01-0.29-0.02c0.02,0.13,0.03,0.26,0,0.39c0,0,0,0.01,0,0.01
		c-0.05,0.21-0.14,0.33-0.24,0.41c0.05,0.1,0.22,0.18,0.45,0.28c0.27,0.11,0.57,0.24,0.74,0.53c0.14,0.23,0.16,0.49,0.14,0.74
		c0.02,0.35-0.03,0.74-0.09,1.06c0.06,0.05,0.12,0.12,0.18,0.2c0.36,0.48,0.18,1,0.05,1.39c-0.04,0.12-0.08,0.23-0.1,0.33
		c0.01,0.26,0.14,0.62,0.32,0.92c0.1,0.15,0.21,0.28,0.3,0.38c0.07,0.06,0.14,0.11,0.21,0.15c0.04,0.02,0.1,0.04,0.17,0.02
		c0.08-0.08,0.19-0.28,0.26-0.4l0.08-0.13c0.03-0.05,0.07-0.12,0.11-0.18c0.13-0.24,0.31-0.44,0.58-0.45
		c0.11-0.01,0.25,0.02,0.38,0.14c0.03,0.02,0.05,0.05,0.07,0.08c0.05,0.06,0.09,0.12,0.14,0.21c0.18,0.34,0.02,0.68-0.12,0.94
		c-0.01,0.02-0.02,0.03-0.03,0.05c0.15-0.11,0.4-0.21,0.71-0.04c0,0,0.01,0,0.01,0c0.18,0.1,0.23,0.28,0.25,0.43
		c0.07,0.04,0.14,0.09,0.22,0.17c0.03,0.03,0.07,0.07,0.11,0.11c0.16,0.17,0.34,0.35,0.4,0.57c0.01,0.05,0.02,0.08,0.02,0.12
		c0.04,0.39-0.13,0.63-0.33,0.78c0,0-0.01,0.01-0.01,0.01c0,0,0,0,0,0c-0.08,0.07-0.17,0.12-0.26,0.14
		c-0.07,0.03-0.14,0.05-0.19,0.07c-0.07,0.02-0.16,0.06-0.21,0.08c-0.09,0.11-0.16,0.21-0.2,0.32c-0.04,0.09-0.05,0.4-0.07,0.63
		c-0.03,0.65-0.07,1.25-0.48,1.5c-0.42,0.25-0.84-0.07-1.14-0.3c-0.06-0.04-0.13-0.1-0.19-0.14c-0.05-0.03-0.09-0.05-0.14-0.06
		c-0.08-0.02-0.17-0.03-0.25-0.02c-0.02,0.01-0.05,0.01-0.07,0.02c-0.09,0.03-0.11,0.05-0.11,0.05c-0.01,0.07-0.04,0.15-0.09,0.24
		c-0.02,0.08-0.05,0.16-0.11,0.24c-0.07,0.1-0.17,0.17-0.29,0.23c-0.11,0.09-0.25,0.17-0.46,0.24c-0.08,0.03-0.18,0.05-0.29,0.07
		c-0.03,0.01-0.07,0.01-0.12,0.02c0.16,0.01,0.36,0,0.52-0.03c0,0,0,0,0,0h0c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0h0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.07-0.01,0.13,0.01,0.19,0.03c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0.06,0.03,0.11,0.07,0.15,0.13c0.09,0.13,0.08,0.31-0.01,0.44c-0.2,0.27-0.39,0.55-0.58,0.82l-0.41,0.57
		c-0.08,0.11-0.19,0.25-0.32,0.39c-0.19,0.22-0.46,0.53-0.5,0.7c-0.03,0.11,0.01,0.21,0.13,0.45c0.05,0.11,0.11,0.22,0.16,0.35
		c0.03,0.09,0.07,0.21,0.1,0.34c0.02,0.09,0.06,0.25,0.1,0.36c0.03,0.05,0.05,0.09,0.07,0.13c0.03,0.03,0.06,0.05,0.08,0.06
		c0.03-0.06,0.07-0.14,0.1-0.2c0.02-0.03,0.03-0.07,0.05-0.1c0.09-0.26,0.21-0.55,0.51-0.68c0.27-0.12,0.51-0.03,0.68,0.06
		c0.1,0.02,0.15,0.03,0.2,0.02c0.05-0.06,0.1-0.12,0.17-0.17c0.19-0.15,0.41-0.39,0.63-0.66c0.1-0.13,0.2-0.25,0.29-0.35
		c0.66-0.78,1.41-1.59,2.35-1.55c0.43,0.02,0.67,0.23,0.81,0.42c0.14,0.14,0.19,0.29,0.19,0.48c0.09,0.49-0.06,1.08-0.22,1.49
		c0.13-0.11,0.23-0.24,0.31-0.4c0.06-0.2,0.06-0.45,0.07-0.7c0.01-0.39,0.02-0.8,0.24-1.09c0.14-0.18,0.35-0.24,0.54-0.29
		c0.01,0,0.02-0.01,0.03-0.01c0.24-0.19,0.26-0.27,0.32-0.71c0,0,0-0.01,0-0.01c-0.01-0.27-0.02-0.6,0.21-0.81c0,0,0,0,0,0
		c0,0,0,0,0,0c0.21-0.2,0.49-0.15,1.6,0.11c0.19,0.05,0.35,0.09,0.41,0.09c0.11,0.01,0.21,0.08,0.27,0.17
		c0.06,0.08,0.08,0.19,0.06,0.29c0,0.01,0,0.02-0.01,0.03l-0.05,0.17c-0.35,1.24-0.39,1.49,0.47,1.81c0.03,0.01,0.06,0.02,0.1,0.04
		c0.12,0.03,0.24,0.07,0.34,0.11c0.46,0.2,0.96,0.48,1.01,1.22c0,0.05,0,0.11-0.01,0.18c-0.01,0.25-0.1,0.51-0.25,0.8
		c-0.21,0.46-0.53,0.9-0.85,1.05c-0.23,0.1-0.43,0.11-0.61,0.03c-0.09-0.04-0.16-0.09-0.21-0.15c-0.12-0.09-0.18-0.21-0.23-0.32
		c-0.04,0.05-0.1,0.09-0.17,0.12c-0.12,0.05-0.23,0.06-0.32,0.05c-0.01,0.04-0.01,0.09-0.02,0.15c-0.02,0.13-0.05,0.27-0.09,0.41
		c-0.01,0.06-0.03,0.12-0.04,0.18c0,0.04-0.01,0.07-0.01,0.11l-0.01,0.17c-0.04,0.4-0.01,0.64,0.13,0.97c0,0.01,0.01,0.02,0.01,0.03
		c0.08,0.17,0.17,0.33,0.25,0.48l0.07,0.13c0,0,0,0,0,0c0.11,0.14,0.23,0.24,0.39,0.32c0.02,0,0.04,0,0.06,0
		c0.18-0.01,0.39-0.03,0.57,0.09c0.01,0.01,0.02,0.01,0.03,0.02c0.04,0.03,0.08,0.06,0.11,0.1c0.15,0.16,0.23,0.35,0.3,0.52
		c0.03,0.06,0.06,0.15,0.1,0.21c0.04,0.02,0.06,0.03,0.07,0.03c0,0,0.14-0.05,0.31-0.58c0.04-0.24,0.09-0.51,0.24-0.74
		c0.28-0.41,1.01-0.72,1.51-0.63c0.35,0.06,0.63,0.3,0.87,0.54c0.05,0.04,0.1,0.09,0.15,0.13c0.15,0.13,0.3,0.26,0.45,0.38
		c0.16,0.09,0.35,0.17,0.55,0.25c0.33,0.13,0.67,0.27,0.94,0.51c0.09,0.07,0.16,0.15,0.21,0.23c0.05,0.07,0.1,0.14,0.12,0.23
		c0.04,0.12,0.04,0.23,0.03,0.34c-0.02,0.18-0.09,0.34-0.18,0.49c-0.02,0.03-0.04,0.06-0.06,0.09c-0.24,0.38-0.59,0.68-0.94,0.97
		c-0.18,0.15-0.35,0.3-0.5,0.45c-0.31,0.32-0.57,0.62-0.68,0.97c-0.06,0.24-0.02,0.48,0.03,0.77c0.02,0.15,0.05,0.31,0.06,0.47
		c0.04,0.24,0.05,0.49,0,0.75c-0.11,0.56-0.63,1.4-1.08,1.78c-0.11,0.1-0.28,0.15-0.53,0.22c0.1,0.07,0.21,0.16,0.29,0.26
		c0.16,0.21,0.21,0.4,0.21,0.57c0.01,0.03,0.01,0.06,0.01,0.09c0.02,0.23,0,0.46-0.02,0.66c-0.02,0.21-0.03,0.35-0.02,0.46
		c0.06,0.16,0.18,0.27,0.37,0.36c0.05,0.02,0.09,0.04,0.13,0.07c0.01,0,0.02,0.01,0.03,0.01c0.19,0.05,0.44,0.12,0.7,0.41
		c0.13,0.15,0.23,0.27,0.32,0.37c0.25,0.3,0.26,0.31,0.84,0.33c0.2,0,0.45,0,0.72,0.08c0.75,0.17,0.93,0.6,0.96,0.87
		c0.03,0.26-0.08,0.54-0.33,0.84c-0.32,0.38-0.86,0.77-1.27,0.79c-0.26,0.01-0.47-0.09-0.63-0.18c-0.01,0-0.01-0.01-0.02-0.01
		c-0.12-0.01-0.27-0.01-0.42-0.01c-0.01,0-0.02,0.01-0.03,0.01c-0.11,0.05-0.22,0.1-0.33,0.13l-0.09,0.03
		c-0.13,0.04-0.27,0.08-0.32,0.1c0,0.01,0,0.02,0,0.03c0.01,0.08,0.01,0.28,0.02,0.4c0,0.03,0,0.06,0,0.09
		c0,0.05,0.01,0.1,0.01,0.15c0.01,0.17,0.03,0.34,0.04,0.5c0.09,1.1,0.17,2.05-0.76,3.14c-0.07,0.09-0.17,0.18-0.26,0.28
		c-0.11,0.11-0.3,0.3-0.33,0.38c0.01,0.17,0.34,1.07,0.47,1.26c0.06,0.06,0.09,0.08,0.09,0.08c0-0.01,0.05-0.02,0.09-0.04
		c0.17-0.06,0.43-0.14,0.8-0.08c0.01,0,0.01,0,0.02,0c0,0,0,0,0.01,0h0c0,0,0,0,0,0c0.68,0.12,0.96,0.58,1.1,1.04
		c0.05,0.16,0.09,0.31,0.12,0.45c0.02,0.08,0.04,0.16,0.06,0.24c0.1,0.4,0.24,0.64,0.41,0.73c0.14,0.07,0.34,0.05,0.57-0.02
		c0.14-0.06,0.29-0.13,0.44-0.19c0.33-0.16,0.68-0.32,1.04-0.37c0.2-0.06,0.43-0.1,0.64,0.03c0.1,0.04,0.18,0.12,0.24,0.23
		c0.04,0.06,0.07,0.12,0.11,0.19c0.09,0.2,0.09,0.44,0.08,0.69c0,0.1,0,0.17,0,0.24c0.01,0.03,0.01,0.05,0.02,0.07
		c0.01,0.02,0.03,0.05,0.05,0.08c0.11-0.08,0.26-0.17,0.44-0.29c0.06-0.04,0.11-0.07,0.13-0.09c0.07-0.05,0.14-0.1,0.2-0.14
		c0.18-0.12,0.29-0.2,0.35-0.29c0.01-0.02,0.02-0.03,0.03-0.05c0.03-0.05,0.05-0.11,0.05-0.19c0-0.08-0.01-0.17-0.04-0.32
		l-0.03-0.18c-0.03-0.23-0.07-0.46-0.1-0.69c-0.03-0.19-0.06-0.39-0.09-0.58c-0.02-0.15,0.05-0.31,0.19-0.39
		c0.03-0.02,0.07-0.03,0.1-0.04c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.11-0.02,0.23,0,0.32,0.07c0.58,0.46,1.11,0.85,1.64,1.21
		c0.01,0.01,0.02,0.02,0.04,0.02c0,0,0,0,0,0c0.1,0.07,0.25,0.14,0.4,0.22c0.33,0.17,0.66,0.35,0.9,0.56
		c0.08,0.07,0.15,0.14,0.2,0.22c0.22,0.32,0.26,0.76,0.3,1.19c0.02,0.26,0.05,0.53,0.11,0.72c0,0,0,0,0,0
		c0.09,0.26,0.22,0.43,0.38,0.62c0.05,0.05,0.1,0.11,0.16,0.17c0.1,0.1,0.21,0.22,0.33,0.35c0.18,0.21,0.28,0.43,0.34,0.63
		c0.04,0.09,0.08,0.19,0.11,0.29l0.07,0.19c0.02,0.05,0.04,0.1,0.06,0.16c0.02,0.06,0.04,0.12,0.07,0.19
		c0.05,0.08,0.1,0.15,0.13,0.19c0.02,0,0.04,0,0.06,0.01c0.26,0.03,0.64,0.08,0.81,0.34c0.02-0.01,0.04-0.01,0.07-0.02
		c0.42-0.09,0.71,0.01,0.96,0.14c0.11,0.06,0.22,0.12,0.33,0.18c0.1,0.06,0.19,0.11,0.28,0.16c0.2,0.1,0.39,0.21,0.58,0.33
		c0.2,0.12,0.39,0.24,0.57,0.32c0.01,0,0.01,0,0.01,0.01c0.14,0,0.29,0.02,0.52,0.17c0.44,0.29,0.71,0.75,0.77,1.31
		c0.02,0.19,0.02,0.39,0,0.58c-0.05,0.59-0.31,1.2-0.72,1.67c-0.02,0.03-0.05,0.06-0.07,0.08c-0.1,0.11-0.21,0.21-0.3,0.31
		c-0.52,0.52-0.73,0.76-0.53,1.35c0.01,0.03,0.02,0.07,0.03,0.1c0.27,0.8,0.57,1.58,0.85,2.3c0.17,0.44,0.3,0.54,0.8,0.45
		c0.09-0.04,0.19-0.07,0.32-0.1c0.31-0.07,0.56,0.04,0.75,0.11c0.05,0.02,0.11,0.05,0.16,0.06c0.08-0.04,0.19-0.11,0.23-0.14
		c0.03-0.02,0.07-0.03,0.1-0.05c0,0,0,0,0,0c0.03-0.07,0.09-0.14,0.16-0.18c0.15-0.09,0.3-0.18,0.46-0.27
		c0.43-0.27,0.9-0.56,1.43-0.7c0.16-0.05,0.33-0.08,0.5-0.09c0.08-0.12,0.21-0.18,0.37-0.23l0.19-0.06
		c0.18-0.05,0.23-0.07,0.36-0.16c0,0,0.04-0.08,0.08-0.13c0.03-0.12,0.11-0.22,0.23-0.27c0,0,0,0,0.01,0
		c0.1-0.04,0.2-0.05,0.28-0.03c0.05-0.06,0.12-0.11,0.2-0.13c0.2-0.05,0.77-0.2,1.06,0.4c0.03,0.07,0.05,0.15,0.06,0.24
		c0.08,0.34,0.01,0.76-0.22,1.15c-0.11,0.18-0.24,0.28-0.33,0.36c-0.07,0.06-0.1,0.08-0.12,0.13c0,0.27,0.23,0.63,0.43,0.93
		c0.09,0.13,0.17,0.26,0.23,0.38c0.05,0.1,0.11,0.21,0.16,0.33c0.07,0.11,0.13,0.21,0.18,0.29c0.06,0.11,0.12,0.22,0.18,0.34
		c0.18,0.35,0.36,0.72,0.61,0.86c0.02,0,0.04,0,0.06,0.01c0.11,0.01,0.24,0.03,0.36,0.07c0.09,0.03,0.17,0.07,0.25,0.14
		c0.23,0.21,0.25,0.56,0.28,0.91c0.01,0.13,0.02,0.34,0.05,0.39c0.01,0.03,0.03,0.06,0.03,0.09c0,0,0,0,0,0l0.03,0.13l2.8,0.48
		c1.89,0.33,3.79,0.62,5.78,0.9c0.52,0.07,1.03,0.16,1.54,0.24l0.82,0.13c0,0,0,0,0,0c0.13,0.02,0.32,0.03,0.53,0.04
		c0.68,0.04,1.24,0.09,1.6,0.27c0.22-0.08,0.47-0.25,0.7-0.4c0.12-0.08,0.24-0.16,0.35-0.23c0.09-0.05,0.19-0.11,0.28-0.16
		c0.06-0.04,0.13-0.08,0.18-0.12c0.13-0.08,0.25-0.15,0.36-0.21c0.57-0.33,0.98-0.57,1.31-1.05c-0.07-0.04-0.14-0.1-0.21-0.18
		c-0.19-0.21-0.41-0.75-0.45-0.95c-0.06-0.28-0.02-0.45,0.04-0.6c0.01-0.05,0.01-0.11,0.02-0.16c0.02-0.12,0.04-0.21,0.06-0.28
		c0-0.01,0-0.02,0.01-0.04c0.01-0.04,0-0.08-0.05-0.2c0,0,0,0,0,0c-0.02-0.05-0.08-0.11-0.14-0.18c-0.12-0.13-0.26-0.28-0.33-0.51
		c-0.07-0.21-0.05-0.4-0.04-0.55c0,0,0,0,0,0c-0.11-0.37-0.24-0.52-0.51-0.72c-0.02-0.01-0.05-0.03-0.07-0.04
		c-0.04-0.03-0.14-0.07-0.24-0.12c-0.3-0.15-0.44-0.22-0.53-0.3c0,0-0.01-0.01-0.01-0.01c-0.05-0.02-0.08-0.03-0.11-0.04
		c-0.02-0.01-0.03-0.02-0.05-0.03c-0.05-0.04-0.12-0.11-0.14-0.21c-0.01,0-0.02-0.01-0.03-0.01c-0.07,0.01-0.15,0.02-0.24,0
		c-0.09,0-0.2-0.01-0.3-0.07c-0.3-0.16-0.41-0.37-0.44-0.57c-0.01-0.03-0.02-0.06-0.04-0.09c-0.07-0.17-0.08-0.32-0.09-0.43
		c0-0.04-0.01-0.07-0.01-0.09c-0.1-0.02-0.25-0.06-0.4-0.17c0,0,0,0,0,0c-0.12-0.09-0.37-0.33-0.49-0.47
		c-0.51-0.46-0.61-1.07-0.26-1.74c0.05-0.09,0.09-0.16,0.12-0.22c0.01-0.02,0.03-0.04,0.03-0.06c0-0.01-0.01-0.02-0.01-0.03
		c0,0,0,0,0,0c-0.02-0.05-0.06-0.17-0.08-0.24l-0.02-0.06c-0.13-0.42-0.16-0.53-0.16-0.6c0,0,0,0,0,0c0,0,0,0,0-0.01
		c-0.01-0.12,0.02-0.22,0.06-0.29c0.01-0.01,0.01-0.02,0.02-0.03c-0.05-0.08-0.09-0.19-0.09-0.31c0-0.02,0.01-0.09,0.02-0.17
		c0,0,0,0,0-0.01c-0.02-0.09-0.02-0.16-0.02-0.21c-0.05-0.02-0.09-0.05-0.14-0.09c-0.01-0.01-0.02-0.01-0.03-0.02
		c-0.04-0.04-0.08-0.08-0.11-0.15c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.08-0.14-0.12-0.26-0.13-0.36c-0.17,0-0.5,0.11-0.7,0.18
		c-0.23,0.07-0.45,0.14-0.63,0.17l-0.34,0.05c-0.97,0.13-2.18,0.29-3.03-0.17c-0.69-0.38-0.83-0.92-0.95-1.4
		c-0.06-0.22-0.11-0.43-0.21-0.63c-0.13-0.24-0.32-0.46-0.54-0.68c-0.25-0.27-0.51-0.55-0.69-0.91c-0.12-0.25-0.18-0.53-0.23-0.79
		c-0.07-0.36-0.13-0.67-0.34-0.83c-0.22-0.17-0.63-0.21-1.02-0.26c-0.36-0.04-0.73-0.09-1.05-0.22c-0.53-0.22-0.75-0.57-0.94-0.89
		c-0.13-0.2-0.25-0.39-0.45-0.57l-0.24-0.21c-0.5-0.43-0.86-0.74-1.51-0.83c-0.34-0.05-0.68-0.04-1.04-0.03
		c-0.15,0-0.29,0.01-0.44,0.01c-0.15,0-0.3,0-0.46-0.02c-0.64-0.05-1.34-0.18-1.99-0.38c-0.4-0.12-0.92-0.31-1.36-0.66
		c-0.12-0.1-0.22-0.22-0.32-0.34c-0.09-0.11-0.18-0.22-0.25-0.26l-1.19-0.59c-0.01,0-0.02-0.01-0.03-0.02
		c-0.52-0.32-0.85-0.59-1.17-1.22c-0.18-0.36-0.29-0.68-0.34-1.04c-0.01-0.07-0.01-0.15-0.01-0.23c0-0.08,0-0.17,0.01-0.26
		c0-0.07,0.01-0.17,0.01-0.27c0-0.05,0-0.09,0-0.13c0-0.04-0.01-0.07-0.01-0.09c0,0-0.01-0.01-0.17-0.01c-0.16,0-0.39,0-0.62-0.13
		c-0.57-0.31-0.63-0.9-0.67-1.32c-0.01-0.1-0.02-0.19-0.03-0.28c-0.01-0.06-0.11-0.36-0.14-0.43c-0.05-0.11-0.07-0.2-0.09-0.26
		c0,0-0.01,0-0.01-0.01l-0.01-0.01c-0.25-0.07-0.49-0.26-0.54-0.29c-0.12-0.1-0.26-0.23-0.4-0.36c-0.08-0.08-0.16-0.16-0.23-0.22
		c-0.68-0.61-0.88-1.31-0.92-1.98c-0.01-0.22-0.01-0.45,0-0.66C259.96,209.18,259.93,209.14,259.82,208.95z"
          />
          <path
            id="EstadoDeMexico"
            d="M208.07,212.13c-0.01,0.01-0.02,0.01-0.02,0.02c-0.06,0.08-0.14,0.14-0.22,0.19
		c-0.08,0.08-0.17,0.16-0.25,0.25c0.14,0.05,0.3,0.11,0.49,0.2c0.35,0.13,0.72,0.29,0.91,0.76c0.2,0.49,0.21,1.09,0.22,1.67
		c0.01,0.36,0.01,0.69,0.06,0.96c0.15,0.84,0.32,1.57,0.53,2.23c0.07,0.21,0.13,0.38,0.19,0.49c0.06,0.08,0.11,0.14,0.16,0.17
		c0.04,0.03,0.08,0.04,0.11,0.05c0.06-0.01,0.13-0.05,0.21-0.1c0.05-0.03,0.1-0.07,0.16-0.1c0.09-0.08,0.18-0.17,0.25-0.25
		c0.13-0.13,0.26-0.26,0.37-0.35c0.1-0.07,0.2-0.15,0.31-0.22c0.25-0.17,0.52-0.35,0.65-0.56c0.06-0.12,0.09-0.22,0.11-0.33
		c0.04-0.2,0.1-0.39,0.26-0.55c0.06-0.07,0.13-0.12,0.23-0.16c0.07-0.04,0.16-0.07,0.26-0.1c0.25-0.08,0.52-0.12,0.78-0.15
		c0.05-0.01,0.1-0.01,0.16-0.02c0.03-0.01,0.05-0.01,0.08-0.02c0.33-0.09,0.67-0.18,1.03-0.2c0.14-0.01,0.27,0.01,0.4,0.03
		c0.15,0.02,0.31,0.06,0.5,0.12c0.5,0.17,0.52,0.18,0.8-0.03c0.06-0.05,0.13-0.11,0.19-0.18c0.06-0.08,0.12-0.16,0.18-0.25
		c0.27-0.39,0.58-0.82,1.09-0.9c0.25-0.03,0.47,0.05,0.65,0.19c0.24,0.15,0.42,0.38,0.57,0.59c0.02,0.03,0.05,0.06,0.07,0.09
		c0.02,0.02,0.03,0.03,0.05,0.05c0.11,0.11,0.23,0.18,0.38,0.26c0.06-0.19,0.15-0.36,0.25-0.52c0.05-0.09,0.1-0.19,0.15-0.28
		c0.14-0.27,0.28-0.52,0.36-0.79c0.03-0.09,0.06-0.17,0.08-0.25c0.09-0.32,0.23-0.66,0.63-0.92c0.18-0.12,0.36-0.19,0.53-0.27
		c0.07-0.03,0.13-0.06,0.19-0.08c0.27-0.18,0.42-0.35,0.47-0.58c-0.01-0.14-0.05-0.33-0.07-0.46c-0.1-0.51-0.2-1.04,0.17-1.29
		c0.09-0.06,0.15-0.13,0.21-0.18c0.03-0.03,0.06-0.05,0.09-0.08c0.02-0.2-0.08-0.53-0.19-0.85c-0.03-0.06-0.05-0.13-0.08-0.19
		c-0.22-0.53-0.48-1.16-0.24-1.78c0.02-0.05,0.04-0.1,0.06-0.15c0.08-0.16,0.19-0.29,0.34-0.4c0.04-0.03,0.08-0.06,0.12-0.08
		c0.09-0.05,0.19-0.09,0.29-0.13v0c0.19-0.07,0.39-0.05,0.56-0.04c0.11,0.01,0.24,0.01,0.29-0.01c0,0,0,0,0,0
		c0.01-0.01,0.03-0.07,0.05-0.11c0.04-0.11,0.09-0.24,0.2-0.37c0.05-0.05,0.09-0.1,0.15-0.13c0.01-0.01,0.01-0.01,0.02-0.02
		c0.14-0.13,0.3-0.19,0.43-0.24c0.07-0.03,0.12-0.05,0.16-0.07c0.01-0.03,0.04-0.09,0.07-0.16c0.12-0.28,0.27-0.62,0.6-0.69
		c0.1-0.02,0.3-0.03,0.5,0.15c0.14,0.12,0.17,0.3,0.18,0.46c0.01,0.08,0.01,0.15,0.01,0.22c0,0.02,0,0.03,0,0.05
		c0.02,0.04,0.02,0.05,0.1,0.09c0.1,0.05,0.25,0.13,0.38,0.32c0.02,0.04,0.05,0.07,0.06,0.11c0.04,0.05,0.08,0.1,0.12,0.18
		c0.08,0.17,0.05,0.4,0.02,0.63c-0.01,0.07-0.02,0.14-0.02,0.18c0.02,0.21,0.08,0.28,0.21,0.46c0.06,0.08,0.13,0.18,0.21,0.29
		c0.23,0.36,0.3,0.67,0.3,0.99c0.01,0.08,0.01,0.17,0,0.26c-0.01,0.1-0.03,0.2-0.05,0.3c-0.04,0.27-0.01,0.37,0.04,0.55
		c0.04,0.13,0.08,0.29,0.1,0.52c0,0.01,0,0.01,0,0.02c0.02,0.09,0.02,0.19,0.01,0.3c-0.01,0.1-0.06,0.2-0.13,0.31
		c-0.03,0.06-0.06,0.12-0.1,0.18c0.11,0.11,0.21,0.23,0.3,0.34c0.05,0.06,0.1,0.12,0.14,0.17c0.07,0.07,0.15,0.14,0.22,0.21
		c0.34,0.29,0.75,0.54,1.21,0.74c0.38,0.16,0.4,0.15,0.62,0.03c0.07-0.04,0.15-0.08,0.24-0.13c0.04-0.17,0.11-0.34,0.17-0.49
		c0.04-0.11,0.09-0.22,0.12-0.32c0.02-0.11,0.04-0.23,0.07-0.36l0.01-0.06c0.1-0.56,0.05-1.19,0.01-1.85
		c-0.05-0.71-0.1-1.43,0.02-2.11c0.03-0.2,0.08-0.39,0.16-0.58c0.04-0.09,0.08-0.19,0.12-0.28c0.04-0.09,0.09-0.16,0.12-0.21
		c-0.14-0.22-0.27-0.45-0.4-0.67c-0.1-0.18-0.2-0.35-0.31-0.52c-0.07-0.12-0.08-0.26-0.02-0.38c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0.02-0.05,0.06-0.09,0.1-0.12c0.29-0.22,0.52-0.23,0.7-0.24c0.01,0,0.01,0,0.02,0
		c0.1-0.17,0.22-0.33,0.33-0.49c0.02-0.02,0.03-0.04,0.05-0.07c0.16-0.25,0.39-0.58,0.43-0.77c0,0,0,0,0,0
		c0.01-0.06,0-0.12-0.02-0.19c-0.01-0.01-0.02-0.02-0.02-0.03l-0.1-0.12c-0.06-0.08-0.13-0.18-0.21-0.3
		c-0.02-0.02-0.03-0.05-0.05-0.07c-0.06-0.06-0.12-0.13-0.18-0.19l-0.1-0.1c-0.23-0.23-0.36-0.25-0.64-0.3
		c-0.15-0.03-0.34-0.06-0.56-0.14c0,0,0,0-0.01,0c-0.01,0-0.03-0.01-0.04-0.01c0,0,0,0,0,0c0,0,0,0,0,0
		c-0.07-0.03-0.12-0.05-0.16-0.08c-0.05-0.03-0.09-0.06-0.13-0.09c-0.02-0.02-0.04-0.03-0.06-0.04c-0.03-0.01-0.07-0.01-0.14-0.02
		c-0.01,0-0.02,0-0.03,0c-0.01,0-0.02,0-0.03,0c-0.21,0.03-0.21,0.03-0.32,0.13c-0.04,0.04-0.09,0.08-0.16,0.13
		c-0.04,0.03-0.07,0.06-0.1,0.1c-0.14,0.15-0.37,0.39-0.77,0.34c-0.31-0.05-0.48-0.21-0.57-0.34c-0.14-0.16-0.24-0.41-0.2-0.77
		c0.03-0.25,0.12-0.51,0.2-0.71c0.02-0.09,0.05-0.17,0.07-0.26c0.08-0.29,0.21-0.75,0.21-1.04c-0.02-0.03-0.04-0.06-0.07-0.08
		c-0.24-0.25-0.44-0.19-0.96,0.04c-0.14,0.06-0.27,0.12-0.41,0.17c-0.05,0.02-0.1,0.03-0.15,0.05c-0.09,0.03-0.18,0.06-0.27,0.09
		c-0.14,0.03-0.26,0.05-0.36,0.06c-0.05,0.01-0.09,0.01-0.12,0.02c-0.11,0.04-0.19,0.12-0.3,0.28c-0.1,0.14-0.19,0.29-0.29,0.43
		c-0.05,0.1-0.09,0.19-0.14,0.29c-0.09,0.19-0.18,0.39-0.29,0.57c-0.06,0.15-0.13,0.29-0.24,0.4c-0.01,0.01-0.05,0.03-0.11,0.08
		c-0.02,0.02-0.04,0.04-0.06,0.05c-0.1,0.09-0.78,0.48-0.93,0.52c-0.02,0-0.03,0.01-0.04,0.01c-0.12,0.05-0.23,0.08-0.3,0.07
		c-0.45-0.05-0.64-0.36-0.73-0.69c-0.12-0.26-0.17-0.49-0.21-0.68c-0.05-0.21-0.08-0.37-0.19-0.53c-0.05-0.04-0.11-0.08-0.16-0.12
		c-0.26-0.19-0.55-0.4-0.66-0.75c-0.11-0.36,0.01-0.66,0.15-0.91c0.01-0.02,0.02-0.04,0.03-0.06l0.07-0.14
		c0.13-0.27,0.22-0.45,0.24-0.59c0-0.09-0.01-0.18-0.04-0.26c-0.06-0.11-0.18-0.23-0.38-0.39c-0.16-0.14-0.31-0.15-0.57-0.14
		c-0.28,0.03-0.67,0.08-1.06-0.2c-0.33-0.24-0.56-0.57-0.78-0.9c-0.15-0.23-0.3-0.44-0.46-0.6c-0.04-0.03-0.13-0.08-0.22-0.14
		c-0.07-0.04-0.13-0.07-0.17-0.11c-0.09-0.03-0.19-0.06-0.29-0.08c-0.01,0-0.03,0.01-0.05,0.01c-0.14,0.04-0.28,0.05-0.42,0.07
		c-0.24,0.03-0.32,0.04-0.35,0.08c-0.02,0.1-0.03,0.24-0.03,0.37c0,0.03,0,0.06,0,0.1c0.01,0.22,0.01,0.49-0.14,0.77c0,0,0,0,0,0
		c-0.27,0.51-0.71,0.89-1.14,1.26c-0.24,0.21-0.47,0.4-0.65,0.61c-0.09,0.1-0.17,0.23-0.25,0.37c-0.03,0.05-0.06,0.1-0.09,0.15
		c0.03,0.03,0.07,0.07,0.1,0.1c0.18,0.18,0.37,0.38,0.45,0.63c0.03,0.08,0.05,0.18,0.04,0.28c-0.01,0.07-0.03,0.13-0.05,0.19
		c-0.05,0.12-0.13,0.24-0.24,0.34c-0.14,0.13-0.3,0.18-0.46,0.21c-0.1,0.03-0.18,0.05-0.25,0.07c-0.16,0.03-0.19,0.04-0.24,0.13
		c0,0.04,0,0.09,0,0.12c0,0.12,0.01,0.23-0.01,0.34c-0.01,0.1-0.03,0.19-0.06,0.28c-0.01,0.04-0.01,0.08-0.02,0.12
		c-0.06,0.26-0.16,0.48-0.25,0.69c-0.05,0.12-0.11,0.24-0.15,0.37c-0.23,0.66-0.16,1.26,0.03,2.01c0.01,0.02,0.01,0.04,0.02,0.06
		c0,0,0,0,0,0c0.02,0.08,0.04,0.16,0.07,0.23c0.11,0.36,0.25,0.82-0.14,1.27c-0.26,0.3-0.7,0.46-1.1,0.6
		c-0.18,0.07-0.36,0.13-0.47,0.19c-0.07,0.04-0.13,0.07-0.18,0.1c0,0.07,0,0.16,0,0.27c0.04,0.18,0.07,0.37,0.01,0.55
		c-0.08,0.25-0.34,0.53-0.69,0.88c-0.1,0.11-0.2,0.2-0.26,0.27c-0.45,0.54-0.98,1.05-1.49,1.53l-0.27,0.25
		c-0.08,0.07-0.16,0.14-0.26,0.22c-0.12,0.09-0.24,0.19-0.3,0.27c-0.02,0.03-0.04,0.05-0.07,0.07c-0.03,0.03-0.07,0.06-0.09,0.09
		c-0.01,0.04-0.01,0.08,0,0.15c0,0.01,0,0.02,0,0.04c0,0.01,0.01,0.01,0.01,0.02c0.08,0.16,0.04,0.36-0.1,0.47
		C208.1,212.11,208.09,212.12,208.07,212.13z"
          />
          <path
            id="Puebla"
            d="M243.24,224.17l-0.02,0.08c-0.05,0.17-0.12,0.33-0.18,0.47c-0.16,0.35-0.18,0.44-0.06,0.6c0,0,0,0,0,0.01
		c0.05,0.04,0.11,0.07,0.16,0.11c0.17,0.1,0.35,0.22,0.49,0.41c0.19,0.26,0.29,0.59,0.38,0.9c0.09,0.29,0.2,0.65,0.35,0.84
		c0.02,0.03,0.05,0.05,0.08,0.07c0.04,0.03,0.07,0.05,0.1,0.06c0.01,0,0.01,0,0.02,0c0.02,0,0.03,0,0.05-0.01
		c0.1-0.08,0.24-0.39,0.3-0.54c0.07-0.16,0.14-0.31,0.22-0.44c0.04-0.07,0.08-0.14,0.13-0.2c0.23-0.3,0.54-0.54,0.85-0.73
		c0.26-0.16,0.53-0.29,0.82-0.38c0.12-0.04,0.25-0.06,0.36-0.08c0.49-0.09,0.75-0.01,1.07,0.15c0.19,0.07,0.37,0.15,0.55,0.23
		c0.18,0.08,0.35,0.16,0.51,0.22c0.31,0.08,0.64,0.11,0.91,0.07c0.23-0.04,0.35-0.17,0.55-0.44c0.13-0.16,0.27-0.35,0.48-0.51
		c0.04-0.03,0.08-0.06,0.12-0.08c0.11-0.09,0.24-0.18,0.38-0.24c0.18-0.08,0.34-0.15,0.49-0.21c0.51-0.2,0.68-0.27,0.93-0.91
		c0.03-0.07,0.07-0.12,0.13-0.2c0.01-0.02,0.01-0.04,0.01-0.06c-0.02-0.01-0.03-0.02-0.05-0.03c-0.06-0.05-0.1-0.12-0.13-0.2
		c-0.35-0.19-0.43-0.56-0.45-0.89c-0.02-0.11-0.02-0.22-0.02-0.31c0-0.08,0-0.16-0.02-0.24c-0.01-0.06-0.02-0.12-0.03-0.17
		c-0.02,0-0.03,0-0.05,0.01c-0.04,0.01-0.08,0.03-0.11,0.05l-0.16,0.07c-0.12,0.05-0.23,0.09-0.35,0.14
		c-0.27,0.11-0.53,0.22-0.78,0.29c-0.37,0.14-0.74,0.22-1.11,0.07c-0.34-0.14-0.59-0.44-0.77-0.92c-0.07-0.2-0.12-0.41-0.16-0.62
		c-0.04-0.16-0.07-0.3-0.1-0.42c-0.11-0.33-0.23-0.47-0.52-0.52c-0.18-0.03-0.3,0.01-0.44,0.06c-0.24,0.08-0.65,0.22-1.04-0.31
		c-0.04-0.06-0.1-0.17-0.17-0.32c-0.23-0.48-0.52-1.3-0.47-1.58c0.04-0.2,0.19-0.37,0.6-0.78c0.11-0.11,0.22-0.22,0.28-0.29
		c0.64-0.8,0.58-1.47,0.5-2.4c-0.02-0.23-0.04-0.48-0.05-0.73l0-0.02c-0.04-0.4-0.1-1,0.38-1.31c0.24-0.16,0.6-0.24,0.94-0.26
		c0.11-0.05,0.23-0.08,0.35-0.09c0.19-0.01,0.35,0.04,0.48,0.11c0.12,0.02,0.22,0.05,0.31,0.08c0.15,0.05,0.17,0.05,0.29-0.01
		c0.14-0.07,0.39-0.29,0.51-0.46c0.11-0.16,0.12-0.24,0.12-0.25c0,0-0.04-0.09-0.34-0.18c0,0,0,0-0.01,0
		c-0.13-0.03-0.29-0.05-0.49-0.05c-0.01,0-0.03,0-0.04,0c-0.03,0-0.07,0-0.1,0c-0.4,0.01-0.9,0.03-1.35-0.51
		c-0.35-0.42-0.41-0.5-0.71-0.66c-0.1-0.03-0.21-0.07-0.33-0.14c-0.4-0.22-0.57-0.51-0.63-0.81c-0.08-0.26-0.09-0.53-0.08-0.79
		c0.01-0.19,0.04-0.33,0.06-0.44c0.01-0.05,0.02-0.09,0.02-0.12c-0.04-0.13-0.12-0.18-0.32-0.31c-0.15-0.1-0.35-0.22-0.54-0.42
		c-0.01-0.01-0.02-0.02-0.02-0.03c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c-0.05-0.06-0.07-0.14-0.07-0.22v0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0,0,0,0,0c0-0.04,0.01-0.07,0.03-0.11c0,0,0-0.01,0-0.01
		c0.06-0.16,0.2-0.25,0.38-0.32c0.11-0.05,0.24-0.09,0.39-0.14c0.17-0.06,0.49-0.17,0.56-0.23c0.47-0.48,0.74-1.07,0.73-1.62
		c0-0.03,0-0.07-0.01-0.1c-0.01-0.09-0.03-0.18-0.05-0.27c-0.05-0.24-0.1-0.5-0.1-0.77c0-0.18,0.03-0.34,0.08-0.5
		c0.03-0.11,0.07-0.21,0.12-0.32c0.27-0.56,0.75-1,1.17-1.39c0.12-0.11,0.23-0.22,0.34-0.32c0.05-0.05,0.11-0.11,0.17-0.16
		c0.13-0.12,0.32-0.3,0.43-0.46c0.06-0.1,0.08-0.16,0.09-0.2c0-0.01-0.01-0.03-0.01-0.04c-0.07-0.05-0.27-0.13-0.4-0.18
		c-0.09-0.04-0.19-0.07-0.28-0.12c-0.31-0.14-0.57-0.31-0.82-0.5c-0.1-0.06-0.2-0.13-0.3-0.21c-0.11-0.09-0.22-0.2-0.33-0.31
		c-0.02-0.02-0.05-0.05-0.07-0.07c-0.05-0.05-0.11-0.09-0.16-0.14c-0.07-0.06-0.2-0.16-0.23-0.18c0,0,0,0,0,0
		c-0.02,0-0.14,0.03-0.25,0.05c-0.53,0.12-0.55,0.14-0.71,0.65c-0.01,0.02-0.01,0.04-0.02,0.06c-0.06,0.35-0.15,0.79-0.6,1.03
		c-0.29,0.15-0.54,0.19-0.76,0.11c-0.14-0.05-0.24-0.13-0.32-0.23c-0.02-0.02-0.05-0.04-0.07-0.06c-0.17-0.16-0.23-0.36-0.28-0.52
		c-0.01-0.03-0.02-0.07-0.03-0.11c-0.1,0.02-0.25,0.04-0.4-0.02c-0.1-0.01-0.2-0.03-0.3-0.07c-0.31-0.15-0.46-0.4-0.58-0.6
		c-0.16-0.22-0.27-0.46-0.38-0.71c-0.15-0.32-0.25-0.66-0.26-1.03c0-0.22,0.04-0.45,0.08-0.67c0.07-0.8,0.14-1.1,0.32-1.27
		c0.17-0.17,0.37-0.17,0.5-0.15c0.02,0,0.03-0.01,0.05-0.01c0.16-0.33,0.42-0.37,0.62-0.34c0.41,0.06,0.55,0.42,0.62,0.62
		c0.01,0.01,0.01,0.03,0.02,0.05c0.11-0.05,0.26-0.26,0.46-0.61c0.01-0.02,0.02-0.03,0.03-0.05c0.08-0.17,0.14-0.34,0.16-0.47
		c0.01-0.07,0-0.14-0.02-0.19c-0.07-0.2-0.33-0.38-0.8-0.56c-0.03-0.01-0.05-0.01-0.08-0.02c-0.34-0.09-0.72-0.19-0.98-0.47
		c-0.5-0.56-0.27-1.43-0.08-2.06c-0.02,0-0.04-0.01-0.06-0.02c-0.22-0.05-0.6-0.15-0.85-0.19c0,0.01,0,0.01,0,0.02
		c0,0.07,0,0.14-0.01,0.18c0,0.09,0,0.17-0.01,0.24l-0.02,0.12c-0.03,0.23-0.07,0.49-0.3,0.76c-0.11,0.13-0.26,0.19-0.4,0.24
		c-0.01,0.01-0.03,0.02-0.04,0.03c-0.05,0.04-0.13,0.1-0.21,0.15c-0.01,0.02-0.01,0.04-0.02,0.05c-0.04,0.12-0.04,0.29-0.05,0.47
		c0,0.21-0.01,0.46-0.08,0.69c-0.03,0.11-0.07,0.21-0.12,0.31c-0.04,0.11-0.09,0.22-0.16,0.32c-0.18,0.26-1.22,1.14-1.83,1.24
		c-0.03,0.02-0.06,0.05-0.09,0.08c-0.08,0.1-0.16,0.2-0.23,0.3c-0.02,0.04-0.05,0.09-0.08,0.15c-0.2,0.39-0.35,0.67-0.6,0.79
		c0,0,0,0,0,0c-0.2,0.1-0.41,0.07-0.58,0.05c-0.01,0-0.03,0-0.04-0.01c-0.01,0-0.02,0-0.03,0c-0.07,0.02-0.13,0.03-0.17,0.05
		c-0.03,0.05-0.07,0.13-0.1,0.21c0,0.06,0,0.13,0.01,0.23c0,0.1,0.01,0.17,0.02,0.21c0.02,0.01,0.05,0.01,0.08,0.02
		c0.03,0,0.06,0,0.08,0c0.18,0,0.42,0,0.67,0.13c0.31,0.16,0.4,0.25,0.53,0.59c0,0,0,0,0,0.01v0c0.02,0.06,0.05,0.16,0.07,0.28
		c0.05,0.21,0.12,0.5,0.16,0.7c0.04,0.08,0.06,0.16,0.08,0.24c0.02,0.13-0.02,0.25-0.11,0.34c-0.1,0.1-0.21,0.18-0.33,0.26
		c-0.07,0.13-0.12,0.24-0.17,0.36c-0.01,0.01-0.01,0.03-0.02,0.04c-0.01,0.08-0.02,0.16-0.03,0.24c0,0.03-0.01,0.06-0.02,0.08
		c-0.12,0.29-0.39,0.58-0.65,0.85c-0.08,0.09-0.16,0.17-0.23,0.25c-0.15,0.2-0.3,0.4-0.44,0.6l-0.07,0.1
		c-0.06,0.08-0.11,0.16-0.13,0.19c0.01,0,0.03,0.04,0.05,0.12l0.03,0.13c0.02,0.08,0.05,0.19,0.1,0.31c0.05,0.09,0.1,0.17,0.14,0.23
		c0.11,0.18,0.2,0.33,0.25,0.43c0.05,0.11,0.1,0.23,0.15,0.35c0.08,0.19,0.17,0.38,0.26,0.55c0.11,0.02,0.21,0.03,0.31,0.03
		c0.05-0.01,0.21-0.08,0.3-0.12c0.18-0.08,0.3-0.16,0.45-0.14c0.03,0,0.06,0,0.09,0.01c0.3,0.04,0.47,0.18,0.56,0.3
		c0.15,0.21,0.16,0.47,0.12,0.71c0.52,0.13,1,0.66,1.27,0.96l0.1,0.11c0.04,0.04,0.08,0.08,0.12,0.11c0.13,0.11,0.32,0.28,0.33,0.6
		c0,0.08-0.01,0.18-0.04,0.28c-0.05,0.19-0.2,0.26-0.33,0.29c0.04,0.05,0.08,0.1,0.11,0.14c0.03,0.04,0.05,0.08,0.07,0.12
		c0.01,0,0.02,0,0.03,0c0.03,0,0.05-0.01,0.11-0.03c0.14-0.05,0.39-0.14,0.72,0.02c0.25,0.12,0.39,0.35,0.48,0.52
		c0.03,0.05,0.05,0.09,0.08,0.13c0.05,0.07,0.1,0.16,0.15,0.26c0.03,0.07,0.09,0.17,0.12,0.2c0.02,0,0.1,0,0.15,0
		c0.1,0,0.21-0.01,0.32,0.02c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.05,0.01,0.11,0.04,0.16,0.07c0.38,0.24,0.55,0.6,0.46,0.97
		c-0.01,0.06-0.03,0.12-0.06,0.17c-0.12,0.31-0.39,0.58-0.75,0.72c-0.19,0.08-0.4,0.09-0.6,0.07c-0.2-0.01-0.43-0.06-0.67-0.12
		c-0.28-0.07-0.73-0.17-0.88-0.09c-0.03,0.05-0.04,0.14-0.05,0.33c-0.01,0.18-0.01,0.42-0.12,0.65c-0.1,0.27-0.32,0.52-0.82,0.62
		c-0.11,0.02-0.21,0.03-0.3,0.03c-0.09,0-0.17-0.01-0.24-0.05c-0.21-0.09-0.3-0.27-0.35-0.45c-0.03-0.09-0.04-0.13-0.09-0.17
		c0,0,0,0,0,0c-0.16-0.13-0.35-0.1-0.86,0.46c-0.19,0.21-0.32,0.32-0.45,0.39c-0.23,0.15-0.48,0.23-0.8,0.32
		c-0.02,0.01-0.05,0.01-0.07,0.02c-0.2,0.14-0.46,0.21-0.87-0.02c-0.29-0.16-0.41-0.44-0.51-0.67c-0.03-0.07-0.06-0.15-0.1-0.21
		c-0.13-0.21-0.31-0.36-0.5-0.53c-0.13-0.12-0.27-0.24-0.4-0.38c-0.05-0.05-0.09-0.1-0.14-0.16c-0.22-0.27-0.35-0.56-0.47-0.84
		c-0.12-0.27-0.23-0.52-0.42-0.73c-0.03-0.03-0.07-0.07-0.11-0.11c-0.12-0.09-0.24-0.2-0.34-0.35c-0.08-0.1-0.14-0.2-0.18-0.32
		c-0.08-0.26-0.04-0.45-0.01-0.56c-0.02-0.01-0.04-0.03-0.07-0.05c-0.01-0.01-0.03-0.02-0.05-0.03c-0.16-0.06-0.38-0.12-0.57-0.17
		c-0.02,0-0.04-0.01-0.06-0.01c-0.16-0.03-0.32-0.07-0.47-0.11c-0.06-0.02-0.12-0.03-0.16-0.04c-0.04-0.01-0.07-0.03-0.1-0.05
		c-0.01-0.01-0.02-0.01-0.03-0.02c-0.02,0.03-0.03,0.07-0.05,0.1c-0.05,0.15-0.09,0.3-0.12,0.46c-0.04,0.36,0,0.77,0.04,1.2
		c0.02,0.23,0.04,0.45,0.05,0.67c0.02,0.72,0.02,1.58-0.18,2.47c-0.06,0.31-0.15,0.65-0.31,0.95c0.01,0.04,0.01,0.08,0.02,0.11
		c0.01,0.02,0.01,0.04,0.02,0.06c0.04,0.12,0.1,0.27,0.09,0.45c-0.01,0.3-0.11,0.61-0.26,0.88c-0.01,0.03-0.03,0.06-0.04,0.09
		c-0.02,0.04-0.04,0.07-0.07,0.11c-0.13,0.21-0.27,0.38-0.38,0.51c0,0,0,0,0,0c-0.01,0.01-0.02,0.03-0.04,0.04l-0.09,0.09
		c-0.14,0.14-0.26,0.25-0.35,0.33c0.01,0.02,0.02,0.05,0.03,0.07c0.02,0.03,0.11,0.13,0.18,0.21c0.18,0.16,0.4,0.38,0.48,0.65
		c0.12,0.25,0.14,0.52-0.07,0.76c-0.13,0.14-0.29,0.22-0.47,0.23c-0.19,0.01-0.38-0.07-0.54-0.17c0.05,0.4,0.2,0.86,0.31,1.18
		l0.08,0.23c0.15,0.48,0.34,1.21,0.29,1.71c0.01,0.12,0,0.26-0.1,0.4c-0.16,0.22-0.44,0.23-0.53,0.23c-0.01,0-0.01,0-0.02,0
		c-0.25,0-0.4-0.2-0.54-0.39c-0.06-0.05-0.12-0.1-0.18-0.15c-0.29-0.25-0.73-0.64-0.97-0.55c-0.01,0-0.02,0.01-0.03,0.01
		c-0.13,0.07-0.26,0.2-0.39,0.33c-0.01,0.01-0.03,0.03-0.04,0.04c-0.16,0.17-0.34,0.36-0.56,0.49c-0.37,0.21-0.82,0.33-1.29,0.42
		c0.01,0.1,0.01,0.21,0.01,0.32c-0.01,0.13-0.04,0.23-0.06,0.32c0.01,0.04,0.02,0.07,0.02,0.11c0.03,0.12,0.06,0.25,0.07,0.39
		c0.12,0.02,0.23,0.07,0.32,0.2c0.01,0.01,0.03,0.02,0.05,0.03c0.12,0.08,0.18,0.19,0.21,0.29c0.01,0.01,0.01,0.03,0.02,0.04
		c0.06,0,0.13,0,0.2,0.02c0.06,0.02,0.13,0.05,0.19,0.1c0.2,0.19,0.24,0.41,0.26,0.59c0,0,0,0.01,0,0.01
		c0.01,0.1,0.03,0.19,0.07,0.28c0.02,0.05,0.04,0.07,0.05,0.08c0.03,0,0.06,0.01,0.09,0c0.05,0,0.12-0.01,0.17-0.02
		c0.08-0.01,0.18-0.02,0.28-0.03c0,0,0,0,0,0c0.02,0,0.03,0,0.05,0c0.02,0,0.04,0,0.06,0.01c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0.07,0.02,0.14,0.06,0.19,0.11c0.04,0.05,0.08,0.11,0.09,0.17v0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0.02,0.01,0.04,0.01,0.06c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0.02,0,0.03,0,0.05c-0.13,0.83,0.52,1.41,1.16,1.68
		c0.2,0.09,0.41,0.16,0.63,0.21c0.2,0.05,0.39,0.08,0.56,0.1c0.04,0,0.07,0.01,0.11,0.01c0.01,0,0.01,0,0.02,0
		c0.18,0.01,0.38-0.02,0.59-0.05c0.27-0.04,0.54-0.08,0.82-0.04c0.02,0,0.04,0,0.06,0.01c0.18,0,0.3,0.05,0.47,0.16
		c0.09,0.05,0.17,0.12,0.24,0.18c0.16,0.13,0.5,0.49,0.78,0.86c0.09-0.07,0.2-0.13,0.31-0.14c0.24-0.04,0.44,0.07,0.58,0.15
		c0.07,0.04,0.11,0.06,0.13,0.07c0.1-0.03,0.17-0.12,0.34-0.36c0.02-0.05,0.04-0.09,0.07-0.15c0.13-0.29,0.28-0.62,0.59-0.81
		c0.4-0.24,0.97-0.13,1.43-0.03c0.09,0.02,0.17,0.03,0.24,0.05c0.1,0.01,0.23,0,0.37,0c0.3-0.01,0.62-0.02,0.86,0.06
		c0,0,0.01,0,0.01,0c0.44,0.15,0.57,0.45,0.72,0.79c0.12,0.27,0.29,0.65,0.44,0.69c0.02,0.01,0.06,0.01,0.12,0
		c0.02,0,0.04-0.01,0.07-0.02c0.07-0.02,0.17-0.07,0.3-0.14c0.63-0.38,0.62-0.59,0.31-1.33c-0.08-0.19-0.15-0.36-0.19-0.53
		c-0.1-0.24-0.16-0.49-0.13-0.76c0.05-0.39,0.54-1.37,0.98-1.91c0.11-0.15,0.23-0.26,0.35-0.32c0.11-0.06,0.28-0.12,0.48-0.05
		c0.22-0.13,0.28-0.17,0.34-0.18c0.08-0.04,0.16-0.06,0.24-0.07c0.11-0.02,0.21,0,0.28,0.04c0.2,0.08,0.31,0.25,0.38,0.43
		c0.08,0.16,0.11,0.33,0.1,0.49c0,0.16-0.03,0.32-0.09,0.47C243.25,224.12,243.25,224.15,243.24,224.17z"
          />
          <path
            id="Tabasco"
            d="M289.13,227.89C289.13,227.89,289.13,227.89,289.13,227.89C289.13,227.89,289.13,227.89,289.13,227.89
		c-0.14,0-0.46,0.1-0.67,0.17c-0.13,0.04-0.24,0.08-0.33,0.1c-0.2,0.05-0.38,0.13-0.58,0.21c-0.21,0.08-0.42,0.17-0.66,0.24
		c-0.19,0.05-0.37,0.08-0.53,0.11c-0.09,0.02-0.2,0.03-0.28,0.05c0,0,0,0,0,0c0.11,0.03,0.23,0.09,0.29,0.24
		c0.03,0.05,0.04,0.1,0.05,0.15c0,0.01,0.01,0.02,0.01,0.03c0.01,0.03,0.02,0.05,0.03,0.08c0.04,0.11,0.04,0.24,0.01,0.44
		c0,0.01,0.01,0.03,0.01,0.05c0.06,0.2,0.1,0.35,0.11,0.45c0.02,0.18-0.06,0.31-0.13,0.39c0.03,0.1,0.07,0.24,0.1,0.33l0.03,0.09
		c0.03,0.09,0.05,0.16,0.07,0.21c0.09,0.25,0.08,0.32,0.02,0.61c-0.02,0.09-0.06,0.17-0.12,0.27c-0.06,0.1-0.13,0.23-0.15,0.33
		c-0.03,0.23-0.02,0.29-0.02,0.3c0.01,0.01,0.05,0.06,0.08,0.1c0.04,0.05,0.08,0.1,0.13,0.16c0.05,0.04,0.12,0.09,0.19,0.13
		c0,0,0,0,0,0c0.08,0.05,0.13,0.12,0.16,0.19c0,0,0.01,0.01,0.01,0.01c0.14,0.01,0.33,0.02,0.47,0.17c0.02,0.03,0.05,0.05,0.07,0.08
		c0.08,0.07,0.17,0.16,0.24,0.31c0.12,0.23,0.11,0.45,0.1,0.6c0,0.03,0,0.06,0,0.08c0.02,0.03,0.04,0.05,0.07,0.07
		c0.06-0.01,0.12-0.02,0.18-0.01c0.12-0.03,0.28-0.04,0.43,0.05c0,0,0.11,0.08,0.21,0.19c0.07,0.04,0.13,0.11,0.16,0.18
		c0.12,0.03,0.15,0.04,0.2,0.08c0.15,0.11,0.27,0.19,0.38,0.27c0.07,0.05,0.13,0.09,0.19,0.14c0.05,0.03,0.09,0.05,0.13,0.06
		c0.24,0.11,0.41,0.21,0.61,0.59c0.13,0.25,0.14,0.47,0.14,0.64c0.01,0.04,0.02,0.08,0.03,0.12c0.05,0.21,0.1,0.31,0.2,0.48
		c0.06,0.11,0.13,0.23,0.21,0.4c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0v0c0.15,0.33,0.15,0.49,0.09,0.71
		c-0.01,0.03-0.01,0.07-0.01,0.12c-0.01,0.15-0.04,0.26-0.07,0.34c-0.01,0.26,0.03,0.64,0.31,0.88c0.13-0.1,0.38-0.52,0.49-0.71
		c0.08-0.14,0.15-0.26,0.22-0.36c0.02-0.03,0.04-0.05,0.06-0.08c0.01-0.02,0.02-0.03,0.03-0.05c0.17-0.26,0.32-0.42,0.46-0.56
		c0.08-0.09,0.16-0.17,0.24-0.26c0.07-0.08,0.15-0.16,0.23-0.24c0.01-0.02,0.01-0.04,0.02-0.06c0.04-0.11,0.13-0.2,0.24-0.24
		c0,0,0,0,0.01,0c0.1-0.11,0.21-0.2,0.3-0.27c0.07-0.06,0.12-0.1,0.16-0.14c0.04-0.05,0.07-0.1,0.08-0.15
		c0.01-0.02,0.01-0.05,0.01-0.07c0-0.03-0.04-0.13-0.07-0.18c-0.06-0.14-0.14-0.32-0.13-0.52c0,0,0,0,0,0c0-0.01,0-0.02,0-0.03
		c0.02-0.13,0.09-0.21,0.18-0.32c0.01-0.01,0.02-0.02,0.03-0.03c-0.04-0.07-0.06-0.15-0.06-0.25c0-0.08,0-0.14,0.01-0.2
		c-0.02-0.12-0.01-0.26,0.05-0.4c0.07-0.16,0.21-0.23,0.31-0.27c0.03-0.03,0.05-0.05,0.08-0.07c0.04-0.04,0.08-0.08,0.12-0.12
		c0.05-0.05,0.09-0.09,0.13-0.14c0-0.02,0.01-0.04,0.01-0.07c0.01-0.09,0.02-0.2,0.07-0.34c0.05-0.14,0.16-0.38,0.29-0.6l0.01-0.05
		c0-0.15,0.07-0.29,0.18-0.37c0.09-0.07,0.2-0.09,0.29-0.1c0.12-0.08,0.25-0.14,0.39-0.18c0.03-0.02,0.05-0.03,0.07-0.05
		c0.05-0.05,0.12-0.09,0.2-0.11c0,0,0.01,0,0.01,0c0.48-0.08,0.86,0.13,1.16,0.41c0.01,0.01,0.02,0.01,0.03,0.02
		c0.24,0.16,0.4,0.38,0.54,0.57c0.15,0.19,0.26,0.33,0.4,0.42c0.11,0.05,0.23,0.08,0.35,0.08c0.18,0,0.39-0.04,0.52-0.1
		c0.03-0.02,0.07-0.04,0.1-0.06c0.12-0.09,0.27-0.11,0.41-0.04c0.14,0.07,0.22,0.21,0.21,0.37c-0.01,0.14-0.01,0.29-0.01,0.44
		c0,0.36,0,0.78-0.11,1.13c-0.06,0.19-0.13,0.32-0.18,0.43c-0.09,0.17-0.13,0.26-0.13,0.5c0,0.19,0.03,0.37,0.06,0.57
		c0.04,0.24,0.08,0.48,0.07,0.74c0,0.13-0.03,0.26-0.05,0.39c0,0.02,0,0.04,0,0.06c0,0.12-0.01,0.24-0.01,0.32
		c0.16-0.04,0.41-0.08,0.7,0.09c0.31,0.19,0.38,0.52,0.43,0.74c0.01,0.05,0.02,0.1,0.04,0.15l0.01,0.01c0,0,0,0,0,0
		c0.14,0.38,0.28,0.71,0.54,1.05c0.3,0.4,0.4,0.43,0.42,0.44c0.1,0.01,0.45-0.29,0.59-0.4c0.19-0.16,0.39-0.32,0.58-0.47l0.26-0.21
		c0.29-0.23,0.57-0.48,0.86-0.72c0.46-0.4,0.93-0.8,1.43-1.17c0.23-0.18,0.47-0.35,0.72-0.5c0.05-0.03,0.11-0.05,0.16-0.07
		c0.09-0.05,0.17-0.1,0.24-0.16c0.03-0.1,0.08-0.21,0.17-0.32c0.15-0.16,0.34-0.27,0.56-0.35c0.1-0.04,0.2-0.07,0.32-0.1
		c0.17-0.04,0.35-0.08,0.52-0.11c0.03-0.01,0.06-0.01,0.09-0.02c0.13-0.04,0.3-0.1,0.47-0.16c0.36-0.12,0.63-0.21,0.82-0.25
		c0.08-0.02,0.13-0.03,0.19-0.04c0.24-0.01,0.41,0.08,0.54,0.14c0.04,0.02,0.08,0.04,0.1,0.05c0.01,0,0.01,0,0.02,0
		c0.01,0,0.02,0,0.02,0c-0.04-0.12-0.07-0.3,0.01-0.48c0.09-0.21,0.3-0.34,0.48-0.47c0.16-0.18,0.57-0.58,0.86-0.59
		c0.34-0.02,0.51,0.16,0.61,0.36c0.03,0.04,0.06,0.08,0.07,0.1c0.06,0.09,0.08,0.19,0.09,0.28c0.01-0.01,0.02-0.02,0.04-0.03
		c0.06-0.08,0.16-0.14,0.25-0.16c0.14-0.03,0.29,0.01,0.44,0.06c0.23,0.03,0.46,0.08,0.53,0.1c0.27-0.05,0.46,0.21,0.54,0.32
		c0.08,0.11,0.22,0.3,0.1,0.52c0.01,0.03,0.02,0.07,0.03,0.1c0.04,0.12,0.09,0.26,0.1,0.42c0.02,0.23,0.01,0.4,0,0.53
		c0,0.01,0,0.03,0,0.04c0.04,0.01,0.1,0.03,0.18,0.05c0.16,0.03,0.3,0.08,0.42,0.14c0.2,0.06,0.39,0.16,0.55,0.39
		c0.38,0.54,0.53,1.8,0.37,2.38c-0.04,0.14-0.08,0.24-0.13,0.32c0.03,0.07,0.12,0.11,0.33,0.19c0.1,0.04,0.23,0.07,0.36,0.09
		c0.36,0.07,0.91,0.19,1.02,0.82c0.04,0.21-0.02,0.39-0.06,0.51c0,0.01-0.01,0.03-0.01,0.05c0.02,0.06,0.04,0.1,0.09,0.14
		c0.03,0,0.06,0.01,0.09,0.01c0.08,0.01,0.17,0.01,0.26,0.04c0.1,0.01,0.2,0.05,0.31,0.14c0.16,0.14,0.23,0.34,0.28,0.5
		c0.02,0.05,0.03,0.1,0.05,0.14c0,0,0,0,0,0.01c0.01,0.02,0.02,0.03,0.02,0.05l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0,0,0,0,0v0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0.02,0.09,0.01,0.2-0.04,0.28c-0.07,0.12-0.19,0.19-0.33,0.19c-0.03,0-0.12,0.01-0.13,0.06
		c0,0.01,0,0.02,0,0.04c0.04,0.05,0.12,0.11,0.23,0.14c0.02,0,0.04,0.01,0.06,0.02c0.25,0.05,0.6,0.06,0.89,0.06
		c0.19,0,0.36,0,0.49,0.02c0,0,0.01,0,0.01,0c0.39,0.04,0.8,0.06,1.22,0.08c0.71,0.03,1.44,0.07,2.13,0.2
		c0.27-1.29,0.33-2.64,0.38-3.95c0.03-0.75,0.06-1.53,0.13-2.27l0.09-0.95c-0.1-0.31,0-0.7,0.09-1.08c0.01-0.02,0.01-0.05,0.02-0.08
		c0.01-0.13,0.03-0.24,0.04-0.33c0.01-0.05,0.01-0.11,0.02-0.15c-0.05-0.01-0.13-0.02-0.25-0.04c-0.17-0.02-0.34-0.04-0.5-0.05
		c-0.29-0.03-0.59-0.05-0.89-0.12c-0.11-0.01-0.21-0.03-0.3-0.05c-0.56-0.17-0.99-0.54-1.38-0.9c-0.32-0.3-0.6-0.4-0.95-0.53
		c-0.02,0-0.04-0.01-0.05-0.01c-0.13-0.04-0.25-0.08-0.35-0.14c-0.12-0.05-0.25-0.11-0.39-0.18c-0.5-0.25-0.79-0.32-1.42-0.33
		c-0.09,0-0.17-0.01-0.23-0.01c-0.03,0-0.06,0-0.08,0c-0.02,0.01-0.04,0.01-0.06,0.01c-0.03,0.04-0.07,0.09-0.09,0.12
		c-0.02,0.02-0.03,0.05-0.04,0.09c-0.03,0.11-0.02,0.25-0.01,0.42c0.01,0.25,0.03,0.54-0.1,0.82c-0.28,0.61-0.93,0.49-1.36,0.42
		c-0.04-0.01-0.08-0.01-0.12-0.02c-0.13-0.01-0.27-0.01-0.42-0.01c-0.45,0-0.92-0.01-1.32-0.12c-0.05-0.01-0.1-0.03-0.15-0.05
		c-0.39-0.15-1.32-0.77-1.62-1.16c-0.05-0.07-0.08-0.14-0.14-0.33c0-0.01,0-0.02-0.01-0.02c0,0-0.01,0-0.01-0.01
		c-0.09-0.07-0.18-0.14-0.27-0.23c-0.34-0.35-0.68-0.71-1.07-1.05c-0.03-0.03-0.06-0.05-0.09-0.08c-0.02-0.01-0.03-0.02-0.04-0.04
		c-0.15-0.12-0.25-0.22-0.33-0.36c-0.09-0.14-0.12-0.27-0.12-0.47c0,0,0,0,0,0c-0.02-0.25,0.02-0.51,0.05-0.74
		c0.01-0.08,0.03-0.16,0.03-0.24l0-0.05c0.04-0.36,0.07-0.72,0.11-1.08c0-0.02,0-0.03,0.01-0.05c0.02-0.23,0.05-0.47,0.08-0.7
		c0.01-0.12,0.04-0.26,0.07-0.41c0.05-0.26,0.13-0.61,0.08-0.77c-0.02-0.07-0.03-0.11-0.04-0.14c-0.01,0-0.03,0-0.04,0
		c-0.09,0-0.21-0.01-0.37-0.03c-0.19-0.02-0.4-0.03-0.63-0.04c-0.44-0.01-0.9-0.03-1.27-0.15l-0.03,0c-0.03,0-0.05,0-0.08,0
		c-0.01,0-0.01,0-0.02,0c-0.27,0.05-0.44-0.05-0.53-0.15c-0.09-0.1-0.13-0.24-0.12-0.39c0-0.05,0.01-0.11,0.03-0.17
		c-0.05-0.14-0.08-0.29-0.11-0.43c-0.04-0.17-0.07-0.34-0.13-0.47c-0.03-0.06-0.06-0.12-0.08-0.17c-0.04-0.07-0.08-0.15-0.12-0.23
		c-0.11,0.03-0.22,0.06-0.34,0.1c-0.3,0.09-0.6,0.19-0.93,0.22c-0.15,0.02-0.34-0.01-0.53-0.03c-0.08-0.01-0.2-0.02-0.3-0.03
		c0.06,0.08,0.11,0.18,0.13,0.3c0.05,0.27,0.01,0.47-0.03,0.64c-0.01,0.07-0.03,0.14-0.04,0.22c0,0.01,0,0.04,0,0.07
		c0,0.03,0,0.06,0,0.1c0.02,0.59,0,1.02-0.29,1.22c-0.16,0.1-0.37,0.08-0.49-0.06c-0.27-0.29-0.22-0.77-0.18-1.27
		c0.02-0.18,0.04-0.5,0.03-0.69c-0.12,0.11-0.26,0.24-0.36,0.32c-0.17,0.16-0.32,0.29-0.37,0.33c-0.47,0.33-1.12,0.74-1.93,0.9
		c-0.34,0.07-0.77,0-1.22-0.06c-0.19-0.03-0.4-0.06-0.6-0.08c0.05,0.06,0.08,0.13,0.1,0.21c0.1,0.42-0.66,1.09-0.67,1.1
		c-0.1,0.09-0.24,0.12-0.37,0.08c-0.01,0-0.05-0.02-0.05-0.02c-0.1-0.04-0.19-0.12-0.24-0.21c-0.03-0.06-0.04-0.13-0.04-0.19
		c-0.02,0-0.03,0-0.04,0c-0.15,0-0.29-0.08-0.35-0.22c-0.08-0.16-0.07-0.32-0.07-0.44c0-0.04,0-0.07,0-0.11
		c-0.02-0.04-0.03-0.09-0.03-0.13c0-0.04,0-0.08,0.02-0.12c-1.07-0.16-2.36-0.31-3.52-0.26c-0.39,0.02-0.77,0.05-1.12,0.12
		c-0.39,0.07-2.23,0.8-3.37,1.26C289.23,227.88,289.18,227.89,289.13,227.89z"
          />
          <path
            id="Guanajuato"
            className={`${styles.clickable} ${estado=== "Guanajuato" ? " fill-[#9a3324]" : "fill-[#154734]"}`}
            onClick={(e) => setEstado(e.target.id)}
            d="M204.86,197.72c0.04,0.05,0.09,0.07,0.16,0.09c0.18,0.02,0.53-0.02,1.24-0.32c0.03-0.01,0.05-0.02,0.07-0.03
		c0.11-0.05,0.21-0.09,0.29-0.12c0.06-0.02,0.12-0.04,0.18-0.06c0.27-0.08,0.46-0.09,0.68-0.01c0.32,0.08,0.58,0.27,0.84,0.46
		c0.11,0.08,0.23,0.17,0.36,0.25c0.07,0.05,0.15,0.11,0.24,0.18c0.04,0.03,0.08,0.06,0.12,0.09c0.2,0.11,0.39,0.16,0.56,0.1
		c0.04-0.02,0.08-0.03,0.12-0.06c0.26-0.19,0.52-0.53,0.67-0.73c0.28-0.37,0.51-0.67,0.69-1c0.1-0.22,0.19-0.43,0.25-0.61
		c0.01-0.04,0.03-0.09,0.04-0.15c0.08-0.29,0.2-0.69,0.46-0.93c-0.02-0.02-0.03-0.03-0.04-0.05c-0.09-0.1-0.2-0.19-0.31-0.29
		c-0.13-0.11-0.27-0.23-0.4-0.37c-0.01-0.01-0.02-0.01-0.03-0.02c-0.06-0.04-0.15-0.08-0.22-0.16c-0.07-0.06-0.13-0.16-0.16-0.31
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.03-0.17,0.03-0.31,0.1-0.41c-0.03-0.03-0.07-0.06-0.1-0.08
		c-0.14-0.12-0.32-0.26-0.43-0.48c-0.12-0.25-0.1-0.56-0.09-0.84c-0.02-0.26-0.02-0.53-0.01-0.79c0-0.24,0-0.48-0.01-0.71
		c-0.03-0.31-0.08-0.61-0.2-0.87c-0.11-0.15-0.3-0.29-0.48-0.43c-0.21-0.16-0.44-0.33-0.59-0.54c-0.07-0.09-0.13-0.19-0.16-0.31
		c-0.01-0.06-0.02-0.11-0.02-0.17c0-0.11,0.02-0.23,0.08-0.36c0.14-0.37,0.47-0.8,0.88-1.31c0.1-0.12,0.19-0.24,0.27-0.34
		c0.05-0.08,0.09-0.17,0.13-0.26c0.14-0.28,0.28-0.58,0.51-0.82c0.29-0.3,0.8-0.62,1.32-0.51c0.18,0.04,0.32,0.12,0.43,0.22
		c0.14,0.08,0.24,0.17,0.33,0.24c0.08,0.07,0.13,0.11,0.19,0.13c0.03,0,0.06-0.02,0.11-0.06c0.12-0.08,0.31-0.2,0.62-0.21
		c0.33,0,0.62,0.17,0.87,0.32c0.03,0.02,0.06,0.04,0.09,0.06c0.24,0.09,0.51,0.19,0.59,0.15c0,0,0.09-0.05,0.19-0.43
		c0.01-0.06,0.02-0.13,0.03-0.21c0.01-0.04,0.01-0.07,0.02-0.11c0-0.03,0-0.05,0.01-0.08c0.02-0.31,0.05-0.67,0.23-0.92
		c0.11-0.16,0.26-0.26,0.39-0.33c0,0,0.01-0.01,0.01-0.01c0.17-0.19,0.32-0.36,0.41-0.64c0-0.01,0.01-0.02,0.01-0.03
		c0.04-0.34,0.13-0.83,0.72-1.04c0.38-0.13,0.7,0,0.93,0.1c0.06,0.03,0.12,0.05,0.19,0.07c0.77,0.25,1.07,0.31,1.69-0.16
		c0.04-0.03,0.07-0.05,0.09-0.07c-0.06-0.06-0.12-0.12-0.18-0.2c-0.07-0.09-0.15-0.2-0.21-0.34c-0.16-0.37-0.14-0.73-0.13-1.05
		c0-0.04,0-0.09,0.01-0.13l0-0.1c0,0,0,0,0,0c0-0.16,0-0.27-0.03-0.36c-0.02-0.02-0.03-0.03-0.04-0.05c-0.05-0.05-0.1-0.12-0.22-0.2
		c-0.15-0.11-0.33-0.19-0.52-0.27l-0.19-0.09c-0.73-0.35-1.39-0.7-2-1.05c-0.7-0.37-1.39-0.78-2.01-1.31
		c-0.18-0.15-0.43-0.31-0.72-0.44c-0.1-0.03-0.21-0.04-0.34-0.07c-0.11-0.02-0.23-0.04-0.37-0.07l-0.15-0.03
		c-0.28-0.06-0.41-0.08-0.52-0.02c-0.04,0.03-0.07,0.09-0.11,0.22c-0.01,0.01-0.01,0.03-0.02,0.04c-0.04,0.13-0.09,0.28-0.23,0.41
		c-0.01,0.01-0.02,0.02-0.02,0.02c-0.04,0.04-0.08,0.08-0.14,0.12c-0.17,0.12-0.35,0.16-0.48,0.18c-0.07,0.01-0.13,0.02-0.16,0.04
		c-0.03,0.03-0.05,0.07-0.09,0.16c-0.07,0.17-0.2,0.45-0.59,0.59c-0.15,0.05-0.31,0.06-0.46,0.04c-0.59-0.04-1.19-0.46-1.63-0.8
		c-0.66-0.48-1.23-1.08-1.7-1.58c-0.02-0.02-0.04-0.05-0.06-0.07l-0.06-0.06c-0.12-0.12-0.23-0.27-0.34-0.41
		c-0.3-0.38-0.59-0.73-0.97-0.89c-0.32-0.13-0.7-0.18-1.08-0.21c-0.02,0-0.03,0-0.05,0c-0.3-0.02-0.6-0.05-0.88-0.11
		c-0.16-0.03-0.3-0.09-0.42-0.16c-0.45-0.21-0.73-0.53-1.02-0.87c-0.11-0.13-0.23-0.27-0.36-0.41c-0.04-0.02-0.09-0.04-0.13-0.05
		c-0.05,0.01-0.13,0.03-0.26,0.07c-0.11,0.03-0.21,0.07-0.32,0.09c-0.04,0.01-0.09,0.02-0.13,0.03c-0.28,0.04-0.56,0.03-0.85-0.02
		c-0.24-0.04-0.48-0.12-0.7-0.23c-0.01,0.01-0.02,0.01-0.03,0.02c-0.15,0.12-0.27,0.22-0.35,0.32c-0.06,0.28,0.04,0.67,0.14,1.03
		c0.08,0.28,0.09,0.52,0.06,0.73c0,0.3-0.12,0.64-0.5,1.06c-0.11,0.12-0.22,0.22-0.32,0.32c-0.16,0.15-0.25,0.24-0.3,0.35
		c-0.01,0.03-0.02,0.06-0.03,0.08c-0.02,0.07-0.02,0.16,0,0.28c0.01,0.05,0.01,0.11,0.02,0.16c0.04,0.18,0.12,0.38,0.19,0.58
		c0.15,0.4,0.3,0.81,0.28,1.26c-0.05,1.28-1.63,2-2.57,2.43l-0.1,0.05c-0.1,0.05-0.22,0.09-0.33,0.13c-0.14,0.05-0.27,0.1-0.37,0.15
		c0,0-0.01,0-0.01,0.01c0,0,0,0,0,0c-0.06,0.04-0.11,0.07-0.15,0.09c-0.02,0.1-0.06,0.23-0.17,0.4c-0.23,0.36-0.53,0.68-0.82,0.99
		l-0.48,0.51c-0.66,0.7-1.28,1.37-1.77,2.15c-0.07,0.11-0.12,0.21-0.16,0.29c-0.04,0.15-0.05,0.3-0.01,0.43c0,0.01,0,0.01,0.01,0.02
		c0.04,0.11,0.1,0.23,0.19,0.39c0.02,0.03,0.04,0.07,0.06,0.11c0.04,0.08,0.09,0.15,0.14,0.23c0.22,0.37,0.48,0.79,0.56,1.22
		c0.06,0.28,0.03,0.57-0.16,0.86c-0.22,0.34-0.49,0.58-0.75,0.81c-0.29,0.26-0.54,0.48-0.7,0.82c-0.03,0.08-0.06,0.17-0.09,0.26
		c0,0.03-0.01,0.07-0.01,0.1c0.09,0.02,0.16,0.04,0.22,0.06c0.1,0.04,0.13,0.04,0.17,0.03c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0h0c0.05-0.01,0.1-0.01,0.15,0c0,0,0,0,0,0c0.08,0.01,0.15,0.05,0.21,0.11c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0.01c0,0,0,0,0,0c0.09,0.11,0.12,0.25,0.07,0.38c0,0.01-0.01,0.03-0.01,0.04
		c-0.02,0.06-0.03,0.14,0.01,0.19c0.02,0.02,0.04,0.03,0.06,0.04c-0.01-0.01-0.01-0.04-0.02-0.08c0,0,0,0,0-0.01c0,0,0,0,0,0v0
		c0,0,0,0,0,0c0,0,0,0,0,0c-0.01-0.04-0.01-0.08,0-0.13c0,0,0,0,0,0v0c0,0,0,0,0,0v0c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.02-0.09,0.07-0.17,0.15-0.23c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.02-0.01,0.04-0.02,0.06-0.03
		c0.03-0.01,0.06-0.02,0.1-0.02h0c0,0,0,0,0,0c0,0,0,0,0,0h0c0.14-0.02,0.29,0.06,0.37,0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0.01,0.01,0.02,0.01,0.03c0.02,0.05,0.04,0.1,0.06,0.15c0.02,0.08,0.04,0.16,0.05,0.21c0.02,0.12,0.01,0.27,0,0.44
		c-0.01,0.12-0.01,0.24-0.01,0.34c0,0.07,0.01,0.17,0.01,0.28c0,0.09,0,0.24,0.01,0.38c0.06,0.34,0.14,0.44,0.26,0.47
		c0.06,0,0.14-0.02,0.27-0.06c0.15-0.04,0.32-0.08,0.51-0.09c0.13-0.01,0.24,0.05,0.31,0.15l0.04,0.05c0.1-0.06,0.25-0.1,0.36-0.07
		c0.07,0.02,0.14,0.05,0.2,0.07c0.21,0.01,0.4,0.06,0.56,0.1c0.29,0.08,0.41,0.09,0.53,0.02c0.07-0.05,0.14-0.14,0.22-0.25
		c0.05-0.11,0.1-0.23,0.15-0.35c0.11-0.28,0.22-0.57,0.43-0.76c0.31-0.29,1.84-0.42,2.21-0.12c0.21,0.17,0.37,0.77,0.47,1.23
		c0.11,0.33,0.21,0.69,0.19,0.98c-0.01,0.26-0.08,0.48-0.14,0.67c-0.01,0.04-0.02,0.08-0.03,0.12c-0.02,0.26-0.01,0.45,0.07,0.6
		c0.02,0.03,0.05,0.05,0.07,0.08c0.2,0.21,0.54,0.44,1.16,0.28c0.12-0.04,0.24-0.1,0.39-0.17c0.14-0.07,0.28-0.13,0.43-0.19
		c0.22-0.12,0.47-0.22,0.75-0.23c0.2,0,0.4,0.05,0.59,0.11c0.26,0.06,0.52,0.15,0.76,0.23c0.14,0.05,0.28,0.09,0.4,0.13
		c0.42,0.09,0.91,0.23,1.24,0.57c0.11,0.12,0.21,0.26,0.27,0.44c0.01,0.04,0.02,0.08,0.03,0.12c0.03,0.22-0.03,0.43-0.08,0.61
		C204.89,197.57,204.86,197.67,204.86,197.72z"
          />
          <path
            id="Zacatecas"
            d="M194.93,167.74c0.03,0.06,0.11,0.16,0.2,0.27c0.13,0.16,0.22,0.28,0.28,0.38c0.02,0,0.05,0.01,0.08,0.01
		c0.15,0.02,0.36,0.04,0.62,0.19c0.45,0.25,0.86,0.6,1.26,0.94c0.18,0.15,0.36,0.31,0.54,0.44c0.1,0.08,0.22,0.18,0.33,0.28
		c0.05,0.04,0.09,0.08,0.14,0.13c0.15,0.11,0.29,0.22,0.44,0.33c0.01,0.01,0.02,0.01,0.03,0.01c0.05,0.02,0.08,0.05,0.12,0.07
		c0.44-0.49,0.9-0.95,1.35-1.4l0.05-0.05c0.09-0.09,0.2-0.18,0.32-0.29c0.1-0.09,0.23-0.2,0.35-0.32c0.07-0.09,0.14-0.18,0.19-0.27
		c0.13-0.22,0.13-0.49,0.1-0.81c0,0,0-0.01,0-0.01c-0.03-0.19-0.05-0.38-0.05-0.54c0-0.22-0.02-0.47-0.04-0.74
		c-0.01-0.2-0.03-0.4-0.04-0.61c-0.05-0.69-0.06-1.38,0.18-1.79c0.13-0.21,0.31-0.33,0.47-0.43c0.01-0.01,0.02-0.02,0.03-0.02
		c0.24-0.22,0.37-0.33,0.31-0.71c-0.04-0.24-0.13-0.45-0.27-0.63c-0.26-0.29-0.62-0.51-0.96-0.64c-0.16-0.05-0.32-0.09-0.49-0.1
		c-0.14-0.02-0.28-0.02-0.41,0c-0.34,0.04-0.64,0.19-0.92,0.39c-0.17,0.12-0.33,0.28-0.51,0.45c-0.13,0.13-0.23,0.25-0.32,0.36
		c-0.12,0.14-0.24,0.29-0.39,0.4c-0.18,0.17-0.32,0.22-0.67,0.23c-0.47,0-1.26-0.32-1.56-0.49c0,0,0,0,0,0l-0.03-0.02
		c-0.06-0.03-0.1-0.05-0.13-0.07c-0.11-0.05-0.17-0.09-0.22-0.16c-0.1-0.09-0.16-0.18-0.22-0.34c-0.1-0.18-0.16-0.37-0.21-0.56
		c-0.02-0.06-0.03-0.12-0.05-0.17c-0.15-0.34-0.33-0.66-0.53-0.97c-0.12-0.16-0.25-0.31-0.39-0.48l-0.38-0.46
		c-0.33-0.4-0.63-0.77-0.9-1.15c-0.35-0.44-0.63-0.9-0.81-1.59c-0.05-0.18-0.14-0.38-0.25-0.6c-0.07-0.16-0.15-0.32-0.21-0.48
		c-0.14-0.29-0.24-0.55-0.21-0.79c0.04-0.31,0.25-0.55,0.43-0.75c0.1-0.12,0.19-0.22,0.24-0.31c0.08-0.32-0.07-0.56-0.38-0.99
		l-0.07-0.09c-0.01-0.01-0.02-0.03-0.03-0.04c-0.08-0.09-0.15-0.2-0.2-0.32c-0.22-0.42-0.12-0.66,0.3-1.19
		c0.1-0.13,0.22-0.23,0.32-0.32c0.2-0.23,0.3-0.41,0.42-0.71c-0.01-0.19,0.03-0.35,0.12-0.46c0.07-0.11,0.21-0.2,0.44-0.23
		c0.33-0.06,0.53,0.13,0.64,0.28c0.02,0.02,0.03,0.03,0.05,0.05c0.04,0.04,0.11,0.12,0.14,0.13c0.01,0,0.02,0.01,0.03,0.01
		c0.07-0.01,0.16-0.02,0.21-0.03l0.06-0.01c0.68-0.11,1.08-0.57,1.54-1.1c0.14-0.16,0.28-0.32,0.43-0.47
		c0.39-0.4,1.23-1.13,1.9-1.31c0.2-0.05,0.35-0.06,0.46-0.07c0.15-0.01,0.16-0.01,0.27-0.13c0.03-0.03,0.05-0.07,0.08-0.1
		c0.02-0.04,0.04-0.08,0.06-0.13c0.1-0.22,0.23-0.49,0.49-0.71c0.19-0.15,0.4-0.28,0.62-0.4c0.17-0.11,0.34-0.21,0.51-0.31
		c0.51-0.31,1-0.61,1.49-0.98c0.31-0.24,0.63-0.48,0.93-0.74c0.37-0.3,0.75-0.64,1.16-1.01c0.28-0.25,0.59-0.49,0.88-0.72
		c0.04-0.03,0.08-0.06,0.12-0.1c0.13-0.11,0.26-0.21,0.39-0.32c0.78-0.62,1.3-1.41,1.84-2.25c0.29-0.44,0.59-0.9,0.93-1.33
		c0.1-0.13,0.21-0.25,0.32-0.37c0.17-0.19,0.35-0.36,0.55-0.52c0.15-0.12,0.33-0.24,0.51-0.36c0.36-0.28,0.6-0.58,0.88-0.93
		c0.04-0.04,0.07-0.09,0.11-0.13c-0.04-0.06-0.1-0.14-0.13-0.15c-0.08-0.03-0.08-0.04-0.23,0.06c-0.13,0.08-0.3,0.18-0.55,0.2
		c-0.56,0.04-1.09-0.33-1.52-0.79c-0.24-0.23-0.43-0.49-0.62-0.76c-0.01-0.01-0.01-0.02-0.02-0.03c-0.13-0.18-0.27-0.4-0.41-0.64
		c-0.13-0.21-0.27-0.44-0.42-0.65c-0.13-0.18-0.27-0.35-0.43-0.5c-0.08-0.08-0.13-0.11-0.16-0.12c-0.01,0-0.01,0-0.02,0
		c-0.03,0.01-0.11,0.07-0.16,0.11c-0.18,0.14-0.52,0.39-0.96,0.2c-0.13-0.06-0.22-0.14-0.28-0.23c-0.04-0.04-0.07-0.08-0.09-0.12
		c-0.04,0.03-0.07,0.07-0.12,0.1c-0.35,0.23-0.66,0.36-1.02,0.42c-1.02,0.18-1.69,0.03-1.99-0.46c-0.2-0.32-0.16-0.58-0.07-0.79
		c0.01-0.03,0.02-0.06,0.03-0.09c0.04-0.14,0.09-0.25,0.13-0.34c0.01-0.01,0.01-0.03,0.02-0.04c-0.04-0.02-0.11-0.06-0.21-0.11
		c-0.17-0.08-0.34-0.14-0.5-0.19c-0.43-0.15-0.92-0.31-1.22-0.84c-0.12-0.22-0.19-0.4-0.26-0.57c-0.13-0.33-0.2-0.51-0.52-0.7
		c-0.06-0.03-0.11-0.07-0.17-0.1c-0.07-0.04-0.15-0.08-0.23-0.12l-0.09-0.05c-0.4-0.2-0.82-0.39-1.24-0.57
		c-0.04-0.02-0.08-0.03-0.12-0.05c-0.19-0.08-0.38-0.16-0.56-0.24c-0.03-0.01-0.07-0.03-0.1-0.05l-0.13-0.05
		c-0.21-0.09-0.43-0.2-0.65-0.31c-0.52-0.25-1.05-0.51-1.55-0.6c-0.5-0.08-1.09-0.04-1.66,0c-0.28,0.02-0.55,0.04-0.81,0.05
		c-0.04,0-0.08,0-0.12,0c-0.02,0-0.06,0-0.09,0c-0.17,0-0.35-0.01-0.53-0.01c-0.43-0.01-0.88-0.03-1.25,0.04l-0.16,0.03
		c0,0.03,0,0.06-0.01,0.09c0.15,0.3,0.3,0.6,0.46,0.9c0.31,0.61,0.64,1.23,0.91,1.88c0.13,0.22,0.26,0.46,0.32,0.72
		c0.17,0.8-0.02,1.83-0.2,2.75c-0.07,0.36-0.13,0.7-0.17,0.99c-0.01,0.09-0.02,0.2-0.03,0.32c-0.01,0.15-0.02,0.31-0.04,0.46
		c-0.05,0.7-0.1,0.85-0.26,0.96c-0.11,0.08-0.22,0.1-0.45,0.07c-0.34,0.06-0.81-0.02-1.36-0.13c-0.16-0.03-0.3-0.06-0.41-0.08
		c-0.3-0.04-0.52-0.02-0.77,0.02c-0.1,0.08-0.2,0.16-0.34,0.17c-0.23,0.02-0.5-0.03-0.76-0.09c-0.7-0.06-1.41-0.22-2.1-0.38
		c-0.26-0.06-0.52-0.12-0.78-0.17c-0.41-0.07-0.81-0.15-1.22-0.22c-0.11-0.02-0.22-0.04-0.32-0.06c-0.47-0.09-0.78-0.15-1.19-0.01
		c-0.05,0.02-0.11,0.04-0.16,0.06c-0.17,0.09-0.34,0.19-0.5,0.29c-0.13,0.08-0.27,0.16-0.39,0.23c-0.07,0.04-0.15,0.08-0.24,0.12
		c-0.11,0.06-0.21,0.13-0.32,0.18c-0.53,0.29-1.11,0.6-1.7,0.89c-0.05,0.02-0.13,0.06-0.23,0.1c-0.05,0.02-0.11,0.05-0.18,0.07
		c-0.07,0.06-0.12,0.12-0.14,0.18c0,0.03,0.01,0.06,0.01,0.08c0.03,0.18,0.05,0.35,0.02,0.5c0,0.03-0.01,0.05-0.01,0.08
		c0,0.06-0.01,0.13-0.03,0.2c0,0.01,0,0.02,0,0.03c-0.01,0.1-0.03,0.33-0.28,0.42c-0.21,0.18-0.55,0.28-0.84,0.36
		c-0.1,0.03-0.19,0.06-0.26,0.08c-0.18,0.07-0.37,0.13-0.56,0.19c-0.35,0.11-0.71,0.23-0.99,0.39c-0.25,0.14-0.26,0.2-0.27,0.57
		c-0.01,0.16-0.01,0.34-0.04,0.54c-0.01,0.08-0.03,0.16-0.05,0.24c-0.01,0.07-0.02,0.14-0.03,0.19c-0.05,0.22-0.11,0.41-0.18,0.6
		c-0.08,0.24-0.15,0.44-0.17,0.66c-0.03,0.4-0.02,0.98,0.03,1.2c0,0,0,0,0,0c0,0,0,0,0,0c0.02,0.06,0.07,0.12,0.16,0.21
		c0.12,0.13,0.27,0.29,0.34,0.54c0.03,0.11,0.05,0.2,0.06,0.3c0.01,0.07,0.01,0.14-0.01,0.21c-0.02,0.12-0.06,0.22-0.12,0.31
		c-0.21,0.31-0.6,0.35-0.9,0.37c-0.14,0.01-0.31,0.03-0.38,0.07c-0.06,0.03-0.07,0.06-0.13,0.21c-0.05,0.13-0.1,0.3-0.24,0.46
		c-0.23,0.28-0.53,0.38-0.78,0.48c-0.13,0.05-0.25,0.09-0.35,0.15h0c-0.1,0.06-0.19,0.13-0.26,0.21c-0.07,0.07-0.14,0.15-0.2,0.23
		c-0.34,0.49-0.39,1.14-0.44,1.82c-0.03,0.42-0.06,0.85-0.17,1.25c-0.02,0.11-0.04,0.21-0.07,0.32c-0.14,0.47-0.32,0.63-0.56,0.78
		c-0.05,0.05-0.11,0.09-0.17,0.14c-0.55,0.39-0.71,0.81-0.86,1.53c-0.19,0.93-0.34,1.89-0.48,2.81c-0.07,0.48-0.15,0.96-0.23,1.43
		c-0.16,0.98-0.33,1.96-0.5,2.94l-0.02,0.11c-0.14,0.83-0.41,1.37-0.78,2.02c-0.03,0.06-0.06,0.12-0.09,0.18
		c0.03-0.01,0.07-0.02,0.1-0.04c0.12-0.04,0.25-0.09,0.37-0.14c0.47-0.18,1.01-0.39,1.53-0.43c0.23-0.02,0.46,0,0.67,0.02
		c0.12,0.01,0.23,0.02,0.34,0.02c0.09,0,0.18,0,0.25-0.01c0,0,0.01,0,0.01,0c0.14-0.01,0.24-0.03,0.32-0.06
		c0.19-0.09,0.24-0.29,0.36-0.74l0.03-0.12c0.01-0.03,0.02-0.06,0.03-0.09c0.02-0.06,0.03-0.13,0.05-0.2c0,0,0,0,0,0l0.02-0.07
		c0.03-0.12,0.07-0.24,0.07-0.27c0,0.02-0.09-0.06-0.17-0.13l-0.05-0.04c-0.05-0.05-0.13-0.11-0.22-0.17
		c-0.33-0.24-0.67-0.49-0.72-0.82c-0.05-0.32,0.13-0.62,0.32-0.9c0.07-0.11,0.17-0.27,0.19-0.34c0-0.01,0-0.02,0-0.02
		c0.03-0.2,0.01-0.44-0.01-0.69c-0.01-0.18-0.03-0.35-0.03-0.51c0-0.04,0.01-0.08,0.02-0.12c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0-0.01c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.02-0.03,0.05-0.05,0.08-0.08
		c0.09-0.07,0.21-0.09,0.32-0.06c0.67,0.17,1.41,0.42,2.09,0.71c0.03,0.01,0.06,0.03,0.09,0.04c0.03,0.01,0.05,0.01,0.07,0.02
		c0.15,0.03,0.31,0.08,0.47,0.31c0.08,0.11,0.43,1.12,0.29,1.43c-0.03,0.07-0.08,0.14-0.13,0.19c-0.18,0.22-0.47,0.32-0.72,0.39
		c-0.08,0.02-0.2,0.06-0.27,0.1c-0.02,0.02-0.03,0.03-0.05,0.05c0.01,0.06,0.07,0.21,0.1,0.29c0.11,0.28,0.26,0.65,0.02,1
		c-0.09,0.13-0.22,0.2-0.34,0.24c-0.06,0.04-0.12,0.07-0.18,0.1c0.01,0.13,0.01,0.27,0,0.42c-0.01,0.1-0.01,0.22-0.02,0.36l0,0.24
		c0,0.15,0,0.3-0.01,0.46c0.1-0.29,0.19-0.58,0.29-0.87c0.1-0.31,0.2-0.61,0.3-0.91c0,0,0,0,0,0l0.04-0.13
		c0.01-0.04,0.03-0.08,0.04-0.12c0.1-0.31,0.21-0.65,0.37-0.93c0.14-0.28,0.29-0.46,0.47-0.54c0.26-0.12,0.56-0.07,0.82,0.03
		c0.07,0.02,0.14,0.04,0.19,0.07l0.11,0.05c0.06,0.03,0.19,0.1,0.26,0.21c0,0,0.01,0.01,0.01,0.01c0.06,0.06,0.09,0.13,0.11,0.21
		c0,0.02,0.01,0.04,0.01,0.06c0.02,0.03,0.04,0.07,0.07,0.1l0.06,0.02c0.12,0.04,0.21,0.14,0.24,0.27c0,0.01,0.01,0.03,0.01,0.04
		c0,0.01,0,0.02,0.01,0.03c0.01,0.05,0.01,0.1,0,0.15c-0.02,0.2-0.12,0.41-0.22,0.6c-0.01,0.02-0.02,0.05-0.04,0.07
		c-0.02,0.04-0.04,0.08-0.05,0.11c0,0,0,0,0,0.01c-0.03,0.07-0.06,0.15-0.09,0.22c-0.18,0.44-0.33,0.87-0.47,1.31
		c-0.08,0.26-0.14,0.52-0.19,0.77c-0.03,0.18-0.06,0.36-0.08,0.55c-0.02,0.16-0.04,0.32-0.07,0.49c-0.02,0.11-0.05,0.22-0.09,0.32
		c-0.02,0.08-0.04,0.17-0.04,0.25c-0.01,0.11-0.02,0.19-0.03,0.25c0.03,0.07,0.06,0.14,0.09,0.23c0.14,0.37,0.16,0.41,0.83,0.19
		c0.05-0.07,0.13-0.15,0.26-0.2c0.14-0.06,0.28-0.04,0.37-0.03c0.24-0.11,0.43-0.27,0.58-0.55c0.07-0.13,0.15-0.25,0.23-0.37
		c0.14-0.25,0.3-0.49,0.55-0.78c0.16-0.18,0.29-0.34,0.39-0.51c0.09-0.17,0.14-0.34,0.16-0.53c0.01-0.16-0.06-0.28-0.17-0.47
		c-0.05-0.09-0.11-0.19-0.16-0.3c-0.05-0.08-0.09-0.17-0.08-0.29c0-0.06,0.02-0.12,0.03-0.18c0.06-0.25,0.25-0.49,0.42-0.72
		c0.06-0.07,0.11-0.14,0.13-0.18c0.03-0.05,0.07-0.09,0.12-0.12c0.06-0.04,0.14-0.06,0.22-0.06c0.14,0,0.26,0.08,0.33,0.2
		c0.01,0.02,0.03,0.04,0.04,0.06c0.15,0.21,0.44,0.44,0.69,0.64c0.15,0.12,0.29,0.23,0.4,0.34c0,0,0,0,0,0
		c0.01,0.01,0.03,0.03,0.04,0.04c0.06,0.05,0.12,0.1,0.19,0.15c0.23,0.18,0.49,0.39,0.63,0.63c0.06,0.1,0.1,0.19,0.12,0.28
		c0.06,0.14,0.06,0.27,0.02,0.43c0.11-0.01,0.22,0.01,0.33,0.05c0.24,0.05,0.42,0.22,0.5,0.47l0.01,0c0,0,0,0,0.01,0
		c0.31-0.07,0.49,0.04,0.59,0.14c0.1,0.1,0.2,0.3,0.11,0.62l-0.09,0.28c0,0,0,0,0,0c-0.05,0.18-0.14,0.33-0.24,0.46
		c-0.1,0.13-0.22,0.25-0.32,0.36c-0.12,0.12-0.24,0.24-0.3,0.35c-0.01,0.02,0,0.04,0.01,0.06c0,0,0.01,0.01,0.01,0.01
		c0.06,0.07,0.15,0.17,0.2,0.31c0.06,0.13,0.1,0.3,0.05,0.5c-0.04,0.19-0.2,0.3-0.46,0.47c-0.07,0.05-0.17,0.11-0.23,0.16
		c-0.02,0.04-0.03,0.09-0.05,0.15c-0.01,0.04-0.03,0.08-0.04,0.12c-0.02,0.08-0.05,0.17-0.1,0.27l-0.05,0.1
		c-0.17,0.32-0.29,0.46-0.64,0.54c-0.01,0-0.02,0.01-0.03,0.01c-0.49,0.15-0.8-0.02-1.03-0.15c-0.03-0.01-0.05-0.03-0.07-0.04
		c-0.11-0.05-0.18-0.08-0.27-0.08c0,0,0,0,0,0c-0.12,0.01-0.48,0.21-0.62,0.29l-0.11,0.06c-0.26,0.15-0.35,0.28-0.49,0.5
		c-0.06,0.09-0.12,0.18-0.19,0.27c-0.04,0.05-0.08,0.11-0.13,0.16c0,0,0,0,0,0c0,0,0,0-0.01,0.01c-0.19,0.22-0.5,0.38-0.8,0.53
		c-0.16,0.08-0.41,0.21-0.5,0.29c0.02,0.04,0.05,0.09,0.07,0.16c0.05,0.08,0.1,0.15,0.12,0.24c0.05,0.17,0.05,0.32,0.04,0.45
		c0,0.04,0,0.09,0,0.14c0,0.01-0.04,1.33-0.22,1.72c-0.24,0.53-1.33,1.4-1.76,1.6c0,0,0,0-0.01,0c-0.03,0.01-0.05,0.03-0.07,0.04
		c-0.04,0.02-0.05,0.03-0.06,0.04c-0.04,0.03-0.08,0.05-0.13,0.07c-0.08,0.03-0.19,0.05-0.3,0c-0.04-0.01-0.08-0.03-0.11-0.06
		c-0.02-0.01-0.05-0.04-0.14-0.08c0.05,0.5,0.01,1.01-0.19,1.57c-0.01,0.03-0.03,0.07-0.04,0.11c-0.01,0.02-0.01,0.04-0.02,0.05
		c-0.04,0.11-0.13,0.27-0.22,0.44c-0.07,0.13-0.19,0.35-0.27,0.53c0.15-0.14,0.36-0.32,0.65-0.33c0.12,0,0.23,0.01,0.32,0.04
		c0.44,0.12,0.56,0.5,0.62,0.72c0,0.01,0.01,0.02,0.01,0.04c0.01,0.01,0.01,0.02,0.02,0.03c0.02,0,0.16,0,0.25,0
		c0.15,0,0.32,0,0.48,0.03c0.01,0,0.01,0,0.02,0c0.49,0.1,0.98,0.3,1.46,0.53c0.23,0.11,0.46,0.22,0.69,0.33
		c0.33,0.16,0.66,0.33,0.98,0.46c0.68,0.29,1.35,0.57,2.03,0.85c-0.01-0.07-0.03-0.15-0.04-0.22c-0.09-0.44-0.18-0.9-0.07-1.39
		c0.02-0.09,0.04-0.18,0.08-0.29c0.06-0.38,0.18-0.72,0.42-0.94c0.08-0.08,0.19-0.16,0.34-0.21c0.17-0.1,0.35-0.15,0.57-0.11
		c0.21,0.03,0.34,0.13,0.43,0.23c0.05,0.04,0.09,0.09,0.12,0.12c0.03,0.02,0.06-0.02,0.1-0.04c0.03-0.03,0.05-0.06,0.08-0.09
		c0.11-0.13,0.25-0.29,0.47-0.37c0.28-0.09,0.55-0.03,0.79,0.02c0.08,0.02,0.16,0.04,0.23,0.04c0.01,0,0.01-0.01,0.02-0.01
		c0.08-0.06,0.2-0.13,0.38-0.2c0.1-0.04,0.2-0.04,0.28-0.03c0.13-0.08,0.27-0.17,0.44-0.24c0.13-0.05,0.24-0.07,0.33-0.08
		c0.04-0.02,0.07-0.03,0.11-0.04c0.02-0.01,0.04-0.01,0.05-0.02c0,0,0.02-0.02,0.11-0.17c0.03-0.05,0.06-0.11,0.09-0.17
		c0.02-0.06,0.05-0.13,0.07-0.18l0.06-0.14c0.03-0.08,0.06-0.17,0.09-0.25l0.06-0.16c0.18-0.49,0.18-0.54-0.02-0.71
		c-0.04-0.04-0.09-0.07-0.14-0.1c-0.06-0.02-0.12-0.05-0.18-0.07c-0.32-0.13-0.67-0.27-0.89-0.61c-0.09-0.15-0.14-0.29-0.16-0.42
		c-0.03-0.16-0.02-0.35,0.03-0.57c0.04-0.18,0.13-0.37,0.25-0.56c0.02-0.05,0.04-0.1,0.06-0.14c-0.57-0.37-1.1-1.21-1.16-1.85
		c-0.02-0.23,0.01-0.45,0.07-0.66c0.1-0.35,0.29-0.66,0.53-0.94c0.25-0.31,0.54-0.58,0.79-0.81l0.04-0.04
		c0.5-0.47,0.85-0.88,1.15-1.38c0.1-0.19,0.19-0.38,0.25-0.56c0.02-0.09,0.03-0.18,0.05-0.27c0.06-0.35,0.13-0.74,0.38-1.02
		c0.03-0.03,0.06-0.06,0.09-0.09c0.01-0.01,0.02-0.02,0.03-0.02c0.38-0.35,0.9-0.38,1.37-0.4c0.18-0.01,0.35-0.02,0.48-0.05
		c0.52-0.12,0.79-0.41,1.17-0.79c0.05-0.05,0.1-0.1,0.15-0.15c0.4-0.44,1.16-1.23,1.75-0.73c0.32,0.27,0.26,0.69,0.22,0.98
		c-0.01,0.07-0.03,0.19-0.02,0.22c0,0.01,0,0.01,0,0.02c0.04-0.02,0.08-0.03,0.13-0.04c0.14-0.05,0.27-0.01,0.37,0.04
		c0.13,0.07,0.2,0.19,0.25,0.32c0.03,0.04,0.06,0.1,0.1,0.16c0,0.01,0.01,0.01,0.01,0.02c0.01,0,0.02,0.01,0.03,0.01
		c0.13,0.02,0.27,0.07,0.37,0.2c0.05,0.04,0.1,0.09,0.15,0.15c0.07,0.1,0.09,0.2,0.08,0.3c0.13-0.03,0.29-0.01,0.47,0.1
		c0.2,0.12,0.27,0.3,0.3,0.44c0,0.01,0.01,0.01,0.01,0.02c0.15-0.02,0.35-0.01,0.53,0.14c0.31,0.26,0.24,0.61,0.11,0.89
		c-0.06,0.17-0.16,0.31-0.25,0.43c0,0,0,0,0,0c-0.02,0.03-0.03,0.06-0.04,0.08l-0.06,0.14c0,0.01-0.01,0.02-0.01,0.03l0.02,0.01
		c0,0,0,0,0.01,0c0.04,0.01,0.09,0.01,0.13,0.02c0.18,0.02,0.4,0.05,0.63,0.15c0.07,0.03,0.13,0.06,0.18,0.09
		c0.02,0.01,0.04,0.02,0.07,0.04c0.35,0.22,0.43,0.36,0.54,0.59C194.86,167.6,194.89,167.66,194.93,167.74z"
          />
          <path
            id="Aguascalientes"
            d="M189.92,162.86c0-0.04,0-0.08,0-0.12c-0.07,0.05-0.17,0.13-0.29,0.24c-0.1,0.09-0.2,0.18-0.29,0.27
		c-0.01,0.01-0.03,0.03-0.04,0.04l-0.07,0.08c-0.39,0.42-0.79,0.86-1.55,1.04c-0.02,0-0.03,0.01-0.05,0.01
		c-0.03,0.01-0.05,0.01-0.08,0.02c-0.17,0.03-0.35,0.05-0.55,0.06c-0.34,0.02-0.69,0.05-0.88,0.21c-0.07,0.08-0.1,0.2-0.14,0.4
		c-0.02,0.08-0.03,0.16-0.05,0.24c-0.03,0.14-0.08,0.28-0.13,0.42c-0.04,0.15-0.08,0.3-0.16,0.44c-0.05,0.09-0.1,0.18-0.15,0.27
		c-0.11,0.21-0.24,0.42-0.38,0.64c-0.25,0.39-0.63,0.72-0.99,1.04c-0.3,0.27-0.56,0.5-0.75,0.73c-0.15,0.19-0.27,0.38-0.33,0.59
		c-0.05,0.17-0.06,0.35-0.02,0.55c0.07,0.38,0.28,0.58,0.58,0.86c0.07,0.07,0.14,0.14,0.22,0.21c0.05,0.05,0.1,0.1,0.14,0.14
		c0.2-0.15,0.41-0.26,0.63-0.3c0.4-0.07,0.72,0.04,1,0.21c0.02,0.01,0.04,0.02,0.06,0.03c0.25,0.14,0.45,0.33,0.64,0.51
		c0.06,0.06,0.13,0.12,0.19,0.17c0.26,0.19,0.49,0.29,0.8,0.38c0.35,0.06,0.72,0.19,1.09,0.32c0.29,0.1,0.57,0.2,0.84,0.26
		c0.22,0.05,0.43,0.07,0.61,0.07c0,0,0.01,0,0.01,0c0.01,0,0.02,0,0.02,0c0,0,0,0,0,0c0.04,0,0.09-0.01,0.13-0.02
		c0.46-0.11,0.99-0.56,1.4-0.89l0.28-0.22c0.13-0.1,0.26-0.21,0.36-0.32c0.03-0.05,0.06-0.1,0.09-0.14
		c0.01-0.02,0.02-0.03,0.02-0.05c0.01-0.05,0.02-0.09,0.04-0.15c0.04-0.2,0.1-0.5,0.44-0.72c0.19-0.13,0.4-0.14,0.57-0.15
		c0.02,0,0.04,0,0.06,0c0.27-0.15,0.49-0.37,0.74-0.63c0.03-0.03,0.05-0.05,0.08-0.08c0.09-0.1,0.18-0.2,0.27-0.28
		c0.06-0.05,0.12-0.1,0.18-0.15c0.01-0.01,0.02-0.02,0.04-0.03c0.05-0.05,0.1-0.09,0.15-0.13c0.01-0.01,0.02-0.02,0.03-0.02
		c-0.03-0.02-0.05-0.05-0.08-0.08c-0.24-0.25-0.35-0.51-0.44-0.72c-0.1-0.23-0.16-0.37-0.34-0.49c-0.1-0.05-0.24-0.07-0.37-0.1
		c-0.14-0.03-0.29-0.05-0.43-0.11c-0.17-0.04-0.35-0.13-0.46-0.31c-0.15-0.15-0.16-0.31-0.11-0.52c0.02-0.26,0.16-0.45,0.28-0.61
		c0.01-0.02,0.02-0.04,0.04-0.06c0.02-0.03,0.04-0.07,0.07-0.11c-0.05-0.02-0.1-0.04-0.16-0.07c-0.09-0.02-0.18-0.05-0.26-0.12
		c-0.13-0.11-0.18-0.26-0.19-0.37c0,0-0.01,0-0.01-0.01c-0.28,0.13-0.52,0.08-0.74-0.14c-0.15-0.15-0.15-0.36-0.15-0.54
		c0-0.02,0-0.03,0-0.05c-0.03-0.01-0.06-0.03-0.09-0.04c-0.06-0.02-0.12-0.05-0.17-0.1c-0.03-0.03-0.05-0.06-0.08-0.09
		c-0.04-0.04-0.08-0.08-0.12-0.12c-0.07,0.04-0.16,0.07-0.26,0.07c-0.19,0.03-0.36-0.06-0.46-0.14c-0.22-0.17-0.23-0.42-0.22-0.6
		C189.92,163.24,189.92,163.03,189.92,162.86z"
          />
          <path
            id="Jalisco"
            d="M170.92,158.72c0.05-0.06,0.11-0.11,0.18-0.16c0.14-0.11,0.33-0.18,0.52-0.25c0.09-0.03,0.21-0.08,0.29-0.12
		c-0.02-0.16-0.1-0.47-0.15-0.56c-0.03-0.06-0.05-0.1-0.07-0.13c-0.04-0.01-0.08-0.03-0.11-0.04c-0.04-0.01-0.08-0.03-0.12-0.05
		c-0.43-0.18-0.89-0.33-1.34-0.47c-0.05-0.01-0.09-0.03-0.14-0.05c0.02,0.26,0.03,0.53-0.01,0.78c0,0.02-0.01,0.04-0.01,0.05
		c-0.04,0.23-0.16,0.43-0.27,0.61c-0.06,0.11-0.16,0.27-0.16,0.33c0,0.07,0.25,0.25,0.39,0.34c0.3,0.2,0.68,0.46,0.75,0.89
		c0.01,0.08,0.01,0.17-0.01,0.26c0,0,0,0,0.01,0c0.08-0.05,0.17-0.07,0.24-0.1c0.01-0.01,0.01-0.01,0.02-0.02
		c-0.01-0.03-0.02-0.07-0.03-0.1c-0.03-0.09-0.07-0.19-0.1-0.31c-0.03-0.1-0.05-0.18-0.06-0.23c-0.08-0.29-0.08-0.45,0.16-0.67
		C170.9,158.75,170.91,158.73,170.92,158.72z"
          />
          <path
            id="Nayarit"
            d="M164.06,163.94c0.07-0.13,0.13-0.25,0.18-0.36l0.08-0.18c0.06-0.14,0.12-0.26,0.17-0.36
		c0.05-0.11,0.1-0.23,0.15-0.35c0.19-0.44,0.22-0.82,0.19-1.24l-0.1-0.73c-0.02,0.01-0.05,0.01-0.07,0.02
		c-0.34,0.09-0.71,0.19-1.08,0.2c-0.09,0.01-0.14,0.02-0.17,0.02c-0.19,0.04-0.29,0-0.43-0.08c-0.05-0.02-0.11-0.05-0.2-0.1
		c0,0,0,0,0,0c-0.19-0.09-0.43-0.22-0.67-0.39c-0.29-0.21-0.61-0.49-0.77-0.83c-0.19-0.39-0.22-0.76-0.25-1.09
		c-0.04-0.45-0.06-0.72-0.42-0.98c-0.21-0.15-0.39-0.25-0.55-0.29c-0.42-0.05-0.85,0.19-1.45,0.58c-0.05,0.03-0.1,0.07-0.16,0.11
		c-0.11,0.08-0.22,0.16-0.32,0.23c-0.48,0.37-0.99,0.74-1.35,0.37c-0.13-0.14-0.16-0.32-0.12-0.63c-0.01-0.56,0.16-1.23,0.16-1.24
		c0.09-0.31,0.22-0.5,0.37-0.66c0.17-0.2,0.38-0.37,0.66-0.6l0.01-0.01c0.08-0.07,0.16-0.14,0.24-0.21c0.1-0.08,0.17-0.14,0.23-0.18
		c0.01-0.01,0.02-0.01,0.03-0.02c0-0.04,0-0.09,0.01-0.16c0.05-0.99-0.09-1.28-0.74-1.59c-0.15-0.07-0.31-0.16-0.45-0.25
		c-0.14-0.06-0.27-0.12-0.38-0.18c-0.21-0.13-0.41-0.28-0.6-0.43c-0.25-0.19-0.48-0.37-0.73-0.49c-0.13-0.06-0.27-0.1-0.41-0.13
		c-0.04-0.01-0.07-0.01-0.1-0.02c-0.13,0-0.27,0.01-0.42,0.02c-0.26,0.02-0.55,0.05-0.85-0.03c-0.18-0.05-0.35-0.15-0.49-0.25
		c-0.09-0.04-0.17-0.08-0.24-0.12c-0.09,0.06-0.19,0.08-0.3,0.05c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c-0.02-0.01-0.05-0.01-0.07-0.01c-0.2-0.03-0.34,0.06-0.41,0.14c-0.14,0.15-0.2,0.39-0.14,0.59c0.02,0.05,0.03,0.1,0.05,0.14
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.04,0.1,0.09,0.17,0.13,0.23
		c0.07,0.11,0.17,0.27,0.13,0.48c-0.05,0.24-0.27,0.38-0.56,0.56c0,0,0,0-0.01,0c-0.67,0.42-1.12,0.9-1.15,1.23
		c-0.01,0.08,0.01,0.2,0.18,0.35c0.03,0.03,0.06,0.05,0.09,0.07c0.01,0,0.02,0.01,0.03,0.01c0.24,0.09,0.61,0.22,0.77,0.64
		c0.08,0.21,0.05,0.4,0.01,0.55c0.01,0.1,0.04,0.23,0.07,0.37c0.03,0.15,0.07,0.31,0.09,0.47c0.01,0.05,0.02,0.09,0.02,0.15
		c0,0.01,0,0.03,0,0.04c0.01,0.25-0.02,0.5-0.17,0.71c-0.22,0.3-0.65,0.44-1.11,0.51c-0.56,0.16-1.2,0.23-1.73,0.29
		c-0.01,0-0.02,0-0.03,0c-0.03,0.01-0.05,0.01-0.08,0.02c0.01,0.02,0.02,0.05,0.03,0.07c0.03,0.06,0.05,0.13,0.07,0.19
		c0.03,0.09,0.07,0.18,0.1,0.27c0.02,0.07,0.04,0.13,0.06,0.2c0.03,0.09,0.05,0.18,0.07,0.27c0.02,0.07,0.03,0.13,0.05,0.2
		c0.02,0.09,0.04,0.18,0.05,0.27c0.01,0.07,0.02,0.13,0.03,0.2c0.01,0.09,0.02,0.18,0.03,0.27c0.01,0.06,0.01,0.13,0.02,0.19
		c0.01,0.09,0.01,0.19,0.01,0.28c0,0.06,0,0.12,0,0.18c0,0.1-0.01,0.21-0.01,0.31c0,0.05,0,0.1-0.01,0.15
		c-0.01,0.15-0.03,0.31-0.06,0.46c-0.05,0.29-0.12,0.58-0.18,0.87c-0.2,0.86-0.38,1.6,0.08,2.31c0.66,1.02,1.15,1.98,1.53,3.01
		c0.03,0.07,0.06,0.15,0.09,0.23c0.08,0.2,0.18,0.43,0.22,0.67c0.03,0.15,0.02,0.31,0.02,0.46c0,0.06-0.01,0.13,0,0.2
		c0,0.07,0.01,0.12,0.02,0.15c0.09,0.18,0.81,0.67,1.55,1.06c0.13,0.07,0.26,0.1,0.39,0.13c0.31,0.07,0.73,0.18,0.9,0.85
		c0.15,0.61-0.12,0.95-0.33,1.23c-0.13,0.17-0.24,0.31-0.3,0.54c-0.07,0.3-0.04,0.64,0,1.01c0.04,0.36,0.07,0.74,0.01,1.12
		c-0.01,0.09-0.03,0.22-0.04,0.36c-0.06,0.64-0.13,1.51-0.63,1.9c-0.23,0.18-0.44,0.2-0.59,0.22c-0.1,0.01-0.15,0.02-0.21,0.05
		c-0.08,0.04-0.19,0.23-0.27,0.35c-0.08,0.13-0.17,0.26-0.27,0.37l-0.11,0.12c-0.18,0.19-0.41,0.42-0.57,0.57
		c-0.08,0.07-0.17,0.13-0.26,0.19c-0.1,0.07-0.19,0.13-0.23,0.19c-0.02,0.03-0.05,0.12-0.06,0.19c-0.05,0.19-0.12,0.44-0.34,0.61
		c-0.06,0.05-0.12,0.08-0.18,0.11c0.03,0.02,0.06,0.05,0.07,0.06c0.24,0.13,0.32,0.11,0.54,0.06c0.15-0.04,0.33-0.1,0.57-0.08
		c0.69,0,0.84,0.34,0.93,0.65c0.03,0.09,0.04,0.16,0.09,0.22c0.02-0.02,0.03-0.05,0.05-0.07c0.15-0.18,0.29-0.37,0.44-0.57
		c0.33-0.44,0.67-0.88,1.05-1.28c0.18-0.2,0.39-0.41,0.62-0.58c0.23-0.17,0.5-0.32,0.8-0.3c0.04,0,0.1,0.01,0.17,0.03c0,0,0,0,0,0
		c0.13,0.01,0.24,0.07,0.31,0.17c0.03,0,0.06,0,0.1,0.01c0.62,0.15,0.91-0.05,1.34-0.36c0.25-0.18,0.54-0.38,0.92-0.53
		c0.01,0,0.01-0.01,0.02-0.01c0.22-0.1,0.48-0.16,0.76-0.12c0.15,0.02,0.27,0.05,0.39,0.11c0.38,0.17,0.64,0.5,0.86,0.8
		c0.16,0.21,0.31,0.4,0.47,0.52c0.13,0.08,0.27,0.09,0.44,0.1c0.27,0.02,0.64,0.05,0.97,0.39c0.22,0.23,0.33,0.47,0.42,0.69
		c0.04,0.09,0.07,0.18,0.12,0.25c0.11,0.15,0.24,0.27,0.49,0.45c0.01,0,0.01,0.01,0.02,0.01c0.19,0.11,0.4,0.23,0.56,0.43
		c0.11,0.14,0.16,0.29,0.19,0.4c0.01,0.04,0.03,0.1,0.04,0.12c0,0,0,0,0,0c0.04,0.04,0.2,0.13,0.31,0.19
		c0.02-0.12,0.04-0.24,0.06-0.36c0.01-0.04,0.01-0.09,0.02-0.13c0.08-0.57,0.16-1.15,0.57-1.83c0,0,0-0.01,0.01-0.01
		c0.5-0.8,0.65-1.4,0.6-2.3c0-0.01,0-0.02,0-0.03l-0.01-0.15c-0.01-0.06-0.01-0.12-0.02-0.17c-0.03-0.25-0.05-0.47,0.11-0.66
		c0.18-0.2,0.41-0.21,0.87-0.22c0,0,0,0,0.01,0c0.4-0.02,0.73-0.07,1.05-0.22c0.07-0.03,0.14-0.07,0.21-0.11h0
		c0.01-0.01,0.02-0.01,0.03-0.02c0.19-0.12,0.36-0.27,0.54-0.43l0.23-0.2c0,0,0,0,0.01-0.01c0.05-0.06,0.09-0.1,0.12-0.14
		c0.09-0.09,0.09-0.1,0.15-0.29c0.02-0.06,0.02-0.14,0.03-0.22c0.01-0.11,0.02-0.24,0.07-0.39c0-0.01,0.01-0.03,0.01-0.04
		c-0.04-0.02-0.07-0.05-0.11-0.07c-0.39-0.25-0.79-0.5-1.15-0.85c-0.12-0.11-0.27-0.23-0.42-0.35c-0.3-0.24-0.62-0.49-0.82-0.78
		c-0.17-0.19-0.25-0.36-0.27-0.52c-0.05-0.21,0.02-0.39,0.2-0.59c0.03-0.04,0.05-0.08,0.08-0.12c0.06-0.09,0.13-0.14,0.2-0.18
		c0.14-0.17,0.18-0.27,0.23-0.47c0-0.02,0.01-0.04,0.01-0.07c0.03-0.11,0.06-0.22,0.09-0.33c0.01-0.02,0.01-0.05,0.02-0.07
		c0.13-0.4,0.32-0.78,0.52-1.18c0.1-0.19,0.1-0.25,0.1-0.26h0c-0.01,0-0.07-0.07-0.13-0.12c-0.09-0.09-0.2-0.21-0.33-0.37
		c-0.27-0.34-0.5-0.69-0.72-1.03c-0.28-0.42-0.55-0.83-0.87-1.2c-0.06-0.06-0.13-0.11-0.2-0.17c-0.37-0.31-0.75-0.62-0.92-0.96
		c-0.09-0.13-0.15-0.28-0.16-0.42c-0.01-0.18,0.06-0.36,0.18-0.57C163.8,164.42,163.93,164.17,164.06,163.94z"
          />
          <path
            id="Durango"
            d="M165.08,159.87c0.04,0,0.09,0.02,0.13,0.03c0.02,0.01,0.04,0.02,0.06,0.03c0.03,0.02,0.06,0.05,0.08,0.07
		c0,0,0,0,0,0c0.04,0.05,0.07,0.12,0.08,0.18l0,0.01c0.02,0.14,0.04,0.28,0.06,0.41c0.01,0.05,0.02,0.11,0.03,0.16
		c0.16-0.38,0.3-0.78,0.37-1.23c0.14-0.81,0.26-1.64,0.37-2.47c0.23-1.6,0.46-3.26,0.86-4.83c0.14-0.56,0.36-1.2,0.89-1.63
		c0.16-0.13,0.29-0.21,0.39-0.27c0.17-0.17,0.27-0.39,0.34-0.63c0.03-0.21,0.04-0.46,0.04-0.69c0.01-0.25,0.01-0.52,0.05-0.77
		c0.08-0.54,0.2-1.11,0.47-1.61c0.14-0.28,0.34-0.54,0.62-0.76c0.07-0.06,0.15-0.12,0.24-0.17c0,0,0,0,0,0
		c0.18-0.11,0.35-0.18,0.51-0.24c0.24-0.09,0.37-0.14,0.47-0.29c0.05-0.07,0.07-0.13,0.09-0.21c0.06-0.17,0.14-0.41,0.47-0.6
		c0.21-0.12,0.46-0.16,0.68-0.19c0.1-0.02,0.24-0.04,0.32-0.07c-0.01-0.04-0.04-0.12-0.11-0.24c-0.05-0.08-0.09-0.13-0.13-0.17
		c-0.09-0.1-0.2-0.22-0.27-0.47c0,0,0-0.01,0-0.01c0,0,0-0.01,0-0.01c-0.13-0.47-0.13-1.39-0.07-1.8c0.04-0.26,0.14-0.51,0.23-0.75
		c0.06-0.15,0.11-0.3,0.15-0.44c0.01-0.11,0.01-0.25,0.02-0.37c0.02-0.62,0.04-1.02,0.2-1.26c0.2-0.29,0.69-0.47,1.74-0.76
		c0.2-0.05,0.36-0.1,0.45-0.13c0.08-0.03,0.18-0.06,0.28-0.09c0.17-0.05,0.36-0.12,0.46-0.18c0.07-0.05,0.13-0.08,0.19-0.11
		c0-0.02,0.01-0.04,0.01-0.06c0-0.03-0.01-0.08-0.02-0.14c-0.02-0.13-0.05-0.3-0.02-0.5c-0.02-0.18-0.01-0.35,0.08-0.5
		c0.07-0.12,0.2-0.2,0.46-0.31c0.43-0.34,1.11-0.65,1.93-1.02c0.19-0.08,0.37-0.16,0.52-0.23c0.07-0.04,0.13-0.08,0.2-0.12
		c0.31-0.19,0.64-0.39,0.97-0.54c0.29-0.15,0.6-0.26,0.91-0.27c0.44-0.02,0.92,0.1,1.39,0.21c0.21,0.05,0.41,0.1,0.6,0.13
		c0.16,0.03,0.31,0.06,0.47,0.09c0.38,0.07,0.77,0.14,1.15,0.21l1.11,0.21c0.11,0.02,0.24,0.05,0.38,0.08
		c0.07,0.02,0.14,0.03,0.21,0.05c0.07,0.01,0.14,0.01,0.21,0.01c0.19,0.01,0.22-0.01,0.28-0.05c0.12-0.08,0.26-0.17,0.53-0.18
		c0.42-0.02,0.88,0.06,1.33,0.14c0.23,0.04,0.46,0.09,0.67,0.11c0.04,0,0.11,0.01,0.21,0.03c0.05,0.01,0.13,0.02,0.21,0.03
		c0.01-0.03,0.02-0.09,0.03-0.16c0.02-0.2,0.03-0.42,0.04-0.52c0.01-0.11,0.01-0.2,0.02-0.23l0.08-0.64
		c0.06-0.49,0.12-0.98,0.17-1.47l0.04-0.33c0.07-0.61,0.13-1.1-0.08-1.63c0,0,0,0,0,0c-0.02-0.06-0.04-0.11-0.07-0.17
		c-0.05-0.09-0.11-0.19-0.15-0.27l-0.96-1.99c-0.21,0.31-0.46,0.6-0.71,0.88l-0.14,0.16c-0.09,0.11-0.24,0.25-0.4,0.39
		c-0.05,0.05-0.12,0.11-0.18,0.17c-0.07,0.09-0.13,0.18-0.19,0.27c-0.24,0.37-0.21,0.84-0.19,1.37c0.01,0.16,0.02,0.31,0.02,0.47v0
		c0,0,0,0,0,0c0,0,0,0.01,0,0.01v0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0v0c-0.01,0.15-0.1,0.28-0.25,0.34
		c-0.15,0.06-0.32,0.01-0.42-0.11c-0.7-0.81-1.52-1.32-2.79-1.74c-0.35-0.08-0.71-0.17-1.05-0.36c-0.3-0.17-0.85-0.58-1.04-0.97
		c-0.11-0.21-0.14-0.43-0.15-0.64c-0.02-0.19,0.01-0.38,0.04-0.56c0-0.02,0-0.03,0.01-0.05c0.02-0.18,0.05-0.35,0.04-0.5
		c-0.02-0.41-0.28-0.62-0.75-0.95c-0.34-0.24-0.72-0.52-0.95-0.95c-0.09-0.16-0.14-0.31-0.19-0.44c-0.06-0.16-0.1-0.29-0.22-0.44
		c0,0,0,0-0.01-0.01c-0.02-0.02-0.09-0.07-0.13-0.1c-0.13-0.09-0.28-0.21-0.38-0.37c-0.08-0.11-0.14-0.24-0.14-0.4
		c-0.01-0.17,0.03-0.31,0.09-0.42c0.03-0.06,0.08-0.12,0.12-0.16c0.14-0.13,0.31-0.2,0.43-0.25c0.23-0.09,0.29-0.13,0.32-0.26
		c0.01-0.05,0.02-0.1,0.02-0.14c-0.02-0.07-0.05-0.14-0.11-0.23c-0.03-0.04-0.08-0.08-0.13-0.13c-0.13-0.13-0.3-0.29-0.41-0.52
		c-0.39-0.77,0.01-1.69,0.54-2.23c0.17-0.17,0.35-0.33,0.53-0.5c0.36-0.32,0.7-0.63,0.94-1c0.11-0.18,0.2-0.36,0.26-0.55
		c0.2-0.67,0.16-1.4,0.12-2.17c-0.04-0.7-0.07-1.43,0.08-2.13c0.11-0.51,0.31-0.93,0.49-1.29c0.09-0.19,0.18-0.37,0.25-0.55
		c0.14-0.44,0.17-0.85,0.05-1.26c-0.01-0.04-0.03-0.08-0.04-0.13c-0.17-0.47-0.47-0.82-0.81-1.21l-0.13-0.16
		c-0.25-0.29-0.53-0.59-0.84-0.91c-0.68-0.71-1.37-1.44-1.8-2.24c-0.61-0.33-1.23-0.63-1.85-0.88l-0.13-0.05c0,0,0,0,0,0
		c-0.96-0.33-2.09-0.6-3.34-0.8c-0.54-0.09-0.86-0.1-1.12,0c-0.07,0.03-0.14,0.07-0.21,0.11c-0.19,0.14-0.38,0.35-0.64,0.68
		c-0.19,0.24-0.4,0.46-0.61,0.69c-0.1,0.11-0.2,0.22-0.3,0.33c-0.26,0.29-0.52,0.61-0.78,0.93c-0.44,0.53-0.88,1.08-1.37,1.56
		c-0.16,0.2-0.35,0.4-0.55,0.52c-0.86,0.53-1.43,0.04-1.8-0.43c-0.08-0.08-0.15-0.17-0.21-0.24c-0.17-0.19-0.34-0.39-0.51-0.52
		c-0.06-0.05-0.1-0.08-0.13-0.1c-0.12,0.09-0.31,0.19-0.61,0.13c-0.04-0.01-0.09-0.02-0.12-0.03c-0.11-0.02-0.23-0.06-0.35-0.14
		c-0.22-0.14-0.3-0.37-0.36-0.54c-0.09,0.07-0.22,0.13-0.41,0.16c-0.03,0-0.06,0.01-0.09,0.01c-0.07,0.01-0.14,0.02-0.23,0
		c-0.23-0.03-0.36-0.19-0.44-0.3c0,0-0.01-0.01-0.01-0.01c-0.08,0.07-0.19,0.14-0.35,0.17c-0.15,0.03-0.28,0.04-0.41,0.04
		c-0.2,0.02-0.42-0.04-0.75-0.22c-0.05-0.03-0.09-0.06-0.14-0.09c-0.04-0.02-0.08-0.05-0.11-0.08c-0.11-0.08-0.22-0.22-0.33-0.37
		c-0.08-0.09-0.16-0.18-0.24-0.24c-0.03-0.02-0.07-0.05-0.11-0.09c-0.02,0-0.05,0.01-0.06,0.01c-0.09,0.01-0.23,0.08-0.36,0.14
		c-0.09,0.04-0.17,0.08-0.25,0.11c-0.09,0.03-0.21,0.09-0.35,0.16c-0.41,0.2-0.88,0.43-1.24,0.4c-0.08-0.01-0.16-0.03-0.24-0.07
		c-0.23-0.09-0.47-0.28-0.75-0.51c-0.08-0.07-0.16-0.13-0.22-0.18c-0.09-0.06-0.18-0.12-0.27-0.17c-0.09-0.06-0.19-0.11-0.28-0.17
		c-0.42-0.24-0.85-0.5-1.18-0.83c-0.09-0.09-0.16-0.18-0.21-0.26c-0.04-0.05-0.06-0.11-0.09-0.16c-0.03-0.07-0.05-0.13-0.07-0.19
		c-0.04-0.12-0.08-0.22-0.19-0.37c-0.13-0.17-0.36-0.33-0.58-0.41c-0.03-0.01-0.06-0.01-0.08-0.02c-0.05,0.01-0.23,0.06-0.32,0.1
		c-0.2,0.08-0.39,0.15-0.59,0.11c-0.27-0.05-0.53-0.31-0.86-0.66c-0.07-0.08-0.13-0.14-0.17-0.18l-1.93-1.7
		c-0.08,0.11-0.17,0.21-0.26,0.31c-0.17,0.18-0.32,0.36-0.38,0.54c0,0,0,0.01,0,0.01c0,0.01-0.01,0.03-0.01,0.04
		c-0.05,0.12-0.17,0.21-0.31,0.23c-0.02,0-0.03,0-0.05,0c-0.13,0-0.26-0.07-0.33-0.18c-0.03-0.05-0.05-0.08-0.07-0.11
		c-0.08,0.26-0.16,0.72-0.2,0.99c-0.08,0.48-0.13,0.79-0.23,0.99c0,0.01-0.01,0.01-0.01,0.02c-0.1,0.19-0.19,0.31-0.28,0.43
		c-0.13,0.18-0.2,0.27-0.22,0.56c-0.05,0.51-0.13,0.97-0.63,1.39c-0.78,0.67-1.65,0.6-2.45,0.47c0.01,0.1,0.04,0.19,0.07,0.29
		c0.03,0.08,0.08,0.17,0.13,0.27c0.07,0.15,0.16,0.31,0.24,0.51c0.11,0.26,0.18,0.5,0.23,0.72c0.09,0.35,0.12,0.74,0,1.17
		c-0.04,0.15-0.1,0.29-0.17,0.41c-0.1,0.19-0.23,0.34-0.36,0.46c-0.13,0.12-0.27,0.21-0.41,0.29c-0.18,0.11-0.37,0.21-0.57,0.29
		c-0.05,0.02-0.1,0.04-0.15,0.06c-0.01,0-0.02,0.01-0.02,0.01c-0.13,0.07-0.22,0.12-0.3,0.15c-0.01,0.01-0.02,0.01-0.03,0.01
		c-0.01,0.07-0.03,0.18-0.05,0.3l-0.02,0.14c-0.03,0.22-0.05,0.45-0.07,0.68c-0.04,0.44-0.08,0.89-0.2,1.32
		c-0.13,0.48-0.42,0.8-0.76,1.06c-0.26,0.22-0.55,0.42-0.83,0.61c-0.06,0.04-0.11,0.08-0.17,0.11c-0.04,0.03-0.07,0.06-0.11,0.09
		c-0.42,0.34-0.89,0.73-1.39,0.82c-0.26,0.04-0.56,0-0.83-0.03c-0.1-0.01-0.2-0.03-0.29-0.03c-0.6-0.04-1.21-0.08-1.82-0.09
		c-0.04,0-0.09,0-0.14-0.01c-0.12,0-0.25,0-0.37,0c-0.16,0-0.25,0.01-0.3,0.01c-0.01,0.3-0.04,0.6-0.09,0.89
		c-0.11,0.77-0.34,1.45-0.57,2.17c-0.58,1.78-0.03,3.6,0.58,5.15l0.06,0.15c0.08,0.17,0.15,0.34,0.23,0.49
		c0.07,0.14,0.14,0.29,0.2,0.43c0.07,0.15,0.14,0.3,0.21,0.45c0.06,0.11,0.13,0.17,0.23,0.26c0.09,0.07,0.18,0.16,0.27,0.28
		c0.06,0.05,0.13,0.1,0.2,0.17c0.14,0.14,0.15,0.3,0.14,0.43c0.09,0.01,0.18,0.02,0.28,0.05c0.42,0.14,0.62,0.29,0.71,0.5
		c0.03,0.04,0.06,0.08,0.08,0.13c0.08,0.15,0.08,0.29,0.08,0.4c0.09,0.04,0.18,0.09,0.24,0.2l0.07,0.12
		c0.05,0.09,0.1,0.18,0.15,0.27c0.1,0.12,0.2,0.24,0.3,0.36c0.46,0.54,0.94,1.1,1.15,1.93c0.04,0.16,0.07,0.33,0.1,0.51
		c0.02,0.08,0.03,0.16,0.05,0.25c0.02,0.14,0.04,0.29,0.07,0.44c0.08,0.58,0.17,1.23,0.52,1.54c0.02,0.02,0.04,0.04,0.06,0.05
		c0.21,0.16,0.48,0.25,0.73,0.26c0.38-0.02,0.7-0.28,0.89-0.48c0.05-0.06,0.11-0.12,0.17-0.18c0.2-0.23,0.46-0.47,0.83-0.56
		c0.2-0.06,0.41-0.08,0.65-0.02c0.4,0.09,0.77,0.48,1.21,0.98c0.07,0.08,0.14,0.16,0.2,0.22c0.1,0.09,0.21,0.19,0.31,0.28
		c0.31,0.28,0.63,0.56,0.91,0.88c0,0,0,0,0,0c0,0,0,0,0,0c0.01,0.01,0.02,0.02,0.03,0.03c0.37,0.44,0.55,0.97,0.72,1.48
		c0.1,0.31,0.2,0.6,0.33,0.85c0.1,0.18,0.17,0.34,0.22,0.5c0.09,0.24,0.12,0.51,0.1,0.94c-0.01,0.32-0.06,0.63-0.11,0.93
		c-0.06,0.42-0.11,0.82-0.1,1.22c0.04,0.63,0.24,1.33,0.62,2.17c0.01,0.02,0.02,0.04,0.03,0.06c0.1,0.2,0.2,0.4,0.32,0.6
		c0.17,0.3,0.24,0.58,0.31,0.82c0.01,0.03,0.01,0.06,0.02,0.08c0.07,0.18,0.15,0.36,0.22,0.53c0.01,0.02,0.03,0.04,0.04,0.06
		c0.05,0.07,0.1,0.15,0.15,0.23c0.04,0.07,0.08,0.14,0.11,0.23c0.02,0.06,0.04,0.12,0.05,0.19c0.03,0.17-0.01,0.31-0.06,0.4
		c0.01,0.01,0.02,0.02,0.03,0.03c0.06,0.08,0.13,0.17,0.13,0.31c0,0.08-0.02,0.16-0.05,0.25c0,0,0,0.01,0,0.01
		c0.02,0.19,0,0.3-0.01,0.38c-0.01,0.04-0.01,0.06-0.01,0.1c0.07,0.11,0.15,0.25,0.19,0.43c0.03,0.15,0,0.28-0.02,0.38
		c0,0.01,0,0.01,0,0.02c0.02,0.05,0.03,0.07,0.03,0.07c0.1,0.06,0.2,0.12,0.34,0.25c0,0,0,0,0,0c0.26,0.25,0.44,0.55,0.62,0.83
		c0.14,0.23,0.28,0.44,0.43,0.62c0.04,0.04,0.07,0.07,0.09,0.1l0.04,0.05c0.02,0.03,0.04,0.05,0.06,0.07
		c0.09,0.01,0.18,0.02,0.31,0.04c0.03,0,0.11,0,0.19,0c0.45-0.01,0.72-0.01,0.89,0.16c0.17,0.17,0.21,0.45,0.23,0.83
		c0.02,0.08,0.03,0.14,0.04,0.2c0,0.02,0.01,0.04,0.01,0.06c0.15,0.07,0.28,0.16,0.39,0.25c0.01,0.01,0.02,0.02,0.03,0.02
		c0.01,0,0.02,0.01,0.02,0.01c0.28,0.12,0.52,0.13,0.83,0.12c0.18,0,0.38,0,0.6,0.03c0.11,0.01,0.22,0.02,0.34,0.05
		c0.16,0.04,0.3,0.1,0.44,0.17c0.42,0.19,0.8,0.46,1.18,0.73c0.19,0.13,0.36,0.26,0.54,0.37c0.06,0.02,0.11,0.05,0.17,0.07
		c0.42,0.17,0.86,0.34,1.09,0.62c0.35,0.41,0.46,1.76,0.37,2.12c-0.08,0.36-0.36,0.55-0.58,0.71c-0.07,0.05-0.13,0.09-0.2,0.14
		l-0.02,0.02c-0.02,0.02-0.04,0.04-0.07,0.06l-0.33,0.3c-0.08,0.07-0.14,0.12-0.19,0.17c-0.16,0.2-0.22,0.4-0.25,0.8
		c0,0.04-0.02,0.11-0.03,0.21c-0.01,0.04-0.02,0.11-0.03,0.19c0.07-0.05,0.15-0.1,0.22-0.15c0.03-0.03,0.06-0.05,0.08-0.07
		c0.1-0.08,0.19-0.15,0.25-0.19c0.05-0.04,0.11-0.07,0.16-0.11c0.08-0.06,0.15-0.11,0.2-0.14c0.64-0.41,1.17-0.7,1.8-0.56
		c0.36,0.05,0.73,0.23,1.12,0.6c0.46,0.44,0.51,0.96,0.56,1.42c0.02,0.18,0.03,0.37,0.08,0.55c0.07,0.31,0.31,0.56,0.56,0.74
		c0.18,0.13,0.37,0.24,0.55,0.32c0,0,0,0,0,0c0.13,0.06,0.28,0.08,0.44,0.08c0.02,0,0.04,0,0.07,0c0.28-0.02,0.59-0.1,0.89-0.18
		c0.09-0.02,0.18-0.05,0.27-0.07c0.07-0.02,0.14-0.03,0.21-0.05C165,159.87,165.04,159.86,165.08,159.87z"
          />
        </g>
      </svg>
      </div>
    </div>
	</div>
	<div className="w-[90%] sm:w-[15%] text-left font-semibold text-2xl text-[#154734]">
	
            <div id="NuevoLeon" className={`hover:cursor-pointer ${estado=== "NuevoLeon" ? " text-[#9a3324]" : "text-[#154734]"}`} onClick={(e) => setEstado(e.target.id)}>{`\u2022`}Nuevo León</div>
     
		  <div id="Queretaro" className={`hover:cursor-pointer ${estado=== "Queretaro" ? " text-[#9a3324]" : "text-[#154734]"}`} onClick={(e) => setEstado(e.target.id)}>{`\u2022`}Querétaro</div>
       
		  <div id="Guanajuato" className={`hover:cursor-pointer ${estado=== "Guanajuato" ? " text-[#9a3324]" : "text-[#154734]"}`} onClick={(e) => setEstado(e.target.id)}>{`\u2022`}Guanajuato</div>
       
		  <div id="Hidalgo" className={`hover:cursor-pointer ${estado=== "Hidalgo" ? " text-[#9a3324]" : "text-[#154734]"}`} onClick={(e) => setEstado(e.target.id)}>{`\u2022`}Hidalgo</div>
        
		  <div id="Jalisco" className={`hover:cursor-pointer ${estado=== "Jalisco" ? " text-[#9a3324]" : "text-[#154734]"}`} onClick={(e) => setEstado(e.target.id)}>{`\u2022`}Jalisco</div>
        
		  <div id="CDMX" className={`hover:cursor-pointer ${estado=== "CDMX" ? " text-[#9a3324]" : "text-[#154734]"}`} onClick={(e) => setEstado(e.target.id)}>{`\u2022`}Ciudad de México</div>
      
	</div>
	</div>
  );
}

export default Mapa3;
