import React from "react";
import { useParams } from "react-router-dom";
import Plasticos from "./Plasticos";
import Quimicos2 from "./Quimicos2";


function Lineas2() {
  let { division } = useParams();
  
if (division==="Químicos"){
  return (
    <div>
      <div className="w-100 relative">
        <img
          src="/banner-matraz.jpg"
          alt="banner-divisiones"
          className="w-[100%]"
        />
      </div>
      <div className="w-[100%]">
       <Quimicos2 />
      </div>
      
    </div>
  );
}else if (division==="Plásticos"){
  return(
    <div>
     <div className="w-100 relative">
        <img
          src="/banner_pellets-blanco.jpg"
          alt="banner-divisiones"
          className="w-[100%]"
        />
      </div>
      <div className="w-[100%]">
       <Plasticos />
      </div>
    </div>
  )

}
 
}

export default Lineas2;
