import React from "react";
import { useParams } from "react-router-dom";
import productosDB from "../database/productosDB";

function Familia(props) {
  const productos = productosDB;
  let { linea } = useParams();

  const styles = {
    titulodivision:
      "w-[90%] sm:w-[500px] bg-[#154734] text-[#fff] font-bold mt-16 text-3xl rounded-t-2xl py-4",
    box: "min-w-[45%] w-[100%] sm:max-w-[50%]",
    categoriaprod: " p-[20px] flex-col justify-center items-center border-t-4",
    categoriaprodtitle:
      "m-auto w-[40%] text-[#9a3324] font-bold text-2xl text-center pb-2",
    listatipoproductos: "m-auto w-[60%] text-left",
    listasubproductos: "italic text-lg ",
    categoriatipoproductos: "pt-10",
  };

  const productosLinea = productos.filter((row) => {
    return row.linea === linea;
  });

  let familiasUnicas = [];
  productosLinea.forEach((element) => {
    if (!familiasUnicas.includes(element.familia)) {
      familiasUnicas.push(element.familia);
    }
  });

  // const productosBien = JSON.stringify(productosLinea);
  // const familiasBien = JSON.stringify(familiasUnicas);

  return (
    <div>
      <div className="w-100 relative">
        <img
          src="/pellets_banner.jpg"
          alt="banner-divisiones"
          className="w-[100%]"
        />
      </div>
      <div className="flex flex-col justify-center items-center w-[100%]">
        {/* <div>{productosBien}</div>
      <div>{familiasBien}</div> */}
        <div className={styles.titulodivision}>{linea}</div>
<div className="flex flex-row flex-wrap justify-center sm:w-[90%] w-[100%] sm:max-w-[1500px]">
        {familiasUnicas.map((familiaUnica, index) => (
          <div className="min-w-[45%] w-[100%] sm:max-w-[750px]" key={index}>
            <div className={styles.categoriaprod}>
              <div
                className={styles.categoriaprodtitle}
              >{`\u2022  ${familiaUnica}`}</div>
              <div className={styles.listatipoproductos}>
                <ul className={styles.listasubproductos}>
                  {productosLinea
                    .filter((rowLinea) => {
                      return rowLinea.familia === familiaUnica;
                    })
                    .map((b) => (
                      <li
                        className="hover:text-[#154734] hover:font-semibold"
                        key={b.producto_id}
                      >
                        <a
                          href={"/Producto?id=" + b.producto_id}
                        >{`\u2022  ${b.nombre}`}</a>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        ))}
        </div>
      </div>
    </div>
  );
}

export default Familia;
