import React from "react";
import { useLocation } from "react-router-dom";
import productosDB from "./database/productosDB";
import { FaRegFilePdf } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { addToCart } from "./redux/cartSlice";
import Tabs2 from "./Tabs2";
import { useState } from "react";

function Producto() {
  // const[selCantidad, setSelCantidad]=useState(1);
  // const cantidad = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const[cantidad, setCantidad]=useState("100 kg")
  const dispatch = useDispatch();
  const productos = productosDB;
  const { search } = useLocation();
  const match = search.match(/id=(.*)/);
  const id = parseInt(match?.[1]);
  const styles = {
    linea: "flex flex-row justify-center  w-[90%] sm:w-[60%] items-center ",
    cajaIzq: "text-left text-lg px-10 py-2 bg-[#c6c6c5]  w-[50%] m-1",
    cajaDer: "text-center text-lg bg-[#ececec] w-[50%] m-1 py-2",
  };

  let producto = productos.filter((row) => {
    return row.producto_id === id;
  })[0];
  console.log(producto);
  let usos=[];
  usos = producto.usos.split("/");
  let que =[];
  que.push(producto.descripcion);
  const items = [
    { title: "¿Que es?", content: `${que}` },
    { title: "Usos comunes e Industrias", content: usos },
    // { title: "Manipulación y Seguridad", content: producto.seguridad },
  ];
  let presentacion = [];
  presentacion = producto.presentacion.split("/");
  
  const[selPresentacion, setSelPresentacion]=useState(presentacion[0]||"");

  let productoCotizar={
    producto_id:producto.producto_id,
    sinonimo:producto.sinonimo,
    nombre:producto.nombre,
    // cantidad:selCantidad,
    cantidad:cantidad,
    presentacion:selPresentacion,
  }

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}


  return (
    <div>
      {/* <div>{id}</div> */}
      <div className="flex justify-center flex-col items-center">
        <div className="flex flex-col w-[100%] sm:w-[60%] justify-center items-center py-4">
          <div className="text-xl font-bold bg-[#154734] text-[#fff] w-[90%] sm:w-[60%] py-4 m-4 flex flex-col">
            <div>{producto.nombre.toUpperCase()}</div>
            <div className="text-lg">{producto.sinonimo}</div>
          </div>

          <div className="flex justify-center flex-col items-center w-[100%] pt-4">
            {/* <div className={styles.linea}>
              <div className={styles.cajaIzq}>Linea:</div>
              <div className={styles.cajaDer}>{producto.linea_id}</div>
            </div>
            <div className={styles.linea}>
              <div className={styles.cajaIzq}>Familia:</div>
              <div className={styles.cajaDer}>{producto.familia_id}</div>
            </div> */}
            <div className={styles.linea}>
              <div className={styles.cajaIzq}>Número CAS:</div>
              <div className={styles.cajaDer}>{producto.cas}</div>
            </div>
            <div className={styles.linea}>
              <div className={styles.cajaIzq}>Acrónimo:</div>
              <div className={styles.cajaDer}>{producto.acronimo}</div>
            </div>
            <div className=" flex flex-row pt-4 items-center  w-[90%] sm:w-[70%]">
              <div className="m-auto flex flex-row justify-center items-center text-[#9a3324] font-semibold text-md w-[50%]">
                <FaRegFilePdf className="pr-2" size="30px" />
                <a href="https://www.google.com">Ficha Técnica</a>
              </div>
              <div className="m-auto flex flex-row  justify-center items-center text-[#9a3324] font-semibold text-md w-[50%]">
                <FaRegFilePdf className="pr-2" size="30px" />
                <a href="https://www.google.com">Hoja de Seguridad</a>
              </div>
            </div>
          </div>

          <div className="flex-col justify-center items-center w-[90%] sm:w-[60%]">
            <Tabs2 items={items} />
          </div>

          <div className="flex flex-col w-[100%] items-center pr-14">
            <div className="flex flex-row justify-center items-center w-[100%] sm:w-[60%] ">
              <div className="text-right text-lg font-semibold  w-[50%] m-1 py-2 pr-2">
                Presentación:
              </div>
              <div className="text-center text-lg w-[50%] m-1 py-2">
                <select
                  id="selectPresentacion"
                  className="w-[80%] py-2 text-center border-2 border-[#bbbaba]"
                  onChange={(e) => setSelPresentacion(e.target.value) }
                >
                  {presentacion.map((index, element) => (
                    <option key={index} value={presentacion[element]}>
                      {capitalizeFirstLetter(presentacion[element])}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="flex flex-row justify-center items-center w-[80%] sm:w-[60%]">
              <div className="text-right text-lg font-semibold w-[50%] m-1 py-2 pr-2">
                Cantidad:
              </div>
              <div className="text-center text-lg w-[50%] m-1 py-2">

              <input
            className="w-[70%] sm:w-[80%] py-2 text-center border-2 border-[#bbbaba] focus:outline-[#bbbaba] focus:outline-2 focus:outline"
            type="text"
            id="cantidad"
            value={cantidad}
            onChange={(e) => setCantidad(e.target.value)}
            placeholder="50 piezas"
            required
          />

                {/* <select
                  id="selectCantidad"
                  className="w-[80%] py-2 text-center border-2 border-[#bbbaba]"
                  onChange={(e) => setSelCantidad(e.target.value) }
                >
                  {cantidad.map((index, element) => (
                    <option key={index} value={cantidad[element]}>
                      {cantidad[element]}
                    </option>
                  ))}
                </select> */}
              </div>
            </div>
          </div>
          <div className="py-4">
            <button
              className="bg-[#9a3324] text-[#fff] text-center text-xl font-extrabold py-2 px-6 rounded-3xl"
              onClick={() => {
                dispatch(addToCart(productoCotizar));
                alert("El producto ha sido agregado a la solicitud");
              }}
            >
              COTIZAR AHORA
            </button>
          </div>
        </div>

        {/* <div>{JSON.stringify(producto)}</div>
        <div>{JSON.stringify(productoCotizar)}</div> */}
      </div>
    </div>
  );
}

export default Producto;
