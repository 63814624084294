import React from 'react';
import Mapa2 from './Mapa2';
import Mapa3 from './Mapa3';

function Ubicaciones() {
  return (
    <div className="">
    <img src="/banner-saludo.jpg" alt="banner-ubicaciones" className='w-[100%]'/>
    <div className="">
      <h1 className="text-center pt-10 font-semibold text-[#154734] text-5xl">
        Ubicaciones
      </h1>
      <p className="text-lg pt-5 px-10">
        En Centurión, entendemos la importancia de la puntualidad y la
        conveniencia cuando se trata de satisfacer las necesidades de nuestros
        clientes. Es por eso que nos enorgullecemos de contar con 6
        ubicaciones estratégicas a lo largo del país. Te invitamos a visitar cualquiera de
      nuestras ubicaciones para experimentar nuestro compromiso con la
      excelencia y la satisfacción del cliente. Estamos comprometidos en
        brindarte un servicio excepcional, justo cuando lo necesitas. 
      </p>
    </div>
    <div className="flex flex-col items-center sm:flex-row">
      <div className="w-[100%] sm:w-[90%]">
      <Mapa3 />
      </div>
      {/* <div className="sm:w-[20%] w-[100%] sm:text-left text-center font-semibold text-2xl text-[#154734]">
        <ul>
          <li>
            -Nuevo León
          </li>
          <li>
            -Querétaro
          </li>
          <li>
            -Guanajuato
          </li>
          <li>
            -Hidalgo
          </li>
          <li>
            -Jalisco
          </li>
          <li>
            -Ciudad de México
          </li>
        </ul>
      
    </div> */}
    
    </div>
    <div>
   
    </div>
    
  </div>
  )
};

export default Ubicaciones