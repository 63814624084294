import React from "react";
import productosDB from "../database/productosDB";

function Plasticos() {
  const productos = productosDB;
  let division = "Plásticos";

  // const styles = {
  //   titulodivision:
  //     "w-[90%] sm:w-[500px] bg-[#154734] text-[#fff] font-bold mt-16 text-3xl rounded-t-2xl py-4",
  //   box: "min-w-[45%] w-[100%] sm:max-w-[50%]",
  //   categoriaprod: " p-[20px] flex-col justify-center items-center border-t-4",
  //   categoriaprodtitle:
  //     "m-auto w-[40%] text-[#9a3324] font-bold text-2xl text-center pb-2",
  //   listatipoproductos: "m-auto w-[60%] text-left",
  //   listasubproductos: "italic text-lg ",
  //   categoriatipoproductos: "pt-10",
  // };

  const productosDivision = productos.filter((row) => {
    return row.division === division;
  });

  const alfabeto = [];
  productosDivision.forEach((element) => {
    if (!alfabeto.includes(element.linea[0].toLowerCase())) {
      alfabeto.push(element.linea[0].toLowerCase());
    }
  });

  let lineasUnicas = [];
  productosDivision.forEach((element) => {
    if (!lineasUnicas.includes(element.linea)) {
      lineasUnicas.push(element.linea);
    }
  });

  // const productosBien = JSON.stringify(productosLinea);
  // const familiasBien = JSON.stringify(familiasUnicas);

  return (
    <div>
      <div className="flex flex-col justify-center items-center w-[100%]">
        {/* <div>{productosBien}</div>
        <div>{familiasBien}</div> */}
        <h1 className="text-center pt-10 font-semibold text-[#154734] text-4xl">
          {division.toUpperCase()}
        </h1>
        <div className="flex flex-col justify-center items-center sm:w-[90%] w-[100%] sm:max-w-[1500px]">
          {alfabeto.map((letra, index) => (
            <div className="w-[100%] flex flex-col justify-center items-center">
              <div
                className="font-bold w-[100%] border-t-4 border-dashed text-left p-8 mt-4"
                key={index}
              >
                <h1 className="text-4xl text-[#9a3324]">
                  {letra.toUpperCase()}
                </h1>
              </div>
              <div className="flex sm:flex-row flex-col sm:flex-wrap sm:justify-start justify-center items-center sm:items-start sm:w-[90%] w-[100%] sm:max-w-[1800px]">
                {lineasUnicas
                  .filter((lineaPlastico) => {
                    return lineaPlastico[0].toLowerCase() === letra.toLowerCase();
                  })
                  .map((lineaUnica, index) => (
                    <div
                      className="flex flex-col justify-center items-center min-w-[45%] w-[90%] sm:w-[50%] sm:max-w-[950px] pb-4"
                      key={index}
                    >
                      <div className="flex-col flex justify-center items-center">
                        <a href={"/Grado/" + lineaUnica}>
                          <div className="w-[400px] sm:w-[400px] bg-[#154734] text-[#fff] font-bold m-4 text-2xl rounded-t-2xl py-4">
                            {lineaUnica}
                          </div>
                        </a>
                        <div className="mx-4 w-[60%] text-left">
                          <ul className="italic text-xl ">
                            {productosDivision
                              .filter((rowLinea) => {
                                return rowLinea.linea === lineaUnica;
                              })
                              .map((b) => (
                                <li
                                  className="hover:text-[#154734] hover:font-semibold"
                                  key={b.producto_id}
                                >
                                  <a
                                    href={"/Producto?id=" + b.producto_id}
                                  >{`\u2022  ${b.nombre}`}</a>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ))}

          {/* {familiasUnicas.map((familiaUnica, index) => (
            <div className="min-w-[45%] w-[100%] sm:max-w-[750px]" key={index}>
              <div className=" p-[20px] flex-col justify-center items-center border-t-4">
                <div
                  className="w-[90%] sm:w-[500px] bg-[#154734] text-[#fff] font-bold mt-16 text-3xl rounded-t-2xl py-4"
                >{familiaUnica}</div>
                <div className="m-auto w-[60%] text-left">
                  <ul className="italic text-lg ">
                    {productosLinea
                      .filter((rowLinea) => {
                        return rowLinea.familia === familiaUnica;
                      })
                      .map((b) => (
                        <li
                          className="hover:text-[#154734] hover:font-semibold"
                          key={b.producto_id}
                        >
                          <a
                            href={"/Producto?id=" + b.producto_id}
                          >{`\u2022  ${b.nombre}`}</a>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          ))} */}
        </div>
      </div>
    </div>
  );
}

export default Plasticos;
