import React from 'react';
import {
    BsFillEnvelopeAtFill,
    BsFillTelephoneFill
  } from "react-icons/bs";
  import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
  import { FaLocationDot } from "react-icons/fa6";
  import Boletin from './Boletin';

function Footer() {
    const styles = {
        socialIcons:
          "text-[#fff] bg-[#9a3324] px-3 py-3 mx-1 rounded-full hover:bg-[#154734] hover:p-4 ease-in-out duration-300",
      };
  return (
    <footer>
    <div className="w-full h-full shadow-xl bg-gradient-to-b from-[#fff] to-[#d5d6d8] px-4">
      
      <div className="h-full w-full flex flex-col justify-between items-center sm:flex-row">
      <Boletin/>
      <div>
        <img className="w-[300px]" src="/LOGO 25 AÑOS.png" alt="25años"></img>
      </div>
        <div className="flex flex-row items-center justify-items-center py-5 w-[100px]">
          <a
            href="https://www.facebook.com/profile.php?id=100057876390668"
            className={styles.socialIcons}
            rel="noopener noreferrer" target="_blank"
          >
            <FaFacebookF size={20} className="" />
          </a>
          <a
            href="https://www.linkedin.com/company/qpcenturion/"
            className={styles.socialIcons}
            rel="noopener noreferrer" target="_blank"
          >
            <FaLinkedinIn size={20} className="" />
          </a>
        </div>

        <div className="flex flex-row px-4 pt-4">
          <div className="bg-[#999797] h-70 w-1 relative left-5 z-0 rounded-lg"></div>
          <div className="z-10 font-semibold">
            <div className="flex flex-row py-1 items-center">
              <div className="bg-[#9a3324] px-2 py-2">
                <BsFillEnvelopeAtFill size={20} className="text-[#fff]" />
              </div>
              <div className="px-2">ventas@centurion.mx</div>
            </div>

            <div className="flex flex-row py-0 items-center">
              <div className="bg-[#9a3324] px-2 py-2">
                <BsFillTelephoneFill size={20} className="text-[#fff]" />
              </div>
              <div className="px-2">Tel: (55) 51180800</div>
            </div>

            <div className="flex flex-row py-0 items-center">
              <div className="bg-[#9a3324] px-2 py-6">
                <FaLocationDot size={20} className="text-[#fff]" />
              </div>
              <div className="px-2 text-left">
                Av. Insurgentes Norte 1501, int 203,
                <br />
                Tepeyac Insurgentes, Gustavo A. Madero,
                <br />
                Ciudad de México, C.P. 07020
                <br />
              </div>
            </div>
            <div className="pt-2 pb-4 text-left">
            <a href="https://maps.app.goo.gl/1LRDp8v6TpThDpx77"rel="noopener noreferrer" target="_blank">
              <h3 className="pl-10 text-[#9a3324] font-semibold text-xl hover:text-[#154734]">
                Maps
              </h3>
            </a>
          </div>
          </div>

          
        </div>
      </div>
    </div>
  </footer>
  )
}

export default Footer