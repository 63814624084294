import React from "react";
import ProductosCart from "./ProductosCart";
import { useSelector } from "react-redux";
import CotizarForm from "./CotizarForm";
import ContactForm from "./ContactForm";

function Cotizar() {
  const cartItems = useSelector((state) => state.cart.cartItems);


  return (
    <div>
      <div className="text-5xl text-[#154734] font-bold p-6">
        SOLICITUD DE COTIZACIÓN
      </div>

      {/* <div>{JSON.stringify(cartItems)}</div> */}

      <div className="flex flex-col justify-center items-center w-[100%]">
        <div className={`bg-[#154734] flex flex-row w-[90%] sm:w-[60%] items-center justify-between text-[#fff] text-xl font-semibold px-4 ${cartItems.length===0?" hidden":""} `}>
          <div>Producto</div>
          <div>Cantidad</div>
        </div>

        <div className="w-[90%] sm:w-[60%] py-4">
          {cartItems.length === 0 ? (
            <div className="text-lg font-semibold">
              Su lista de compras está vacía, agregue productos a la lista para enviarle una cotización.
            </div>
          ) : (
            <div></div>
          )}
          {cartItems.map((producto, key) => {
            return (
              <ProductosCart
                item={producto}
                key={producto.producto_id}
                no={key}
              />
            );
          })}
        </div>

        <div className={`flex flex-row justify-around w-[90%] sm:w-[60%] ${cartItems.length===0?" pb-4":""} `}>
          <a
            href="/Divisiones"
            className="text-[#fff] bg-[#9a3324] rounded-full text-lg py-2 px-8 m-2 font-semibold"
          >
            Seleccionar más productos
          </a>
          <button
            className={`text-[#fff] bg-[#9a3324] rounded-full text-lg py-2 px-8 m-2 font-semibold ${cartItems.length===0?" hidden":""} `}
            onClick={() => {
              localStorage.clear();
              window.location.reload();
            }}
          >
            Eliminar todos los productos
          </button>
        </div>
      </div>

      <div className={`w-[100%] flex flex-col justify-center items-center ${cartItems.length===0?" hidden":""} `}>
        <div className="bg-[#154734] flex w-[90%] sm:w-[60%] items-center text-[#fff] text-xl font-semibold px-4 mt-8">Datos de Contacto</div>
        <div className="w-[90%] sm:w-[60%] flex px-4 mt-4 font-medium">Favor proporcionarnos los siguientes datos para el envío de su cotización</div>
        <div className="w-[90%] sm:w-[60%] py-4">
          <ContactForm />
        </div>
      </div>
    </div>
  );
}

export default Cotizar;
