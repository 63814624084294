import React from "react";
import productosDB from "../database/productosDB";

function Divisiones2() {
  let unique = [];
  productosDB.forEach((element) => {
    if (!unique.includes(element.division)) {
      unique.push(element.division);
    }
  });
  const img_matrix = {
    Químicos: "circulo_quimicos.png",
    Plásticos: "circulo_plasticos.png",
  };

  return (
    <div>
      <div className="w-100 relative">
        <img
          src="/banner-planta.jpg"
          alt="banner-divisiones"
          className="w-[100%]"
        />
      </div>
      <h1 className="text-center pt-10 font-semibold text-[#154734] text-5xl">
        DIVISIONES
      </h1>
      {/* <div>{JSON.stringify(unique)}</div> */}

      <div className="flex flex-col sm:flex-row m-4 items-center justify-center">
        <a href={`/Lineas/${unique[0]}`} className="flex flex-col my-4 mx-20">
          <div className="flex flex-col justify-center items-center">
            <div className="w-[400px] rounded-full m-2">
              <img
                src={`/${img_matrix[unique[0]]}`}
                alt="circulo_químicos"
                className="w-[100%]"
              />
            </div>
            <div className="w-[300px] text-[#154734] bg-[#c8c6c6] rounded-full font-bold text-4xl py-3 m-2 hover:text-[#fff] hover:bg-[#9a3324]">
              {unique[0]}
            </div>
          </div>
        </a>

        <div className="h-[500px] w-[6px] bg-[#EDEDED] rounded-xl mx-10 sm:block hidden"></div>

        <a href="/Lineas/Plásticos" className="flex flex-col my-4 mx-20">
          <div className="flex flex-col justify-center items-center">
            <div className="w-[400px] rounded-full m-2">
              <img
                src={`/${img_matrix["Plásticos"]}`}
                alt="circulo plasticos"
                className="w-[100%]"
              />
            </div>
            <div className="w-[300px] text-[#fff] bg-[#9a3324] rounded-full font-bold text-4xl py-3 m-2 hover:text-[#154734] hover:bg-[#c8c6c6]">
              Plásticos
            </div>
          </div>
        </a>

        {/* {unique.map((item, index) => (
          
             <a
             className='border border-[#e44141] bg-[#e66566] rounded-xl p-4'
             href={`/Lineas/${item}`}
             // ref={index === 0 ? firstBtnRef : null}
             key={index}
            >
              {item}
              
              </a>
           
         
        ))} */}
      </div>
      {/* <div>{JSON.stringify(productosDB)}</div> */}
    </div>
  );
}

export default Divisiones2;
